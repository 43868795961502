import React, { memo, useState, useEffect, ReactNode } from 'react'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import { Theme, makeStyles, Menu } from '../../core'
import { DownArrowIcon } from '../../icons'
import { boxShadowLevels, brandColors } from '../../theme'
import { useMenuToggle } from '../../utils/hooks/useMenuToggle'
import Spinner from '../Spinner'
import { FunctionalTagOption } from './components/FunctionalTagOption'
import { TAG_STYLE_COLORS } from './constants'
import { IFunctionalTagOption, TAG_STYLE, TOptionValue, TAG_SIZE } from './types'

export enum FUNCTIONAL_TAG_STYLE {
  success = 'success',
  error = 'error',
  info = 'info',
}

const useFunctionalTagStyles = makeStyles<
  Theme,
  {
    tagStyle: TAG_STYLE
    size: TAG_SIZE
    required: boolean
    disabled: boolean
  }
>(theme => ({
  root: {
    cursor: 'pointer',
    display: 'inline-block',
    userSelect: 'none',
  },
  trigger: {
    alignItems: 'center',
    borderRadius: theme.spacing(5.5),
    display: 'inline-flex',
    fontWeight: 'bold',
  },
  triggerLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  legend: {
    border: 0,
    lineHeight: 1.3,
    margin: 0,
    padding: 0,
    textTransform: 'none',
    width: '100%',
    fontSize: '12px',
    marginBottom: '4px',
    '&::after': {
      content: ({ required }) => (required ? '"*"' : ''),
      position: 'relative',
      paddingLeft: '2px',
    },
  },
  tagSize: ({ size }) => {
    if (size === TAG_SIZE.xsmall) {
      return {
        fontSize: '12px',
        lineHeight: '12px',
        height: '24px',
        padding: theme.spacing(0, 0.75),
        '& svg, & .spinner': {
          height: theme.spacing(1.5),
          width: theme.spacing(1.5),
          marginLeft: theme.spacing(0.75),
        },
      }
    }
    if (size === TAG_SIZE.small) {
      return {
        fontSize: '14px',
        lineHeight: '14px',
        height: '32px',
        padding: theme.spacing(0, 1.25),
        '& svg, & .spinner': {
          height: theme.spacing(1.75),
          width: theme.spacing(1.75),
          marginLeft: theme.spacing(1.75),
        },
      }
    }
    if (size === TAG_SIZE.medium) {
      return {
        fontSize: '16px',
        lineHeight: '16px',
        height: '40px',
        padding: theme.spacing(0, 1.25),
        '& svg, & .spinner': {
          height: theme.spacing(2),
          width: theme.spacing(2),
          marginLeft: theme.spacing(1.25),
        },
      }
    }
    return {
      fontSize: '20px',
      lineHeight: '20px',
      height: '44px',
      padding: theme.spacing(0, 1.5),
      '& svg, & .spinner': {
        height: theme.spacing(2.5),
        width: theme.spacing(2.5),
        marginLeft: theme.spacing(1.5),
      },
    }
  },
  tagStyle: ({ disabled, tagStyle }) => ({
    ...TAG_STYLE_COLORS[tagStyle],
    background: TAG_STYLE_COLORS[tagStyle].background,
    color: TAG_STYLE_COLORS[tagStyle].text,
    border: `2px solid ${TAG_STYLE_COLORS[tagStyle].background}`,
    ...(!disabled && {
      '&:hover, &:focus, &:active': {
        border: `2px solid ${TAG_STYLE_COLORS[tagStyle].text}`,
        backgroundColor: TAG_STYLE_COLORS[tagStyle].text,
        color: brandColors.white,
      },
    }),
  }),
  tagOutline: ({ disabled, tagStyle }) => ({
    backgroundColor: brandColors.white,
    borderColor: TAG_STYLE_COLORS[tagStyle].background,
    color:
      tagStyle === TAG_STYLE.errorDark
        ? TAG_STYLE_COLORS[tagStyle].background
        : TAG_STYLE_COLORS[tagStyle].text,
    ...(!disabled && {
      '&:hover, &:focus, &:active': {
        backgroundColor: TAG_STYLE_COLORS[tagStyle].background,
        color: TAG_STYLE_COLORS[tagStyle].text,
      },
      '&:focus': {
        borderColor: brandColors.skyBlue2,
        boxShadow: `inset 0 0 0 1px ${brandColors.skyBlue2}`,
        outline: 'none',
      },
    }),
  }),
  menu: {
    minWidth: '220px !important',
    maxWidth: '440px !important',
    boxShadow: boxShadowLevels.high,
    backgroundColor: brandColors.white,
    border: `1px solid ${brandColors.coolGray3}`,
    borderRadius: '2px',
    color: brandColors.coolGray8,
    display: 'block',
    height: 'auto',
    marginTop: theme.spacing(1),
    maxHeight: '228px',
    overflowY: 'auto',
    padding: 0,
  },
  itemContainer: {
    backgroundColor: brandColors.white,
    '&:hover': {
      backgroundColor: brandColors.coolGray1,
    },
    padding: '4px 0',
    margin: '1px 0',
  },
}))

export interface IFunctionalTagProps<T extends TOptionValue = string> {
  id?: string
  size?: TAG_SIZE
  value: T
  options: IFunctionalTagOption<T>[]
  isLoading?: boolean
  dataTest?: string
  onSelect?: (selectedOption: IFunctionalTagOption<T>) => void
  /**
   * @default default
   */
  renderType?: 'default' | 'withDescription'
  label?: ReactNode
  /**
   * @default false
   */
  required?: boolean
  disabled?: boolean
}

export const FunctionalTag = memo<IFunctionalTagProps>(
  ({
    id,
    size = TAG_SIZE.medium,
    value,
    options,
    isLoading,
    onSelect,
    dataTest = 'functional-tag',
    label,
    required = false,
    renderType = 'default',
    disabled = false,
  }) => {
    const {
      anchorEl,
      expanded: open,
      handleClick: openFilter,
      handleClose: closeFilter,
    } = useMenuToggle()

    const [selectedOption, setSelectedOption] = useState<IFunctionalTagOption>(
      options.find(o => o.value === value) || options[0]
    )
    const styles = useFunctionalTagStyles({
      tagStyle: selectedOption?.style || TAG_STYLE.info,
      size,
      required,
      disabled,
    })

    useEffect(() => {
      setSelectedOption(options.find(o => o.value === value) || options[0])
    }, [value, options])

    const optionSelectedHandler = (option: IFunctionalTagOption) => {
      if (onSelect) {
        onSelect(option)
      }
      closeFilter()
    }

    const triggerClasses = classNames(styles.trigger, styles.tagStyle, styles.tagSize, {
      [styles.tagOutline]: selectedOption?.outlineOnly || false,
    })

    return (
      <span className={styles.root} data-test={dataTest}>
        {label && <legend className={styles.legend}>{label}</legend>}
        <button
          id={id}
          aria-haspopup='menu'
          aria-expanded={!!open}
          aria-controls={open ? 'basic-menu' : undefined}
          className={triggerClasses}
          disabled={disabled}
          onClick={openFilter}>
          <span className={styles.triggerLabel} data-test={`${dataTest}-label`}>
            {selectedOption?.label || 'Invalid Value'}
          </span>
          {isLoading ? <Spinner position='relative' /> : null}
          {!isLoading && !disabled ? <DownArrowIcon /> : null}
        </button>
        <Menu
          data-test={`${dataTest}-menu`}
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={closeFilter}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          slotProps={{
            paper: {
              className: styles.menu,
            },
          }}>
          {options.map((option, i) => (
            <FunctionalTagOption
              key={`dpl-functional-tag-listbox-item-${option.value}-${uuid()}`}
              size={size}
              option={option}
              onOptionSelected={optionSelectedHandler}
              dataTest={`${dataTest}-listbox-${option.value}`}
              showDescription={renderType === 'withDescription'}
              autoFocus={i === 0} // autofocus first element in menu
            />
          ))}
        </Menu>
      </span>
    )
  }
)

export default FunctionalTag
