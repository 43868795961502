import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation'
import { TYPOGRAPHY_THEME_OPTIONS } from '../typography'
import { calcRem } from '../utils'

export const MuiTimePickerToolbarThemeOptions: PickerComponents['MuiTimePickerToolbar'] = {
  styleOverrides: {
    root: {
      '& > span': {
        ...TYPOGRAPHY_THEME_OPTIONS.caption,
        textTransform: 'capitalize',
        fontSize: calcRem(12),
      },
      '& .MuiPickersToolbar-content': {
        fontWeight: 500,
        '& .MuiTimePickerToolbar-hourMinuteLabel': {
          '& span': {
            ...TYPOGRAPHY_THEME_OPTIONS.h3,
          },
        },
      },
    },
  },
}
