import moment, { Moment } from 'moment-timezone'
import { AllowedTimezones } from '../../../constants/allowedTimezones'
import FORMATS from '../../../constants/datetime'
import { isTimeOnTheHour } from '../../RelativeDateTime/utils'
import { TDatetimeFormat } from '../types'

export interface IDatetimeFormatterArgs {
  /**
   * A string in ISO 8601 format containing the time you want to display.
   * This value can be generating using `moment(...).format()`
   * It will be parsed in the given `displayTimezone` |  so if the string
   * includes an offset, the time value will be automatically offset to the
   * given `displayTimezone`.
   *
   * @example '2011-10-05T14:48:00.000Z'
   * @example '2011-10-05T14:48:00.000-0400'
   */
  datetime: string
  /**
   * A string value indicating the timezone in which you want to display the
   * given time. This is a required field, that should either come from the
   * backend or generated using `moment.tz.guess()`.
   *
   * @exmaple America/New_York
   */
  displayTimezone?: AllowedTimezones
  /**
   * The format you wish to display the given datetime in – this should map to
   * an available format in the datetimes guidelines in figma.
   */
  format: TDatetimeFormat
  /**
   * Set to true when formatting the given datetime within a sentence.
   */
  inSentence?: boolean
  /**
   * Set to true if you want to append the timezone to the end of the formatted
   * string.
   */
  showTimezone?: boolean
  /**
   * When set to true, enables the abbreviated format
   */
  abbreviated?: boolean
  /**
   * Set to true if you want to shorten the time when on the hour.
   * @example '9am'
   */
  timeShort?: boolean
  /**
   * If true, 'am' and 'pm' is displayed 'AM' and 'PM'
   */
  isUppercaseAmPm?: boolean
  /**
   * Set to true if the action occurred in the past, showing a prefix `on`.
   * If false, indicates the action is still valid, showing a prefix `since`.
   */
  isPastAction?: boolean
}

export const optionalAppendedTimezone = (baseFormat: string, showTimezone?: boolean): string => {
  return `${baseFormat}${showTimezone ? ' z' : ''}`
}

export const datetimeFormatter = ({
  abbreviated,
  datetime,
  displayTimezone = moment.tz.guess(),
  format,
  inSentence,
  showTimezone,
  timeShort,
  isPastAction,
}: IDatetimeFormatterArgs): string => {
  // moment.calendarFormat allows us to customize how the Moment#calendar
  // function evaluates it's keys: https://momentjs.com/docs/#/customization/calendar-format/
  moment.calendarFormat = (myMoment: Moment, now: Moment): string => {
    const yesterday = now.clone().subtract(1, 'days').startOf('day')
    const isToday = myMoment.isSame(now, 'days')
    const isLastDay = myMoment.isSame(yesterday, 'days')
    const isThisYear = myMoment.isSame(now, 'years')

    if (isToday) {
      return 'sameDay'
    }

    if (isLastDay) {
      return 'lastDay'
    }

    if (isThisYear) {
      return 'sameYear'
    }

    return 'sameElse'
  }

  const momentDatetime = moment.tz(datetime, displayTimezone)
  const shouldShortenTime = isTimeOnTheHour(momentDatetime) && timeShort
  const timeFromNow = momentDatetime
    .fromNow()
    .replace('hours', 'hr')
    .replace('a day ago', '1 day ago')

  switch (format) {
    case 'pastOrOngoing': {
      const prefix = isPastAction ? 'on' : 'since'
      const calendarOption = {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        sameYear: FORMATS.monthDayYear,
        sameElse: FORMATS.monthDayYear,
      }

      const formattedDate = momentDatetime.calendar(undefined, calendarOption)

      if (formattedDate !== 'Today' && formattedDate !== 'Yesterday') {
        return `${prefix} ${formattedDate}`
      }

      return formattedDate
    }

    case 'time': {
      const momentMilitaryTimeFormatShort12H = shouldShortenTime
        ? FORMATS.militaryTimeFormatShortHour12H
        : FORMATS.militaryTimeFormatShort12H
      return momentDatetime.format(
        optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)
      )
    }

    case 'T24HourClock': {
      return momentDatetime.format(
        optionalAppendedTimezone(FORMATS.militaryTimeFormat, showTimezone)
      )
    }

    case 'dateAndYear': {
      return momentDatetime.calendar(undefined, {
        sameDay: '[Today]',
        sameYear: abbreviated ? FORMATS.dayShort : FORMATS.monthDay,
        sameElse: abbreviated ? FORMATS.singleDateFormat : FORMATS.readableFormat,
      })
    }

    case 'dateAndYearAlways': {
      return momentDatetime.calendar(undefined, {
        sameDay: '[Today]',
        sameYear: FORMATS.monthDayYear,
        sameElse: FORMATS.monthDayYear,
      })
    }

    case 'dateAndYearFullDateAlways': {
      return momentDatetime.calendar(undefined, {
        sameDay: FORMATS.singleDateFormat,
        lastDay: FORMATS.singleDateFormat,
        sameYear: FORMATS.singleDateFormat,
        sameElse: FORMATS.singleDateFormat,
      })
    }

    case 'weekdayDateAndYear': {
      return momentDatetime.calendar(undefined, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        sameYear: abbreviated ? FORMATS.dayFormatShort : FORMATS.dayMonth,
        sameElse: abbreviated ? FORMATS.dayFormatYear : FORMATS.dayFormatFull,
      })
    }

    case 'timeDateAndYear': {
      const momentMilitaryTimeFormatShort12H = shouldShortenTime
        ? FORMATS.militaryTimeFormatShortHour12H
        : FORMATS.militaryTimeFormatShort12H

      const momentDatetime12HShortNoTimezoneFormat = shouldShortenTime
        ? FORMATS.datetime12HShortHourNoTimezone
        : FORMATS.datetime12HShortNoTimezone

      const momentReadableDateTimeFormat = shouldShortenTime
        ? FORMATS.readableDateTimeShortHourFormat
        : FORMATS.readableDateTimeFormat

      const momentDatetime12HInputFormat = shouldShortenTime
        ? FORMATS.datetime12HshortHourInputFormat
        : FORMATS.datetime12HInputFormat

      const momentReadableDateTimeFormatWithYear = shouldShortenTime
        ? FORMATS.readableDateTimeShortHourFormatWithYear
        : FORMATS.readableDateTimeFormatWithYear

      const calendarOption = inSentence
        ? {
            sameDay: `[Today at] ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
            sameYear: `${abbreviated ? FORMATS.dayShort : FORMATS.monthDay} [at] ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
            sameElse: `${abbreviated ? FORMATS.singleDateFormat : FORMATS.readableFormat} [at] ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
          }
        : {
            sameDay: `[Today], ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
            sameYear: optionalAppendedTimezone(
              abbreviated ? momentDatetime12HShortNoTimezoneFormat : momentReadableDateTimeFormat,
              showTimezone
            ),
            sameElse: optionalAppendedTimezone(
              abbreviated ? momentDatetime12HInputFormat : momentReadableDateTimeFormatWithYear,
              showTimezone
            ),
          }

      return momentDatetime.calendar(undefined, calendarOption)
    }

    case 'T24HourClockWeekdayDateAndYear': {
      const momentMilitaryTimeFormat = FORMATS.militaryTimeFormat
      const calendarOption = inSentence
        ? {
            sameDay: `[Today at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormat,
              showTimezone
            )}`,
            lastDay: `[Yesterday at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormat,
              showTimezone
            )}`,
            sameYear: `${
              abbreviated ? FORMATS.dayFormatShort : FORMATS.dayMonth
            } [at] ${optionalAppendedTimezone(momentMilitaryTimeFormat, showTimezone)}`,
            sameElse: `${
              abbreviated ? FORMATS.dayFormatYear : FORMATS.dayFormatFull
            } [at] ${optionalAppendedTimezone(momentMilitaryTimeFormat, showTimezone)}`,
          }
        : {
            sameDay: `[Today], ${optionalAppendedTimezone(momentMilitaryTimeFormat, showTimezone)}`,
            lastDay: `[Yesterday], ${optionalAppendedTimezone(
              momentMilitaryTimeFormat,
              showTimezone
            )}`,
            sameYear: `${
              abbreviated ? FORMATS.dayFormatShort : FORMATS.dayMonth
            }, ${optionalAppendedTimezone(momentMilitaryTimeFormat, showTimezone)}`,
            sameElse: `${
              abbreviated ? FORMATS.dayFormatYear : FORMATS.dayFormatFull
            }, ${optionalAppendedTimezone(momentMilitaryTimeFormat, showTimezone)}`,
          }

      return momentDatetime.calendar(undefined, calendarOption)
    }
    case 'timeWeekdayDateAndYear': {
      const momentMilitaryTimeFormatShort12H = shouldShortenTime
        ? FORMATS.militaryTimeFormatShortHour12H
        : FORMATS.militaryTimeFormatShort12H
      const calendarOption = inSentence
        ? {
            sameDay: `[Today at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12H,
              showTimezone
            )}`,
            sameYear: `${
              abbreviated ? FORMATS.dayFormatShort : FORMATS.dayMonth
            } [at] ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
            sameElse: `${
              abbreviated ? FORMATS.dayFormatYear : FORMATS.dayFormatFull
            } [at] ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
          }
        : {
            sameDay: `[Today], ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12H,
              showTimezone
            )}`,
            sameYear: `${
              abbreviated ? FORMATS.dayFormatShort : FORMATS.dayMonth
            }, ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
            sameElse: `${
              abbreviated ? FORMATS.dayFormatYear : FORMATS.dayFormatFull
            }, ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
          }

      return momentDatetime.calendar(undefined, calendarOption)
    }
    case 'T24TimeWeekdayDateAndYear': {
      const momentMilitaryTimeFormatShort12H = shouldShortenTime
        ? FORMATS.militaryTimeFormatShort
        : FORMATS.militaryTimeFormat
      const calendarOption = inSentence
        ? {
            sameDay: `[Today at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12H,
              showTimezone
            )}`,
            sameYear: `${
              abbreviated ? FORMATS.dayFormatShort : FORMATS.dayMonth
            } [at] ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
            sameElse: `${
              abbreviated ? FORMATS.dayFormatYear : FORMATS.dayFormatFull
            } [at] ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
            lastDay: `[Yesterday at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12H,
              showTimezone
            )}`,
          }
        : {
            sameDay: `[Today], ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12H,
              showTimezone
            )}`,
            sameYear: `${
              abbreviated ? FORMATS.dayFormatShort : FORMATS.dayMonth
            }, ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
            sameElse: `${
              abbreviated ? FORMATS.dayFormatYear : FORMATS.dayFormatFull
            }, ${optionalAppendedTimezone(momentMilitaryTimeFormatShort12H, showTimezone)}`,
            lastDay: `[Yesterday at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12H,
              showTimezone
            )}`,
          }

      return momentDatetime.calendar(undefined, calendarOption)
    }
    case 'timeDateAndYearSecondary': {
      const momentMilitaryTimeFormatShort12HUpperCase = shouldShortenTime
        ? FORMATS.militaryTimeFormatShortHour12HUpperCase
        : FORMATS.militaryTimeFormatShort12HUpperCase

      const calendarOption = inSentence
        ? {
            sameDay: `[${timeFromNow}] [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            lastDay: `[${timeFromNow}] [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            sameYear: `[on] ${optionalAppendedTimezone(
              FORMATS.singleDateFormat,
              showTimezone
            )} [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            sameElse: `[on] ${optionalAppendedTimezone(
              FORMATS.singleDateFormat,
              showTimezone
            )} [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
          }
        : {
            sameDay: `[Today], ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            sameYear: optionalAppendedTimezone(FORMATS.singleDateFormat, showTimezone),
            sameElse: optionalAppendedTimezone(FORMATS.singleDateFormat, showTimezone),
          }

      return momentDatetime.calendar(undefined, calendarOption)
    }
    case 'T24TimeDateAndYearSecondary': {
      const momentMilitaryTimeFormatShort12HUpperCase = FORMATS.militaryTimeFormat

      const calendarOption = inSentence
        ? {
            sameDay: `[${timeFromNow}] [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            nextDay: `[Tomorrow] [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            nextWeek: `[on] ${optionalAppendedTimezone(
              FORMATS.singleDateFormat,
              showTimezone
            )} [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            lastDay: `[${timeFromNow}] [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            sameYear: `[on] ${optionalAppendedTimezone(
              FORMATS.singleDateFormat,
              showTimezone
            )} [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            sameElse: `[on] ${optionalAppendedTimezone(
              FORMATS.singleDateFormat,
              showTimezone
            )} [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
          }
        : {
            sameDay: `[Today], ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            nextDay: `[Tomorrow] [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            nextWeek: `[on] ${optionalAppendedTimezone(
              FORMATS.singleDateFormat,
              showTimezone
            )} [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            lastDay: `[${timeFromNow}] [at] ${optionalAppendedTimezone(
              momentMilitaryTimeFormatShort12HUpperCase,
              showTimezone
            )}`,
            sameYear: optionalAppendedTimezone(FORMATS.singleDateFormat, showTimezone),
            sameElse: optionalAppendedTimezone(FORMATS.singleDateFormat, showTimezone),
          }

      return momentDatetime.calendar(undefined, calendarOption)
    }
    case 'weekdayDateYearTime': {
      const momentMilitaryTimeFormatShort12H = shouldShortenTime
        ? FORMATS.militaryTimeFormatShortHour12H
        : FORMATS.militaryTimeFormatShort12H
      const calendarOption = {
        sameDay: `[Today], ${optionalAppendedTimezone(
          momentMilitaryTimeFormatShort12H,
          showTimezone
        )}`,
        sameYear: `${FORMATS.dayFormatFull}, ${optionalAppendedTimezone(
          momentMilitaryTimeFormatShort12H,
          showTimezone
        )}`,
        sameElse: `${FORMATS.dayFormatFull}, ${optionalAppendedTimezone(
          momentMilitaryTimeFormatShort12H,
          showTimezone
        )}`,
      }

      return momentDatetime.calendar(undefined, calendarOption)
    }
    default:
      return momentDatetime.toISOString()
  }
}
