import React from 'react'
import { PickersActionBarProps } from '@mui/x-date-pickers'
import classNames from 'classnames'
import { makeStyles } from '../../../core'
import { IModalAction } from '../../Modals'
import { ModalFooter } from '../../Modals/ModalBase/ModalFooter'

interface IDatePickerActionBarProps extends PickersActionBarProps {}

const useDatePickerActionBarStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogActions-root': {
      justifyContent: 'flex-end',
      padding: theme.spacing(1),
      '& > button': {
        flex: 0,
      },
    },
  },
}))

export function DatePickerActionBar({
  actions: actionsProp = [],
  className,
  onAccept,
  onCancel,
  ...props
}: IDatePickerActionBarProps) {
  const classes = useDatePickerActionBarStyles()
  const rootClassNames = classNames(classes.root, className)
  const actions: IModalAction[] = actionsProp.map(action => {
    if (action === 'cancel') {
      return {
        label: 'Cancel',
        action: onCancel,
        ButtonProps: {
          variant: 'text',
        },
      }
    }
    return {
      label: 'OK',
      action: onAccept,
      ButtonProps: {
        variant: 'text',
      },
    }
  })

  if (!actions?.length) return null

  return <ModalFooter actions={actions} className={rootClassNames} {...props} />
}
