import React, { MouseEventHandler, useCallback } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { FORMATS } from '../../constants/datetime'
import { useToggle } from '../../utils/useToggle'
import { formatDateTimeRange } from '../FormattedDateTimeRange'
import { DateRangeCalendarOption } from './components/DateRangeCalendarOption'
import { DateRangeNamedOption } from './components/DateRangeNamedOption'
import { DateRangePickerButton } from './components/DateRangePickerLabel'
import { useDateRangePickerStyles } from './hooks/useDateRangePickerStyles'
import { IDateRangePickerOption } from './types'

const DATE_RANGE_DEFAULT_OPTIONS: IDateRangePickerProps['options'] = [
  {
    key: 'custom',
    label: 'Select range',
    type: 'calendar',
  },
  {
    key: 'last_week',
    label: 'Last week',
    type: 'namedRange',
    dateRange: {
      startAt: moment().subtract(1, 'week').startOf('week').format(FORMATS.yearFirst),
      endAt: moment().subtract(1, 'week').endOf('week').format(FORMATS.yearFirst),
    },
  },
  {
    key: 'yesterday',
    label: 'Yesterday',
    type: 'namedRange',
    dateRange: {
      startAt: moment().subtract(1, 'days').format(FORMATS.yearFirst),
    },
  },
  {
    key: 'today',
    label: 'Today',
    type: 'namedRange',
    dateRange: {
      startAt: moment().format(FORMATS.yearFirst),
    },
  },
  {
    key: 'tomorrow',
    label: 'Tomorrow',
    type: 'namedRange',
    dateRange: {
      startAt: moment().add(1, 'days').format(FORMATS.yearFirst),
    },
  },
  {
    key: 'this_week',
    label: 'This week',
    type: 'namedRange',
    dateRange: {
      startAt: moment().startOf('week').format(FORMATS.yearFirst),
      endAt: moment().endOf('week').format(FORMATS.yearFirst),
    },
  },
  {
    key: 'next_week',
    label: 'Next week',
    type: 'namedRange',
    dateRange: {
      startAt: moment().add(1, 'week').startOf('week').format(FORMATS.yearFirst),
      endAt: moment().add(1, 'week').endOf('week').format(FORMATS.yearFirst),
    },
  },
]

export interface IDateRangePickerProps {
  options?: IDateRangePickerOption[]
  label: string
  onSelect: (value: IDateRangePickerOption | null) => void
  /**
   * @default false
   */
  isOpen?: boolean
  /**
   * @default null
   */
  value: IDateRangePickerOption | null
  dataTest?: string
}

const getCalendarButtonLabel = (dateRange: { startAt: string; endAt?: string } | undefined) => {
  const { endAt, startAt } = dateRange || {}

  if (startAt && endAt) {
    return formatDateTimeRange({
      startAt,
      endAt,
      endAtTimezone: moment.tz.guess(),
      startAtTimezone: moment.tz.guess(),
      format: 'dateAndYear',
    })
  }

  return null
}

export function DateRangePicker({
  dataTest = 'DateRangePicker',
  isOpen: isOpenProp = false,
  label,
  onSelect,
  options = DATE_RANGE_DEFAULT_OPTIONS,
  value: selectedOption,
}: IDateRangePickerProps) {
  const [isOpen, toggle, wrapperRef] = useToggle<HTMLDivElement>(isOpenProp)

  const hasOptions = options && options?.length > 0
  const hasSelectedOption = Boolean(selectedOption?.key)

  const classes = useDateRangePickerStyles()
  const triggerButtonIsOpen = isOpen || hasSelectedOption
  const triggerButtonClasses = classNames(classes.triggerButton, {
    [classes.triggerButtonOpen]: triggerButtonIsOpen,
    [classes.triggerButtonClose]: !triggerButtonIsOpen,
  })

  const selectHandler = useCallback(
    option => {
      onSelect(option)
      toggle()
    },
    [onSelect, toggle]
  )

  const clearSelectionHandler: MouseEventHandler<HTMLElement> = useCallback(
    event => {
      event.stopPropagation()
      onSelect(null)
      if (isOpen) {
        toggle()
      }
    },
    [isOpen, onSelect, toggle]
  )

  const buttonLabel =
    selectedOption?.type === 'calendar'
      ? getCalendarButtonLabel(selectedOption?.dateRange)
      : selectedOption?.label

  return (
    <div className={classes.root} ref={wrapperRef} data-test={dataTest}>
      <DateRangePickerButton
        label={buttonLabel || label}
        className={triggerButtonClasses}
        onClear={clearSelectionHandler}
        onClick={toggle}
        showClearAction={hasSelectedOption}
      />
      {isOpen && hasOptions && (
        <div className={classes.optionsWrapper} data-test={`${dataTest}-options`}>
          {options?.length &&
            options.map(option => {
              const DateRangePickerOption =
                option.type === 'calendar' ? DateRangeCalendarOption : DateRangeNamedOption

              return (
                <DateRangePickerOption
                  option={option}
                  selectedOption={selectedOption}
                  parentRef={wrapperRef}
                  onSelectOption={selectHandler}
                  key={option.key}
                />
              )
            })}
        </div>
      )}
    </div>
  )
}

export default DateRangePicker
