import React, { memo } from 'react'
import classnames from 'classnames'
import { Skeleton, Theme, makeStyles } from '../../core'
import { brandColors, theme } from '../../theme'
import { TAG_STYLE_COLORS } from './constants'
import { TAG_SIZE, TAG_STYLE } from './types'

export interface ITagProps {
  label: string | JSX.Element
  icon?: JSX.Element
  size?: TAG_SIZE
  tagStyle?: TAG_STYLE
  textStyle?: string
  dataTooltip?: string
  outlineOnly?: boolean
  className?: string
  dataTest?: string
  /**
   * Set to true to render a Skeleton component in place of the tag. Indicates
   * that the tag is waiting to receive some data before rendering.
   */
  loading?: boolean
  style?: {
    [key: string]: string
  }
}

const useTagStyles = makeStyles<Theme, { tagStyle: TAG_STYLE; size: TAG_SIZE }>(() => ({
  tag: {
    alignItems: 'center',
    borderRadius: theme.spacing(5.5),
    display: 'inline-flex',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  tagSize: ({ size }) => {
    if (size === TAG_SIZE.xsmall) {
      return {
        fontSize: '12px',
        height: theme.spacing(3),
        padding: theme.spacing(0, 0.75),
      }
    }
    if (size === TAG_SIZE.small) {
      return {
        fontSize: '14px',
        height: theme.spacing(4),
        padding: theme.spacing(0, 1.25),
      }
    }
    if (size === TAG_SIZE.medium) {
      return {
        fontSize: '16px',
        height: theme.spacing(5),
        padding: theme.spacing(0, 1.25),
      }
    }
    return {
      fontSize: '20px',
      height: theme.spacing(5.5),
      padding: theme.spacing(0, 1.5),
    }
  },
  tagStyle: ({ tagStyle }) => ({
    background: TAG_STYLE_COLORS[tagStyle].background,
    color: TAG_STYLE_COLORS[tagStyle].text,
    border: `2px solid ${TAG_STYLE_COLORS[tagStyle].background}`,
    '&:hover, &:focus, &:active': {
      border: `2px solid ${TAG_STYLE_COLORS[tagStyle].background}`,
    },
  }),
  tagOutline: ({ tagStyle }) => ({
    backgroundColor: brandColors.white,
    borderColor: TAG_STYLE_COLORS[tagStyle].background,
    color:
      tagStyle === TAG_STYLE.errorDark
        ? TAG_STYLE_COLORS[tagStyle].background
        : TAG_STYLE_COLORS[tagStyle].text,
  }),
  iconFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  iconLabel: {
    marginLeft: theme.spacing(1),
  },
}))

export const Tag = memo<ITagProps>(
  ({
    size = TAG_SIZE.medium,
    tagStyle = TAG_STYLE.dark,
    className = '',
    dataTest = '',
    textStyle = '',
    label,
    icon,
    dataTooltip,
    outlineOnly,
    style,
    loading,
  }) => {
    const classes = useTagStyles({ tagStyle, size })
    const tagClassNames = classnames(className, classes.tag, classes.tagStyle, classes.tagSize, {
      [classes.tagOutline]: outlineOnly,
    })
    const iconLabelClassNames = classnames(classes.iconLabel, textStyle)

    return loading ? (
      <Skeleton className={tagClassNames} variant='rectangular' height={32} width={100} />
    ) : (
      <span className={tagClassNames} style={style} data-tooltip={dataTooltip} data-test={dataTest}>
        {icon ? (
          <span className={classes.iconFlex}>
            <span className={classes.iconFlex}>{icon}</span>
            {label && <span className={iconLabelClassNames}>{label}</span>}
          </span>
        ) : (
          <span className={textStyle}>{label}</span>
        )}
      </span>
    )
  }
)

export default Tag
