import {
  useQueryParams as _useQueryParams,
  QueryParamConfigMap,
  QueryParamOptions,
  SetQuery,
} from 'use-query-params'

export const useQueryParams = <TParams extends Record<string, any>>(
  paramConfigMap: QueryParamConfigMap,
  options?: QueryParamOptions
): [TParams, SetQuery<{ [key in keyof TParams]: any }>] => {
  /**
   * use-query-params returns an indexable type as the _filters return value
   * which doesn't allow for accessing properties via `filters.property`.
   * Type-casting fixes that problem for now.
   */
  const [_filters, _setFilters] = _useQueryParams(paramConfigMap, options)

  const filters = _filters as TParams
  const setFilters = _setFilters as SetQuery<{ [key in keyof TParams]: any }>

  return [filters, setFilters]
}
