import React, { useCallback, useState } from 'react'
import { Avatar } from 'dpl'
import Autocomplete, { IAutocompleteProps } from 'dpl/components/Autocomplete'
import { Box, Typography } from 'dpl/core'
import { EmailIcon } from 'dpl/icons'
import compact from 'lodash/compact'
import { IOrganizationUserStatus } from '../../../../../../types/graphqlTypes'
import { IMemberFragment } from './graphql/MemberFragment'
import { useSearchMembersQuery } from './graphql/SearchMembers'
import { isValidEmail } from './utils'

export interface IValue extends Omit<IMemberFragment, 'status'> {
  status: IOrganizationUserStatus | 'new'
  error?: string
}
export type TFreeSolo = true

interface IAutocompleteBaseProps extends IAutocompleteProps<IValue, TFreeSolo> {}

export interface IAutocompleteOrganizationUserProps
  extends Omit<
    IAutocompleteBaseProps,
    | 'freeSolo'
    | 'options'
    | 'loading'
    | 'inputValue'
    | 'onInputChange'
    | 'isOptionEqualToValue'
    | 'getOptionLabel'
    | 'renderOption'
    | 'renderStartAdornment'
  > {
  /**
   * @default 'AutocompleteOrganizationUser'
   */
  dataTest?: string
  onEnterPress?: (input: string) => void
}

export function AddNewMemberAutocomplete({
  dataTest = 'AddNewMemberAutocomplete',
  error,
  onEnterPress,
  ...props
}: IAutocompleteOrganizationUserProps) {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<Nullable<IValue>>(null)

  const { data, loading, refetch } = useSearchMembersQuery()
  const { searchOrganizationUsers } = data || {}
  const { nodes } = searchOrganizationUsers || {}
  const members: IValue[] = [
    ...compact(nodes),
    ...(isValidEmail(inputValue)
      ? ([
          {
            id: inputValue,
            status: 'new',
            user: {
              id: inputValue,
              firstName: '',
              lastName: '',
              emailAddress: inputValue,
            },
          },
        ] as IValue[])
      : []),
  ]

  const inputValueChangeHandler: IAutocompleteBaseProps['onInputChange'] = useCallback(
    (_, newInputValue) => {
      setInputValue(newInputValue)
      refetch({ searchTerm: newInputValue })
    },
    [refetch]
  )

  const changeHandler: IAutocompleteBaseProps['onChange'] = useCallback(
    (event, newValue, reason) => {
      if (typeof newValue !== 'string') {
        if (props.onChange) {
          props.onChange(event, newValue, reason)
        }
        setInputValue('')
      }
    },
    [setSelectedUser, setInputValue, props]
  )

  const handleEnter: IAutocompleteBaseProps['onKeyDown'] = event => {
    if (['Enter', ' '].includes(event.key) && onEnterPress) {
      onEnterPress(inputValue)
      setInputValue('')
    }
  }

  const handleBlur: IAutocompleteBaseProps['onBlur'] = () => {
    if (onEnterPress) {
      onEnterPress(inputValue)
    }
    setInputValue('')
  }

  return (
    <Autocomplete<IValue, TFreeSolo>
      {...props}
      error={Boolean(error)}
      dataTest={dataTest}
      freeSolo
      loading={loading}
      label='User Contact Email'
      helperText={error || 'Add multiple emails by pasting or typing them in and clicking “enter”.'}
      options={members}
      value={selectedUser}
      onKeyDown={handleEnter}
      onChange={changeHandler}
      onBlur={handleBlur}
      inputValue={inputValue}
      onInputChange={inputValueChangeHandler}
      getOptionDisabled={({ status }) => status !== 'new'}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => (typeof option === 'string' ? option : option.user.emailAddress)}
      renderOption={(optionProps, option) => (
        <li {...optionProps}>
          <Box
            width='100%'
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'>
            <Box display='flex' gap={1} alignItems='center'>
              {option.status !== 'new' ? (
                <Avatar
                  name={`${option.user.firstName} ${option.user.lastName}`}
                  size='xsmall'
                  hideTooltip
                />
              ) : (
                <EmailIcon />
              )}
              <Box display='flex' flexDirection='column'>
                <Typography variant='body1'>
                  {option.user.firstName} {option.user.lastName}
                </Typography>
                <Typography variant='body2'>{option.user.emailAddress}</Typography>
              </Box>
            </Box>
            {option.status === IOrganizationUserStatus.active && (
              <Typography variant='caption'>Already Member</Typography>
            )}
            {option.status === IOrganizationUserStatus.invited && (
              <Typography variant='caption'>Already Invited</Typography>
            )}
            {option.status === IOrganizationUserStatus.deleted && (
              <Typography variant='caption'>Locked Email</Typography>
            )}
          </Box>
        </li>
      )}
    />
  )
}
