interface TGetGraphqlAfterCursorParams {
  pageNumber: number
  pageSize: number
}

/**
 * getGraphqlAfterCursor calculates the cursor right before the first one on `pageNumber`.
 * @param pageNumber page to query for
 * @param pageSize amount of items per page
 * @returns base64 string representation of the cursor
 * @example
 * ```
 * getGraphqlPageCursor(1, 20) // ''
 * getGraphqlPageCursor(2, 20) // 'MjA='
 * ```
 */
export const getGraphqlAfterCursor = ({ pageNumber, pageSize }: TGetGraphqlAfterCursorParams) => {
  const pageIndex = pageNumber

  if (pageIndex < 1) {
    // no need to calculate the cursor for the first page
    return ''
  }

  /**
   * more info on btoa in the link below
   * https://developer.mozilla.org/en-US/docs/Web/API/btoa
   * @param param0
   * @returns
   */
  return btoa(`${pageIndex * pageSize}`)
}
