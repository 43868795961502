import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'

type IBaseControllerProps = ControllerProps<React.ComponentType<any>>

export interface IControlledHiddenInputProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  value?: IBaseControllerProps['value']
  dataTest?: string
}

/**
 * Helper component for use with React Hook Form
 * @see https://react-hook-form.com/api#Controller
 * @example
 * ```
 * <ControlledHiddenInput
 *   name='facility.locality'
 *   control={control}
 * />
 * ```
 */
const ControlledHiddenInput = memo<IControlledHiddenInputProps>(
  ({ control, dataTest, defaultValue, name }) => {
    return (
      <Controller
        dataTest={dataTest}
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={() => <></>}
      />
    )
  }
)

export default ControlledHiddenInput
