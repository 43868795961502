import React from 'react'
import { GridColTypeDef as MuiGridColTypeDef } from '../../../core'
import { DataGridCheckboxCell } from '../cells/DataGridCheckboxCell'

export const getCheckboxGridColTypeDef = (label?: string): MuiGridColTypeDef => ({
  type: 'boolean',
  renderCell: ({ value, ...rest }) => {
    return <DataGridCheckboxCell label={label} checked={value} {...rest} />
  },
  renderEditCell: ({ value, ...rest }) => {
    return <DataGridCheckboxCell label={label} checked={value} {...rest} />
  },
  editable: true,
})
