import { Moment } from 'moment-timezone'
import { DEFAULT_SUGGESTION_STEP_MINUTES, MINUTES_PER_DAY } from '../constants'
import { TIME_INPUT_FORMAT } from '../types'

/**
 * Returns array of times in string format
 * @param {Moment} startAt - Moment when the times begin
 * @param {TIME_INPUT_FORMAT} timeFormat - i.e. 'MILITARY' or 'TWELVE_HR_SHORT'
 * @param {number} suggestionCount - How many results to show
 * @param {number} minuteIncrement - Increments, i.e. '30', '15'
 * @returns i.e. ['10:00am', '10:30am']
 */
export const buildTimes = (
  startAt: Moment,
  timeFormat: TIME_INPUT_FORMAT,
  minuteIncrement: number = DEFAULT_SUGGESTION_STEP_MINUTES
): string[] => {
  const suggestionCount = MINUTES_PER_DAY / minuteIncrement // Default: 48
  return Array.from({ length: suggestionCount }, (n, i) =>
    startAt
      .clone()
      .add(minuteIncrement * i, 'm')
      .format(timeFormat)
  )
}
