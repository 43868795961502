import BookmarkIcon from 'dpl/icons/build/BookmarkIcon'
import CheckOutlineIcon from 'dpl/icons/build/CheckOutlineIcon'
import EmailIcon from 'dpl/icons/build/EmailIcon'
import InfoIcon from 'dpl/icons/build/InfoIcon'
import PhoneIcon from 'dpl/icons/build/PhoneIcon'
import { ISupportModalLink } from 'dpl/templates/SupportModal/types'

export const APP_BAR_HEIGHT = 55
export const NAV_DRAWER_WIDTH_EXPANDED = 250
export const NAV_DRAWER_WIDTH_COLLAPSED = 68
export const ORG_NAV_ITEM_HEIGHT = 69

export const BANNER_HEIGHT_DESKTOP = 43
export const BANNER_HEIGHT_MOBILE = 46

// VITE_APP_NAME that is defined in .env file
export const SOLUTION_CONSOLE_APP_NAME = 'Transfix - Solution Console'

export const CARRIER_APP_MODAL_BODY = `We're here to help! Below are some ways to reach out to us.`

export const CARRIER_APP_LINKS: ISupportModalLink[] = [
  {
    label: 'Visit our FAQs',
    url: 'https://intercom.help/carrier-help/en/',
    description: 'Tutorials & answers to FAQs',
    icon: InfoIcon,
  },
  {
    label: 'Check Status Page',
    url: 'https://status.transfix.io/',
    description: 'Get updates on our system status',
    icon: CheckOutlineIcon,
  },
  {
    label: '(855) 409-0012',
    url: 'tel:8554090012',
    description: 'Call for 24 hour support',
    icon: PhoneIcon,
  },
]

export const SOLUTION_CONSOLE_MODAL_BODY = `We're here to help! Below are some ways to reach out to us.`

export const SOLUTION_CONSOLE_LINKS: ISupportModalLink[] = [
  {
    label: 'Submit a support ticket',
    url: 'http://contact.transfix.io',
    description: 'Raise a request through our contact.transfix.io portal',
    icon: BookmarkIcon,
  },
  {
    label: 'Email us: productsupport@transfix.io',
    url: 'mailto:productsupport@transfix.io',
    description: 'We’ll respond as soon as possible',
    icon: EmailIcon,
  },
  {
    label: 'Visit our FAQs',
    url: 'https://intercom.help/transfix-solutions/en/',
    description: 'Tutorials & answers to FAQs',
    icon: InfoIcon,
  },
  {
    label: 'Check Status Page',
    url: 'https://status.transfix.io/',
    description: 'Get updates on our system status',
    icon: CheckOutlineIcon,
  },
]
