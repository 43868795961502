export interface IOrganizationTeam {
  key: string
}

export enum AdminModuleKeys {
  OrganizationAdminConsole = 'admin_console',
  TransfixAdminConsole = 'transfix_admin_console',
}

export enum AdminModuleRoles {
  OrganizationAdmin = 'organization_admin',
  TransfixUser = 'transfix_user',
  TransfixSuperAdmin = 'transfix_super_admin',
}

export enum ProductModuleRoles {
  Admin = 'admin',
  User = 'user',
}

export enum TeamKeys {
  OrganizationAdmins = 'organization_admins',
}

export enum ModuleTeamKeySuffixes {
  Admins = 'admins',
  Users = 'users',
}

export interface IModuleRoleResult {
  moduleKey: string
  moduleName: string
  role: string | null
}
