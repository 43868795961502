import { Components } from '@mui/material/styles'

export const MuiTypographyThemeOptions: Components['MuiTypography'] = {
  defaultProps: {
    variantMapping: {
      h1: 'h1',
      h2: 'h2',
      h3: 'h3',
      h4: 'h4',
      h5: 'h5',
      h6: 'h6',
      subtitle1: 'h5',
      subtitle2: 'h6',
    },
  },
}
