import React, { memo, useMemo } from 'react'
import { Box, makeStyles } from '../../../core'
import { useBreakpoint } from '../../../hooks'
import { brandColors } from '../../../theme/colors'
import InlineMessage, { INLINE_MESSAGE_VARIANTS } from '../../InlineMessage'
import { IModalBaseProps, ModalBase } from '../ModalBase'

export interface IFormModalProps extends IModalBaseProps {
  /**
   * The errors to display in the form modal
   * @default []
   */
  errors?: string[]
}

const useFormModalStyles = makeStyles(theme => ({
  header: {
    borderBottom: `1px solid ${brandColors.coolGray3}`,
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  body: {
    flexGrow: 1,
  },
  footer: {
    borderTop: `1px solid ${brandColors.coolGray3}`,
  },
}))

export const FormModal = memo<IFormModalProps>(
  ({
    children,
    dataTest = 'FormModal',
    errors = [],
    header,
    headerClassName = '',
    footerClassName = '',
    bodyClassName = '',
    ...props
  }) => {
    const classes = useFormModalStyles()
    const { isMobile } = useBreakpoint()
    const showErrors = useMemo(() => errors.length > 0, [errors])
    const showHeader = useMemo(() => header || showErrors, [header, showErrors])

    return (
      <ModalBase
        fullWidth
        maxWidth='md'
        scroll='paper'
        dataTest={dataTest}
        titleVariant={isMobile ? 'h4' : 'h3'}
        headerClassName={`${headerClassName} ${classes.header}`}
        bodyClassName={`${bodyClassName} ${classes.body}`}
        footerClassName={`${footerClassName} ${classes.footer}`}
        header={
          showHeader ? (
            <>
              {header && <div>{header}</div>}
              {showErrors && (
                <Box display='flex' flexDirection='column' gap={1} data-test={`${dataTest}-errors`}>
                  {errors.map(error => (
                    <InlineMessage
                      key={error}
                      message={error}
                      type={INLINE_MESSAGE_VARIANTS.ALERT}
                      fullWidth
                    />
                  ))}
                </Box>
              )}
            </>
          ) : undefined
        }
        {...props}>
        {children}
      </ModalBase>
    )
  }
)
