import React, { memo } from 'react'
import cx from 'classnames'
import { createStyles, createTheme, makeStyles } from '../../core/styles'
import { TIconSize } from '../../icons/components/SvgIcon/types'
import { TIconComponent } from '../../icons/types'
import { brandColors } from '../../theme/colors'

export interface IBadgeProps {
  /**
   * @default undefined
   */
  icon?: TIconComponent
  /**
   * @default undefined
   */
  iconClassName?: string
  /**
   * @default 'small'
   */
  size?: keyof typeof BADGE_SIZE
  /**
   * @default 'gray'
   */
  color?: keyof typeof BADGE_COLOR
  className?: string
  /**
   * @default 'badge'
   */
  dataTest?: string
  label: string
}

type TClassStyleKey = 'root' | 'icon'

type TStyleProps = {
  /**
   * @default 'gray'
   */
  color: keyof typeof BADGE_COLOR
  /**
   * @default 'small'
   */
  size: keyof typeof BADGE_SIZE
}

export enum BADGE_SIZE {
  small = 'small',
  medium = 'medium',
}

export enum BADGE_COLOR {
  blue = 'blue',
  green = 'green',
  orange = 'orange',
  red = 'red',
  gray = 'gray',
  gold = 'gold',
}

export const BADGE_SIZE_STYLE: Record<BADGE_SIZE, { fontSize: string; lineHeight: string }> = {
  [BADGE_SIZE.small]: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  [BADGE_SIZE.medium]: {
    fontSize: '0.875rem',
    lineHeight: '1.1875rem',
  },
}

export const BADGE_ICON_SIZE_STYLE: Record<BADGE_SIZE, string> = {
  [BADGE_SIZE.small]: 'small',
  [BADGE_SIZE.medium]: 'medium',
}

export const BADGE_COLOR_STYLE: Record<BADGE_COLOR, { backgroundColor: string; color: string }> = {
  [BADGE_COLOR.gray]: {
    backgroundColor: brandColors.coolGray2,
    color: brandColors.coolGray8,
  },
  [BADGE_COLOR.blue]: {
    backgroundColor: brandColors.skyBlue1,
    color: brandColors.skyBlue6,
  },
  [BADGE_COLOR.green]: {
    backgroundColor: brandColors.green1,
    color: brandColors.green3,
  },
  [BADGE_COLOR.orange]: {
    backgroundColor: brandColors.warning0,
    color: brandColors.warning1,
  },
  [BADGE_COLOR.red]: {
    backgroundColor: brandColors.error0,
    color: brandColors.error1,
  },
  [BADGE_COLOR.gold]: {
    backgroundColor: brandColors.gold1,
    color: brandColors.black,
  },
}

const useBadgeStyles = makeStyles(() => {
  const theme = createTheme()
  return createStyles<TClassStyleKey, TStyleProps>({
    root: {
      display: 'inline-flex',
      fontWeight: 600,
      borderRadius: theme.shape.borderRadius,
      alignItems: 'center',
      padding: theme.spacing(0.25, 0.5),
      backgroundColor: ({ color: colorProp }) => {
        const { backgroundColor } = BADGE_COLOR_STYLE[colorProp]
        return backgroundColor
      },
      color: ({ color: colorProp }) => {
        const { color } = BADGE_COLOR_STYLE[colorProp]
        return color
      },
      fontSize: ({ size: sizeProp }) => {
        const { fontSize } = BADGE_SIZE_STYLE[sizeProp]
        return fontSize
      },
      lineHeight: ({ size: sizeProp }) => {
        const { lineHeight } = BADGE_SIZE_STYLE[sizeProp]
        return lineHeight
      },
    },
    icon: {
      marginRight: theme.spacing(0.5),
    },
  })
})

export const Badge = memo<IBadgeProps>(
  ({
    className,
    color: colorProp = 'gray',
    dataTest = 'badge',
    icon: IconComponent,
    iconClassName,
    label,
    size: sizeProp = 'small',
  }) => {
    const size = BADGE_SIZE[sizeProp]
    const color = BADGE_COLOR[colorProp]
    const iconSize = BADGE_ICON_SIZE_STYLE[sizeProp] as TIconSize
    const classes = useBadgeStyles({ size, color })

    return (
      <div className={cx(classes.root, className)} data-test={dataTest}>
        {IconComponent && (
          <IconComponent size={iconSize} className={cx(classes.icon, iconClassName)} />
        )}
        {label}
      </div>
    )
  }
)

export default Badge
