import { Components } from '@mui/material/styles'
import { brandColors } from '../colors'
import { wilsonFontFamily } from '../fontFamilies'
import ExoBoldWoff2 from '../fonts/exo/Exo2-Bold.woff2'
import ExoExtraBoldWoff2 from '../fonts/exo/Exo2-ExtraBold.woff2'
import ExoExtraBoldItalicWoff2 from '../fonts/exo/Exo2-ExtraBoldItalic.woff2'
import ExoItalicWoff2 from '../fonts/exo/Exo2-Italic.woff2'
import ExoRegularWoff2 from '../fonts/exo/Exo2-Regular.woff2'
import ExoSemiBoldWoff2 from '../fonts/exo/Exo2-SemiBold.woff2'
import { calcRem } from '../utils'

/**
 * @name CssBaseline
 * @see https://mui.com/material-ui/api/css-baseline/
 */
export const MuiCssBaselineThemeOptions: Components['MuiCssBaseline'] = {
  styleOverrides: `
    @font-face {
      font-family: 'Exo';
      font-display: swap;
      font-style: normal;
      font-weight: normal;
      src: local('Exo'), local('Exo-Regular'), url(${ExoRegularWoff2}) format('woff2');
    }

    @font-face {
      font-family: 'Exo';
      font-display: swap;
      font-style: italic;
      font-weight: normal;
      src: local('Exo'), local('Exo-Italic'), url(${ExoItalicWoff2}) format('woff2');
    }

    @font-face {
      font-family: 'Exo';
      font-display: swap;
      font-style: normal;
      font-weight: bold;
      src: local('Exo'), local('Exo-Bold'), url(${ExoBoldWoff2}) format('woff2');
    }

    @font-face {
      font-family: 'Exo';
      font-display: swap;
      font-style: italic;
      font-weight: bold;
      src: local('Exo'), local('Exo-BoldItalic'), url(${ExoExtraBoldItalicWoff2}) format('woff2');
    }

    @font-face {
      font-family: 'Exo';
      font-display: swap;
      font-style: normal;
      font-weight: bold;
      src: local('Exo'), local('Exo-ExtraBold'), url(${ExoExtraBoldWoff2}) format('woff2');
    }

    @font-face {
      font-family: 'Exo';
      font-display: swap;
      font-style: normal;
      font-weight: 600;
      src: local('Exo'), local('Exo-SemiBold'), url(${ExoSemiBoldWoff2}) format('woff2');
    }

    html {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      min-height: 100vh;
    }

    *, *::before, *::after {
      box-sizing: inherit;
    }

    strong, b {
      font-weight: 700;
    }

    body {
      color: rgba(0, 0, 0, 0.87);
      margin: 0;
      font-size: ${calcRem(14)};
      line-height: ${calcRem(18)};
      font-style: normal;
      background-color: ${brandColors.white};
      font-family: ${wilsonFontFamily};
      font-weight: 400;
      letter-spacing: 0;
      min-height: 100vh;
    }

    #app, main{ 
      min-height: 100vh;
    }

    @media (min-width:768px) {
      body {
        font-size: 0.875rem;
        line-height: 1.1875rem;
      }
    }

    @media print {
      body {
        background-color: #fff;
      }
    }

    body::backdrop {
      background-color: #fafafa;
    }
  `,
}
