/*
https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
*/

export const KEY_VALUES = {
  ENTER: 'Enter',
  SPACE: ' ',
  SPACEBAR: 'Spacebar',
}

export const KEY_CODES = {
  ESC: 27,
}
