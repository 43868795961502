import React, { useCallback, useMemo, useState } from 'react'
import { brandColors, ISelectFieldProps, ISwitchProps, SelectField, Switch } from 'dpl'
import { Box, Typography } from 'dpl/core'
import startCase from 'lodash/startCase'
import { OptionItem } from './OptionItem'

export interface IProductModuleProps {
  SelectFieldProps?: Omit<ISelectFieldProps, 'onChange' | 'value'>
  productModule: string
  onChange: (productModule: string, role?: string) => void
  onDeactive: (productModule: string) => void
  value?: string
  disabled?: boolean
}

export function ProductModule({
  SelectFieldProps,
  disabled,
  onChange,
  onDeactive,
  productModule,
  value,
}: IProductModuleProps) {
  const [active, setActive] = useState(Boolean(value))

  const handleSwitchChange: ISwitchProps['onChange'] = (_, checked) => {
    if (!checked) {
      onDeactive(productModule)
    } else {
      onChange(productModule, `${productModule}_users`)
    }
    setActive(checked)
  }
  const handleSelectChange: ISelectFieldProps['onChange'] = event => {
    onChange(productModule, event.target.value)
  }

  const options = useMemo(
    () => [
      {
        label: (
          <OptionItem
            label='User'
            description='Able to perform daily tasks and manage their own profile.'
          />
        ),
        selectedLabel: 'User',
        value: `${productModule}_users`,
      },
      {
        label: (
          <OptionItem
            label='Admin'
            description='Able to access advanced features for enhanced control and oversight.'
          />
        ),
        selectedLabel: 'Admin',
        value: `${productModule}_admins`,
      },
    ],
    [productModule]
  )

  const renderValue = useCallback(
    selected => {
      const selectedOption = options.find(opt => opt.value === selected)
      return selectedOption ? selectedOption.selectedLabel : ''
    },
    [options]
  )

  return (
    <Box border={`1px ${brandColors.coolGray5} solid`} padding={2}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='subtitle1'>{startCase(productModule)}</Typography>
        <Switch checked={active} onChange={handleSwitchChange} disabled={disabled} />
      </Box>
      <SelectField
        {...SelectFieldProps}
        label='Role'
        disabled={disabled || !active}
        onChange={handleSelectChange}
        value={value}
        defaultValue={`${productModule}_users`}
        SelectProps={{
          renderValue,
        }}
        options={options}
      />
    </Box>
  )
}
