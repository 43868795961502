import { StepConnector as MUIStepConnector, withStyles } from '../../core'
import { brandColors } from '../../theme/colors'

export const StepConnector = withStyles({
  alternativeLabel: {
    top: 20,
    left: 'calc(15px - 50%)',
    right: 'calc(50% + 15px)',
  },
  active: {
    '& $line': {
      borderColor: brandColors.skyBlue6,
    },
  },
  completed: {
    '& $line': {
      borderColor: brandColors.skyBlue6,
    },
  },
  line: {
    borderColor: brandColors.coolGray4,
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(MUIStepConnector)

export default StepConnector
