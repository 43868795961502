import { Shadows } from '../core/styles'
import { createShadow } from './utils/createShadow'

const defaultShadowOpacity = 0.15

export const shadows: Shadows = [
  'none',
  createShadow({ px: [0, 1, 2, -1, 0, 1, 1, 0, 0, 1, 3, 0] }),
  createShadow({ px: [0, 3, 1, -2, 0, 2, 2, 0, 0, 1, 5, 0] }),
  createShadow({ px: [0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0] }),
  createShadow({ px: [0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0] }),
  createShadow({ px: [0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0] }),
  createShadow({ px: [0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0] }),
  createShadow({ px: [0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1] }),
  createShadow({ px: [0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2] }),
  createShadow({ px: [0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2] }),
  createShadow({ px: [0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3] }),
  createShadow({ px: [0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3] }),
  createShadow({ px: [0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4] }),
  createShadow({ px: [0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4] }),
  createShadow({ px: [0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4] }),
  createShadow({ px: [0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5] }),
  createShadow({ px: [0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5] }),
  createShadow({ px: [0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5] }),
  createShadow({ px: [0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6] }),
  createShadow({ px: [0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6] }),
  createShadow({ px: [0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7] }),
  createShadow({ px: [0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7] }),
  createShadow({ px: [0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7] }),
  createShadow({ px: [0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8] }),
  createShadow({ px: [0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8] }),
]

export default shadows

export const boxShadowLevels = {
  none: 'none',
  low: createShadow({ px: [0, 1, 2], shadowOpacity: defaultShadowOpacity }),
  lowReverse: createShadow({ px: [0, -1, 2], shadowOpacity: defaultShadowOpacity }),
  medium: createShadow({ px: [0, 4, 8, 4], shadowOpacity: defaultShadowOpacity }),
  mediumReverse: createShadow({ px: [0, -4, 8], shadowOpacity: defaultShadowOpacity }),
  high: createShadow({ px: [0, 6, 12], shadowOpacity: defaultShadowOpacity }),
  highReverse: createShadow({ px: [0, -6, 12], shadowOpacity: defaultShadowOpacity }),
  pop: createShadow({ px: [0, 12, 24], shadowOpacity: defaultShadowOpacity }),
  popReverse: createShadow({ px: [0, -12, 24], shadowOpacity: defaultShadowOpacity }),
}
