import React, { memo } from 'react'
import { Box, createStyles, makeStyles } from '../../core'
import LogoGradientSvg from './svgs/LogoTransfixGradient.svg?react'
import LogoSolidSvg from './svgs/LogoTransfixSolid.svg?react'

const ASPECT_RATIO = 0.20376357
const DEFAULT_WIDTH_PX = 200

const getHeightPx = (width: number): number => {
  return width * ASPECT_RATIO
}

export type ILogoGradientProps = {
  variant?: 'gradient'
  /**
   * @default 'black'
   */
  color?: 'black'
}

export type ILogoSolidProps = {
  variant?: 'solid'
  /**
   * @default 'black'
   */
  color?: 'black' | 'white'
}

type TruncatedProps = ILogoGradientProps | ILogoSolidProps

export type ILogoProps = {
  /**
   * @default 200
   */
  width?: number
} & TruncatedProps

type TClassKey = 'root' | 'svgImage'

const useLogoSolidStyles = makeStyles(() => {
  return createStyles<TClassKey, { color: string; variant: string; width: number }>({
    root: {
      position: 'relative',
      display: 'inline-block',
      /**
       * Only allow setting color for 'solid' style
       */
      color: ({ color, variant }) => (variant === 'solid' ? color : 'black'),
      width: ({ width }) => width,
      height: ({ width }) => getHeightPx(width),
    },
    svgImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  })
})

export const LogoTransfix = memo<ILogoProps>(
  ({ color = 'black', variant = 'solid', width = DEFAULT_WIDTH_PX }) => {
    const classes = useLogoSolidStyles({ color, variant, width })
    const LogoSvg = variant === 'solid' ? LogoSolidSvg : LogoGradientSvg
    return (
      <Box className={classes.root} data-test='logo-transfix'>
        <LogoSvg className={classes.svgImage} />
      </Box>
    )
  }
)
