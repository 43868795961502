import React, { FC, forwardRef } from 'react'
import classNames from 'classnames'
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  Theme,
  makeStyles,
} from '../../core'
import { TIconSize } from '../../icons/components/SvgIcon/types'
import { TIconComponent } from '../../icons/types'

export type TIconButtonProps = Omit<MuiIconButtonProps, 'size'> & {
  Icon: FC | TIconComponent
  dataTest?: string
  /**
   * @default false
   */
  fullWidth?: boolean

  /**
   * @default 'center'
   */
  justifyIcon?: 'start' | 'center' | 'end'
  label?: string
  /**
   * @default false
   */
  noPadding?: boolean
  /**
   * @default 'medium'
   */
  size?: TIconSize
}

const useIconButtonStyles = makeStyles<
  Theme,
  Pick<TIconButtonProps, 'fullWidth' | 'justifyIcon' | 'noPadding'>
>(() => ({
  root: ({ fullWidth, justifyIcon, noPadding }) => {
    const fullWidthStyles = fullWidth
      ? {
          width: '100%',
        }
      : {}

    const noPaddingStyles = noPadding
      ? {
          padding: 0,
          minWidth: 'auto',
        }
      : {}

    return {
      justifyContent: justifyIcon,
      ...fullWidthStyles,
      ...noPaddingStyles,
    }
  },
}))

/**
 * @name IconButton
 * @see https://mui.com/material-ui/api/icon-button/
 */
function IconButtonComponent(
  {
    Icon: IconComponent,
    className: classNameProp,
    color = 'primary',
    dataTest,
    fullWidth = false,
    justifyIcon = 'center',
    label,
    noPadding = false,
    size = 'medium',
    ...props
  }: TIconButtonProps,
  ref:
    | ((instance: HTMLButtonElement | null) => void)
    | React.MutableRefObject<HTMLButtonElement | null>
    | null
) {
  const classes = useIconButtonStyles({
    fullWidth,
    justifyIcon,
    noPadding,
  })

  const className = classNames(classes.root, classNameProp)

  return (
    <MuiIconButton
      {...props}
      color={color}
      ref={ref}
      aria-label={label}
      className={className}
      data-test={dataTest}>
      <IconComponent size={size} />
    </MuiIconButton>
  )
}

export const IconButton = forwardRef<HTMLButtonElement, TIconButtonProps>(IconButtonComponent)

export default IconButton
