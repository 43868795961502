import { useCallback, useState } from 'react'
import { IToastState, TToastNewState } from './types'

interface IUseGlobalToastState {
  toastState: IToastState
  openToast: (newToastState: TToastNewState) => void
  closeToast: () => void
}

/**
 * This hook contains all of the necessary state and update functions for
 * working with a Toast component anywhere.
 */
export const useGlobalToast = (): IUseGlobalToastState => {
  const [toastState, setToastState] = useState<IToastState>({
    toastOpen: false,
    duration: 5000,
    toastMessage: '',
    multiLine: false,
    toastType: 'default',
  })

  const openToast = useCallback((newToastState: TToastNewState) => {
    setToastState({ ...newToastState, toastOpen: true })
  }, [])

  const closeToast = useCallback(() => {
    setToastState(prevState => ({ ...prevState, toastOpen: false }))
  }, [])

  return { toastState, openToast, closeToast }
}
