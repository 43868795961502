import React, { MouseEvent } from 'react'
import {
  ConfirmationModal,
  IConfirmationModalProps,
  IModalAction,
} from '../../../components/Modals'
import { Link, Typography } from '../../../core'

export interface IResetPasswordConfirmationModalProps
  extends Pick<IConfirmationModalProps, 'actions' | 'open' | 'onClose'> {
  email: string
  onResendEmail: (e: MouseEvent<HTMLButtonElement>, resend?: boolean) => void
}

export function ResetPasswordConfirmationModal({
  email,
  onClose,
  onResendEmail,
  open,
}: IResetPasswordConfirmationModalProps) {
  const actions: IModalAction[] = [
    {
      label: 'Close',
      type: 'confirm',
      action: onClose,
      ButtonProps: { variant: 'text' },
    },
  ]

  const resendEmailHandler = (e: MouseEvent<HTMLButtonElement>) => {
    onResendEmail(e, true)
  }

  return (
    <ConfirmationModal
      dataTest='reset-password-confirmation-modal'
      title='Check your email to reset your password.'
      maxWidth='md'
      actions={actions}
      open={open}
      onClose={onClose}>
      <Typography variant='body2'>
        A link to reset your password was sent to you at {email}
      </Typography>
      <Typography variant='body2' mt={3}>
        Didn’t get it?{' '}
        <Link component='button' onClick={resendEmailHandler} fontWeight={600}>
          Resend email
        </Link>
      </Typography>
    </ConfirmationModal>
  )
}
