import React, { ReactElement, useEffect, ReactNode } from 'react'
import classNames from 'classnames'
import { makeStyles, Box, Theme } from '../../core'
import { brandColors } from '../../theme'
import { INLINE_MESSAGE_VARIANTS } from '../InlineMessage'
import { ActionConfig, Footer } from './components/Footer'
import { Header } from './components/Header'

interface IUseFullPageOverlayStylesProps {
  open: boolean
}

const useFullPageOverlayStyles = makeStyles<Theme, IUseFullPageOverlayStylesProps>(() => ({
  root: {
    height: '100vh',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: 9999,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: brandColors.white,
    display: ({ open }) => (open ? 'flex' : 'none'),
  },
  fullPageOverlayStep: {
    flexGrow: 1,
    overflowY: 'scroll',
  },
  displayNone: {
    display: 'none',
  },
}))

export interface FullPageOverlayConfig {
  title: string
  subtitle?: string
  primaryActionConfig?: ActionConfig
  secondaryActionConfig?: ActionConfig
  tertiaryActionConfig?: ActionConfig
}

export interface IFullPageOverlayProps {
  /**
   * Whether the overlay is visible
   *
   * @default false
   */
  open: boolean

  /**
   * Called when the overlay is closed
   *
   * @default NOOP
   */
  onClose: () => void

  /**
   * Called when navigating to the next step in an overlay
   *
   * @default NOOP
   */
  onChangeStep?: (step: number) => void

  /**
   * Configuration for each step in the overlay. The length of this
   * configuration array _must_ match the number of child components, as each
   * child component represents a step. This configuration covers the following
   * attributes for each step
   *
   * - title: The text displayed at the top of the overlay
   * - subtitle: Text displayed underneath the title (only visible on single
   * step overlays)
   * - primaryActionConfig: Configuration for the primary action button
   * - secondaryActionConfig: Configuration for the secondary action button
   * - tertiaryActionConfig: Configuration for the tertiary action button
   */
  stepConfig: FullPageOverlayConfig[]

  /**
   * Configuration for the text displayed in the footer of the overlay. The
   * text and subtext will be combined into a single string with the format
   * <text> · <subtext>
   */
  footerMessaging?: {
    text: string
    subtext: string
  }

  /**
   * Optional render function. Pass this in the render footer content
   * in between the tertiary CTA on the left and the primary and secondary
   * CTAs on the right.
   *
   * If a render function is passed in, then the footerMessage will _not_ be
   * rendered.
   */
  renderCustomFooter?: () => React.ReactNode

  /**
   * Function invoked when the back arrow at the top right of the overlay is
   * pressed.
   */
  onPressBackButton: () => void
  currentStep: number
  children: ReactElement[]
  errors?: string[] | ReactNode[]
  errorVariant?: INLINE_MESSAGE_VARIANTS
  className?: string
  /**
   * Determines whether or not to render all step components at once, and allow for
   * traversal between steps without needing to remount the component.  Keep in
   * mind this may have performance implications since all components are mounted.
   *
   * Components remount themselves when it is their step by default.
   *
   * @default false
   */
  renderAll?: boolean
}

export function FullPageOverlay({
  children,
  className: classNameProp,
  currentStep,
  errorVariant,
  errors,
  footerMessaging,
  onChangeStep,
  onClose,
  onPressBackButton,
  open,
  renderAll = false,
  renderCustomFooter,
  stepConfig,
}: IFullPageOverlayProps) {
  useEffect(() => {
    if (onChangeStep) {
      onChangeStep(currentStep)
    }
  }, [currentStep, onChangeStep])

  const currentStepFallback = stepConfig?.[currentStep] ? currentStep : 0

  const { primaryActionConfig, secondaryActionConfig, tertiaryActionConfig } =
    stepConfig?.[currentStepFallback] || {}

  const classes = useFullPageOverlayStyles({ open })
  const className = classNames(classes.root, classNameProp)

  return (
    <Box className={className} data-test='full-page-overlay'>
      <Header
        currentStep={currentStepFallback}
        totalSteps={children.length}
        onClose={onClose}
        onPressBackButton={onPressBackButton}
        headerConfig={stepConfig?.[currentStepFallback]}
        messages={(errors ?? []).map(error => ({
          message: error,
          type: errorVariant ?? INLINE_MESSAGE_VARIANTS.ALERT,
        }))}
      />
      {renderAll ? (
        children.map((child, idx) => {
          const shouldDisplayClass = idx === currentStepFallback ? '' : classes.displayNone
          return (
            <div
              key={`${child.key}-full-page-overlay`}
              className={`${classes.fullPageOverlayStep} ${shouldDisplayClass}`}>
              {child}
            </div>
          )
        })
      ) : (
        <div className={classes.fullPageOverlayStep}>{children[currentStepFallback]}</div>
      )}
      <Footer
        primaryActionConfig={primaryActionConfig}
        secondaryActionConfig={secondaryActionConfig}
        tertiaryActionConfig={tertiaryActionConfig}
        messaging={footerMessaging}
        renderCustomFooter={renderCustomFooter}
      />
    </Box>
  )
}

export default FullPageOverlay
