import React, { ReactNode } from 'react'
import camelCase from 'lodash/camelCase'
import { GridValidRowModel as MuiGridValidRowModel } from '../../../core'
import {
  DataGridColumnBulkActionMenuItem,
  IDataGridColumnBulkActionProps,
} from '../components/DataGridColumnBulkActionMenuItem'
import { IDataGridBulkAction } from '../types'

interface IGetBulkActionSlotsAndPropsReturnType<TRowModel extends MuiGridValidRowModel> {
  slots: {
    [key: string]: React.ReactNode
  }
  slotProps: {
    [key: string]: IDataGridColumnBulkActionProps<TRowModel>
  }
}

const getBulkActionKey = (label: string) => `bulkAction-${camelCase(label)}`

export function getBulkActionSlotsAndProps<TRowModel extends MuiGridValidRowModel>(
  selectedRows: Map<TRowModel['id'], TRowModel>,
  bulkActions: IDataGridBulkAction<TRowModel>[]
): IGetBulkActionSlotsAndPropsReturnType<TRowModel> {
  const slots: {
    [key: string]: ReactNode
  } = Object.fromEntries(
    bulkActions.map(bulkAction => {
      const { label } = bulkAction
      return [getBulkActionKey(label), DataGridColumnBulkActionMenuItem]
    })
  )

  const slotProps = Object.fromEntries(
    bulkActions.map(bulkAction => {
      const { icon, label, onClick } = bulkAction
      return [
        getBulkActionKey(label),
        {
          selectedRows,
          label,
          icon,
          onClickBulkAction: onClick,
        },
      ]
    })
  )

  return {
    slots,
    slotProps,
  }
}
