import React, { memo, ReactNode } from 'react'
import { Typography } from '@mui/material'
import { Box, ITypographyProps, makeStyles, DialogTitle as MuiDialogTitle } from '../../../../core'
import CloseIcon from '../../../../icons/build/CloseIcon'
import { IconButton } from '../../../IconButton'

export interface IModalHeaderProps {
  /**
   * The class name of the root element
   * @default ''
   */
  className?: string
  /**
   * The data-test attribute
   * @default 'ModalHeader'
   */
  dataTest?: string
  /**
   * Nodes that are displayed below the title and close button
   */
  header?: ReactNode
  /**
   * If false, the close button will not be displayed
   * @default true
   */
  includeCloseIconButton?: boolean
  /**
   * Callback function triggered when the close button is clicked
   */
  onClose: () => void
  /**
   * The title of the modal
   */
  title: ReactNode
  /**
   * The typography variant of the title
   * @default 'h3'
   */
  titleVariant?: ITypographyProps['variant']
  /**
   * The subtitle of the modal
   * @default ''
   */
  subtitle?: string
  /**
   * The typography variant of the subtitle
   * @default 'subtitle1'
   */
  subtitleVariant?: ITypographyProps['variant']
}

const useModalHeaderStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 2, 0, 3),
    },
  },
  closeButton: {
    padding: theme.spacing(0.625),
  },
  title: {
    flex: 'auto',
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
  },
}))

export const ModalHeader = memo<IModalHeaderProps>(
  ({
    className = '',
    dataTest = 'ModalHeader',
    header,
    includeCloseIconButton = true,
    onClose,
    subtitle = '',
    subtitleVariant = 'subtitle1',
    title,
    titleVariant = 'h3',
  }) => {
    const classes = useModalHeaderStyles()
    return (
      <Box
        display='flex'
        flexDirection='column'
        gap={1}
        className={`${className} ${classes.root}`}
        data-test={dataTest}>
        <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
          <div>
            <MuiDialogTitle className={classes.title} variant={titleVariant}>
              {title}
            </MuiDialogTitle>
            {subtitle && (
              <Typography className={classes.subtitle} variant={subtitleVariant}>
                {subtitle}
              </Typography>
            )}
          </div>
          {includeCloseIconButton && (
            <IconButton
              Icon={CloseIcon}
              onClick={onClose}
              label='Close Modal'
              size='large'
              dataTest={`${dataTest}-close-button`}
              color='secondary'
              className={classes.closeButton}
            />
          )}
        </Box>
        {header && <div>{header}</div>}
      </Box>
    )
  }
)
