import React from 'react'
import { IntercomProvider } from 'react-use-intercom'
import { ApolloProvider } from '@apollo/client'
import { AirhornProvider } from 'airhorn'
import { AuthProvider, IAuthClientOptions } from 'auth/b2b'
import { ToastProvider } from 'dpl/components/Toast'
import { BreakpointProvider, ThemeProvider } from 'dpl/providers'
import { FlagsProvider } from 'flags'
import { getAppGraphQLClient } from 'network'
import { ADMIN_CONSOLE_EVENTS } from './constants/events'
import { useUserPermissionsLazyQuery } from './graphql/UserPermissions'
import { Router } from './routes'

const graphQLClient = getAppGraphQLClient({
  onError: () => {
    // TODO: Handle Errors
  },
  onUnauthorized: () => {
    // TODO: Handle log out
  },
})

const flags = new Set(['test_flag_staging'])

const {
  VITE_BASE_API,
  VITE_CONSOLE_URL,
  VITE_INTERCOM_APP_ID,
  VITE_STYTCH_COOKIE_DOMAIN,
  VITE_STYTCH_PUBLIC_TOKEN,
} = import.meta.env

const clientOptions: IAuthClientOptions = {
  baseApi: `${VITE_BASE_API}/authentication/v1/b2b`,
  publicToken: VITE_STYTCH_PUBLIC_TOKEN,
  cookieOptions: {
    path: '/',
    domain: VITE_STYTCH_COOKIE_DOMAIN,
    opaqueTokenCookieName: 'b2b_auth_session',
    jwtCookieName: 'b2b_auth_session_jwt',
    istCookieName: 'b2b_auth_intermediate_session_token',
    availableToSubdomains: true,
  },
}

export function App() {
  return (
    <IntercomProvider appId={VITE_INTERCOM_APP_ID}>
      <ApolloProvider client={graphQLClient}>
        <FlagsProvider baseApiUrl={VITE_BASE_API} customFlags={flags}>
          <ThemeProvider>
            <AirhornProvider
              appName='Admin Console'
              customFeatures={ADMIN_CONSOLE_EVENTS}
              config={{ service: 'admin' }}>
              <BreakpointProvider>
                <ToastProvider>
                  <AuthProvider
                    clientOptions={clientOptions}
                    discoveryRedirectTo={`${VITE_CONSOLE_URL}/login`}
                    signInRedirectTo={VITE_CONSOLE_URL}
                    usePermissionsLazyQuery={useUserPermissionsLazyQuery}>
                    <Router />
                  </AuthProvider>
                </ToastProvider>
              </BreakpointProvider>
            </AirhornProvider>
          </ThemeProvider>
        </FlagsProvider>
      </ApolloProvider>
    </IntercomProvider>
  )
}
