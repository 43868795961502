import React from 'react'
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent as MuiDialogContent,
  makeStyles,
  Theme,
  Fade as MuiFade,
} from '../../../core'
import { brandColors } from '../../../theme'
import { INavProps, Nav } from '../Nav'
import { APP_BAR_HEIGHT, ORG_NAV_ITEM_HEIGHT } from '../constants'

export interface IMobileNavProps {
  /**
   * Used to render navigation links by section
   * @default []
   */
  navLists?: INavProps['navLists']
  /**
   * Used to render the organization nav button
   * @default false
   */
  isOrgNavButtonEnabled?: INavProps['isOrgNavButtonEnabled']
  /**
   * Props used to render the organization nav button pinned to the bottom of the nav drawer
   * OrgNavButton is only rendered if isOrgNavButtonEnabled is true
   * @default undefined
   */
  orgNavButtonProps?: INavProps['orgNavButtonProps']
  /**
   * Used to render the organization nav item pinned to the bottom of the nav drawer
   * OrgNavItem is only rendered if isOrgNavButtonEnabled is false
   * @default undefined
   */
  orgNavItem?: INavProps['orgNavItem']
  /**
   * Used to indicate if the mobile nav should be open or closed
   * @default false
   */
  isOpen?: MuiDialogProps['open']
  /**
   * @default 'MobileNav'
   */
  dataTest?: string
  /**
   * Set the color of the drawer
   * @default 'dark'
   */
  color?: INavProps['color']
  /**
   * Transfix applications the user has access
   */
  apps?: INavProps['apps']
  /**
   * Function that triggers when user clicks on admin console link
   */
  onClickAdminConsoleLink?: INavProps['onClickAdminConsoleLink']
  /**
   * Function to close the menu
   */
  onClose?: () => void
}

const useMobileNavStyles = makeStyles<Theme, Pick<IMobileNavProps, 'color'>>(theme => ({
  root: {
    top: APP_BAR_HEIGHT,
    bottom: ORG_NAV_ITEM_HEIGHT,
  },
  dialogPaper: {
    background: ({ color }) => (color === 'dark' ? brandColors.coolGray8 : brandColors.coolGray2),
    boxShadow: 'none',
  },
  dialogContent: {
    padding: theme.spacing(2, 0, 0),
  },
}))

export function MobileNav({
  apps,
  dataTest = 'MobileNav',
  isOpen = false,
  navLists = [],
  orgNavItem = undefined,
  color = 'dark',
  onClickAdminConsoleLink,
  onClose,
  isOrgNavButtonEnabled,
  orgNavButtonProps,
}: IMobileNavProps) {
  const classes = useMobileNavStyles({ color })

  return (
    <MuiDialog
      data-test={dataTest}
      open={isOpen}
      className={classes.root}
      PaperProps={{ className: classes.dialogPaper }}
      TransitionComponent={MuiFade}
      fullScreen
      hideBackdrop>
      <MuiDialogContent className={classes.dialogContent}>
        <Nav
          navLists={navLists}
          orgNavItem={orgNavItem}
          orgNavButtonProps={orgNavButtonProps}
          isOrgNavButtonEnabled={isOrgNavButtonEnabled}
          dataTest={dataTest}
          isOpen
          apps={apps}
          color={color}
          onClickAdminConsoleLink={onClickAdminConsoleLink}
          onClose={onClose}
        />
      </MuiDialogContent>
    </MuiDialog>
  )
}
