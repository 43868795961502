import { useState, useCallback } from 'react'
import mapValues from 'lodash/mapValues'

/**
 * Creates a typed state object, along with helper methods to access and toggle modal state
 * @example
 * ```
 * const { modalState, toggleModal } = useModalState({ myModal: false })
 * ```
 */
export const useModalState = <T extends Record<string, boolean>>(initialState: T) => {
  const [modalState, setModalState] = useState<T>({ ...initialState })

  /**
   * Toggles specific modal state, with optional defined state
   * @example toggleModal('myModal', false)
   */
  const toggleModal = useCallback(<K extends keyof T>(keyName: K, value?: boolean) => {
    setModalState(prev => {
      // sets all other states to false
      const newState = {
        ...mapValues(prev, () => false),
      } as T

      // only used value if passed as second argument
      const newValue = typeof value === 'boolean' ? value : !prev[keyName]

      return {
        ...newState,
        [keyName]: newValue,
      }
    })
  }, [])

  /**
   * Generates a toggleHandler function that can be passed as a prop
   * @example const toggleHandler = createToggleHandler('myModal')
   */
  const createToggleHandler = useCallback(
    <K extends keyof T>(keyName: K, value?: boolean) =>
      () => {
        return toggleModal(keyName, value)
      },
    [toggleModal]
  )

  return {
    modalState,
    toggleModal,
    createToggleHandler,
  }
}
