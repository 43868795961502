import React, { useRef, ChangeEvent, ReactNode } from 'react'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import { Button, IButtonProps, makeStyles, createStyles } from '../../core'
import UploadIcon from '../../icons/build/UploadIcon'
import { IFileBody } from '../../types/file'
import { filePromises } from '../../utils/fileUploadHelper'

export interface IUploadFile extends IFileBody {
  uuid: string
}

export interface IUploadButtonInputProps {
  /**
   * @default false
   */
  disabled?: boolean
  /**
   * @default 'Upload'
   */
  label?: ReactNode
  /**
   * Pass props to <Button /> child component
   * @default { size: 'medium', color: 'primary', startIcon: <UploadIcon /> }
   */
  ButtonProps?: Partial<Pick<IButtonProps, 'variant' | 'color' | 'size' | 'startIcon'>>
  /**
   * Pass props to <input type='file /> child component
   * @default { accept: '.pdf, .jpg, .jpeg, .png, .doc, .docx, .tiff, .heic', multiple: true }
   */
  InputProps?: Partial<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  >
  onUpload: (files: IUploadFile[]) => void
  className?: string
  /**
   * @default UploadInputButton
   */
  dataTest?: string
}

const DEFAULT_INPUT_PROPS: IUploadButtonInputProps['InputProps'] = {
  accept: '.pdf, .jpg, .jpeg, .png, .doc, .docx, .tiff, .heic',
  multiple: true,
}

const DEFAULT_BUTTON_PROPS: IUploadButtonInputProps['ButtonProps'] = {
  size: 'medium',
  color: 'primary',
  startIcon: <UploadIcon />,
}

type TClassKey = 'input' | 'label' | 'button'

const useUploadInputStyles = makeStyles(
  createStyles<TClassKey, Pick<IUploadButtonInputProps, 'disabled'>>({
    input: {
      display: 'none',
    },
    label: {
      display: 'inline-block',
      cursor: props => (props.disabled ? 'disabled' : 'pointer'),
    },
    button: {
      transition: 'none',
      cursor: props => (props.disabled ? 'disabled' : 'pointer'),

      /**
       * Overrides global styles in rogers that set
       * styles for `label span` selector
       */
      '& span, & .btn-icon-label': {
        paddingLeft: '4px !important',
        paddingRight: '4px !important',
        lineHeight: 'inherit',
        fontSize: 'inherit',
        marginTop: 0,
        marginBottom: 0,
      },
    },
  })
)

/**
 * Helper component to show a button for uploading files
 */
export function UploadInputButton({
  disabled = false,
  label = 'Upload',
  InputProps = {},
  ButtonProps = {},
  onUpload,
  className,
  dataTest = 'UploadInputButton',
}: IUploadButtonInputProps) {
  const buttonProps = { ...DEFAULT_BUTTON_PROPS, ...ButtonProps }
  const inputProps = { ...DEFAULT_INPUT_PROPS, ...InputProps }

  const id = useRef(uuid())
  const classes = useUploadInputStyles({ disabled })
  const buttonRef = useRef<HTMLButtonElement>(null)

  const changeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e || {}
    const { files } = target || {}

    if (files) {
      const fileList = Array.from(files)
      const packageFiles = await Promise.all(filePromises(fileList))

      const pkgFiles = packageFiles.map(pkg => {
        const pkgFile = pkg as IUploadFile
        pkgFile.uuid = uuid()
        return pkgFile
      })

      if (buttonRef.current) {
        buttonRef.current.blur()
      }

      onUpload(pkgFiles)
    }
  }

  const buttonClasses = classNames(classes.button, className)

  return (
    <Button
      {...buttonProps}
      disabled={disabled}
      className={buttonClasses}
      component='label'
      data-test={dataTest}>
      {label}
      {/**
       * Input is hidden by default.
       * Only element visible is <Button />
       */}
      <input
        {...inputProps}
        className={classes.input}
        id={id.current}
        type='file'
        onChange={changeHandler}
        data-test='file-input'
      />
    </Button>
  )
}

export default UploadInputButton
