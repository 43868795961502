import React from 'react'
import { DataGridAdvancedTextCell } from 'dpl/components/DataGrid'
import { datetimeFormatter } from 'dpl/components/FormattedDateTime'
import { Typography } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'
import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import { IReturnGetRows } from '../utils/grid'

type IDataGridStatusCellProps = IReturnGetRows['status']

const colorTextByStatus: Record<string, string> = {
  deleted: brandColors.error1,
  invited: brandColors.warning1,
  default: brandColors.black,
}

export function DataGridStatusCell({ state, statusChangedAt }: IDataGridStatusCellProps) {
  const isActive = state === 'active'

  const dateTimeFormatted = datetimeFormatter({
    datetime: statusChangedAt,
    format: 'pastOrOngoing',
    isPastAction: !isActive,
  })

  const bottomCaption = startCase(dateTimeFormatted)

  return (
    <DataGridAdvancedTextCell bottomCaption={bottomCaption}>
      <Typography
        style={{ color: colorTextByStatus[state] ?? colorTextByStatus.default }}
        variant='body2'>
        {capitalize(state)}
      </Typography>
    </DataGridAdvancedTextCell>
  )
}

export default DataGridStatusCell
