import React, { useMemo } from 'react'
import kebabCase from 'lodash/kebabCase'
import startCase from 'lodash/startCase'
import { AvatarGroup, makeStyles, Typography, Box, IButtonProps, Button, Theme } from '../../core'
import AdminIcon from '../../icons/build/AdminIcon'
import { brandColors } from '../../theme/colors'
import { Avatar } from '../Avatar'
import { FormattedDateTime } from '../FormattedDateTime'
import { Tooltip } from '../Tooltip'

export interface IOrganizationProfileHeaderProps {
  /**
   * The name of the organization.
   */
  orgName: string
  /**
   * The URL of the organization's logo.
   */
  orgImgSrc?: string
  /**
   * An array of names of the organization's administrators.
   * @default []
   */
  orgAdminNames: string[]
  /**
   * The date when the organization became active, formatted as a string.
   * @example '2023-10-15T04:00:00Z'
   */
  orgActiveDate: string
  /**
   * For each action provided, a button will appear on the right of the title
   * @example {
   *  label: 'Edit Profile',
   *  onClick: () => {},
   *  buttonProps: { variant: 'outlined' }
   * }
   */
  actions?: {
    label: string
    onClick: () => void
    buttonProps?: IButtonProps
  }[]
  /**
   * The status of the organization.
   */
  status: 'active' | 'pending' | 'canceled'
}

const useOrganizationProfileHeaderStyles = makeStyles<
  Theme,
  Pick<IOrganizationProfileHeaderProps, 'status'>
>(theme => ({
  root: {
    background: brandColors.coolGray0,
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  orgName: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      marginBottom: theme.spacing(0.5),
    },
  },
  orgAdmins: {
    color: brandColors.coolGray5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginBottom: theme.spacing(1.5),
    },
  },
  orgAdminTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  orgActiveDate: ({ status }) => ({
    color: status === 'pending' ? brandColors.warning : brandColors.coolGray5,
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  }),
  actions: {
    selfAlign: 'flex-end',
  },
}))

const MAX_SHOWN_ADMIN = 5

export function OrganizationProfileHeader({
  actions = [],
  orgAdminNames = [],
  orgName,
  orgActiveDate,
  orgImgSrc,
  status,
}: IOrganizationProfileHeaderProps) {
  const classes = useOrganizationProfileHeaderStyles({ status })
  const shownAdmin = useMemo(() => orgAdminNames.slice(0, MAX_SHOWN_ADMIN), [orgAdminNames])
  const remainingAdminsCount = useMemo(
    () => orgAdminNames.length - MAX_SHOWN_ADMIN,
    [orgAdminNames]
  )
  const haveAdmins = useMemo(() => orgAdminNames.length > 0, [orgAdminNames])

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <div>
          <Avatar variant='rounded' name={orgName} size='xlarge' src={orgImgSrc} hideTooltip />
        </div>
        <div>
          <Typography variant='h3' className={classes.orgName}>
            {orgName}
          </Typography>
          <div className={classes.orgAdmins}>
            {status === 'active' && (
              <>
                <div className={classes.orgAdminTitle}>
                  <Tooltip title='Organization Admin'>
                    <div>
                      <AdminIcon size='large' color={haveAdmins ? 'coolGray5' : 'warning'} />
                    </div>
                  </Tooltip>
                  {haveAdmins ? (
                    <Typography variant='subtitle1'>Organization Admin</Typography>
                  ) : (
                    <Typography color={brandColors.warning} variant='subtitle1'>
                      No Organization Admin Assigned
                    </Typography>
                  )}
                </div>
                <Box display='flex' alignItems='center' gap={0.5} data-test='app-admin'>
                  <AvatarGroup max={MAX_SHOWN_ADMIN}>
                    {shownAdmin.map(admin => (
                      <Avatar key={admin} name={admin} size='xsmall' data-test='shown-app-admin' />
                    ))}
                  </AvatarGroup>

                  {remainingAdminsCount > 0 && (
                    <Typography variant='body2' component='div' data-test='remaining-app-admin'>
                      +{remainingAdminsCount}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </div>
          <Typography variant='caption' component='div' className={classes.orgActiveDate}>
            {startCase(status)} since{' '}
            <FormattedDateTime datetime={orgActiveDate} format='dateAndYear' />
          </Typography>
        </div>
      </div>
      <div>
        {actions.map(action => {
          const { label, onClick, buttonProps = {} } = action || {}
          return (
            <Button
              className={classes.actions}
              key={label}
              data-test={`${kebabCase(label)}-button`}
              variant='text'
              onClick={onClick}
              {...buttonProps}>
              {label}
            </Button>
          )
        })}
      </div>
    </div>
  )
}
