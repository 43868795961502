import React from 'react'
import { Typography, makeStyles } from '../../../../core'
import SearchIcon from '../../../../icons/build/SearchIcon'
import { brandColors } from '../../../../theme'

const useDataGridNoRowsOverlayStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1.5),
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
  },
  searchIcon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: brandColors.coolGray3,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export function DataGridNoRowsOverlay() {
  const classes = useDataGridNoRowsOverlayStyles()
  return (
    <div className={classes.root} data-test='DataGridNoRowsOverlay'>
      <div className={classes.searchIcon}>
        <SearchIcon size='xlarge' color='coolGray5' />
      </div>
      <Typography variant='h4' color={brandColors.black}>
        No Results
      </Typography>
    </div>
  )
}
