import React, { useCallback } from 'react'
import Switch, { ISwitchProps } from '../../../../../Switch'
import { IDataGridFilterProps } from '../types'

export interface IDataGridSwitchProps
  extends IDataGridFilterProps<boolean>,
    Pick<ISwitchProps, 'label'> {
  /**
   * @default 'DataGridSwitch'
   */
  dataTest?: string
}

export function DataGridSwitch({
  dataTest = 'DataGridSwitch',
  label,
  name,
  onChange,
  value,
  variant,
  ...props
}: IDataGridSwitchProps) {
  const changeHandler: ISwitchProps['onChange'] = useCallback(
    event => {
      const { target } = event || {}
      const { checked } = target || {}
      onChange({ name, value: checked })
    },
    [name, onChange]
  )

  return (
    <Switch
      dataTest={dataTest}
      color='primary'
      onChange={changeHandler}
      checked={Boolean(value)}
      value
      label={variant === 'header' ? label : undefined}
      {...props}
    />
  )
}
