import { DISPLAY } from '../../../constants'
import { GridColTypeDef as MuiGridColTypeDef } from '../../../core'
import { formatRelativeTime } from '../../RelativeDateTime'

export const relativeDateGridColTypeDef: MuiGridColTypeDef = {
  type: 'dateTime',
  valueFormatter: value => {
    return value ? formatRelativeTime({ datetime: value, inSentence: true }) : DISPLAY.empty
  },
}
