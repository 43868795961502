import React, { createContext, useContext, useMemo, useState, PropsWithChildren } from 'react'
import { useEffectOnce } from 'react-use'
import { makeApiRequest, useRequest } from 'network'
import { GLOBAL_FLAGS } from './globalFlags'

interface FlagsContextState {
  hasLoaded: boolean
  isFlagEnabled: (flagName: string) => boolean
  toggleFlag: (flagName: string) => void
}

export const FlagsContext = createContext<FlagsContextState>({
  hasLoaded: false,
  isFlagEnabled: () => false,
  toggleFlag: () => {},
})

type FlagsProviderProps = PropsWithChildren<{
  customFlags?: Set<string>
  baseApiUrl: string
}>

interface IFeatureFlagResponseBody {
  flags:
    | {
        key: string
        enabled: string
      }[]
    | null
}

function getFeatureFlagsRequest(baseApiUrl: string) {
  return makeApiRequest<void, IFeatureFlagResponseBody>({
    url: `${baseApiUrl}/broker-platform/flags`,
    method: 'GET',
  })
}

const DEFAULT_EMPTY_FLAGS = new Set<string>()

export function FlagsProvider({
  children,
  customFlags = DEFAULT_EMPTY_FLAGS,
  baseApiUrl,
}: FlagsProviderProps) {
  const [flags, setFlags] = useState(new Set<string>())
  const [hasLoaded, setHasLoaded] = useState(false)
  const [getFeatureFlags] = useRequest({
    requestFn: getFeatureFlagsRequest,
  })

  /**
   * useEffect to load enabled flags from all potential sources and
   * creating/setting the enabledFlags Set with the result.
   */
  useEffectOnce(() => {
    const initFeatureFlags = async () => {
      try {
        const definedFlags = GLOBAL_FLAGS.union(customFlags)
        const enabledFlags = new Set<string>()

        /**
         * Add enabled flags via query params.
         */
        const queryParams = new URL(window.location.href).searchParams

        queryParams.forEach((queryParamValue, queryParamName) => {
          if (definedFlags.has(queryParamName) && queryParamValue === 'true') {
            enabledFlags.add(queryParamName)
          }
        })

        /**
         * Add enabled flags via api.
         */
        const { data } = await getFeatureFlags(baseApiUrl)
        const { flags = [] } = data || {}

        flags?.forEach(({ enabled, key }) => {
          if (definedFlags.has(key) && enabled) {
            enabledFlags.add(key)
          }
        })

        setFlags(enabledFlags)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error)
      } finally {
        setHasLoaded(true)
      }
    }

    initFeatureFlags()
  })

  const flagsState = useMemo<FlagsContextState>(() => {
    const toggleFlag = (flagName: string) => {
      setFlags(prev => {
        const prevFlags = new Set([...prev])

        if (prevFlags.has(flagName)) {
          prevFlags.delete(flagName)
        } else {
          prevFlags.add(flagName)
        }

        return prevFlags
      })
    }

    const isFlagEnabled = (flagName: string) => flags.has(flagName)

    return {
      hasLoaded,
      isFlagEnabled,
      toggleFlag,
    }
  }, [hasLoaded, flags])

  return (
    <FlagsContext.Provider value={flagsState}>
      {/* Avoid rendering the rest until feature flags are ready. */}
      {hasLoaded && children}
    </FlagsContext.Provider>
  )
}

export const useFlagsContext = () => useContext(FlagsContext)
