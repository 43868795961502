import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import {
  ElementDragType,
  EventPayloadMap,
} from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview'
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import classNames from 'classnames'
import { makeStyles } from '../../../core'
import { brandColors, shadows } from '../../../theme'
import { DropIndicator } from '../../DraggableList/components'
import { IBaseItem } from '../types'

type TItemStateType = 'idle' | 'preview' | 'is-dragging' | 'is-dragging-over'
type TItemState = {
  type: TItemStateType
  closestEdge?: Edge | null
}
const idle: TItemState = { type: 'idle' }
const draggingState: TItemState = { type: 'is-dragging' }

export interface IKanbanItemProps<TItem extends IBaseItem> {
  /**
   * Children to render
   */
  children: React.ReactNode
  /**
   * Item to display
   */
  item: TItem
  /**
   * Data attribute for testing
   */
  dataTest?: string
  /**
   * ID of the column
   */
  columnId: string
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: brandColors.white,
    border: `1px solid ${brandColors.coolGray3}`,
    borderRadius: theme.spacing(0.5),
    boxShadow: shadows[1],
    cursor: 'grab',
    position: 'relative',
  },
  dragging: {
    opacity: 0.5,
  },
  dropIndicator: {
    transform: 'translateY(-6px)',
  },
}))

export function KanbanItem<TItem extends IBaseItem>({
  children,
  columnId,
  dataTest,
  item,
}: IKanbanItemProps<TItem>) {
  const classes = useStyles()
  const ref = useRef<HTMLDivElement>(null)
  const [dragging, setDragging] = useState<boolean>(false)
  const [state, setState] = useState<TItemState>(idle)
  const [previewContainer, setPreviewContainer] = useState<{
    container: HTMLElement
    rect: DOMRect
  }>()

  const DragPreview = (
    <div
      style={{
        boxSizing: 'border-box',
        width: previewContainer?.rect.width ? previewContainer.rect.width + 8 : 'auto',
        height: previewContainer?.rect.height ? previewContainer.rect.height + 8 : 'auto',
        backgroundColor: brandColors.white,
        border: `4px solid ${brandColors.skyBlue3}`,
        borderRadius: 4,
      }}>
      {children}
    </div>
  )

  useEffect(() => {
    const el = ref.current

    if (!el) return undefined

    return combine(
      draggable({
        element: el,
        getInitialData: () => ({
          item,
          columnId,
        }),
        onDragStart: () => setDragging(true),
        onDrop: () => setDragging(false),
        onGenerateDragPreview({
          nativeSetDragImage,
          source,
        }: EventPayloadMap<ElementDragType>['onGenerateDragPreview']) {
          const rect = source.element.getBoundingClientRect()
          setCustomNativeDragPreview({
            nativeSetDragImage,
            render({ container }) {
              setPreviewContainer({ container, rect })
              setState({ type: 'preview' })
              return () => setState(draggingState)
            },
          })
        },
      }),
      dropTargetForElements({
        element: el,
        canDrop: ({ source }) => source.data.columnId === columnId,
        getIsSticky: () => true,
        getData({ input }) {
          return attachClosestEdge(
            {
              columnId,
              id: item.id,
            },
            {
              element: el,
              input,
              allowedEdges: ['top', 'bottom'],
            }
          )
        },
        onDrag({ self }) {
          const closestEdgeTarget = extractClosestEdge(self.data)

          if (state.type === 'is-dragging-over' && state.closestEdge === closestEdgeTarget) {
            setState({
              type: 'is-dragging-over',
              closestEdge: state.closestEdge,
            })
          } else {
            setState({
              type: 'is-dragging-over',
              closestEdge: closestEdgeTarget,
            })
          }
        },
        onDragLeave() {
          setState(idle)
        },
        onDrop() {
          setState(idle)
        },
      })
    )
  }, [item, columnId, state, setState])

  const rootClasses = classNames(classes.root, {
    [classes.dragging]: dragging,
  })

  return (
    <>
      <div data-test={dataTest || `item-${item?.id}`} className={rootClasses} ref={ref}>
        {children}
        {state.type === 'is-dragging-over' && state.closestEdge && (
          <DropIndicator edge={state.closestEdge} offset={7} />
        )}
      </div>
      {state.type === 'preview' && previewContainer?.container
        ? createPortal(DragPreview, previewContainer.container)
        : null}
    </>
  )
}
