import React, { memo, useCallback } from 'react'
import classNames from 'classnames'
import { Avatar } from '../../../../components/Avatar'
import { IconButton } from '../../../../components/IconButton'
import { makeStyles, Typography, ListItem, MenuList } from '../../../../core'
import CloseIcon from '../../../../icons/build/CloseIcon'
import LogOutIcon from '../../../../icons/build/LogOutIcon'
import UserIcon from '../../../../icons/build/UserIcon'
import { UserAppBarMenuItem } from './UserAppBarMenuItem'

export interface IUserAppBarMenuContentsProps {
  /**
   * Name of user, displayed in the menu and in the Avatar
   */
  name?: string
  /**
   * Email of user
   */
  email: string
  /**
   * Click action done when a menu item is selected
   */
  onClick: () => void
  /**
   * Class applied to menu
   * @optional
   */
  className?: string
  /**
   * Whether or not to show the 'X' button in top right of menu
   * @default false
   */
  showCloseButton?: boolean
  /**
   * The path to the User Profile page
   */
  userProfileHref?: string
  /**
   * Callback that handles logging out
   */
  onLogout: () => void
}

const useUserAppBarMenuContentsStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      '& li:last-of-type': {
        '&:hover, &:focus, &:active': {
          borderRadius: '0 0 6px 6px',
        },
      },
    },
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(0.25),
    },
  },
  menuHeader: {
    flexDirection: 'column',
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
}))

export const UserAppBarMenuContents = memo<IUserAppBarMenuContentsProps>(
  ({ className, email, name, onClick, onLogout, showCloseButton = false, userProfileHref }) => {
    const classes = useUserAppBarMenuContentsStyles()
    const rootClassNames = classNames(className, classes.root)

    const logoutHandler = useCallback(() => {
      onLogout()
      onClick()
    }, [onClick, onLogout])

    const keyDownHandler = (event: React.KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClick()
      }
    }

    return (
      <MenuList
        id='user-menu'
        aria-labelledby='open-user-menu-button'
        className={rootClassNames}
        onKeyDown={keyDownHandler}
        disablePadding>
        <ListItem divider className={classes.menuHeader}>
          {showCloseButton && (
            <IconButton
              className={classes.closeButton}
              Icon={CloseIcon}
              size='xlarge'
              onClick={onClick}
              color='secondary'
            />
          )}
          <div className={classes.profileInfo}>
            <Avatar size='medium' name={name} hideTooltip dataTest='user-menu-avatar' />
            <div>
              <Typography variant='subtitle2'>{name}</Typography>
              <Typography variant='caption' color='textSecondary'>
                {email}
              </Typography>
            </div>
          </div>
        </ListItem>
        {userProfileHref && (
          <UserAppBarMenuItem
            href={userProfileHref}
            onClick={onClick}
            dataTest='user-menu-profile-button'
            divider
            autoFocus
            Icon={UserIcon}
            label='Profile'
          />
        )}
        <UserAppBarMenuItem
          onClick={logoutHandler}
          dataTest='user-menu-logout-button'
          Icon={LogOutIcon}
          label='Log Out'
          color='error'
        />
      </MenuList>
    )
  }
)
