import React, { useCallback, useState } from 'react'
import { UnpackNestedValue } from 'react-hook-form'
import { IconButton } from 'dpl/components/IconButton'
import { Button, makeStyles, Stack, Typography, Link } from 'dpl/core'
import { EditIcon } from 'dpl/icons'
import { brandColors } from 'dpl/theme'
import ControlledHiddenInput from 'forms/components/ControlledHiddenInput'
import { ControlledPasswordField } from 'forms/components/ControlledPasswordField'
import { ControlledTextField } from 'forms/components/ControlledTextField'
import { IApiErrorResponse } from 'network/src/rest/hooks/useRequest'
import { getAuthApiErrorMessage } from '../../utils/getAuthApiErrorMessage'
import { usePasswordOrMagicLinkLoginForm } from './hooks'
import { TPasswordOrMagicLinkLoginFormSchema } from './schema'

const usePasswordOrMagicLinkLoginFormStyles = makeStyles(theme => ({
  defaultEmail: {
    display: 'flex',
    gap: theme.spacing(1.25),
    alignItems: 'center',
    backgroundColor: brandColors.coolGray1,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
  },
  emailValue: {
    flex: 1,
  },
  editIconButton: {
    '& svg': {
      color: brandColors.skyBlue6,
    },
  },
  passwordContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    gap: theme.spacing(1.5),
  },
  button: {
    display: 'block',
  },
}))

export interface IPasswordOrMagicLinkLoginFormProps {
  /**
   * Default email form value, provided from initial email form step
   * @optional
   */
  defaultEmail?: string
  /**
   * Callback fired when Forgot Password button is clicked
   * @optional Needed if password option is allowed
   */
  onForgotPasswordClick?: (email: string) => void
  /**
   * If allowed, shows the password and log in button
   * @default true
   */
  isPasswordAllowed?: boolean
  /**
   * Callback to submit log in form with password
   * @optional Needed if password option is allowed
   */
  onSubmit?: (
    data: UnpackNestedValue<TPasswordOrMagicLinkLoginFormSchema>
  ) => Promise<IApiErrorResponse | undefined>
  /**
   * If allowed, shows the Send Magic Link button for log in
   * @default true
   */
  isMagicLinkAllowed: boolean
  /**
   * Callback fired when Send Magic Link button is clicked if email is valid
   * @optional Needed if magic link login option is shown
   */
  onSendMagicLinkClick?: (email: string) => void
  /**
   * If form actions are disabled
   */
  disabled: boolean
}

export function PasswordOrMagicLinkLoginForm({
  defaultEmail = '',
  disabled,
  isMagicLinkAllowed = true,
  isPasswordAllowed = true,
  onForgotPasswordClick,
  onSendMagicLinkClick,
  onSubmit: onSubmitProp,
}: IPasswordOrMagicLinkLoginFormProps) {
  const classes = usePasswordOrMagicLinkLoginFormStyles()

  const [isEditingEmail, setIsEditingEmail] = useState(!defaultEmail)

  const methods = usePasswordOrMagicLinkLoginForm({
    mode: 'onSubmit',
    defaultValues: {
      email: defaultEmail,
      password: '',
    },
  })
  const { control, errors, handleSubmit, setError: setFormError, trigger, watch } = methods || {}
  const { email: emailFormValue } = watch() || {}

  const onSubmit = async (formData: TPasswordOrMagicLinkLoginFormSchema) => {
    if (onSubmitProp) {
      const { errors, success } = (await onSubmitProp(formData)) || {}
      if (!success) {
        setFormError('password', { message: getAuthApiErrorMessage(errors) })
      }
    }
  }

  const submitHandler = handleSubmit(onSubmit)

  const editEmailClickHandler = useCallback(() => {
    setIsEditingEmail(true)
  }, [])

  const magicLinkButtonClickHandler = useCallback(async () => {
    let isValidEmail = true
    if (isEditingEmail) {
      // Retrigger email validation if email has been changed
      isValidEmail = await trigger('email')
    }
    if (isValidEmail && onSendMagicLinkClick) {
      onSendMagicLinkClick(emailFormValue)
    }
  }, [emailFormValue, isEditingEmail, onSendMagicLinkClick, trigger])

  const forgotPasswordClickHandler = useCallback(async () => {
    let isValidEmail = true
    if (isEditingEmail) {
      // Retrigger email validation if email has been changed
      isValidEmail = await trigger('email')
    }
    if (isValidEmail && onForgotPasswordClick) {
      onForgotPasswordClick(emailFormValue)
    }
  }, [emailFormValue, isEditingEmail, onForgotPasswordClick, trigger])

  return (
    <form data-test='sign-in-form' onSubmit={submitHandler}>
      <Stack role='form' spacing={2}>
        {!!defaultEmail && !isEditingEmail && (
          <div className={classes.defaultEmail} data-test='default-email-box'>
            <Typography variant='body1' className={classes.emailValue}>
              {defaultEmail}
            </Typography>
            <IconButton
              Icon={EditIcon}
              onClick={editEmailClickHandler}
              className={classes.editIconButton}
              color='secondary'
              size='large'
              dataTest='edit-email-button'
              label='Edit email'
            />
            <ControlledHiddenInput dataTest='email-field' name='email' control={control} />
          </div>
        )}
        {isEditingEmail && (
          <ControlledTextField
            control={control}
            name='email'
            TextFieldProps={{
              dataTest: 'email-field',
              label: 'Email',
              error: Boolean(errors.email?.message),
              helperText: errors.email?.message,
              size: 'medium',
              autoComplete: 'username',
            }}
          />
        )}

        {isPasswordAllowed && (
          <div className={classes.passwordContainer}>
            <ControlledPasswordField
              control={control}
              name='password'
              PasswordFieldProps={{
                dataTest: 'password-field',
                label: 'Password',
                error: Boolean(errors.password?.message),
                helperText: errors.password?.message,
                size: 'medium',
                // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
                autoComplete: 'current-password',
              }}
            />
            <Link
              data-test='forgot-pw-button'
              component='button'
              type='button'
              onClick={forgotPasswordClickHandler}
              underline='always'
              textAlign='left'
              alignSelf='flex-start'
              color='textSecondary'>
              Forgot password
            </Link>
          </div>
        )}

        <div className={classes.actions}>
          {isPasswordAllowed && (
            <Button fullWidth type='submit' data-test='login-button' disabled={disabled}>
              Log In
            </Button>
          )}

          {isMagicLinkAllowed && (
            <>
              {isPasswordAllowed && <Typography variant='caption'>OR</Typography>}
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                onClick={magicLinkButtonClickHandler}
                data-test='send-magic-link-button'
                disabled={disabled}>
                Get one-time login link
              </Button>
            </>
          )}
        </div>
      </Stack>
    </form>
  )
}
