import React from 'react'
import { LinearProgress as MuiLinearProgress, LinearProgressProps, makeStyles } from '../../core'
import { brandColors } from '../../theme/colors'

export interface ILinearProgressProps extends Omit<LinearProgressProps, 'classes' | 'color'> {}

const useProgressBarStyles = makeStyles(theme => ({
  root: {
    backgroundColor: brandColors.coolGray5,
    borderRadius: theme.spacing(1),
  },
  bar: {
    backgroundColor: brandColors.warning1,
    borderRadius: theme.spacing(1),
  },
}))

function LinearProgress({ ...args }: ILinearProgressProps) {
  const classes = useProgressBarStyles()
  return <MuiLinearProgress classes={classes} {...args} />
}

export default LinearProgress
