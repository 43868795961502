import { Components } from '@mui/material/styles'

export const MuiTextFieldThemeOptions: Components['MuiTextField'] = {
  defaultProps: {
    fullWidth: true,
    size: 'medium',
    variant: 'outlined',
    margin: 'none',
  },
}
