import { StytchClientOptions, StytchB2BHeadlessClient } from '@stytch/vanilla-js/b2b/headless'
import ky from 'ky'
import { makeDiscoveryClient, IDiscoveryClient } from '../discovery'
import { makeMagicLinksClient, IMagicLinksClient } from '../magicLinks'
import { IOrganizationsClient, makeOrganizationsClient } from '../organizations'
import { makePasswordsClient, IPasswordsClient } from '../passwords'
import { makeSessionsClient, ISessionsClient } from '../sessions'

export interface IAuthClientOptions {
  /**
   * @description Root URL for the authentication API.
   * @example 'https://api.example.com/authentication/v1/consumer'
   */
  baseApi: string
  cookieOptions: StytchClientOptions['cookieOptions']
  /**
   * @description Token to authenticate the Stytch SDK.
   */
  publicToken: string
}

export interface IAuthClient {
  discovery: IDiscoveryClient
  magicLinks: IMagicLinksClient
  organizations: IOrganizationsClient
  passwords: IPasswordsClient
  sessions: ISessionsClient
  stytch: StytchB2BHeadlessClient
}

export const makeAuthClient = ({
  baseApi,
  cookieOptions,
  publicToken,
}: IAuthClientOptions): IAuthClient => {
  const connection = ky.create({
    prefixUrl: baseApi,
    credentials: 'include',
  })

  /**
   * Use for endpoints not yet built into our own backend.
   */
  const stytch = new StytchB2BHeadlessClient(publicToken, {
    cookieOptions,
  })
  const discovery = makeDiscoveryClient({
    connection,
  })
  const magicLinks = makeMagicLinksClient({
    connection,
  })
  const organizations = makeOrganizationsClient({
    connection,
    stytch,
  })
  const passwords = makePasswordsClient({
    connection,
  })
  const sessions = makeSessionsClient({
    connection,
    stytch,
  })

  return {
    discovery,
    magicLinks,
    organizations,
    passwords,
    sessions,
    stytch,
  }
}
