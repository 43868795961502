export const borderTopRadius = (radii: number) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: radii,
  borderTopRightRadius: radii,
})

export const borderRightRadius = (radii: number) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: radii,
  borderTopLeftRadius: 0,
  borderTopRightRadius: radii,
})

export const borderBottomRadius = (radii: number) => ({
  borderBottomLeftRadius: radii,
  borderBottomRightRadius: radii,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
})

export const borderLeftRadius = (radii: number) => ({
  borderBottomLeftRadius: radii,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: radii,
  borderTopRightRadius: 0,
})

export const borderLeftBottomRadius = (radii: number) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: radii,
  borderTopLeftRadius: radii,
  borderTopRightRadius: radii,
})

export const borderRightBottomRadius = (radii: number) => ({
  borderBottomLeftRadius: radii,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: radii,
  borderTopRightRadius: radii,
})
