import React, { memo } from 'react'
import classNames from 'classnames'
import { Checkbox, GridRenderCellParams, makeStyles, useGridApiContext } from '../../../../core'

const useDataGridCheckboxCellStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editing: {
    padding: theme.spacing(2, 1.5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1.5),
    },
  },
}))

export interface IDataGridCheckboxCellProps extends GridRenderCellParams {
  /**
   * @description Determines if checkbox should be checked or unchecked
   */
  checked: boolean
  /**
   * @description Label to display besides checkbox
   */
  label?: string
}

export const DataGridCheckboxCell = memo<IDataGridCheckboxCellProps>(
  ({ checked, field, id, label }) => {
    const classes = useDataGridCheckboxCellStyles()
    const apiRef = useGridApiContext()
    const cellMode = apiRef.current.getCellMode(id, field)
    const isEditMode = cellMode === 'edit'
    const rootClasses = classNames(classes.root, {
      [classes.editing]: isEditMode,
    })

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked
      if (!isEditMode) {
        await apiRef.current.startCellEditMode({ id, field })
      }
      await apiRef.current.setEditCellValue({ id, field, value: newValue })
      await apiRef.current.stopCellEditMode({ id, field })
    }

    return (
      <div className={rootClasses} data-test='DataGridCheckboxCell'>
        <Checkbox checked={checked} onChange={handleChange} />
        {label}
      </div>
    )
  }
)
