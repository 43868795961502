import { DefaultValues, useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { OrganizationEditFormSchema, TOrganizationEditFormSchema } from '../schema'

export const getDefaultValues = <TFieldValues>(values: DefaultValues<TFieldValues>) => {
  return OrganizationEditFormSchema.cast({
    ...values,
  })
}

export interface IUseEditOrganizationFormProps
  extends Omit<UseFormOptions<TOrganizationEditFormSchema>, 'resolver'> {}

export const useOrganizationEditForm = ({
  defaultValues = {},
  ...rest
}: IUseEditOrganizationFormProps) => {
  return useForm<TOrganizationEditFormSchema>({
    defaultValues: getDefaultValues(defaultValues),
    resolver: yupResolver(OrganizationEditFormSchema),
    ...rest,
  })
}
