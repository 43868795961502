import { createContext, useContext } from 'react'
import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/types'

export interface IDraggableListContextValue {
  listLength: number
  reorderItem: (args: {
    startIndex: number
    indexOfTarget: number
    closestEdgeOfTarget: Edge | null
  }) => void
  instanceId: symbol
}

export const DraggableListContext = createContext<IDraggableListContextValue>({
  listLength: 0,
  reorderItem: () => {},
  instanceId: Symbol('list'),
})

export const useDraggableListContext = () => useContext(DraggableListContext)
