import {
  B2BSessionAuthenticateResponse,
  SessionAuthenticateOptions,
  StytchB2BHeadlessClient,
} from '@stytch/vanilla-js/b2b/headless'
import { KyInstance } from 'ky'
import {
  IB2BSessionInfoResponse,
  IExchangeIntermediateSessionRequestBody,
  ICreateWithMagicLinkTokenRequestBody,
  ICreateWithPasswordRequestBody,
  ICreateWithSsoTokenRequestBody,
  IDeleteSessionResponseBody,
} from './types'

export interface ISessionsClientOptions {
  connection: KyInstance
  stytch: StytchB2BHeadlessClient
}

export interface ISessionsClient {
  authenticate: (options?: SessionAuthenticateOptions) => Promise<B2BSessionAuthenticateResponse>
  createWithMagicLinkToken: (
    data: ICreateWithMagicLinkTokenRequestBody
  ) => Promise<IB2BSessionInfoResponse>
  createWithPassword: (data: ICreateWithPasswordRequestBody) => Promise<IB2BSessionInfoResponse>
  createWithSsoToken: (data: ICreateWithSsoTokenRequestBody) => Promise<IB2BSessionInfoResponse>
  deleteSession: () => Promise<IDeleteSessionResponseBody>
  exchangeIntermediateSession: (
    data: IExchangeIntermediateSessionRequestBody
  ) => Promise<IB2BSessionInfoResponse>
}

export const SESSIONS_ENDPOINTS = {
  createWithMagicLinkToken: 'session/create_with_magic_link_token',
  createWithPassword: 'session/create_with_password',
  createWithSsoToken: 'session/create_with_sso_token',
  deleteSession: 'session',
  exchangeIntermediateSession: 'session/exchange_intermediate_session',
}

export const makeSessionsClient = ({
  connection,
  stytch,
}: ISessionsClientOptions): ISessionsClient => {
  return {
    authenticate: data => {
      return stytch.session.authenticate(data)
    },
    createWithMagicLinkToken: data => {
      return connection.post(SESSIONS_ENDPOINTS.createWithMagicLinkToken, { json: data }).json()
    },
    createWithPassword: data => {
      return connection.post(SESSIONS_ENDPOINTS.createWithPassword, { json: data }).json()
    },
    createWithSsoToken: data => {
      return connection.post(SESSIONS_ENDPOINTS.createWithSsoToken, { json: data }).json()
    },
    deleteSession: () => {
      return connection.delete(SESSIONS_ENDPOINTS.deleteSession).json()
    },
    exchangeIntermediateSession: data => {
      return connection.post(SESSIONS_ENDPOINTS.exchangeIntermediateSession, { json: data }).json()
    },
  }
}
