import React from 'react'
import {
  NumericFormat as ReactNumericFormat,
  NumericFormatProps as ReactNumericFormatProps,
} from 'react-number-format'
import TextField, { ITextFieldProps } from '../TextField'

type TPickedProp =
  | 'allowLeadingZeros'
  | 'allowNegative'
  | 'aria-label'
  | 'autoFocus'
  | 'className'
  | 'decimalScale'
  | 'disabled'
  | 'displayType'
  | 'fixedDecimalScale'
  | 'getInputRef'
  | 'id'
  | 'name'
  | 'onBlur'
  | 'onValueChange'
  | 'placeholder'
  | 'prefix'
  | 'renderText'
  | 'suffix'
  | 'thousandSeparator'
  | 'type'
  | 'value'
  | 'valueIsNumericString'

export interface INumericFormatProps
  extends Pick<ReactNumericFormatProps, TPickedProp>,
    Omit<ITextFieldProps, 'defaultValue' | 'inputRef' | 'onBlur' | 'type' | 'value'> {}

function NumericFormat(props: INumericFormatProps) {
  return <ReactNumericFormat {...props} customInput={TextField} />
}

export default NumericFormat
