import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Color from 'color'
import { Avatar } from '../../../../components/Avatar'
import { Tooltip } from '../../../../components/Tooltip'
import { ButtonBase, makeStyles, Theme, Typography } from '../../../../core'
import { OpenInNewTabIcon } from '../../../../icons'
import { brandColors } from '../../../../theme'

export interface IOrgNavItemProps {
  /**
   * Organization name
   */
  name: string
  /**
   * Organization logo img src
   */
  imgSrc?: string
  /**
   * Path for the Organization link
   */
  href: string
  /**
   * @default true
   */
  isOpen?: boolean
  /**
   * @default 'OrgNavItem'
   */
  dataTest?: string
  /**
   * can access org admin experience?
   * @default false
   */
  isAdmin?: boolean
  /**
   * Function that triggers when user clicks on admin console link
   */
  onClickAdminConsoleLink?: () => void
  /**
   * Function to close the menu
   */
  onClose?: () => void
}

const useOrgNavItemStyles = makeStyles<Theme, Pick<IOrgNavItemProps, 'isOpen'>>(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'center',
    color: brandColors.white,
  },
  avatarButtonBase: {
    borderRadius: theme.spacing(1),
    '&:hover, &:focus, &:active': {
      boxShadow: `0 0 0 4px ${Color(brandColors.white).fade(0.6)}`,
    },
  },
  adminConsoleLink: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    cursor: 'pointer',
  },
}))

export function OrgNavItem({
  dataTest = 'OrgNavItem',
  href,
  imgSrc,
  isAdmin = false,
  isOpen = true,
  name,
  onClickAdminConsoleLink,
  onClose,
}: IOrgNavItemProps) {
  const classes = useOrgNavItemStyles({ isOpen })
  return (
    <div data-test={dataTest} className={classes.root}>
      <Tooltip title={name} placement='right' color='light' disabled={isOpen}>
        <ButtonBase
          to={href}
          className={classes.avatarButtonBase}
          component={RouterLink}
          onClick={onClose}>
          <Avatar
            variant='rounded'
            size='large'
            src={imgSrc}
            alt={name}
            name={name}
            data-test={`${dataTest}-avatar`}
            hideTooltip
          />
        </ButtonBase>
      </Tooltip>
      <div>
        {isOpen && (
          <Typography variant='subtitle2' data-test={`${dataTest}-name`}>
            {name}
          </Typography>
        )}
        {isAdmin && onClickAdminConsoleLink && (
          <Typography
            variant='caption'
            data-test={`${dataTest}-admin-console-link`}
            className={classes.adminConsoleLink}
            onClick={onClickAdminConsoleLink}>
            Admin Console
            <OpenInNewTabIcon size='small' />
          </Typography>
        )}
      </div>
    </div>
  )
}
