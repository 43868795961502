import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { RumInitConfiguration } from '@datadog/browser-rum'
import { initDDR, identifyUserDDR, clearUserDDR } from './config'
import { trackEvent } from './trackEvent'
import { IUserDetails } from './types'

type TGenericFeatures = Record<string, Record<string, string>>
interface IAirhornState<Features = TGenericFeatures> {
  hasLoadedAirhorn: boolean
  features: Features
  trackEvent: <K extends keyof Features>(
    feature: K,
    event: keyof Features[K],
    properties?: Record<string, any>
  ) => void
  identifyUser: (userDetails: IUserDetails) => void
  clearUser: () => void
}

const airhornDefaultState = {
  hasLoadedAirhorn: false,
  features: {},
  trackEvent: () => {},
  identifyUser: () => {},
  clearUser: () => {},
}

const SHOULD_TRACK = ['production', 'staging', 'uat'].includes(import.meta.env.MODE)

const AirhornContext = createContext<IAirhornState>(airhornDefaultState)

export interface IAirhornProps<F extends TGenericFeatures> extends PropsWithChildren<{}> {
  config?: Partial<RumInitConfiguration>
  customFeatures?: F
  appName: string
}

export function AirhornProvider<Features extends TGenericFeatures>({
  appName,
  children,
  config,
  customFeatures,
}: IAirhornProps<Features>) {
  const [hasLoadedAirhorn, setHasLoadedAirhorn] = useState<boolean>(false)
  const [features, setFeatures] = useState<Features>()

  const trackEventWithFeatures: IAirhornState<Features>['trackEvent'] = useCallback(
    (feature, event, properties) => {
      if (!features?.[feature]) {
        throw new Error('Feature does not exist')
      }

      if (!features[feature][event]) {
        throw new Error('Event does not exist')
      }

      const eventValue = `${appName} > ${String(feature)} > ${features[feature][event]}`
      trackEvent(eventValue, properties)
    },
    [features, appName]
  )

  const identifyUser = useCallback((userDetails: IUserDetails) => {
    if (SHOULD_TRACK) {
      identifyUserDDR(userDetails)
    }
  }, [])

  const clearUser = useCallback(() => {
    if (SHOULD_TRACK) {
      clearUserDDR()
    }
  }, [])

  useEffect(() => {
    if (SHOULD_TRACK) {
      initDDR(config)
    }
  }, [config])

  useEffect(() => {
    setFeatures(customFeatures)
    setHasLoadedAirhorn(true)
  }, [customFeatures])

  const airhornState: IAirhornState<Features> = useMemo(() => {
    return {
      hasLoadedAirhorn,
      features: features || ({} as Features),
      trackEvent: trackEventWithFeatures,
      identifyUser,
      clearUser,
    }
  }, [clearUser, features, hasLoadedAirhorn, identifyUser, trackEventWithFeatures])

  return (
    <AirhornContext.Provider value={airhornState}>
      {hasLoadedAirhorn ? children : null}
    </AirhornContext.Provider>
  )
}

export function useAirhornContext<F extends TGenericFeatures>() {
  return useContext(AirhornContext) as any as IAirhornState<F>
}
