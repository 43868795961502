import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'

/**
 * @name ListSubheader
 * @see https://mui.com/material-ui/api/list-subheader/
 */
export const MuiListSubheaderThemeOptions: Components['MuiListSubheader'] = {
  styleOverrides: {
    root: {
      background: 'transparent',
      color: brandColors.coolGray6,
      fontWeight: BASE_THEME.typography.fontWeightMedium,
      marginBottom: BASE_THEME.spacing(1),
    },
    gutters: {
      padding: BASE_THEME.spacing(0, 1),
    },
  },
}
