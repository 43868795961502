import React, { memo } from 'react'
import startCase from 'lodash/startCase'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Stack,
  Typography,
} from '../../../core'
import AdminIcon from '../../../icons/build/AdminIcon'
import { IAppInfo } from '../types'

export interface IAppCardProps extends IAppInfo {}

const useAppCardStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export const AppCard = memo<IAppCardProps>(
  ({ adminModuleRoles: adminModuleRolesProp, productModuleRoles: productModuleRolesProp }) => {
    const classes = useAppCardStyles()

    const adminModuleRoles = adminModuleRolesProp.filter(({ role }) => Boolean(role))
    const productModuleRoles = productModuleRolesProp.filter(({ role }) => Boolean(role))

    return (
      <Card data-test='user-app-card'>
        <CardHeader title='Product Access' />

        <CardContent>
          <Stack spacing={1.5}>
            {productModuleRoles.map(({ moduleKey, moduleName, role }) => (
              <div
                key={moduleKey}
                className={classes.item}
                data-test={`product-module-role-${moduleKey}`}>
                <Typography variant='body2' color='textSecondary'>
                  {moduleName}
                </Typography>
                <Typography variant='body2'>{startCase(role as string)}</Typography>
              </div>
            ))}
          </Stack>

          {adminModuleRoles.length > 0 && (
            <>
              <Divider className={classes.divider} />
              <Typography mb={1.5} variant='subtitle2'>
                Administrative
              </Typography>
              <Stack spacing={1.5}>
                {adminModuleRoles.map(({ moduleKey, role }) => (
                  <div
                    key={moduleKey}
                    className={classes.item}
                    data-test={`admin-module-role-${moduleKey}`}>
                    <Typography variant='body2' color='textSecondary'>
                      {startCase(moduleKey)}
                    </Typography>
                    <Typography display='inline-flex' alignItems='center' variant='body2'>
                      {role?.endsWith('admin') && (
                        <>
                          <AdminIcon size='medium' />
                          &nbsp;
                        </>
                      )}
                      {startCase(role as string)}
                    </Typography>
                  </div>
                ))}
              </Stack>
            </>
          )}
        </CardContent>
      </Card>
    )
  }
)
