import { Components } from '@mui/material/styles'

/**
 * @name ButtonBase
 * @see https://mui.com/material-ui/api/button-base/
 */
export const MuiButtonBaseThemeOptions: Components['MuiButtonBase'] = {
  defaultProps: {
    disableRipple: true, // Disables the ripple effect globally for buttons
  },
  styleOverrides: {
    root: {
      minWidth: `auto !important`,
    },
  },
}
