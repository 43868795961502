import React from 'react'
import {
  ConfirmationModal,
  IConfirmationModalProps,
  IModalAction,
} from '../../../components/Modals'
import { Typography } from '../../../core'
import { IUserProfileInfo } from '../types'

export interface IDeleteAccountConfirmationModalProps
  extends Pick<IConfirmationModalProps, 'actions' | 'open' | 'onClose'> {
  userInfo: IUserProfileInfo
  onConfirm: () => void
}

export function DeleteAccountConfirmationModal({
  onClose,
  onConfirm,
  open,
  userInfo,
}: IDeleteAccountConfirmationModalProps) {
  const { email, fullName } = userInfo || {}

  const actions: IModalAction[] = [
    {
      label: 'Cancel',
      type: 'confirm',
      action: onClose,
      ButtonProps: { color: 'secondary' },
    },
    {
      label: 'Delete',
      type: 'confirm',
      action: onConfirm,
      ButtonProps: { color: 'error' },
    },
  ]

  return (
    <ConfirmationModal
      dataTest='account-deletion-modal'
      title='Are you sure you want to delete this member?'
      maxWidth='md'
      actions={actions}
      open={open}
      onClose={onClose}>
      <Typography variant='body2' fontWeight={600}>
        {fullName} ({email})
      </Typography>
      <Typography variant='body2' mt={3}>
        Deleting a member is permanent and will result in them no longer being able to login and
        access any data.
      </Typography>
    </ConfirmationModal>
  )
}
