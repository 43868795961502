import { ApolloError } from '@apollo/client'
import { IError } from '../types'

interface IGetApolloErrorMessagesProps {
  mutationErrors?: Nullable<IError[]>
  apolloErrors: Nullable<ApolloError>
}

/**
 * @example getApolloErrorMessages({ apolloErrors, mutationErrors }) // ['Error1', 'Error2']
 */
export const getApolloErrorMessages = ({
  apolloErrors,
  mutationErrors,
}: IGetApolloErrorMessagesProps): string[] => {
  const {
    clientErrors = [],
    graphQLErrors = [],
    message: apolloErrorMessage = '',
  } = apolloErrors || {}
  const errors = [...clientErrors, ...graphQLErrors, ...(mutationErrors || [])]
  return [
    ...(apolloErrorMessage ? [apolloErrorMessage] : []),
    ...errors.map(({ message }) => message),
  ]
}
