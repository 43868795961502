import { Operation, ServerError } from '@apollo/client'
import { NetworkError, GraphQLErrors } from '@apollo/client/errors'
import { onError } from '@apollo/client/link/error'

export interface IGetErrorLink {
  onUnauthorized: (networkError: NetworkError) => void
  onError: (errors?: GraphQLErrors, operation?: Operation) => void
}

export const getErrorLink = ({ onError: onErrorProp, onUnauthorized }: IGetErrorLink) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    if (networkError && (networkError as ServerError).statusCode === 401) {
      onUnauthorized(networkError)
    } else {
      onErrorProp(graphQLErrors, operation)
    }
  })
