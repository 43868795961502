import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid/colDef/gridCheckboxSelectionColDef'
import {
  GridValidRowModel as MuiGridValidRowModel,
  GridColDef as MuiGridColDef,
} from '../../../core'
import {
  avatarGridColTypeDef,
  getCheckboxGridColTypeDef,
  getRadioGridColTypeDef,
  relativeDateGridColTypeDef,
} from '../columns'
import { IDataGridBulkAction, TDataGridColDef } from '../types'
import { getEditableCellProps } from './getEditableCellProps'

export const DEFAULT_COLUMN_PROPS: Partial<MuiGridColDef> = {
  display: 'flex',
  align: 'left',
  headerAlign: 'left',
}

export const CUSTOM_CHECKBOX_COLUMN: MuiGridColDef = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  width: 80,
  disableColumnMenu: false,
  headerAlign: 'left',
  align: 'left',
}

export interface IGetDataGridColumnsProps<TRowModel extends MuiGridValidRowModel> {
  /**
   * Array of DataGrid columns
   * @default []
   */
  columns: readonly TDataGridColDef<TRowModel>[]
  /**
   * An array of actions to show in the bulk actions menu
   * @default []
   */
  bulkActions?: IDataGridBulkAction<TRowModel>[]
}

export function getDataGridColumns<TRowModel extends MuiGridValidRowModel>({
  bulkActions = [],
  columns = [],
}: IGetDataGridColumnsProps<TRowModel>): MuiGridColDef<TRowModel>[] {
  // If there are bulk actions we need to add the custom checkbox column
  const customCheckboxColumn = bulkActions?.length ? [CUSTOM_CHECKBOX_COLUMN] : []

  return [
    ...customCheckboxColumn,
    ...columns.map(props => {
      const { editable, type, ...rest } = props
      const editableCellProps = editable ? getEditableCellProps(props) : {}

      /**
       * Spreading custom col type def
       * @see https://mui.com/x/react-data-grid/custom-columns/
       */
      if (type === 'avatar') {
        return {
          ...DEFAULT_COLUMN_PROPS,
          ...rest,
          ...avatarGridColTypeDef,
          ...editableCellProps,
        }
      }

      if (type === 'relativeDate') {
        return {
          ...DEFAULT_COLUMN_PROPS,
          ...rest,
          ...relativeDateGridColTypeDef,
          ...editableCellProps,
        }
      }

      if (type === 'checkbox') {
        return {
          ...DEFAULT_COLUMN_PROPS,
          ...rest,
          ...getCheckboxGridColTypeDef(props.label),
          ...editableCellProps,
        }
      }

      if (type === 'radio') {
        return {
          ...DEFAULT_COLUMN_PROPS,
          ...rest,
          ...getRadioGridColTypeDef(props.label),
          ...editableCellProps,
        }
      }

      /**
       * Standard column def
       */
      return {
        ...DEFAULT_COLUMN_PROPS,
        ...rest,
        ...editableCellProps,
        ...(type ? { type } : {}),
      }
    }),
  ]
}
