import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'
import { boxShadowLevels } from '../shadows'

/**
 * @name Switch
 * @see https://mui.com/material-ui/api/switch/
 */
export const MuiSwitchThemeOptions: Components['MuiSwitch'] = {
  defaultProps: {
    disableRipple: true,
    color: 'primary',
    size: 'medium',
  },
  styleOverrides: {
    root: {
      width: BASE_THEME.spacing(7.5),
      height: BASE_THEME.spacing(5),
    },
    switchBase: {
      top: 1,
      '&:not(.Mui-checked) .MuiSwitch-thumb': {
        borderColor: brandColors.coolGray4,
      },
      '&.Mui-disabled': {
        // Disabled & Checked
        '&.Mui-checked': {
          '& .MuiSwitch-thumb': {
            borderColor: brandColors.coolGray5,
            backgroundColor: brandColors.coolGray5,
          },
          '& + .MuiSwitch-track': {
            backgroundColor: brandColors.coolGray4,
            opacity: 1,
          },
        },
        // Disabled & Not Checked
        '&:not(.Mui-checked)': {
          '& .MuiSwitch-thumb': {
            borderColor: brandColors.coolGray4,
            backgroundColor: brandColors.coolGray3,
          },
          '& + .MuiSwitch-track': {
            backgroundColor: brandColors.coolGray4,
            opacity: 1,
          },
        },
      },
    },
    thumb: {
      borderWidth: '1px',
      borderStyle: 'solid',
      boxShadow: boxShadowLevels.low,
    },
    track: {
      background: brandColors.coolGray4,
      opacity: 1,
      borderRadius: BASE_THEME.spacing(5),
    },
    colorPrimary: {
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: brandColors.skyBlue5,
        opacity: 1,
      },
    },
  },
}
