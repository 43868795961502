import React, { ReactNode, MouseEvent } from 'react'
import classNames from 'classnames'
import { Button } from '../../core'
import { makeStyles } from '../../core/styles'
import { brandColors } from '../../theme/colors'
import { Spinner } from '../Spinner'

export interface IFacetProps {
  id: string
  dataTest: string
  icon?: JSX.Element
  label: string | JSX.Element
  /**
   * Pass in an optional badge that appears after label
   */
  badge?: ReactNode
  isLoading?: boolean
  className?: string
  selected: boolean
  onClick: (selected: boolean, id?: string, e?: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
}

const useFacetStyles = makeStyles(theme => ({
  facet: {
    borderRadius: theme.spacing(2.5),
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
    minHeight: '33px',
    boxShadow: 'none !important',

    '&:not(.facet-selected)': {
      borderColor: `${brandColors.coolGray4}`,
      color: `${brandColors.coolGray8}`,
      '&:hover': {
        backgroundColor: brandColors.coolGray1,
      },
    },
    '&.facet-selected': {
      border: `1px solid ${brandColors.skyBlue6} !important`,
    },
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))

/**
 * @deprecated Use the `ToggleButton` component instead.
 */
export function Facet({
  badge,
  className,
  dataTest = 'facet',
  disabled,
  icon,
  id,
  isLoading,
  label,
  onClick,
  selected,
}: IFacetProps) {
  const classes = useFacetStyles()
  const buttonClasses = classNames(classes.facet, className, {
    'facet-selected': selected && !isLoading,
  })

  const buttonClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    onClick(!selected, id, e)
  }
  return (
    <Button
      startIcon={icon}
      variant={selected && !isLoading ? 'contained' : 'outlined'}
      color={selected && !isLoading ? 'info' : 'primary'}
      disabled={disabled || isLoading}
      id={id}
      className={buttonClasses}
      type='button'
      onClick={buttonClickHandler}
      data-test={dataTest}>
      <div className={classes.buttonContent}>
        <div className='facet-label'>{label}</div>
        {isLoading ? <Spinner position='static' /> : badge}
      </div>
    </Button>
  )
}

export default Facet
