import { Components } from '@mui/material/styles'
import { brandColors } from '../colors'

/**
 * @name StepLabel
 * @see https://mui.com/material-ui/api/step-label/
 */
export const MuiStepLabelThemeOptions: Components['MuiStepLabel'] = {
  styleOverrides: {
    labelContainer: {
      color: brandColors.black,
    },
    root: {
      padding: 0,
    },
  },
}
