import HomeIcon from 'dpl/icons/build/HomeIcon'
import PeopleIcon from 'dpl/icons/build/PeopleIcon'
import { PROTECTED_PATHS } from '../routes/constants'

// placeholders

export const NAV_LISTS = [
  {
    navListItems: [
      {
        title: 'Home',
        href: PROTECTED_PATHS.home,
        Icon: HomeIcon,
      },
      {
        title: 'Members',
        href: PROTECTED_PATHS.members,
        Icon: PeopleIcon,
      },
    ],
  },
]
