import React, { FocusEvent, useState, ChangeEvent, useEffect, ReactNode } from 'react'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import {
  makeStyles,
  InputLabelProps as MuiInputLabelProps,
  FormHelperTextProps as MuiFormHelperTextProps,
} from '../../core'
import InfoIcon from '../../icons/build/InfoIcon'
import { wilsonFontFamily } from '../../theme'
import { brandColors } from '../../theme/colors'
import { FormControl } from '../FormControl'
import { Tooltip } from '../Tooltip'

export enum RowSizes {
  large = 6,
  medium = 4,
  small = 1,
  default = 1,
}

const useTextAreaStyles = makeStyles(theme => ({
  textArea: {
    minHeight: '42px',
    boxShadow: 'none',
    borderRadius: '2px',
    lineHeight: '24px',
    overflow: 'hidden',
    '&:focus': {
      boxShadow: `0 0 0 3px ${brandColors.skyBlue2}`,
    },
    display: 'block',
    height: 'auto',
    resize: 'none',
    width: '100%',
    fontFamily: wilsonFontFamily,
    padding: theme.spacing(0.5, 1),
  },
  disabled: {
    backgroundColor: brandColors.coolGray1,
    borderColor: brandColors.coolGray4,
    color: brandColors.coolGray5,
    cursor: 'not-allowed',
  },
  invalid: {
    border: `1px solid ${brandColors.error1}`,
    '&:focus': {
      boxShadow: `0 0 0 3px ${brandColors.error0}`,
    },
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    color: brandColors.coolGray8,
    cursor: 'pointer',
    margin: theme.spacing(0, 0, 0, 0.25),
    fontSize: '0.75rem',
    '&::after': {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}))

export type RowSizeType = keyof typeof RowSizes

export interface ITextAreaProps {
  size?: RowSizeType
  placeholder: string
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void
  label?: string | ReactNode
  id?: string
  required?: boolean
  disabled?: boolean
  value?: string
  name?: string
  dataTest?: string
  invalid?: boolean
  ariaLabel?: string
  toolTipInfo?: string
  validationMessage?: string
  InputLabelProps?: MuiInputLabelProps
  FormHelperTextProps?: MuiFormHelperTextProps
}

interface IState {
  rows: number
}

const TEXTAREA_LINE_HEIGHT = 24

export const getRowSize = (size: RowSizeType | undefined): number => {
  if (!size || !RowSizes[size]) {
    return RowSizes.default
  }

  return RowSizes[size]
}

export const TextArea = React.memo<ITextAreaProps>(
  ({
    size,
    placeholder,
    onChange,
    label,
    id = uuid(),
    required = false,
    disabled,
    value = '',
    dataTest,
    onBlur,
    name,
    invalid,
    ariaLabel,
    toolTipInfo,
    InputLabelProps = {},
    FormHelperTextProps = {},
    validationMessage,
  }) => {
    const classes = useTextAreaStyles()
    const rows = getRowSize(size)
    const initialState = { rows }
    const [state, setState] = useState<IState>(initialState)

    useEffect(() => {
      if (value) {
        const scrollHeight = document.getElementById(id)?.scrollHeight
        const initialRowSize =
          scrollHeight && value ? Math.floor(scrollHeight / TEXTAREA_LINE_HEIGHT) : rows
        setState({ rows: initialRowSize })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const previousRows = event.target.rows
      event.target.rows = rows // reset number of rows in textarea

      const currentRows = Math.floor(event.target.scrollHeight / TEXTAREA_LINE_HEIGHT)

      if (currentRows === previousRows) {
        event.target.rows = currentRows
      }

      setState({ rows: currentRows })

      if (onChange) onChange(event)
    }

    const textAreaClasses = classNames(classes.textArea, {
      [classes.disabled]: disabled,
      [classes.invalid]: invalid,
    })

    const labelDisplay = label ? (
      <label htmlFor={id} id={`label-${id}`} className={classes.label}>
        {label}
        {toolTipInfo && (
          <Tooltip placement='bottom' title={toolTipInfo}>
            <span>
              <InfoIcon size='small' />
            </span>
          </Tooltip>
        )}
      </label>
    ) : (
      ''
    )

    return (
      <FormControl
        required={required}
        disabled={disabled}
        error={invalid}
        label={labelDisplay}
        validationMessage={validationMessage}
        InputLabelProps={{
          htmlFor: id,
          ...InputLabelProps,
        }}
        FormHelperTextProps={FormHelperTextProps}>
        <textarea
          id={id}
          aria-label={ariaLabel}
          rows={state.rows}
          value={value}
          onBlur={onBlur}
          placeholder={placeholder}
          onChange={handleChange}
          className={textAreaClasses}
          data-test={dataTest}
          disabled={disabled}
          required={required}
          name={name}
        />
      </FormControl>
    )
  }
)

export default TextArea
