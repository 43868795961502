export enum TAG_SIZE {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum TAG_STYLE {
  light = 'light',
  dark = 'dark',
  success = 'success',
  warning = 'warning',
  gold = 'gold',
  error = 'error',
  info = 'info',
  infoLight = 'info-light',
  accentRed = 'accent-red',
  accentPurple = 'accent-purple',
  accentGreen = 'accent-green',
  accentBlue = 'accent-blue',
  accentWarning = 'accent-warning',
  spruceGreen = 'spruce-green',
  plumPurple = 'plum-purple',
  disabled = 'disabled',
  errorDark = 'error-dark',
}

export type TOptionValue = string | number

export interface IFunctionalTagOption<T extends TOptionValue = string> {
  label: string
  value: T
  style?: TAG_STYLE
  outlineOnly?: boolean
  description?: string
  disabled?: boolean
}

export interface ISelectOption<T = any> {
  label: string
  value: T
  className?: string
  description?: string
  /**
   * When an icon is not defined, nothing is displayed in the DOM.
   * This is an example icon that can be passed:
   * <AddIcon size='medium' />
   */
  icon?: JSX.Element
  isLink?: boolean
  /**
   * Used to sort options by groups
   * @example 1
   */
  groupIndex?: number
  /**
   * Label to use when showing options of group
   * @example 'Shipment Type'
   */
  groupName?: string
  disabled?: boolean
}
