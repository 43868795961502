import React from 'react'
import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import { Avatar } from '../../../components/Avatar'
import { datetimeFormatter } from '../../../components/FormattedDateTime'
import { FormattedPhoneNumber } from '../../../components/FormattedPhoneNumber'
import { Tooltip, Typography, makeStyles } from '../../../core'
import AdminIcon from '../../../icons/build/AdminIcon'
import { brandColors } from '../../../theme/colors'
import { IUserProfileInfo } from '../types'

const useUserDetailsHeaderStyles = makeStyles(theme => ({
  root: {
    backgroundColor: brandColors.coolGray0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(4),
    },
  },
  statusDateTextWarning: {
    color: brandColors.warning,
  },
  statusDateTextError: {
    color: brandColors.error,
  },
  statusDateTextActive: {
    color: brandColors.coolGray5,
  },
  accountInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1.5),
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
    },
  },
  userName: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))

export interface IUserDetailsHeaderProps {
  userInfo: IUserProfileInfo
  isOrgAdmin: boolean
}

export function UserDetailsHeader({ isOrgAdmin, userInfo }: IUserDetailsHeaderProps) {
  const classes = useUserDetailsHeaderStyles()
  const { email, fullName, imageSrc, phoneNumber, status, statusChangedAt } = userInfo || {}
  const statusTextClasses = classNames({
    [classes.statusDateTextWarning]: status === 'invited',
    [classes.statusDateTextError]: status === 'deleted',
    [classes.statusDateTextActive]: status === 'active',
  })

  const dateTimeFormatted = datetimeFormatter({
    datetime: statusChangedAt,
    format: 'pastOrOngoing',
    isPastAction: status !== 'active',
  })

  const displayMainText = fullName ?? email

  return (
    <div className={classes.root} data-test='user-details-header'>
      <Avatar name={fullName ?? ''} src={imageSrc} size='xlarge' hideTooltip />
      <div className={classes.accountInfo}>
        <div className={classes.userInfo}>
          <div className={classes.userName}>
            <Typography variant='h3'>{displayMainText}</Typography>
            {isOrgAdmin && (
              <Tooltip title='Organization Admin'>
                <div>
                  <AdminIcon size='large' color='coolGray5' />
                </div>
              </Tooltip>
            )}
          </div>
          {fullName && (
            <Typography variant='subtitle1' color='textSecondary'>
              {email}
            </Typography>
          )}
          <Typography variant='subtitle1' color='textSecondary'>
            <FormattedPhoneNumber phoneNumber={phoneNumber} />
          </Typography>
          <Typography variant='caption' className={statusTextClasses}>
            {capitalize(status)} {dateTimeFormatted}
          </Typography>
        </div>
      </div>
    </div>
  )
}
