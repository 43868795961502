/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, SyntheticEvent, MouseEvent, useState, useMemo } from 'react'
import { usePopper } from 'react-popper'
import classname from 'classnames'
import { Button } from '../../core'
import Box from '../../core/Box'
import Typography from '../../core/Typography'
import { makeStyles } from '../../core/styles'
import { brandColors } from '../../theme/colors'
import { boxShadowLevels } from '../../theme/shadows'
import { useToggle } from '../../utils/useToggle'
import { Card } from '../Card'
import { getPopperConfig, TContextCardRenderType, IPopperOptions } from './utils/ContextCardRecipes'

export interface IContextCardProps {
  /**
   * Clickable text that will trigger opening the context card, displaying the
   * contents of `children`.
   */
  triggerText: JSX.Element | string
  /*
   * Optional title that is rendered at the top of the card, just like in the
   * regular Card component.
   */
  title?: string
  /*
   * Optional button data to render at the bottom of the card. Renders a large
   * primary button.
   */
  button?: {
    text: string
    onClick: () => void
    closeOnClick?: boolean
    cancelText?: string
  }
  /**
   * Component that is rendered inside the context card
   */
  children: JSX.Element
  /**
   * Classes for trigger element
   */
  className?: string
  /**
   * Classes for the parent element
   */
  parentClassName?: string
  /**
   * Classes for context card
   */
  contextCardClassName?: string
  /**
   * Set the render config values for usePopper
   * @default 'default'
   */
  renderType?: TContextCardRenderType
  /**
   * This defines the different values you can set up depending on the renderType
   */
  popperOptions?: IPopperOptions
  /**
   * Optional text displayed below the button inside of ContextCard
   * @example 'Button above sends a SMS message'
   */
  footerText?: string
  /**
   * Set true for removal of dotted lines
   * @default 'false'
   */
  hideDottedLines?: boolean
}

const EMPTY_FUNCTION = () => {}

const useContextCardStyles = makeStyles(theme => ({
  container: {
    visibility: 'hidden',
    padding: `${theme.spacing(1.25)} 0`,
    zIndex: 12,

    '&.open': {
      visibility: 'visible',
    },
    '& .card': {
      boxShadow: boxShadowLevels.high,
    },
  },
  trigger: {
    borderBottom: `${theme.spacing(0.25)} dotted ${brandColors.coolGray5}`,
    '&:hover, &.open': {
      borderBottomColor: brandColors.coolGray6,
    },
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
}))

export function ContextCard({
  button,
  children,
  className,
  contextCardClassName,
  footerText,
  hideDottedLines,
  parentClassName,
  popperOptions,
  renderType = 'default',
  title,
  triggerText,
}: IContextCardProps) {
  const classes = useContextCardStyles()
  const [open, toggleVisibility, wrapperRef] = useToggle<HTMLDivElement>()

  const [referenceElement, setReferenceElement] = useState<Element | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const buttonClickHandler = useCallback(() => {
    if (!button) return

    button.onClick()
    if (button.closeOnClick) {
      toggleVisibility()
    }
  }, [button, toggleVisibility])

  const triggerTextClickHandler = useCallback(
    (e: SyntheticEvent<HTMLSpanElement>) => {
      // Stops the listener created in useToggle from firing when it's the same element (preventing open more than one context card)
      if (referenceElement === wrapperRef.current) {
        e.stopPropagation()
      }
      toggleVisibility()
    },
    [toggleVisibility, wrapperRef, referenceElement]
  )

  const clickHandler = useCallback((e: MouseEvent<HTMLElement>) => e.stopPropagation(), [])

  const popperOptionGenerator = useMemo(() => getPopperConfig(renderType), [renderType])
  const popperConfig = useMemo(
    () => popperOptionGenerator(popperOptions || {}),
    [popperOptions, popperOptionGenerator]
  )

  const { attributes, styles } = usePopper(referenceElement, popperElement, popperConfig)

  const contextCardClasses = classname(contextCardClassName, classes.container, {
    open,
  })
  const triggerClasses = hideDottedLines ? '' : classname(classes.trigger, { open })

  return (
    <div className={parentClassName} ref={wrapperRef}>
      <Box
        className={className}
        component='span'
        // We must respond to the mousedown event because that's what the
        // listener in useToggle is attached to.
        onMouseDown={triggerTextClickHandler}
        // onMouseDown is not handle clicks well with stopPropagation in some cases for example in the tables
        onClick={clickHandler}
        onKeyPress={triggerTextClickHandler}>
        <strong
          className={triggerClasses}
          ref={setReferenceElement}
          data-test='context-card-trigger'
          // a11y attributes
          tabIndex={0}
          role='button'>
          {triggerText}
        </strong>
      </Box>

      {open && (
        <div
          ref={setPopperElement}
          className={contextCardClasses}
          onClick={clickHandler}
          onKeyDown={EMPTY_FUNCTION}
          style={styles.popper}
          {...attributes.popper}>
          <Card title={title} dataTest='context-card-container'>
            {children}
            {button && (
              <div className={classes.buttons}>
                {button.cancelText && (
                  <Button onClick={buttonClickHandler} variant='text'>
                    {button.cancelText}
                  </Button>
                )}
                <Button
                  onClick={buttonClickHandler}
                  size='large'
                  fullWidth={!button.cancelText}
                  color='primary'>
                  {button.text}
                </Button>
              </div>
            )}
            {button && footerText && (
              <Box pt={1.5}>
                <Typography variant='caption' color='textSecondary'>
                  {footerText}
                </Typography>
              </Box>
            )}
          </Card>
        </div>
      )}
    </div>
  )
}

export default ContextCard
