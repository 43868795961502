import React from 'react'
import { useWatch } from 'react-hook-form'
import { brandColors, InlineMessage } from 'dpl'
import { Button, Grid, makeStyles, Typography } from 'dpl/core'
import { InfoIcon } from 'dpl/icons'
import compact from 'lodash/compact'
import get from 'lodash/get'
import { TAddNewMemberFormSchema } from './AddNewMemberFormSchema'
import {
  ControlledAddNewMemberAutocomplete,
  ControlledModuleAccessRolesAutocomplete,
} from './components'
import { isValidEmail } from './components/ControlledAddNewMemberAutocomplete/utils'
import { useAddNewMemberFormContext } from './useAddNewMemberForm'

export interface IAddNewMemberFormProps {
  onClose: () => void
  onSubmit: () => void
}

const useAddNewMemberFormStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  bottomControls: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing(3),
    backgroundColor: brandColors.white,
    borderTop: `1px ${brandColors.coolGray3} solid`,
    zIndex: 1,
  },
  formActions: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  grid: {
    marginBottom: '150px',
    overflow: 'scroll',
  },
}))

export function AddNewMemberForm({ onClose, onSubmit }: IAddNewMemberFormProps) {
  const { control, errors } = useAddNewMemberFormContext()
  const classes = useAddNewMemberFormStyles()

  const { emails = [] } = useWatch<TAddNewMemberFormSchema>({
    name: ['emails'],
    control,
  })
  const validEmails = compact(emails).filter(email => isValidEmail(email))

  return (
    <div className={classes.root}>
      <Grid container columnSpacing={2} rowSpacing={3} className={classes.grid}>
        <Grid item xs={12}>
          <Typography variant='subtitle1' className={classes.title}>
            Members to Invite
          </Typography>
          <ControlledAddNewMemberAutocomplete
            control={control}
            name='emails'
            AutocompleteOrganizationUserProps={{
              error: get(errors, 'emails.message'),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledModuleAccessRolesAutocomplete control={control} name='modules' />
        </Grid>
      </Grid>

      <div className={classes.bottomControls}>
        <div>
          <InlineMessage
            IconComponent={InfoIcon}
            message='Invitation emails will be sent to added members with valid emails. From the invite, they can create their Transfix account.'
          />
        </div>
        <div className={classes.formActions}>
          <Button variant='text' onClick={onClose}>
            Cancel
          </Button>
          {validEmails.length > 0 ? (
            <Button onClick={onSubmit}>
              Send Invitation to {validEmails.length} Member{validEmails.length !== 1 ? 's' : ''}
            </Button>
          ) : (
            <Button onClick={onSubmit}>Send Invitation</Button>
          )}
        </div>
      </div>
    </div>
  )
}
