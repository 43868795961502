import { useMemo } from 'react'
import { getGraphqlAfterCursor } from 'dpl/utils/graphql/getGraphqlAfterCursor'
import { TEAM_KEYS } from '../../../../../constants/roleKeys'
import {
  IOrganizationUser,
  IOrganizationUserSortBy,
  IProductModule,
  ISortOrder,
} from '../../../../../types/graphqlTypes'
import { useOrganizationUsersQuery } from '../../../graphql/OrganizationUsers'
import { IUserRequestParams } from '../types'

interface IUseGetUsersDataReturn {
  members: IOrganizationUser[]
  productModules: IProductModule[]
  isLoading: Nullable<boolean>
  totalItems: number | undefined
}

interface IGetTeamKeys {
  productModules: IUserRequestParams['productModules']
  role: IUserRequestParams['role']
  organizationAdmin: IUserRequestParams['organizationAdmin']
}

export const useGetUsersData = ({
  organizationAdmin,
  organizationProductModules,
  page,
  pageSize,
  productModules,
  role,
  searchName,
  sortBy,
  statuses,
}: IUserRequestParams): IUseGetUsersDataReturn => {
  const sortOrder = useMemo(
    () => (sortBy === IOrganizationUserSortBy.firstName ? ISortOrder.asc : ISortOrder.desc),
    [sortBy]
  )

  const getTeamKeys = ({
    organizationAdmin,
    productModules: productModulesProp,
    role,
  }: IGetTeamKeys) => {
    if (organizationAdmin) {
      return [TEAM_KEYS.ORG_ADMIN]
    }

    if (!role && productModulesProp.length === 0) return []

    const roles = role ? [role] : [TEAM_KEYS.ADMIN, TEAM_KEYS.USERS]
    const productModules =
      productModulesProp.length === 0 ? organizationProductModules : productModulesProp

    return [
      ...productModules?.flatMap(productModule => roles.map(role => `${productModule}_${role}`)),
      ...(roles.includes(TEAM_KEYS.ADMIN) ? [TEAM_KEYS.ORG_ADMIN] : []),
    ]
  }

  const { data: userData, loading: isLoading } = useOrganizationUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      searchTerm: searchName,
      first: pageSize,
      sortBy,
      statuses,
      sortOrder,
      teamKeys: getTeamKeys({ productModules, role, organizationAdmin }),
      after: getGraphqlAfterCursor({ pageNumber: page, pageSize }),
    },
  })

  const members = useMemo(
    () => userData?.searchOrganizationUsers?.edges?.map(edge => edge?.node!) || [],
    [userData]
  ) as IOrganizationUser[]

  const productModulesList = useMemo(() => userData?.productModules || [], [userData])

  return {
    members,
    isLoading,
    totalItems: userData?.searchOrganizationUsers?.totalCount,
    productModules: productModulesList,
  }
}

export default useGetUsersData
