import React from 'react'
import { makeStyles, Menu, MenuItem, Typography, Button } from '../../../core'
import { DownArrowIcon, TransfixLogoIcon } from '../../../icons'
import SvgIcon from '../../../icons/components/SvgIcon'
import { brandColors } from '../../../theme'

const useStyles = makeStyles(theme => ({
  appIcon: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: brandColors.black,
    borderRadius: theme.spacing(1),
    color: brandColors.white,
    marginRight: theme.spacing(1.5),
  },
}))

export interface IApplication {
  name: string
  url: string
  link: string
  Icon: typeof SvgIcon
}

export interface IApplicationSelectorProps {
  id?: string
  apps: IApplication[]
}

export function ApplicationSelector({
  apps,
  id = 'application-selector',
}: IApplicationSelectorProps) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleItemClick = (link: string) => () => {
    window.open(link, '_blank')?.focus()
    setAnchorEl(null)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant='text'
        startIcon={<TransfixLogoIcon size='large' />}
        onClick={handleClick}
        endIcon={<DownArrowIcon />}>
        Transfix Apps
      </Button>
      <Menu
        anchorEl={anchorEl}
        id={id}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {apps.map(({ Icon, link, name, url }) => (
          <MenuItem onClick={handleItemClick(link)}>
            <div className={classes.appIcon}>
              <Icon size='xlarge' />
            </div>
            <div>
              <Typography display='block' variant='subtitle1'>
                {name}
              </Typography>
              <Typography display='block' color='textSecondary' variant='caption'>
                {url}
              </Typography>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
