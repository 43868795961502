import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, makeStyles, Stack } from '../../../../../core'
import SidePanel, { ISidePanelProps } from '../../../../SidePanel'
import { DataGridSortSelectInput, IDataGridFilterProps, IDataGridSortOption } from '../components'
import { SORT_QUERY_PARAM_KEY } from '../constants'
import { IDataGridFilters, TDataGridFiltersConfig } from '../types'
import { DataGridFiltersMenuInput } from './DataGridFiltersMenuInput'

export interface IDataGridFiltersMenuProps<
  TFilters extends IDataGridFilters,
  TSortValue extends string,
> extends Pick<ISidePanelProps, 'open'> {
  /**
   * Configuration object for the filters
   * @example { userSearch: { type: 'textInput' }, isActive: { type: 'switch' }, }
   */
  filtersConfig: TDataGridFiltersConfig<TFilters>
  /**
   * Sort options for the sort select input
   */
  sortOptions?: IDataGridSortOption<TSortValue>[]
  /**
   * The current state of the query params
   * { userSearch: 'John Doe', isActive: true }
   */
  queryParams: TFilters & { [SORT_QUERY_PARAM_KEY]: TSortValue }
  /**
   * Callback function that is called when a filter is changed
   */
  onApply: (queryParams: TFilters & { [SORT_QUERY_PARAM_KEY]: TSortValue }) => void
  /**
   * Callback function that is called when the clear all button is clicked
   */
  onClearAllClick: () => void
  /**
   * Callback function that is called when the close button is clicked
   */
  onClose: () => void
  /**
   * @default 'DataGridFiltersMenu'
   */
  dataTest?: string
}

const useDataGridFiltersMenuComponentStyles = makeStyles(() => ({
  clearLink: {
    display: 'inline-block',
    fontWeight: 600,
    cursor: 'pointer',
  },
}))

/**
 * The DataGridFiltersMenu component is a side panel that contains all the filters for the DataGrid.
 * @important The filters menu differs from the filters header in that it does not apply the filters until the apply button is clicked.
 */
function DataGridFiltersMenuComponent<
  TFilters extends IDataGridFilters,
  TSortValue extends string,
>({
  dataTest = 'DataGridFiltersMenu',
  filtersConfig,
  onApply,
  onClearAllClick,
  onClose,
  open,
  queryParams,
  sortOptions,
}: IDataGridFiltersMenuProps<TFilters, TSortValue>) {
  const classes = useDataGridFiltersMenuComponentStyles()
  /**
   * The filters menu has its own state since the filters are not applied until the apply button is clicked.
   */
  const [menuFilters, setMenuFilters] = useState<TFilters & { [SORT_QUERY_PARAM_KEY]: TSortValue }>(
    queryParams
  )

  const menuTitle = useMemo(() => {
    return `All Filters (${Object.keys(filtersConfig).length})`
  }, [filtersConfig])

  const filterChangeHandler: IDataGridFilterProps<any, keyof TFilters>['onChange'] = useCallback(
    ({ name, value }) => {
      setMenuFilters(prev => ({ ...prev, [name]: value }))
    },
    []
  )

  const sortChangeHandler = useCallback((newSortValue: TSortValue) => {
    setMenuFilters(prev => ({ ...prev, [SORT_QUERY_PARAM_KEY]: newSortValue }))
  }, [])

  const applyFiltersHandler = useCallback(() => {
    onApply(menuFilters)
  }, [menuFilters, onApply])

  const actions: ISidePanelProps['actions'] = useMemo(
    () => [
      {
        label: 'Cancel',
        variant: 'text',
        onClick: onClose,
      },
      {
        label: 'Apply',
        onClick: applyFiltersHandler,
      },
    ],
    [applyFiltersHandler, onClose]
  )

  /**
   * Set the menu filters to the query params when the menu is opened
   */
  useEffect(() => {
    if (open) {
      setMenuFilters(queryParams)
    }
  }, [open, queryParams])

  return (
    <SidePanel
      title={menuTitle}
      anchor='right'
      open={open}
      onClose={onClose}
      actions={actions}
      dataTest={dataTest}>
      <Stack gap={2}>
        <Link
          onClick={onClearAllClick}
          className={classes.clearLink}
          data-test={`${dataTest}-clear`}>
          Clear All Filters
        </Link>
        {sortOptions && (
          <DataGridSortSelectInput
            variant='menu'
            options={sortOptions}
            value={menuFilters[SORT_QUERY_PARAM_KEY]}
            onChange={sortChangeHandler}
            dataTest={`${dataTest}-sort`}
          />
        )}
        <div>
          {Object.keys(filtersConfig).map(filterKey => {
            const key = filterKey as keyof typeof filtersConfig
            return (
              <DataGridFiltersMenuInput
                key={filterKey}
                filterKey={key}
                filtersConfig={filtersConfig}
                filtersCurrentState={menuFilters}
                onChange={filterChangeHandler}
              />
            )
          })}
        </div>
      </Stack>
    </SidePanel>
  )
}

export const DataGridFiltersMenu = memo(
  DataGridFiltersMenuComponent
) as typeof DataGridFiltersMenuComponent
