import React, { memo, useMemo } from 'react'
import { DialogActions as MuiDialogActions } from '../../../../core'
import { IModalAction, ModalAction } from '../ModalAction'

export interface IModalFooterProps {
  /**
   * The actions to display in the modal footer
   * @default []
   */
  actions?: IModalAction[]
  /**
   * The class name of the root element
   * @default ''
   */
  className?: string
  /**
   * @default 'ModalFooter'
   */
  dataTest?: string
  /**
   * The footer to display in the modal
   * @default undefined
   */
  footer?: JSX.Element
}

export const ModalFooter = memo<IModalFooterProps>(
  ({ actions = [], className = '', dataTest = 'ModalFooter', footer }) => {
    const hasActions = useMemo(() => actions.length > 0, [actions])
    return (
      <div data-test={dataTest} className={className}>
        {footer && <div>{footer}</div>}
        {hasActions && (
          <MuiDialogActions>
            {actions.map(({ dataTest: actionDataTest, label, ...props }, index) => (
              <ModalAction
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                label={label}
                dataTest={actionDataTest || `${dataTest}-${label}`}
                {...props}
              />
            ))}
          </MuiDialogActions>
        )}
      </div>
    )
  }
)
