import { Components } from '@mui/material/styles'

/**
 * @name Radio
 * @see https://mui.com/material-ui/api/radio/
 */
export const MuiRadioThemeOptions: Components['MuiRadio'] = {
  defaultProps: {
    color: 'primary',
    disableRipple: true,
    size: 'small',
  },
}
