import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { getErrorLink, IGetErrorLink } from './errorLink'

const appHttpLink = createHttpLink({
  uri: operation => {
    const baseUrl = import.meta.env.VITE_GRAPHQL_API
    const operationName = operation.operationName || 'anonymous'
    return `${baseUrl}?op=${operationName}`
  },
  credentials: 'include',
})

export const getAppGraphQLClient = ({ onError, onUnauthorized }: IGetErrorLink) =>
  new ApolloClient({
    link: ApolloLink.from([getErrorLink({ onUnauthorized, onError }), appHttpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Session: {
          merge: true,
        },
        ImportContractRFPVersionLanesStatus: {
          keyFields: ['contractRfpVersionId'],
        },
      },
    }),
    connectToDevTools: !import.meta.env.PROD,
  })
