import { createContext } from 'react'
import { To } from 'react-router-dom'
import { IAuthClient } from '../clients'

export interface IAuthContextState {
  authClient: IAuthClient
  discoveryRedirectTo: To
  signInRedirectTo: To
}

export const AuthContext = createContext<IAuthContextState>({
  authClient: {} as IAuthClient,
  discoveryRedirectTo: '',
  signInRedirectTo: '',
})
