import React from 'react'
import { Avatar, brandColors } from 'dpl'
import { Box, Typography, Button, ButtonProps } from 'dpl/core'
import { CloseIcon, EmailIcon } from 'dpl/icons'
import { isValidEmail } from './utils'

export interface IEmailToInviteProps {
  email: string
  onDelete: ButtonProps['onClick']
}

export function EmailToInvite({ email, onDelete }: IEmailToInviteProps) {
  const isValid = isValidEmail(email)

  return (
    <Box
      key={email}
      display='flex'
      alignItems='center'
      borderBottom={`1px ${brandColors.coolGray3} solid`}>
      <Box display='flex' alignItems='center' gap={1.5} padding={1.5} width='100%'>
        <Avatar hideTooltip Icon={<EmailIcon color='coolGray8' />} />
        <div>
          <Typography variant='body2' color={isValid ? undefined : brandColors.error1}>
            {email}
          </Typography>
          {!isValid && (
            <Typography variant='caption' color={brandColors.error1}>
              This email address is invalid and no message will be sent.
            </Typography>
          )}
        </div>
      </Box>
      <Button variant='text' onClick={onDelete}>
        <CloseIcon size='large' />
      </Button>
    </Box>
  )
}
