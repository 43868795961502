import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum'
import { IUserDetails } from './types'

const SESSION_SAMPLE_RATE = ['production', 'uat'].includes(import.meta.env.MODE) ? 100 : 25

const DD_RUMS_CONFIG: RumInitConfiguration = {
  applicationId: 'dfe5d9b0-0abb-4388-b716-1a2d7f14b425',
  clientToken: 'pub5510d3160ee4ec6030fa18e0a289146c',
  site: 'datadoghq.com',
  service: 'wilson',
  env: import.meta.env.MODE,
  version: `${import.meta.env.COMMIT_SHA}`,
  sessionSampleRate: SESSION_SAMPLE_RATE,
  sessionReplaySampleRate: 25,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [/https:\/\/.*\.transfix\.io/],
  traceSampleRate: 25,
  /**
   * Make our test and our logs leverage on the same selector when available. More info here:
   * https://docs.datadoghq.com/real_user_monitoring/browser/tracking_user_actions/#declare-a-name-for-click-actions
   */
  actionNameAttribute: 'data-test',
}

export const initDDR = (customConfig?: Partial<RumInitConfiguration>) => {
  const config = {
    ...DD_RUMS_CONFIG,
    ...customConfig,
  }

  if (!config.applicationId) return

  datadogRum.init(config)
  datadogRum.startSessionReplayRecording()
}

export const identifyUserDDR = (userDetails: IUserDetails) => {
  datadogRum.setUser(userDetails)
}

export const clearUserDDR = () => {
  datadogRum.clearUser()
}
