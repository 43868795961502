import startCase from 'lodash/startCase'
import { AdminModuleKeys, AdminModuleRoles, IModuleRoleResult, IOrganizationTeam } from '../types'
import { isOrganizationAdmin } from './isOrganizationAdmin'

interface IGetAdminModuleRolesProps {
  /**
   * @description Does the user belong to the Transfix organization (AKA is a Transfix employee)?
   */
  isTransfixUser: boolean
  /**
   * @description The user's organization teams.
   */
  organizationTeams: Pick<IOrganizationTeam, 'key'>[]
}

/**
 * @returns The user's role for each admin module.
 */
export const getAdminModuleRoles = ({
  isTransfixUser,
  organizationTeams,
}: IGetAdminModuleRolesProps): IModuleRoleResult[] => {
  const isOrgAdmin = isOrganizationAdmin(organizationTeams)

  const moduleRoles = [
    {
      moduleName: startCase(AdminModuleKeys.OrganizationAdminConsole),
      moduleKey: AdminModuleKeys.OrganizationAdminConsole,
      role: isOrgAdmin ? AdminModuleRoles.OrganizationAdmin : null,
    },
  ]

  if (isTransfixUser) {
    moduleRoles.push({
      moduleName: startCase(AdminModuleKeys.TransfixAdminConsole),
      moduleKey: AdminModuleKeys.TransfixAdminConsole,
      role: isOrgAdmin ? AdminModuleRoles.TransfixSuperAdmin : AdminModuleRoles.TransfixUser,
    })
  }

  return moduleRoles
}
