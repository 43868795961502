import React, { useMemo } from 'react'
import { Button, makeStyles } from '../../../../../core'
import FilterUnselectedIcon from '../../../../../icons/build/FilterUnselectedIcon'
import { useBreakpointContext } from '../../../../../providers'
import { DataGridSortSelectInput, IDataGridFilterProps, IDataGridSortOption } from '../components'
import { HEADER_FILTERS_COMPONENT_MAP, SORT_QUERY_PARAM_KEY } from '../constants'
import { IDataGridFilters, TDataGridFiltersConfig } from '../types'

export interface IDataGridFiltersHeaderProps<
  TFilters extends IDataGridFilters,
  TSortValue extends string,
> {
  /**
   * Configuration object for the filters
   * @example { userSearch: { type: 'textInput' }, isActive: { type: 'switch' }, }
   */
  filtersConfig: TDataGridFiltersConfig<TFilters>
  /**
   * Sort options for the sort select input
   */
  sortOptions?: IDataGridSortOption<TSortValue>[]
  /**
   * The current state of the query params
   * { userSearch: 'John Doe', isActive: true }
   */
  queryParams: TFilters & { [SORT_QUERY_PARAM_KEY]: TSortValue }
  /**
   * Callback function that is called when a filter is changed
   */
  onFilterChange: IDataGridFilterProps<any, keyof TFilters>['onChange']
  /**
   * Callback function that is called when the sort is changed
   */
  onSortChange: (value: TSortValue) => void
  /**
   * Callback function that is called when the on the all filters button is clicked
   */
  onAllFiltersClick: () => void
  /**
   * Callback function that is called when the on the clear all button is clicked
   */
  onClearAllClick: () => void
  /**
   * @default 'DataGridFiltersHeader'
   */
  dataTest?: string
  /**
   * Limits the number of filters visible in the header
   * @default {6}
   */
  visibleFiltersLimit?: number
}

const useDataGridFiltersHeaderComponentStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  inputWrapper: {
    flex: 1,
    [theme.breakpoints.up('md')]: {
      flex: '0 1 auto',
      '&.textInput': {
        width: '260px',
      },
    },
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    flexWrap: 'wrap',
    flex: 1,
    [theme.breakpoints.up('md')]: {
      flex: '0 1 auto',
    },
  },
  sort: {
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
}))

function DataGridFiltersHeaderComponent<
  TFilters extends IDataGridFilters,
  TSortValue extends string,
>({
  dataTest = 'DataGridFiltersHeader',
  filtersConfig,
  onAllFiltersClick,
  onClearAllClick,
  onFilterChange,
  onSortChange,
  queryParams,
  sortOptions,
  visibleFiltersLimit = 6,
}: IDataGridFiltersHeaderProps<TFilters, TSortValue>) {
  const classes = useDataGridFiltersHeaderComponentStyles()
  const { isMobile } = useBreakpointContext()

  const filtersKeys = useMemo(() => {
    if (isMobile) {
      return Object.keys(filtersConfig).slice(0, 1)
    }
    return Object.keys(filtersConfig).slice(0, visibleFiltersLimit)
  }, [filtersConfig, visibleFiltersLimit, isMobile])

  const showAllFiltersButton = useMemo(() => {
    if (isMobile) {
      return true
    }
    return Object.keys(filtersConfig).length > visibleFiltersLimit
  }, [filtersConfig, visibleFiltersLimit, isMobile])

  const allFiltersButtonText = useMemo(() => {
    if (isMobile) {
      return `(${Object.keys(filtersConfig).length})`
    }
    return `All Filters (${Object.keys(filtersConfig).length})`
  }, [filtersConfig, isMobile])

  return (
    <div data-test={dataTest} className={classes.root}>
      <div className={classes.filters}>
        {filtersKeys.map(filterKey => {
          const key = filterKey as keyof typeof filtersConfig
          const {
            FilterComponent: FilterComponentProp,
            type: filterType,
            ...filterProps
          } = filtersConfig[key]
          const FilterComponent =
            filterType === 'custom' && FilterComponentProp
              ? FilterComponentProp
              : HEADER_FILTERS_COMPONENT_MAP[filterType]
          return (
            <div key={filterKey} className={`${classes.inputWrapper} ${filterType}`}>
              <FilterComponent
                variant='header'
                name={filterKey}
                onChange={onFilterChange}
                value={queryParams[key]}
                {...filterProps}
              />
            </div>
          )
        })}
        {showAllFiltersButton && (
          <Button
            variant='text'
            size='small'
            onClick={onAllFiltersClick}
            data-test={`${dataTest}-all-filters-button`}
            startIcon={<FilterUnselectedIcon size='large' />}>
            {allFiltersButtonText}
          </Button>
        )}
        {!isMobile && (
          <Button
            variant='text'
            size='small'
            data-test={`${dataTest}-clear-all-button`}
            onClick={onClearAllClick}>
            Clear All
          </Button>
        )}
      </div>
      {!isMobile && sortOptions && (
        <div className={classes.sort}>
          <DataGridSortSelectInput
            variant='header'
            options={sortOptions}
            value={queryParams[SORT_QUERY_PARAM_KEY]}
            onChange={onSortChange}
            dataTest={`${dataTest}-sort`}
          />
        </div>
      )}
    </div>
  )
}

export const DataGridFiltersHeader = React.memo(
  DataGridFiltersHeaderComponent
) as typeof DataGridFiltersHeaderComponent
