import { createStyles, makeStyles } from '../core'
import { StyleRules } from '../core/styles'
import { brandColors, TBrandColor } from '../theme/colors'

/**
 * Returns object keyed by color name
 * @example
 * ```
 * {
 *   skyBlue6: {
 *      color: brandColors.skyBlue6
 *   }
 * }
 * ```
 */
const getBrandColors = (): StyleRules<{}, TBrandColor> => {
  const colorNames = Object.keys(brandColors) as TBrandColor[]
  return colorNames.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: {
        color: brandColors[curr],
      },
    }),
    {}
  ) as StyleRules<{}, TBrandColor>
}

/**
 * Creates a convenient `makeStyles` hook with all available brand colors
 * @example
 * ```
 * const MyComponent = () => {
 *   const classes = useColorStyles()
 *   return <div className={classes.skyBlue6} />
 * }
 * ```
 */
export const useColorStyles = makeStyles(createStyles<TBrandColor, {}>(getBrandColors()))
