import React, { memo } from 'react'
import { Drawer as MuiDrawer, DialogContent, makeStyles } from '../../../core'
import { useBreakpoint } from '../../../hooks'
import { ModalBase, IModalBaseProps } from '../ModalBase'
import { ModalFooter } from '../ModalBase/ModalFooter'
import { ModalHeader } from '../ModalBase/ModalHeader'

export interface IConfirmationModalProps extends IModalBaseProps {}

const useConfirmationModalStyles = makeStyles(theme => ({
  drawer: {
    '& .MuiDrawer-paperAnchorBottom': {
      borderTopLeftRadius: theme.spacing(2),
      borderTopRightRadius: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(3, 3, 0),
    },
    '& div:has(.MuiDialogTitle-root) + .MuiDialogContent-root': {
      padding: theme.spacing(0, 3),
    },
  },
  drawerHeader: {
    padding: theme.spacing(3, 3, 1),
  },
  confirmationModalBody: {
    paddingBottom: 0,
  },
}))

export const ConfirmationModal = memo<IConfirmationModalProps>(props => {
  const {
    actions,
    bodyClassName = '',
    children,
    dataTest = 'ConfirmationModal',
    footer,
    footerClassName = '',
    header,
    headerClassName = '',
    includeCloseIconButton,
    onClose,
    open,
    subtitle,
    subtitleVariant,
    title,
  } = props
  const classes = useConfirmationModalStyles()
  const { isMobile } = useBreakpoint()

  return !isMobile ? (
    <ModalBase
      fullWidth
      maxWidth='xs'
      bodyClassName={`${bodyClassName} ${classes.confirmationModalBody}`}
      {...props}>
      {children}
    </ModalBase>
  ) : (
    <MuiDrawer open={open} anchor='bottom' className={classes.drawer}>
      {title && (
        <ModalHeader
          className={`${headerClassName} ${classes.drawerHeader}`}
          dataTest={`${dataTest}-header`}
          header={header}
          includeCloseIconButton={includeCloseIconButton}
          onClose={onClose}
          title={title}
          titleVariant='h2'
          subtitle={subtitle}
          subtitleVariant={subtitleVariant}
        />
      )}
      <DialogContent className={bodyClassName}>{children}</DialogContent>
      <ModalFooter
        actions={actions}
        className={footerClassName}
        dataTest={`${dataTest}-footer`}
        footer={footer}
      />
    </MuiDrawer>
  )
})
