import React, { memo } from 'react'
import { Button, ButtonProps, makeStyles } from '../../../../core'

const useDataGridButtonCellStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    minWidth: 32,
  },
})

export const DataGridButtonCell = memo<ButtonProps>(({ children, ...rest }) => {
  const classes = useDataGridButtonCellStyles()

  return (
    <div className={classes.root} data-test='DataGridButtonCell'>
      <Button {...rest} className={classes.button}>
        {children}
      </Button>
    </div>
  )
})
