import React, { memo, useCallback } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Box, Link } from 'dpl/core'
import { IApiError } from 'network/src/rest/hooks/useRequest'

interface IResetPasswordErrorProps {
  apiError: IApiError
  loginHref: string
}

function ResetPasswordError({ apiError, loginHref }: IResetPasswordErrorProps) {
  const { key, message } = apiError || {}

  const [searchParams] = useSearchParams()
  const organizationSlug = searchParams.get('slug') ?? undefined
  const navigate = useNavigate()

  const clickHandler = useCallback(() => {
    // Navigate to organization login page
    navigate(`${loginHref}/${organizationSlug}`)
  }, [loginHref, navigate, organizationSlug])

  if (key === 'unrecognized_token' || key === 'used_or_expired_token') {
    return (
      <Box display='flex' gap={0.5} alignItems='center'>
        This link is invalid or has expired.
        <Link component='button' onClick={clickHandler}>
          Request a new link
        </Link>
      </Box>
    )
  }
  return <span>{message}</span>
}

export const ResetPasswordErrorMessage = memo<IResetPasswordErrorProps>(ResetPasswordError)
