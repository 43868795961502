import React, { memo, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { makeStyles } from '../../../../core'
import { Avatar, IAvatarProps } from '../../../Avatar'
import {
  DataGridAdvancedTextCell,
  IDataGridAdvancedTextCellProps,
} from '../DataGridAdvancedTextCell'

export interface IDataGridAvatarCellProps extends IDataGridAdvancedTextCellProps {
  /**
   * @description Props object to forward to Avatar component
   */
  AvatarProps?: Pick<IAvatarProps, 'name' | 'src' | 'size' | 'variant' | 'hideTooltip' | 'Icon'>
  /**
   * @description Determines if avatar should be positioned left or right of children
   * @default 'left'
   */
  avatarPosition?: 'left' | 'right'
  /**
   * @description Boolean value that determines if content should be justified with 'space-between'
   * @default false
   */
  spaceBetween?: boolean
}

const useDataGridAvatarCellStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'inherit',
    gap: theme.spacing(1.5),
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
}))

export const DataGridAvatarCell = memo<PropsWithChildren<IDataGridAvatarCellProps>>(
  ({
    AvatarProps,
    avatarPosition = 'left',
    bottomCaption,
    children,
    className,
    spaceBetween = false,
    topCaption,
  }) => {
    const classes = useDataGridAvatarCellStyles()
    const positionAvatarFirst = avatarPosition === 'left'

    function AvatarComponent() {
      return <Avatar name={typeof children === 'string' ? children : undefined} {...AvatarProps} />
    }

    const rootClasses = classNames(className, classes.root, {
      [classes.spaceBetween]: spaceBetween,
    })

    return (
      <div className={rootClasses} data-test='DataGridAvatarCell'>
        {positionAvatarFirst && <AvatarComponent />}
        {children && (
          <DataGridAdvancedTextCell bottomCaption={bottomCaption} topCaption={topCaption}>
            {children}
          </DataGridAdvancedTextCell>
        )}
        {!positionAvatarFirst && <AvatarComponent />}
      </div>
    )
  }
)
