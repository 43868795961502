export interface IMakeRequestParameters<RequestBody extends Record<string, unknown> | void> {
  url: string
  method: string
  body?: RequestBody
}

export const makeApiRequest = async <
  RequestBody extends Record<string, any> | void,
  ResponseData extends any,
>({
  body,
  method,
  url,
}: IMakeRequestParameters<RequestBody>) => {
  const response = await fetch(url, {
    method,
    body: body ? JSON.stringify(body) : null,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  return response.json() as Promise<ResponseData>
}
