import React, { memo } from 'react'
import {
  FormHelperText,
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  Stack,
  Typography,
} from '../../core'
import { brandColors } from '../../theme'
import { IToggleButtonProps, ToggleButton } from '../ToggleButton'

interface IToggleButtonGroupOption<TValue = string>
  extends Pick<IToggleButtonProps, 'startIcon' | 'label'> {
  value: TValue
}

export interface IToggleButtonGroupProps
  extends Pick<MuiToggleButtonGroupProps, 'value' | 'onChange' | 'exclusive'> {
  /**
   * The options to be displayed
   * @example [{ label: 'Red', value: 'red' }, { label: 'Orange', value: 'orange' }]
   * @default []
   */
  options: IToggleButtonGroupOption[]
  /**
   * The label text to be displayed
   * @default ''
   */
  label?: string
  /**
   * If `true`, the color of the component will be changed to the error color.
   * @default false
   */
  error?: boolean
  /**
   * The helper text content.
   * @default ''
   */
  helperText?: string
  /**
   * @default 'ToggleButtonGroup'
   */
  dataTest?: string
}

export const ToggleButtonGroup = memo<IToggleButtonGroupProps>(
  ({
    dataTest = 'ToggleButtonGroup',
    options = [],
    error = false,
    helperText = '',
    label = '',
    ...props
  }) => {
    return (
      <Stack gap={0.5} data-test={dataTest}>
        {label && (
          <Typography
            variant='caption'
            component='label'
            color={brandColors.coolGray5}
            data-test={`${dataTest}-label`}>
            {label}
          </Typography>
        )}
        <MuiToggleButtonGroup color={error ? 'error' : 'primary'} {...props}>
          {options.map(({ value, ...rest }) => (
            <div key={value}>
              <ToggleButton
                {...rest}
                value={value}
                color={error ? 'error' : 'primary'}
                dataTest={`${dataTest}-${value}`}
              />
            </div>
          ))}
        </MuiToggleButtonGroup>
        {helperText && (
          <FormHelperText error={error} data-test={`${dataTest}-helper-text`}>
            {helperText}
          </FormHelperText>
        )}
      </Stack>
    )
  }
)
