import { useCallback, useState } from 'react'

export interface IUseRequestProps<RequestArgs extends unknown[], ResponseData> {
  requestFn: (...args: RequestArgs) => Promise<ResponseData>
}

export interface IApiError {
  key: string
  message: string
}

export interface IApiErrorResponse {
  success?: boolean
  errors?: IApiError[]
}

export type TRequestFn<RequestArgs extends unknown[], ResponseData, ErrorType> = (
  ...args: RequestArgs
) => Promise<{ data?: ResponseData; error?: ErrorType }>

export type TUseRequestResult<RequestArgs extends unknown[], ResponseData, ErrorType> = [
  TRequestFn<RequestArgs, ResponseData, ErrorType>,
  { data?: ResponseData; error?: ErrorType; loading: boolean },
]

/**
 * @description Generic hook to wrap a function that returns a Promise.
 */
export const useRequest = <
  RequestArgs extends unknown[],
  ResponseData,
  ErrorType extends IApiErrorResponse,
>({
  requestFn,
}: IUseRequestProps<RequestArgs, ResponseData>): TUseRequestResult<
  RequestArgs,
  ResponseData,
  ErrorType
> => {
  const [data, setData] = useState<ResponseData>()
  const [error, setError] = useState<ErrorType>()
  const [loading, setLoading] = useState<boolean>(false)

  const request = useCallback<TRequestFn<RequestArgs, ResponseData, ErrorType>>(
    async (...args) => {
      try {
        setLoading(true)
        const data = await requestFn(...args)
        setData(data)
        setLoading(false)

        return { data }
      } catch (err) {
        let error = err
        // Catching HTTP errors with Ky: https://github.com/sindresorhus/ky?tab=readme-ov-file#httperror
        if (err.name === 'HTTPError') {
          error = await err.response.json()
        }

        setError(error)
        setLoading(false)
        return { error }
      }
    },
    [requestFn]
  )

  return [request, { data, error, loading }]
}
