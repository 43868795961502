import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'

/**
 * @name ToggleButtonGroup
 * @see https://mui.com/material-ui/api/toggle-button-group/
 */
export const MuiToggleButtonGroupThemeOptions: Components['MuiToggleButtonGroup'] = {
  styleOverrides: {
    root: {
      gap: BASE_THEME.spacing(1),
      flexWrap: 'wrap',
    },
    grouped: {
      borderRadius: BASE_THEME.spacing(5),
      margin: 0,
    },
  },
}
