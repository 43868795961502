import React, { FC, forwardRef } from 'react'
import classNames from 'classnames'
import {
  createStyles,
  makeStyles,
  SvgIcon as MuiSvgIcon,
  SvgIconProps as MuiSvgIconProps,
} from '../../../core'
import { getBrandColor } from '../../../theme/utils/getBrandColor'
import { TIconColor, TIconRem, TIconSize, ICON_REM_BY_SIZE } from './types'

export interface ISvgIconProps extends Pick<MuiSvgIconProps, 'className'> {
  /**
   * @default 'inherit'
   */
  color?: TIconColor
  /**
   * @default 'medium'
   */
  size?: TIconSize
  /**
   * @example Add
   */
  component?: FC
  /**
   * @note 'text-top': Aligns the top of the icon with the top of the parent element's font.
   * @note 'baseline': Aligns the baseline of the icon with the baseline of its parent.
   */
  verticalAlign?: 'baseline' | 'text-top'
}

export const getColor = (color: ISvgIconProps['color']): string => {
  if (!color || color === 'inherit') {
    return 'inherit'
  }

  return getBrandColor(color).hex()
}

/**
 * @example getIconRem('small') // { height: '0.75rem', width: '0.75rem' }
 */
const getIconRem = <K extends keyof TIconRem>(
  keyName: K,
  suffix: string = 'rem'
): Record<string, { height: string; width: string }> => {
  const value = `${ICON_REM_BY_SIZE[keyName]}${suffix}`
  return {
    '&.MuiSvgIcon-root': {
      height: value,
      width: value,
    },
  }
}

const useSvgIconStyles = makeStyles(() =>
  createStyles<any, Pick<ISvgIconProps, 'color' | 'verticalAlign'>>({
    root: {
      verticalAlign: ({ verticalAlign }) => verticalAlign,
      color: ({ color }) => getColor(color),
      padding: 0,
    },
    sizeSmall: getIconRem('small'),
    sizeDefault: getIconRem('default'),
    sizeMedium: getIconRem('medium'),
    sizeLarge: getIconRem('large'),
    sizeXlarge: getIconRem('xlarge'),
    sizeXxlarge: getIconRem('xxlarge'),
  })
)

const DefaultSvgComponent = forwardRef<SVGSVGElement, ISvgIconProps>((props, ref) => (
  <svg {...props} ref={ref} />
))

export const SvgIcon = forwardRef<SVGSVGElement, ISvgIconProps>(function SvgIcon(
  {
    className: classNameProp,
    color = 'inherit',
    component = DefaultSvgComponent,
    size = 'default',
    verticalAlign,
    ...props
  },
  ref
) {
  const classes = useSvgIconStyles({ color, verticalAlign })
  const className = classNames(classes.root, classNameProp, {
    [classes.sizeSmall]: size === 'small',
    [classes.sizeDefault]: size === 'default',
    [classes.sizeMedium]: size === 'medium',
    [classes.sizeLarge]: size === 'large',
    [classes.sizeXLarge]: size === 'xlarge',
    [classes.sizeXxlarge]: size === 'xxlarge',
  })

  return <MuiSvgIcon className={className} component={component} ref={ref} {...props} />
})

export default SvgIcon
