import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'

/**
 * @name Avatar
 * @see https://mui.com/material-ui/api/avatar/
 */
export const MuiAvatarThemeOptions: Components['MuiAvatar'] = {
  defaultProps: {
    variant: 'circular',
  },
  styleOverrides: {
    root: {
      fontWeight: BASE_THEME.typography.fontWeightBold,
    },
    rounded: {
      borderRadius: BASE_THEME.spacing(1),
    },
  },
}
