import React, { memo } from 'react'
import { Box, Typography } from 'dpl/core'

export interface IOptionItemProps {
  label: string
  description: string
}

export function OptionItemComponent({ description, label }: IOptionItemProps) {
  return (
    <Box>
      <Typography variant='body2'>{label}</Typography>
      <Typography variant='caption'>{description}</Typography>
    </Box>
  )
}

export const OptionItem = memo(OptionItemComponent)
