import React, { ChangeEvent } from 'react'
import classnames from 'classnames'
import { Theme, makeStyles } from '../../core'
import { brandColors } from '../../theme/colors'
import { ITagProps } from './Tag'
import { TAG_STYLE_COLORS } from './constants'
import { TAG_SIZE, TAG_STYLE, ISelectOption } from './types'

export interface ITagSelectProps extends Omit<ITagProps, 'label' | 'loading'> {
  options: ISelectOption<string>[]
  value: ISelectOption<string> | undefined
  disabled?: boolean
  onChange: (val: ChangeEvent<HTMLSelectElement>) => void
}

const useTagSelectStyles = makeStyles<Theme, { size: TAG_SIZE; tagStyle: TAG_STYLE }>(theme => ({
  root: {
    alignItems: 'center',
    borderRadius: theme.spacing(5.5),
    display: 'inline-block',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    width: 'auto',
    backgroundImage: `url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M20.6475 7.77527C21.1175 8.25829 21.1175 9.03704 20.6475 9.52005L12.6763 17.7117C12.3022 18.0961 11.6978 18.0961 11.3237 17.7117L3.35252 9.52005C2.88249 9.03704 2.88249 8.25829 3.35252 7.77527C3.82254 7.29225 4.58034 7.29225 5.05036 7.77527L12.0048 14.9121L18.9592 7.76541C19.4197 7.29225 20.1871 7.29225 20.6475 7.77527Z' fill='currentColor'/></svg>")`,
    backgroundPosition: 'right 8px center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    appearance: 'none',
    '&::-ms-expand': {
      display: 'none',
    },
  },
  tagSize: ({ size }) => {
    if (size === TAG_SIZE.xsmall) {
      return {
        fontSize: '12px',
        height: theme.spacing(3),
        padding: theme.spacing(0, 2.75, 0, 0.75),
        backgroundSize: theme.spacing(1.5),
      }
    }
    if (size === TAG_SIZE.small) {
      return {
        fontSize: '14px',
        height: theme.spacing(4),
        padding: theme.spacing(0, 4.25, 0, 1.25),
        backgroundSize: theme.spacing(1.75),
      }
    }
    if (size === TAG_SIZE.medium) {
      return {
        fontSize: '16px',
        height: theme.spacing(5),
        padding: theme.spacing(0, 4.25, 0, 1.25),
      }
    }
    return {
      fontSize: '20px',
      height: theme.spacing(5.5),
      padding: theme.spacing(0, 4.5, 0, 1.5),
    }
  },
  tagStyle: ({ tagStyle }) => ({
    backgroundColor: TAG_STYLE_COLORS[tagStyle].background,
    color: TAG_STYLE_COLORS[tagStyle].text,
    border: `2px solid ${TAG_STYLE_COLORS[tagStyle].background}`,
    '&:hover, &:focus, &:active': {
      border: `2px solid ${TAG_STYLE_COLORS[tagStyle].background}`,
    },
  }),
  tagOutline: ({ tagStyle }) => ({
    backgroundColor: brandColors.white,
    borderColor: TAG_STYLE_COLORS[tagStyle].background,
    color:
      tagStyle === TAG_STYLE.errorDark
        ? TAG_STYLE_COLORS[tagStyle].background
        : TAG_STYLE_COLORS[tagStyle].text,
  }),
  disabled: {
    backgroundColor: brandColors.white,
    borderColor: brandColors.coolGray1,
    color: brandColors.coolGray5,
    cursor: 'default',
    '& + label': {
      color: brandColors.coolGray8,
      cursor: 'default',
    },
  },
}))

/**
 * TagSelect is not very visually different from the `<Tag />` or `<FunctionalTag />` elements,
 * but allows us to render native <select /> elements on mobile
 */
export function TagSelect({
  size = TAG_SIZE.medium,
  tagStyle = TAG_STYLE.dark,
  className = '',
  dataTest = '',
  dataTooltip,
  outlineOnly,
  style,
  options = [],
  onChange,
  value: valueProp,
  disabled = false,
}: ITagSelectProps) {
  const classes = useTagSelectStyles({ size, tagStyle })
  const classNames = classnames(className, classes.root, classes.tagSize, classes.tagStyle, {
    [classes.tagOutline]: outlineOnly,
    [classes.disabled]: disabled,
  })
  return (
    <select
      disabled={disabled}
      className={classNames}
      style={style}
      data-tooltip={dataTooltip}
      onChange={onChange}
      value={valueProp?.value}
      data-test={dataTest}>
      {options.map(({ label, value }) => (
        <option key={value} value={value} data-test='tag-select-option'>
          {label}
        </option>
      ))}
    </select>
  )
}

export default TagSelect
