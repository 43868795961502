import React from 'react'
import classNames from 'classnames'
import { Avatar, IAvatarProps } from 'dpl/components/Avatar'
import { Badge, makeStyles } from 'dpl/core'
import EditIcon from 'dpl/icons/build/EditIcon'
import { boxShadowLevels } from 'dpl/theme'
import ControlledUploadInputButton, {
  IControlledUploadInputButtonProps,
} from 'forms/components/ControlledUploadInputButton'

const useUserAvatarStyles = makeStyles(theme => ({
  editBadgeButtonContainer: {
    height: 'auto',
    padding: 0,
  },
  roundedAvatarButtonContainer: {
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  editAvatarButton: {
    minWidth: 'auto',
    borderRadius: '50%',
    padding: theme.spacing(1),
    boxShadow: boxShadowLevels.high,
  },
}))

export interface IControlledAvatarImageUploadProps extends IControlledUploadInputButtonProps {
  avatarName: string
  avatarImageSrc?: string
  variant?: IAvatarProps['variant']
}

export function ControlledAvatarImageUpload({
  UploadInputProps,
  avatarImageSrc = '',
  avatarName,
  control,
  name,
  variant = 'circular',
}: IControlledAvatarImageUploadProps) {
  const classes = useUserAvatarStyles()
  const buttonContainerClassNames = classNames(classes.editBadgeButtonContainer, {
    [classes.roundedAvatarButtonContainer]: variant !== 'circular',
  })

  return (
    <div>
      <Badge
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        slotProps={{
          badge: { className: buttonContainerClassNames },
        }}
        badgeContent={
          <ControlledUploadInputButton
            control={control}
            name={name}
            UploadInputProps={{
              ...UploadInputProps,
              label: <EditIcon size='medium' />,
              className: classes.editAvatarButton,
              ButtonProps: { startIcon: null },
              InputProps: {
                accept: 'image/*',
                multiple: false,
              },
            }}
          />
        }>
        <Avatar
          name={avatarName}
          src={avatarImageSrc}
          variant={variant}
          size='xlarge'
          hideTooltip
        />
      </Badge>
    </div>
  )
}
