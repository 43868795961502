import React, { ReactNode, useMemo } from 'react'
import { DISPLAY } from '../../../../constants'
import { Typography, TableCell, TableRow, Box, AvatarGroup } from '../../../../core'
import { brandColors } from '../../../../theme'
import { Avatar } from '../../../Avatar'
import { FormattedDateTime } from '../../../FormattedDateTime'

const MAX_SHOWN_ADMIN = 5

export interface IOrganizationModulesTableRowProps {
  /**
   * The name of the module being displayed.
   */
  moduleName: string
  /**
   * The date when the module was activated, formatted as a string.
   * @example '2023-08-01T00:00:00Z'
   */
  activatedAt: string
  /**
   * The total number of members in the module.
   * @example 8
   */
  totalMembers?: ReactNode
  /**
   * A list of admin names for the application.
   * @default []
   * @example ['Alice Johnson', 'Bob Smith', 'Charlie Davis']
   */
  adminNames?: string[]
}

export function OrganizationModulesTableRow({
  activatedAt,
  moduleName,
  totalMembers,
  adminNames = [],
}: IOrganizationModulesTableRowProps) {
  const shownAdmin = useMemo(() => adminNames.slice(0, MAX_SHOWN_ADMIN), [adminNames])
  const remainingAdminsCount = useMemo(() => adminNames.length - MAX_SHOWN_ADMIN, [adminNames])
  return (
    <TableRow>
      <TableCell>
        <Typography variant='body2' component='div'>
          {moduleName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' component='div'>
          {totalMembers || 0}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display='flex' alignItems='center' gap={0.5} data-test='app-admin'>
          {adminNames.length > 0 ? (
            <AvatarGroup max={MAX_SHOWN_ADMIN}>
              {shownAdmin.map(admin => (
                <Avatar key={admin} name={admin} size='xsmall' data-test='shown-app-admin' />
              ))}
            </AvatarGroup>
          ) : (
            0
          )}
          {remainingAdminsCount > 0 && (
            <Typography variant='body2' component='div' data-test='remaining-app-admin'>
              +{remainingAdminsCount}
            </Typography>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant='body2' component='div'>
          Active
        </Typography>
        <Typography variant='caption' color={brandColors.coolGray5} component='div'>
          {activatedAt ? (
            <>
              Since <FormattedDateTime datetime={activatedAt} format='dateAndYear' />
            </>
          ) : (
            DISPLAY.empty
          )}
        </Typography>
      </TableCell>
    </TableRow>
  )
}
