import React, { memo, useCallback } from 'react'
import { IPillSelectProps, PillSelect } from '../../../../../PillSelect'
import { IDataGridFilterOption, IDataGridFilterProps } from '../types'

export interface IDataGridMultiSelectProps
  extends IDataGridFilterProps<string[]>,
    Pick<IPillSelectProps<string[]>, 'icon' | 'label'> {
  /**
   * An array of options for the single select
   * @default []
   */
  options: IDataGridFilterOption[]
  /**
   * @default 'DataGridMultiSelect'
   */
  dataTest?: string
}

export const DataGridMultiSelect = memo<IDataGridMultiSelectProps>(
  ({ dataTest = 'DataGridMultiSelect', options = [], onChange, name, ...rest }) => {
    const changeHandler: IPillSelectProps<string[]>['onChange'] = useCallback(
      newVal => {
        onChange({ name, value: newVal })
      },
      [name, onChange]
    )

    return (
      <PillSelect
        dataTest={dataTest}
        options={options}
        onChange={changeHandler}
        multiple
        disablePortal
        {...rest}
      />
    )
  }
)
