export enum IGeocodingAllowedCountryEnum {
  /** CA */
  CA = 'CA',
  /** MX */
  MX = 'MX',
  /** US */
  US = 'US',
}

export interface ICountryOption {
  code: IGeocodingAllowedCountryEnum
  label: string
}

export type TSelectCountryChangeHandler = (value: IGeocodingAllowedCountryEnum) => void
