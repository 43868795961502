import React, { PropsWithChildren, useCallback, useState, useMemo, ReactNode } from 'react'
import { Theme, makeStyles, ThemeProvider } from '../../core'
import { brandColors } from '../../theme'
import { IAppBarProps } from '../AppLayout/AppBar'
import { IUserAppBarMenuProps } from '../AppLayout/AppBar/UserAppBarMenu'
import { MobileNav } from '../AppLayout/MobileNav'
import { INavProps } from '../AppLayout/Nav'
import { AdminAppBar, IAdminAppBarProps } from './AdminAppBar'
import { IApplicationSelectorProps } from './ApplicationSelector'
import { theme } from './theme'

const useAdminLayoutStyles = makeStyles<Theme, { showBanner: boolean }>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: brandColors.coolGray2,
    height: '100vh',
    width: '100vw',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',

    [theme.breakpoints.up('md')]: {
      marginTop: ({ showBanner }) => (showBanner ? theme.spacing(1.5) : 0),
      marginRight: theme.spacing(1.5),
      marginBotton: theme.spacing(1.5),
      marginLeft: theme.spacing(1.5),
      borderRadius: theme.spacing(1.5),
    },
  },
  contentContainer: {
    background: brandColors.white,
    height: '100%',
    overflow: 'auto',
    width: '100%',
    position: 'relative',
  },
}))

export interface IAdminLayoutProps extends PropsWithChildren<{}> {
  /**
   * Banner component shown under `<AppBar />`
   */
  BannerComponent?: ReactNode
  /**
   * Used to show/hide Banner
   * @default false
   */
  showBanner?: boolean
  /**
   * Used to render user avatar and menu in AppBar
   */
  userInfo?: IAppBarProps['userInfo']
  /**
   * Callback that handles logging out from User Menu
   */
  onLogout: IUserAppBarMenuProps['onLogout']
  /**
   * Array of available apps
   */
  apps: IApplicationSelectorProps['apps']
  /**
   * Navigation items that display in the sidebar
   * @default []
   */
  navLists?: INavProps['navLists']
  /**
   * URL of the logo of the organization
   */
  logoSrc: IAdminAppBarProps['logoSrc']
  /**
   * Name of the organization
   */
  orgName: IAdminAppBarProps['orgName']
  /**
   * Title of the nav bar
   */
  title: IAdminAppBarProps['title']
}

export function AdminLayout({
  BannerComponent,
  children,
  userInfo,
  onLogout,
  apps,
  navLists = [],
  orgName,
  logoSrc,
  showBanner = false,
  title,
}: IAdminLayoutProps) {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false)
  const showNav = useMemo(() => navLists.length > 0, [navLists])
  const classes = useAdminLayoutStyles({ showBanner })

  const menuToggleHandler = useCallback(() => {
    setIsMobileNavOpen(prevState => !prevState)
  }, [])

  const closeMobileNavHandler = useCallback(() => {
    setIsMobileNavOpen(false)
  }, [])

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <AdminAppBar
          logoSrc={logoSrc}
          orgName={orgName}
          apps={apps}
          onLogout={onLogout}
          navLists={navLists}
          userInfo={userInfo}
          title={title}
          onMenuClick={menuToggleHandler}
          disableMenuButton={!showNav}
        />

        {showBanner && <div>{BannerComponent}</div>}

        <MobileNav
          color='light'
          isOpen={isMobileNavOpen}
          navLists={navLists}
          apps={apps}
          onClose={closeMobileNavHandler}
        />
      </ThemeProvider>
      <div className={classes.main}>
        <div className={classes.contentContainer}>{children}</div>
      </div>
    </div>
  )
}
