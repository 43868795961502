import React, { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Tooltip } from '../../../../../components'
import {
  makeStyles,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  Theme,
} from '../../../../../core'
import { TIconComponent } from '../../../../../icons/types'
import { brandColors, getBrandColor } from '../../../../../theme'

export interface INavListItemButtonProps {
  title: string
  href: string
  Icon: TIconComponent
  /**
   * @default false
   */
  selected?: MuiListItemButtonProps['selected']
  /**
   * @default true
   */
  isOpen?: boolean
  /**
   * @default 'NavListItemButton'
   */
  dataTest?: string
  /**
   * Set the color of the drawer
   * @default 'dark'
   */
  color?: 'dark' | 'light'
}

const useNavListItemButtonStyles = makeStyles<
  Theme,
  Pick<INavListItemButtonProps, 'isOpen' | 'color'>
>(theme => ({
  root: {
    borderRadius: theme.spacing(1),
    color: ({ color }) => (color === 'dark' ? brandColors.white : brandColors.black),
    justifyContent: ({ isOpen }) => (isOpen ? 'flex-start' : 'center'),

    '&:hover': {
      backgroundColor: ({ color }) =>
        color === 'dark'
          ? getBrandColor('white').alpha(0.2).toString()
          : getBrandColor('black').alpha(0.2).toString(),
    },

    '&.Mui-selected': {
      backgroundColor: brandColors.skyBlue2,
      color: brandColors.skyBlue7,

      '& .MuiListItemIcon-root': {
        color: brandColors.skyBlue7,
      },

      '&:hover': {
        backgroundColor: brandColors.skyBlue2,
      },
    },

    '& .MuiListItemIcon-root': {
      color: ({ color }) => (color === 'dark' ? brandColors.white : brandColors.black),
    },
  },
}))

type TNavListItemButtonProps<D extends React.ElementType = 'div', P = {}> = Omit<
  MuiListItemButtonProps<D, P>,
  'className' | 'LinkComponent' | 'href' | 'selected'
> &
  INavListItemButtonProps

export function NavListItemButtonComponent<D extends React.ElementType, P = {}>(
  {
    Icon,
    color,
    dataTest = 'NavListItemButton',
    href,
    isOpen = true,
    selected = false,
    title,
    ...props
  }: TNavListItemButtonProps<D, P>,
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | React.MutableRefObject<HTMLDivElement | null>
    | null
) {
  const classes = useNavListItemButtonStyles({ isOpen, color })
  return (
    <Tooltip title={title} placement='right' color='light' disabled={isOpen}>
      <MuiListItemButton
        ref={ref}
        className={classes.root}
        component={RouterLink}
        to={href}
        selected={selected}
        data-test={dataTest}
        {...props}>
        <MuiListItemIcon data-test={`${dataTest}-icon`}>
          <Icon size='large' />
        </MuiListItemIcon>
        {isOpen && (
          <MuiListItemText
            data-test={`${dataTest}-text`}
            primaryTypographyProps={{ variant: 'body2', component: 'span', fontWeight: 600 }}>
            {title}
          </MuiListItemText>
        )}
      </MuiListItemButton>
    </Tooltip>
  )
}

/**
 *
 * @name NavListItemButton
 * @description Styled abstraction of MuiListItemButton to be used in the NavDrawer
 * @see https://mui.com/material-ui/api/list-item-button/
 */
export const NavListItemButton = forwardRef(NavListItemButtonComponent)
