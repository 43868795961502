import React, { useMemo } from 'react'
import moment from 'moment-timezone'
import { DatePickerProps as MuiDatePickerProps, MaterialUiPickersDate } from '../../../core/pickers'
import CalendarIcon from '../../../icons/build/CalendarIcon'
import { SvgIcon } from '../../../icons/components/SvgIcon'
import { TextField, ITextFieldProps } from '../../TextField'

interface IDatePickerTextFieldProps extends ITextFieldProps {
  ownerState?: Pick<
    MuiDatePickerProps<MaterialUiPickersDate>,
    'format' | 'value' | 'timezone' | 'slotProps'
  >
  Icon?: typeof SvgIcon
}

export function DatePickerTextField({
  InputLabelProps = {},
  ownerState,
  Icon = CalendarIcon,
  ...props
}: IDatePickerTextFieldProps) {
  const { format, slotProps, timezone, value: datePickerValue } = ownerState || {}
  const { textField: textFieldSlotProps } = slotProps || {}

  const value = useMemo(() => {
    if (!datePickerValue || !datePickerValue?.isValid()) return ''
    if (timezone) {
      return moment.tz(datePickerValue, timezone).format(format)
    }
    return moment(datePickerValue).format(format)
  }, [datePickerValue, format, timezone])

  return (
    <TextField
      {...props}
      {...textFieldSlotProps}
      value={value}
      InputProps={{
        ...props.InputProps,
        startAdornment: <Icon size='xlarge' />,
        // removes default calendar icon from MUI
        endAdornment: null,
        readOnly: true,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        // only shrinks label when there is a value, prevents overlap with startAdornment when label is not.. shrank
        ...(InputLabelProps?.shrink === false && !value
          ? { shrink: false, style: { paddingLeft: '2rem', top: '4px' } }
          : { shrink: true }),
      }}
    />
  )
}
