import React, { memo } from 'react'
import classNames from 'classnames'
import get from 'lodash/get'
import { createStyles, makeStyles } from '../../core'
import LogoIconGradientSvg from './svgs/LogoIconGradient.svg?react'
import LogoIconSolidSvg from './svgs/LogoIconSolid.svg?react'

const ASPECT_RATIO = 0.93636625

type TLogoIconSize = 'xsmall' | 'small' | 'default' | 'large'

const SIZES: Record<TLogoIconSize, number> = {
  xsmall: 19.15,
  small: 40,
  default: 64,
  large: 192,
}

const getHeightPx = (width: number): number => {
  return width * ASPECT_RATIO
}

const getWidthPx = (size: TLogoIconSize): number => {
  return get(SIZES, size, SIZES.default)
}

const getDimensions = (size: TLogoIconSize): { height: number; width: number } => {
  const width = getWidthPx(size)
  const height = getHeightPx(width)
  return {
    width,
    height,
  }
}

type TLogoIconGradientProps = {
  variant?: 'gradient'
  color?: 'black'
}

type TLogoIconSolidProps = {
  variant?: 'solid'
  color?: 'black' | 'white'
}

type TConditionalProps = TLogoIconGradientProps | TLogoIconSolidProps

export type TLogoIconProps = {
  /**
   * @default 'default'
   */
  size?: TLogoIconSize
  className?: string
} & TConditionalProps

type TClassKey = 'root' | 'svgImage'

const useLogoIconStyles = makeStyles(() =>
  createStyles<TClassKey, { height: number; width: number; variant: string; color: string }>({
    root: {
      display: 'inline-block',
      position: 'relative',
      color: ({ color, variant }) => (variant === 'solid' ? color : 'inherit'),
      width: ({ width }) => width,
      height: ({ height }) => height,
    },
    svgImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  })
)

export const LogoIcon = memo<TLogoIconProps>(
  ({ className: classNameProp, color = 'black', size = 'default', variant = 'solid' }) => {
    const { height, width } = getDimensions(size)
    const classes = useLogoIconStyles({ color, variant, height, width })
    const LogoComponent = variant === 'solid' ? LogoIconSolidSvg : LogoIconGradientSvg

    return (
      <span className={classNames(classes.root, classNameProp)} data-test='logo-icon'>
        <LogoComponent className={classes.svgImage} />
      </span>
    )
  }
)

export default LogoIcon
