import { brandColors } from 'dpl/theme/colors'
import { EPasswordStrengthLevel } from '../../types'

export const STRENGTH_NUMBER: Record<EPasswordStrengthLevel, number> = {
  [EPasswordStrengthLevel.great]: 4,
  [EPasswordStrengthLevel.good]: 3,
  [EPasswordStrengthLevel.fair]: 2,
  [EPasswordStrengthLevel.weak]: 1,
}

export const STRENGTH_BAR_COLORS: Record<EPasswordStrengthLevel, string> = {
  [EPasswordStrengthLevel.great]: brandColors.green2,
  [EPasswordStrengthLevel.good]: brandColors.green2,
  [EPasswordStrengthLevel.fair]: brandColors.warning1,
  [EPasswordStrengthLevel.weak]: brandColors.error1,
}

export const STRENGTH_LABEL_COLORS: Record<EPasswordStrengthLevel, string> = {
  [EPasswordStrengthLevel.great]: brandColors.green3,
  [EPasswordStrengthLevel.good]: brandColors.green2,
  [EPasswordStrengthLevel.fair]: brandColors.warning1,
  [EPasswordStrengthLevel.weak]: brandColors.error1,
}
