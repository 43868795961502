import React, { useMemo } from 'react'
import kebabCase from 'lodash/kebabCase'
import {
  List as MuiList,
  ListProps as MuiListProps,
  ListItem as MuiListItem,
  ListSubheader as MuiListSubheader,
  Theme,
} from '../../../../core'
import Typography from '../../../../core/Typography'
import { makeStyles } from '../../../../core/styles'
import { brandColors } from '../../../../theme/colors'
import { INavListItemButtonProps, NavListItemButton } from './NavListItemButton'

export interface INavListProps {
  /**
   * Optional title that displays above nav list items
   * @default ''
   */
  navListTitle?: string
  /**
   * Nav buttons within list
   * @default []
   */
  navListItems: Omit<INavListItemButtonProps, 'isOpen'>[]
  /**
   * @default true
   */
  isOpen?: boolean
  /**
   * @default 'NavList'
   */
  dataTest?: string
  /**
   * Set the color of the drawer
   * @default 'dark'
   */
  color?: 'dark' | 'light'
  /**
   * Function to close the menu
   */
  onClose?: () => void
  /**
   * Number used to sort the nav items.
   */
  position?: number
}

type TNavListProps<D extends React.ElementType = 'div', P = {}> = Omit<
  MuiListProps<D, P>,
  'className'
> &
  INavListProps

const useNavListStyles = makeStyles<Theme, Pick<INavListProps, 'color'>>(theme => ({
  subheaderText: {
    color: ({ color }) => (color === 'dark' ? brandColors.white : brandColors.black),
    textTransform: 'uppercase',
    letterSpacing: 1.5,
  },
  listItem: {
    marginBottom: theme.spacing(0.5),
  },
}))

/**
 *
 * @name NavList
 * @description Styled abstraction of MuiList to be used in the NavDrawer
 * @see https://mui.com/material-ui/api/list/
 */
export function NavList<D extends React.ElementType, P = {}>({
  color = 'dark',
  dataTest = 'NavList',
  navListItems = [],
  navListTitle = '',
  isOpen = true,
  onClose,
  ...props
}: TNavListProps<D, P>) {
  const classes = useNavListStyles({ color })
  const shouldDisplayTitle = useMemo(() => {
    return isOpen && navListTitle
  }, [isOpen, navListTitle])
  return (
    <MuiList data-test={dataTest} {...props} disablePadding>
      {shouldDisplayTitle && (
        <MuiListSubheader data-test={`${dataTest}-subheader`} disableSticky>
          <Typography
            variant='caption'
            component='div'
            className={classes.subheaderText}
            color='white'>
            {navListTitle}
          </Typography>
        </MuiListSubheader>
      )}
      {navListItems.map(({ title, ...navItemProps }) => {
        const key = [dataTest, 'item', kebabCase(navListTitle), kebabCase(title)].join('-')
        return (
          <MuiListItem
            key={key}
            data-test={key}
            className={classes.listItem}
            disablePadding
            disableGutters>
            <NavListItemButton
              title={title}
              isOpen={isOpen}
              dataTest={`${key}-button`}
              color={color}
              onClick={onClose}
              {...navItemProps}
            />
          </MuiListItem>
        )
      })}
    </MuiList>
  )
}
