import React, { forwardRef, PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'
import { Box, makeStyles } from '../../../core'
import { DragHandleButton } from './DragHandleButton'

const useListItemStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  isDragging: {
    opacity: 0.4,
  },
}))

interface IListItemProps {
  /**
   * If item is being dragged. Used for item styling when dragged.
   */
  isDragging?: boolean
  /**
   * Id of the item
   */
  itemId: string
  /**
   * If the drag button should be disabled
   */
  isDisabled?: boolean
  children: ReactNode
  className?: string
  /**
   * Index of item in list
   */
  index: number
}

export function ListItemComponent(
  {
    children,
    className,
    index,
    isDisabled = false,
    isDragging = false,
    itemId,
  }: PropsWithChildren<IListItemProps>,
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | React.MutableRefObject<HTMLDivElement | null>
    | null
) {
  const classes = useListItemStyles()

  const rowClassName = classNames(classes.root, className, {
    [classes.isDragging]: isDragging,
  })

  return (
    <div
      className={rowClassName}
      // data-draggable-id is how we query for this item for post drop flash
      data-draggable-id={itemId}
      ref={ref}>
      <DragHandleButton isDisabled={isDisabled} index={index} isDragging={isDragging} />
      <Box flex='1 1 auto'>{children}</Box>
    </div>
  )
}

export const ListItem = forwardRef(ListItemComponent)
