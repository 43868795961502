import { IDataGridSortOption, TDataGridFiltersConfig } from 'dpl'
import { AdminIcon } from 'dpl/icons'
import { IOrganizationUserSortBy, IOrganizationUserStatus } from '../../../../types/graphqlTypes'
import { IFilters } from './types'

export const FILTERS_OPTIONS_CONFIG: TDataGridFiltersConfig<IFilters> = {
  searchName: {
    type: 'textInput',
    dataTest: 'members-data-grid-search-name-input',
    placeholder: 'Search User Name...',
    label: 'User Name',
  },
  productModules: {
    type: 'multiSelect',
    dataTest: 'members-data-grid-product-modules-select',
    label: 'Products',
    options: [],
  },
  role: {
    type: 'singleSelect',
    dataTest: 'members-data-grid-role-select',
    label: 'Role',
    options: [],
  },
  organizationAdmin: {
    type: 'toggleButton',
    label: 'Organization Admin',
    startIcon: AdminIcon,
    dataTest: 'organization-admin-toggle',
  },
  statuses: {
    type: 'multiSelect',
    dataTest: 'members-data-grid-status-select',
    label: 'Status',
    options: [
      { label: 'Active', value: IOrganizationUserStatus.active },
      { label: 'Invited', value: IOrganizationUserStatus.invited },
      { label: 'Deleted', value: IOrganizationUserStatus.deleted },
    ],
  },
}

export const SORT_OPTIONS_CONFIG: IDataGridSortOption<IOrganizationUserSortBy>[] = [
  {
    label: 'A-Z (First Name)',
    value: IOrganizationUserSortBy.firstName,
  },
  { label: 'Last seen (Most Recent)', value: IOrganizationUserSortBy.lastLogInAt },
]

export const PAGE_RANGES = [20, 40, 60]
