import { KyInstance } from 'ky'
import { ISendSignInLinkRequestBody } from './types'

export interface IMagicLinksClientOptions {
  connection: KyInstance
}

export interface IMagicLinksClient {
  sendSignInLink: (data: ISendSignInLinkRequestBody) => Promise<{}>
}

export const MAGIC_LINKS_ENDPOINTS = {
  sendMagicLink: 'magic_links',
}

export const makeMagicLinksClient = ({
  connection,
}: IMagicLinksClientOptions): IMagicLinksClient => {
  return {
    sendSignInLink: data => {
      return connection
        .post(MAGIC_LINKS_ENDPOINTS.sendMagicLink, {
          json: data,
        })
        .json()
    },
  }
}
