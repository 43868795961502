import React from 'react'
import { FormControlLabel, makeStyles, Switch as MuiSwitch, SwitchProps } from '../../core'

export interface ISwitchProps extends SwitchProps {
  dataTest?: string
  label?: string | React.ReactNode
}

const useSwitchStyles = makeStyles({
  label: {
    '&.Mui-disabled': {
      color: 'inherit',
    },
  },
})

export function Switch({ checked, dataTest, label, onChange, ...props }: ISwitchProps) {
  const classes = useSwitchStyles()

  return (
    <FormControlLabel
      data-test={dataTest}
      control={<MuiSwitch checked={checked} onChange={onChange} {...props} />}
      label={label}
      classes={classes}
    />
  )
}

export default Switch
