import React, { memo, useCallback } from 'react'
import Color from 'color'
import { Avatar } from '../../../../components/Avatar'
import { Box, ButtonBase, Typography, makeStyles } from '../../../../core'
import DropdownDownIcon from '../../../../icons/build/DropdownDownIcon'
import DropdownUpIcon from '../../../../icons/build/DropdownUpIcon'
import { brandColors } from '../../../../theme/colors'
import { OrgNavMenu } from './OrgNavMenu'
import { IOrgNavMenuContentsProps } from './OrgNavMenuContents'

export interface IOrgNavButtonProps
  extends Omit<IOrgNavMenuContentsProps, 'dataTest' | 'onLinkClick'> {
  /**
   * @default 'OrgNavButton'
   */
  dataTest?: string
  /**
   * Is button collapsed?
   * @default false
   */
  isCollapsed?: boolean
}

const useOrgNavButtonStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1),
    color: brandColors.white,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    width: '100%',
    transition: 'all 0.3s ease',
    '&.active, &:hover, &:focus, &:active': {
      backgroundColor: `${Color(brandColors.white).fade(0.8)}`,
    },
  },
}))

export const OrgNavButton = memo<IOrgNavButtonProps>(
  ({
    dataTest = 'OrgNavButton',
    isAdmin,
    isCollapsed = false,
    orgAdminHref,
    orgImgSrc,
    orgName,
    orgNavMenuLinks,
    orgProfileHref,
  }) => {
    const classes = useOrgNavButtonStyles()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const clickHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }, [])

    const closeHandler = useCallback(() => {
      setAnchorEl(null)
    }, [])

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
      <>
        <ButtonBase
          aria-describedby={id}
          className={`${classes.root} ${open ? 'active' : ''}`}
          data-test={dataTest}
          onClick={clickHandler}>
          <Box display='flex' alignItems='center' gap={1}>
            <Avatar
              variant='rounded'
              size='large'
              src={orgImgSrc}
              alt={orgName}
              name={orgName}
              data-test={`${dataTest}-avatar`}
              hideTooltip
            />
            {!isCollapsed && (
              <Typography variant='subtitle2' component='div' data-test={`${dataTest}-name`}>
                {orgName}
              </Typography>
            )}
          </Box>
          {!isCollapsed && (
            <Box display='flex' alignItems='center' justifyContent='center'>
              {open ? (
                <DropdownDownIcon size='xlarge' data-test={`${dataTest}-down`} />
              ) : (
                <DropdownUpIcon size='xlarge' data-test={`${dataTest}-up`} />
              )}
            </Box>
          )}
        </ButtonBase>
        <OrgNavMenu
          anchorEl={anchorEl}
          id={id}
          isAdmin={isAdmin}
          onClose={closeHandler}
          open={open}
          dataTest={`${dataTest}-menu`}
          orgAdminHref={orgAdminHref}
          orgImgSrc={orgImgSrc}
          orgName={orgName}
          orgNavMenuLinks={orgNavMenuLinks}
          orgProfileHref={orgProfileHref}
        />
      </>
    )
  }
)
