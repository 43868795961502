import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from '../../../core'
import { Theme } from '../../../core/styles'
import DragHandleIcon from '../../../icons/build/DragHandleIcon'
import { brandColors } from '../../../theme/colors'
import { useMenuToggle } from '../../../utils/hooks/useMenuToggle'
import { IconButton } from '../../IconButton'
import OverflowMenu from '../../OverflowMenu'
import { useDraggableListContext } from './DraggableListContext'

const useDragHandleButtonStyles = makeStyles<Theme, { isDragging: boolean }>(theme => ({
  menu: {
    marginLeft: theme.spacing(1),
  },
  dragIcon: {
    cursor: 'grab',
    borderRadius: theme.spacing(0.5),
    '&:hover, &:focus': {
      backgroundColor: ({ isDragging }) => (isDragging ? brandColors.gray1 : ''),
      color: ({ isDragging }) => (isDragging ? brandColors.coolGray5 : ''),
    },
  },
}))

interface IDragHandleButtonProps {
  /**
   * Index of item in list
   */
  index: number
  /**
   * If dragging is disabled
   * @default false
   */
  isDisabled?: boolean
  /**
   * If current item is being dragged
   */
  isDragging: boolean
}

export function DragHandleButton({
  index,
  isDisabled = false,
  isDragging,
}: IDragHandleButtonProps) {
  const classes = useDragHandleButtonStyles({ isDragging })
  const { listLength, reorderItem } = useDraggableListContext()
  const { anchorEl, expanded, handleClick, handleClose } = useMenuToggle()

  const isOnlyItem = listLength === 1
  const isMoveUpDisabled = index === 0 || isOnlyItem
  const isMoveDownDisabled = index === listLength - 1 || isOnlyItem

  const actions = [
    {
      itemComponent: <Typography variant='body2'>Move to top</Typography>,
      label: 'Move to top',
      action: () =>
        reorderItem({
          startIndex: index,
          indexOfTarget: 0,
          closestEdgeOfTarget: null,
        }),
      disabled: isMoveUpDisabled,
      dataTest: 'move-to-top-option',
    },
    {
      itemComponent: <Typography variant='body2'>Move up</Typography>,
      label: 'Move up',
      action: () =>
        reorderItem({
          startIndex: index,
          indexOfTarget: index - 1,
          closestEdgeOfTarget: null,
        }),
      disabled: isMoveUpDisabled,
      dataTest: 'move-up-option',
    },
    {
      itemComponent: <Typography variant='body2'>Move down</Typography>,
      label: 'Move down',
      action: () =>
        reorderItem({
          startIndex: index,
          indexOfTarget: index + 1,
          closestEdgeOfTarget: null,
        }),
      disabled: isMoveDownDisabled,
      dataTest: 'move-down-option',
    },
    {
      itemComponent: <Typography variant='body2'>Move to bottom</Typography>,
      label: 'Move to bottom',
      action: () =>
        reorderItem({
          startIndex: index,
          indexOfTarget: listLength - 1,
          closestEdgeOfTarget: null,
        }),
      disabled: isMoveDownDisabled,
      dataTest: 'move-to-bottom-option',
    },
  ]

  return (
    <OverflowMenu
      alignment='left'
      className={classes.menu}
      dropdownId='DragAndDropMenu'
      dataTest={`DragAndDropMenu-${index}`}
      options={actions}
      anchorEl={anchorEl}
      expanded={expanded}
      onClose={handleClose}
      onSelectMenuItem={handleClose}
      overrideMobileStyles
      trigger={
        <IconButton
          className={classes.dragIcon}
          label='Drag handle button'
          dataTest={`DragHandleButton-${index}`}
          Icon={DragHandleIcon}
          color='secondary'
          disabled={isDisabled}
          onClick={handleClick}
          size='large'
        />
      }
    />
  )
}
