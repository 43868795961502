import moment, { Moment } from 'moment-timezone'
import { TIME_INPUT_FORMAT } from '../types'
import { buildTimes } from './buildTimes'

export interface IGetSuggestionsArg {
  guessedOption?: string
  anchorDateTime?: Moment
  timeFormat: TIME_INPUT_FORMAT
  minuteIncrement: number
}

/**
 * Get list of suggestions for <TimeInput />
 */
export const getSuggestions = ({
  guessedOption,
  anchorDateTime = moment().startOf('d').startOf('hour'),
  timeFormat,
  minuteIncrement,
}: IGetSuggestionsArg): string[] => {
  if (guessedOption || anchorDateTime) {
    const time =
      !!guessedOption && !!timeFormat && moment(guessedOption, timeFormat).isValid()
        ? moment(guessedOption, timeFormat)
        : anchorDateTime
    return buildTimes(
      time
        .clone()
        .startOf('d')
        .minutes(60 % minuteIncrement),
      timeFormat,
      minuteIncrement
    )
  }
  // Default options
  return buildTimes(moment().startOf('day').startOf('hour'), timeFormat, minuteIncrement)
}
