import React, { memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Avatar } from '../../../../../components/Avatar'
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '../../../../../core'
import OpenInNewTabIcon from '../../../../../icons/build/OpenInNewTabIcon'
import { TIconComponent } from '../../../../../icons/types'
import { brandColors } from '../../../../../theme/colors'

export interface IOrgNavMenuLink {
  displayDividerBelow?: boolean
  href: string
  Icon: TIconComponent
  title: string
}

export interface IOrgNavMenuContentsProps {
  /**
   * @default 'OrgNavMenuContents'
   */
  dataTest?: string
  /**
   * can access org admin experience?
   * @default false
   */
  isAdmin?: boolean
  /**
   * Path for the Organization Admin Console
   */
  orgAdminHref: string
  /**
   * Organization logo img src
   */
  orgImgSrc?: string
  /**
   * Organization name
   */
  orgName: string
  /**
   * Organization nav menu links
   * @default []
   */
  orgNavMenuLinks?: IOrgNavMenuLink[]
  /**
   * Path for the Organization Profile
   */
  orgProfileHref: string
  /**
   * Callback to be called when a link is clicked
   */
  onLinkClick: () => void
}

const useOrgNavMenuContentsStyles = makeStyles(theme => ({
  divider: {
    borderColor: brandColors.coolGray2,
  },
  list: {
    padding: theme.spacing(0, 0, 1.5, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1.5, 0),
    },
  },
  orgListItem: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1.5),
    },
  },
  listItem: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1.5, 2.5),
    },
  },
}))

export const OrgNavMenuContents = memo<IOrgNavMenuContentsProps>(
  ({
    dataTest = 'OrgNavMenuContents',
    isAdmin,
    orgAdminHref,
    orgImgSrc,
    orgName,
    orgNavMenuLinks = [],
    orgProfileHref,
    onLinkClick,
  }) => {
    const classes = useOrgNavMenuContentsStyles()
    return (
      <List className={classes.list} data-test={dataTest}>
        <ListItemButton
          className={classes.orgListItem}
          component={RouterLink}
          to={isAdmin ? orgAdminHref : orgProfileHref}
          target={isAdmin ? '_blank' : undefined}
          onClick={onLinkClick}>
          <ListItemIcon>
            <Avatar
              variant='rounded'
              size='large'
              src={orgImgSrc}
              alt={orgName}
              name={orgName}
              hideTooltip
            />
          </ListItemIcon>
          <ListItemText
            primary={orgName}
            primaryTypographyProps={{ variant: 'body2', component: 'span', fontWeight: 600 }}
            secondary={isAdmin ? 'Go to Admin Console' : 'View Organization Profile'}
            secondaryTypographyProps={{ variant: 'caption', component: 'span', fontWeight: 600 }}
          />
          {isAdmin && (
            <ListItemIcon>
              <OpenInNewTabIcon size='large' color='coolGray5' />
            </ListItemIcon>
          )}
        </ListItemButton>
        {orgNavMenuLinks.length > 0 && <Divider className={classes.divider} />}
        {orgNavMenuLinks.map(({ Icon, displayDividerBelow, href, title }) => (
          <>
            <ListItemButton
              key={title}
              component={RouterLink}
              to={href}
              className={classes.listItem}
              onClick={onLinkClick}>
              <ListItemIcon>
                <Icon size='large' color='coolGray8' />
              </ListItemIcon>
              <ListItemText
                primary={title}
                primaryTypographyProps={{ variant: 'body2', component: 'span', fontWeight: 600 }}
              />
            </ListItemButton>
            {displayDividerBelow && <Divider className={classes.divider} />}
          </>
        ))}
      </List>
    )
  }
)
