import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'

export const MuiToolbarThemeOptions: Components['MuiToolbar'] = {
  styleOverrides: {
    root: {
      [BASE_THEME.breakpoints.up(1)]: {
        minHeight: '55px',
      },
    },
  },
}
