import React, { memo } from 'react'
import { FormHelperText as MuiFormHelperText, FormHelperTextProps } from '../../core'
import { makeStyles, createStyles } from '../../core/styles'
import { brandColors } from '../../theme/colors'

/**
 * We're overriding FormControl styles to
 * - put the radio buttons border red when there's an error (this is available in latest MUI version as a variation)
 */
const useFormHelperTextStyles = makeStyles(() => {
  return createStyles<'error' | 'root', { hasError: boolean }>({
    root: {
      display: 'inline-block',
    },
    error: {
      color: brandColors.error,
    },
  })
})

export interface IFormHelperTextProps extends FormHelperTextProps {
  /**
   * @default false
   */
  hasError?: boolean
}

export const FormHelperText = memo<IFormHelperTextProps>(
  ({ children, hasError = false, ...props }) => {
    const classes = useFormHelperTextStyles({ hasError })
    return (
      <MuiFormHelperText classes={classes} {...props} error={hasError}>
        {children}
      </MuiFormHelperText>
    )
  }
)

export default FormHelperText
