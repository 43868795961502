import React from 'react'
import { QueryParamProvider } from 'dpl/providers'
import { Members } from './Members'

const DEFAULT_PROPS = {
  name: 'Members',
}

export function MembersContainer() {
  return (
    <QueryParamProvider>
      <Members {...DEFAULT_PROPS} />
    </QueryParamProvider>
  )
}
