import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Color from 'color'
import SupportModal from 'dpl/templates/SupportModal/SupportModal'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { IconButton } from '../../../components/IconButton'
import { Tag, TAG_SIZE, TAG_STYLE } from '../../../components/Tag'
import { Tooltip } from '../../../components/Tooltip'
import { capitalize, makeStyles, AppBar as MuiAppBar, Toolbar } from '../../../core'
import HelpIcon from '../../../icons/build/HelpIcon'
import MenuIcon from '../../../icons/build/MenuIcon'
import { brandColors } from '../../../theme/colors'
import {
  CARRIER_APP_LINKS,
  CARRIER_APP_MODAL_BODY,
  SOLUTION_CONSOLE_APP_NAME,
  SOLUTION_CONSOLE_LINKS,
  SOLUTION_CONSOLE_MODAL_BODY,
} from '../constants'
import { AppBarLink } from './AppBarLink'
import TransfixLogo from './TransfixLogo.svg?react'
import { IUserAppBarMenuProps, UserAppBarMenu } from './UserAppBarMenu'

export interface IAppBarProps {
  /**
   * disables AppBarLink
   */
  disableAppBarLink?: boolean
  /**
   * Used to disable hamburger menu in top left
   * @default false
   */
  disableMenuButton?: boolean
  /**
   * Click action for hamburger menu
   * Used to toggle side nav
   */
  onMenuClick?: () => void
  /**
   * Used to display User avatar and menu
   */
  userInfo?: Pick<IUserAppBarMenuProps, 'name' | 'email' | 'userProfileHref'> & {
    /**
     * @default false
     */
    isAdmin?: boolean
  }
  /**
   * Callback that handles logging out from User Menu
   */
  onLogout: IUserAppBarMenuProps['onLogout']
}

const useAppBarStyles = makeStyles(theme => ({
  menuHome: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  logoButton: {
    '& svg': {
      height: '20px',
      width: 'auto',
    },
    '&:hover, &:focus, &:active': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent !important',
      borderColor: 'transparent !important',
    },
  },
  supportAvatar: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  tags: {
    display: 'inline-flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  iconButton: {
    width: '20px',
    height: '20px',
    '&:hover, &:focus, &:active': {
      color: theme.palette.secondary.main,
      backgroundColor: `${Color(brandColors.white).fade(0.8)} !important`,
      borderColor: 'transparent !important',
      boxShadow: `0 0 0 4px ${Color(brandColors.white).fade(0.8)}`,
      borderRadius: '50%',
    },
  },
}))

const ENV_LABEL = capitalize(import.meta.env.MODE)
const APP_NAME = import.meta.env.VITE_APP_NAME

function AppBar({
  disableAppBarLink,
  disableMenuButton = false,
  onLogout,
  onMenuClick,
  userInfo,
}: IAppBarProps) {
  const classes = useAppBarStyles()
  const { createToggleHandler, modalState } = useModalState({
    support: false,
  })

  const { isAdmin = false } = userInfo || {}

  const toggleSupportModal = createToggleHandler('support')

  const supportModalLinks =
    APP_NAME === SOLUTION_CONSOLE_APP_NAME ? SOLUTION_CONSOLE_LINKS : CARRIER_APP_LINKS
  const supportModalBody =
    APP_NAME === SOLUTION_CONSOLE_APP_NAME ? SOLUTION_CONSOLE_MODAL_BODY : CARRIER_APP_MODAL_BODY

  return (
    <MuiAppBar position='relative'>
      <Toolbar>
        <div className={classes.menuHome}>
          {!disableMenuButton && (
            <Tooltip title='Show/hide menu' placement='left' color='light'>
              <IconButton
                Icon={MenuIcon}
                size='large'
                onClick={onMenuClick}
                noPadding
                dataTest='appbar-menu-button'
                className={classes.iconButton}
              />
            </Tooltip>
          )}
          <AppBarLink
            IconButtonProps={{
              Icon: TransfixLogo,
              href: '/',
              className: classes.logoButton,
              dataTest: 'appbar-logo-button',
              component: RouterLink,
            }}
            isDisabled={disableAppBarLink}
          />
          <div className={classes.tags}>
            {!import.meta.env.PROD && (
              <Tag size={TAG_SIZE.xsmall} label={ENV_LABEL} tagStyle={TAG_STYLE.error} />
            )}
            {userInfo && isAdmin && (
              <Tag size={TAG_SIZE.xsmall} label='TFX Admin' dataTest='appbar-admin-tag' />
            )}
          </div>
        </div>
        <div className={classes.supportAvatar}>
          <AppBarLink
            tooltipText='Support'
            IconButtonProps={{
              Icon: HelpIcon,
              onClick: toggleSupportModal,
              dataTest: 'appbar-support-button',
              className: classes.iconButton,
            }}
          />
          {userInfo && <UserAppBarMenu {...userInfo} onLogout={onLogout} />}
        </div>
        <SupportModal
          open={modalState.support}
          onClose={toggleSupportModal}
          links={supportModalLinks}
          modalBody={supportModalBody}
        />
      </Toolbar>
    </MuiAppBar>
  )
}

export default AppBar
