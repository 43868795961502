import { Components } from '@mui/material/styles'
import Color from 'color'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'
import { boxShadowLevels } from '../shadows'
import { TYPOGRAPHY_THEME_OPTIONS } from '../typography'

export const MuiSliderThemeOptions: Components['MuiSlider'] = {
  styleOverrides: {
    rail: {
      opacity: 0.3,
    },
    markLabel: {
      ...TYPOGRAPHY_THEME_OPTIONS.caption,
      color: BASE_THEME.palette.text.secondary,
    },
    thumb: {
      boxShadow: boxShadowLevels.low,
      variants: [
        {
          props: { size: 'medium' },
          style: {
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: `0 0 0 ${BASE_THEME.spacing(1.375)} ${Color(brandColors.skyBlue6).fade(0.84)}`,
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: `0 0 0 ${BASE_THEME.spacing(1.25)} ${Color(brandColors.skyBlue6).fade(0.84)}`,
            },
          },
        },
      ],
    },
    valueLabel: {
      ...TYPOGRAPHY_THEME_OPTIONS.subtitle2,
      padding: BASE_THEME.spacing(0.25, 0.5),
      borderRadius: BASE_THEME.spacing(0.5),
      opacity: 0.8,
      backgroundColor: brandColors.coolGray8,
      '&::before': { display: 'none' }, // hides arrow
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(0%, -75%)',
      },
      variants: [
        {
          props: { orientation: 'vertical' },
          style: {
            left: '30px',
            right: 'auto',
          },
        },
      ],
    },
  },
}
