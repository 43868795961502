import React from 'react'
import { brandColors, IconButton } from 'dpl'
import { OverflowMenu } from 'dpl/components/OverflowMenu'
import { Box } from 'dpl/core'
import { DeleteIcon } from 'dpl/icons'
import EllipsisIcon from 'dpl/icons/build/EllipsisIcon'
import EmailSentIcon from 'dpl/icons/build/EmailSentIcon'
import { useMenuToggle } from 'dpl/utils/hooks/useMenuToggle'
import { useMemberActions } from '../../../../../../hooks/useMemberActions'

export interface IMembersOverflowMenuProps {
  userId: string
  userStatus: string
}

export function MembersOverflowMenu({ userId, userStatus }: IMembersOverflowMenuProps) {
  const { deleteUser, reactivateUser, resendInvitation } = useMemberActions(userId)

  const resendInvitationOption = {
    action: resendInvitation,
    itemComponent: (
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        data-test='overflow-menu-resend-option'>
        <EmailSentIcon size='large' />
        <Box ml={2}>Resend Invitation</Box>
      </Box>
    ),
    label: 'duplicate',
  }

  const deleteOption = {
    action: deleteUser,
    itemComponent: (
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        data-test='overflow-menu-delete-option'>
        <DeleteIcon size='large' color='error1' />
        <Box ml={2} color={brandColors.error1}>
          Delete Account
        </Box>
      </Box>
    ),
    label: 'delete',
  }

  const reactivateOption = {
    action: reactivateUser,
    itemComponent: (
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        data-test='overflow-menu-reactivate-option'>
        <EmailSentIcon size='large' />
        <Box ml={2}>Reactivate Account</Box>
      </Box>
    ),
    label: 'reactivate',
  }

  const options = (() => {
    switch (userStatus) {
      case 'invited':
        return [resendInvitationOption, deleteOption]
      case 'deleted':
        return [reactivateOption]
      case 'active':
        return [deleteOption]
      default:
        return []
    }
  })()

  const { anchorEl, expanded, handleClick, handleClose } = useMenuToggle()

  return (
    <OverflowMenu
      dataTest='members-overflow-menu'
      dropdownId='members-overflow-menu'
      anchorEl={anchorEl}
      expanded={expanded}
      onClose={handleClose}
      onSelectMenuItem={handleClose}
      options={options}
      trigger={
        <IconButton
          label='overflow-trigger'
          Icon={EllipsisIcon}
          onClick={handleClick}
          size='medium'
          color='secondary'
        />
      }
    />
  )
}
