import React, { memo, PropsWithChildren } from 'react'
import compact from 'lodash/compact'
import { makeStyles } from '../../../../core'
import LaneArrowIcon from '../../../../icons/build/LaneArrowIcon'
import { TIconComponent } from '../../../../icons/types'
import {
  DataGridAdvancedTextCell,
  IDataGridAdvancedTextCellProps,
} from '../DataGridAdvancedTextCell'

interface IDataGridLaneCellLocation {
  city: string
  stateCode?: string
  postalCode?: string
}

export interface IDataGridLaneCellProps extends IDataGridAdvancedTextCellProps {
  /**
   * @description Contains the props to form the pickup location item in the lane
   */
  pickupProps: IDataGridLaneCellLocation
  /**
   * @description Contains the props to form the delivery location item in the lane
   */
  deliveryProps: IDataGridLaneCellLocation
  /**
   * @description Icon to be displayed at the end of the lane
   */
  icon?: TIconComponent
}

const useDataGridLaneCellStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'inherit',
    gap: theme.spacing(1.5),
  },
  locationCellItems: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}))

function DataGridLaneCellItem({ city, postalCode, stateCode }: IDataGridLaneCellLocation) {
  const locationString = compact([city, stateCode, postalCode]).join(', ')
  return <div>{locationString}</div>
}

export const DataGridLaneCell = memo<PropsWithChildren<IDataGridLaneCellProps>>(
  ({ bottomCaption, deliveryProps, icon: Icon, pickupProps, topCaption }) => {
    const classes = useDataGridLaneCellStyles()

    return (
      <div className={classes.root} data-test='DataGridLaneCell'>
        <DataGridAdvancedTextCell bottomCaption={bottomCaption} topCaption={topCaption}>
          <div className={classes.locationCellItems}>
            <DataGridLaneCellItem {...pickupProps} />
            <LaneArrowIcon />
            <DataGridLaneCellItem {...deliveryProps} />
            {!!Icon && <Icon size='medium' />}
          </div>
        </DataGridAdvancedTextCell>
      </div>
    )
  }
)
