import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { attachClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { Skeleton } from '@mui/material'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Badge } from '../..'
import { makeStyles, Theme, Typography, Button } from '../../../core'
import { AddIcon } from '../../../icons'
import { brandColors } from '../../../theme'
import { IBaseItem } from '../types'
import { DropHere } from './DropHere'
import { KanbanItem } from './KanbanItem'

export interface IKanbanColumnProps<TItem extends IBaseItem> {
  /**
   * Title of the column
   */
  title: string
  /**
   * Number of items in the column
   */
  items?: TItem[]
  /**
   * ID of the column
   */
  id: string
  /**
   * Function to determine if an item can be dropped in a column
   */
  canDrop: (item: TItem, columnId: string) => boolean
  /**
   * Data attribute for testing
   */
  dataTest?: string
  /**
   * Ref to the scrollable element
   */
  scrollRef: HTMLElement | null
  /**
   * Estimated size of an item
   */
  estimateSize: number
  /**
   * Component to render for each item
   */
  ItemComponent: React.ComponentType<{ item: TItem }>
  /**
   * Whether the column is loading more items
   */
  loading?: boolean
  /**
   * Defines if the column should show the add new item button or not
   * @default false
   */
  showAddNewItem?: boolean
  /**
   * Label to display for adding a new item
   */
  addNewItemLabel?: ReactNode
  /**
   * Function to call when user clicks on new item button
   */
  onAddNewItem?: () => void
  /**
   * Total number of items in the column
   */
  totalCount: number
}

const useStyles = makeStyles<Theme, { isDraggedOver: boolean }>(theme => ({
  root: {
    backgroundColor: brandColors.coolGray1,
    paddingTop: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',

    '&:hover $addNewItem': {
      opacity: 1,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
  dragOver: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: brandColors.skyBlue1,
    border: `2px solid ${brandColors.activeBlue}`,
    padding: theme.spacing(1.5, 2),
    alignItems: 'flex-start',
    height: '100%',
  },
  items: ({ isDraggedOver }) => ({
    position: isDraggedOver ? 'absolute' : undefined,
    opacity: isDraggedOver ? 0 : 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.5, 2),
  }),
  content: {
    flex: 1,
  },
  addNewItem: {
    opacity: 0,
  },
}))

export function KanbanColumn<TItem extends IBaseItem>({
  canDrop,
  items = [],
  id,
  title,
  dataTest = `column-${id}`,
  scrollRef,
  estimateSize,
  ItemComponent,
  loading = false,
  showAddNewItem = false,
  addNewItemLabel,
  onAddNewItem,
  totalCount,
}: IKanbanColumnProps<TItem>) {
  const ref = useRef<HTMLDivElement>(null)
  const [isDraggedOver, setIsDraggedOver] = useState(false)
  const classes = useStyles({ isDraggedOver })
  const count = items.length || 0
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => scrollRef,
    estimateSize: () => estimateSize,
    overscan: 5,
  })

  useEffect(() => {
    const el = ref.current

    if (!el) return undefined

    return dropTargetForElements({
      element: el,
      getData: ({ input }) =>
        attachClosestEdge(
          {
            columnId: id,
          },
          {
            element: el,
            input,
            allowedEdges: ['top', 'bottom'],
          }
        ),
      canDrop: ({ source }) => {
        if (source.data.columnId === id) return false

        return canDrop(source.data.item as TItem, id)
      },
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
    })
  }, [canDrop, setIsDraggedOver, id])

  return (
    <div className={classes.root} ref={ref} data-test={dataTest}>
      <div className={classes.title}>
        <Typography variant='subtitle1'>{title}</Typography>
        <Badge label={(totalCount || 0).toString()} color='gray' />
      </div>
      <div className={classes.content}>
        {isDraggedOver && (
          <div className={classes.dragOver}>
            <DropHere />
          </div>
        )}
        <div className={classes.items}>
          <div
            style={{
              height: `${virtualizer?.getTotalSize()}px`,
              position: 'relative',
              width: '100%',
            }}>
            {virtualizer?.getVirtualItems().map(virtualRow => {
              const item = items[virtualRow.index]

              return (
                <div
                  ref={virtualizer.measureElement}
                  key={`${id}-${virtualRow.key}`}
                  data-index={virtualRow.index}
                  style={{
                    position: 'absolute',
                    top: 0,
                    transform: `translateY(${virtualRow.start}px)`,
                    width: '100%',
                    paddingBottom: '12px',
                  }}>
                  <KanbanItem item={item} columnId={id}>
                    <ItemComponent item={item} />
                  </KanbanItem>
                </div>
              )
            })}
          </div>
          {loading && (
            <div>
              <Skeleton variant='rectangular' height={estimateSize} width='100%' />
            </div>
          )}
          {showAddNewItem && onAddNewItem && (
            <div className={classes.addNewItem}>
              <Button startIcon={<AddIcon size='large' />} variant='text' onClick={onAddNewItem}>
                {addNewItemLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
