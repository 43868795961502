import { UploadInputSchema } from 'forms/components/ControlledUploadInputButton/schema'
import * as yup from 'yup'

const REQUIRED_MESSAGE = 'This field is required'

export const OrganizationEditFormSchema = yup.object({
  /**
   * Image for the organization.
   */
  image: UploadInputSchema,
  /**
   * Name of the organization.
   */
  name: yup.string().required(REQUIRED_MESSAGE),
})

export type TOrganizationEditFormSchema = yup.InferType<typeof OrganizationEditFormSchema>
