import React, { memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, Typography, MenuItem } from '../../../../core'
import { TIconComponent } from '../../../../icons/types'
import { TBrandColor } from '../../../../theme/colors'

export interface IUserAppBarMenuItemProps {
  label: string
  onClick?: () => void
  /**
   * Adds a 1px border to bottom of menu item
   * @default false
   */
  divider?: boolean
  /**
   * Focuses on button on first mount. Used to focus on first menu item when menu is open.
   * @default false
   */
  autoFocus?: boolean
  dataTest?: string
  Icon: TIconComponent
  color?: TBrandColor
  /**
   * Provide a url path in lieu of an onClick handler
   * @optional
   */
  href?: string
}

const useUserAppBarMenuItemStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'center',
    justifyContent: 'flex-start',

    [theme.breakpoints.up('md')]: {
      height: '44px', // per design
    },
  },
}))

export const UserAppBarMenuItem = memo<IUserAppBarMenuItemProps>(
  ({ Icon, autoFocus = false, color, dataTest, divider = false, href, label, onClick }) => {
    const classes = useUserAppBarMenuItemStyles()

    return (
      <MenuItem
        to={href}
        component={href ? RouterLink : 'li'}
        onClick={onClick}
        data-test={dataTest}
        divider={divider}
        autoFocus={autoFocus}
        className={classes.root}>
        <Icon size='large' color={color} />
        <Typography variant='body2' fontWeight={600} color={color}>
          {label}
        </Typography>
      </MenuItem>
    )
  }
)
