import { Components } from '@mui/material/styles'
import { brandColors } from '../colors'

/**
 * @name FilledInput
 * @see https://mui.com/material-ui/api/filled-input/
 */
export const MuiFilledInputThemeOptions: Components['MuiFilledInput'] = {
  styleOverrides: {
    root: {
      backgroundColor: brandColors.coolGray1,
      '&:hover': {
        backgroundColor: brandColors.coolGray1,
      },
      '&.Mui-error': {
        backgroundColor: brandColors.error0,
      },
      '&.Mui-disabled': {
        backgroundColor: brandColors.gray1,
        '&::before': {
          borderBottomColor: brandColors.gray2,
          borderBottomStyle: 'solid',
        },
      },
    },
  },
}
