import React, { memo, useCallback } from 'react'
import SearchIcon from '../../../../../../icons/build/SearchIcon'
import { ITextFieldProps, TextField } from '../../../../../TextField'
import { IDataGridFilterProps } from '../types'

export interface IDataGridSearchInputProps
  extends IDataGridFilterProps<string>,
    Pick<ITextFieldProps, 'placeholder' | 'label'> {
  /**
   * The data-test attribute for testing purposes
   * @default 'DataGridSearchInput'
   */
  dataTest?: string
}

/**
 * Renders a search input component for the DataGrid.
 */
export const DataGridSearchInput = memo<IDataGridSearchInputProps>(
  ({
    dataTest = 'DataGridSearchInput',
    label: labelProp,
    name,
    onChange,
    placeholder: placeholderProp = 'Search',
    value: valueProp,
    variant,
    ...rest
  }: IDataGridSearchInputProps) => {
    const changeHandler: ITextFieldProps['onChange'] = useCallback(
      event => {
        const { target } = event || {}
        const { value } = target || {}
        onChange({ name, value })
      },
      [name, onChange]
    )

    const clearHandler: ITextFieldProps['onClear'] = useCallback(() => {
      onChange({ name, value: '' })
    }, [name, onChange])

    return (
      <TextField
        dataTest={dataTest}
        placeholder={placeholderProp}
        clearable={Boolean(valueProp)}
        value={valueProp || ''}
        onChange={changeHandler}
        onClear={clearHandler}
        size={variant === 'menu' ? 'medium' : 'small'}
        label={variant === 'menu' ? labelProp : undefined}
        InputProps={{
          startAdornment: <SearchIcon size='xlarge' />,
        }}
        {...rest}
      />
    )
  }
)
