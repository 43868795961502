import { DefaultValues, useForm, UseFormOptions, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddNewMemberFormSchema, TAddNewMemberFormSchema } from './AddNewMemberFormSchema'

export const getDefaultValues = <TFieldValues>(values: DefaultValues<TFieldValues>) => {
  return AddNewMemberFormSchema.cast({
    ...values,
  })
}

interface IUseAddNewMemberFormProps
  extends Omit<UseFormOptions<TAddNewMemberFormSchema>, 'resolver'> {}

export const useAddNewMemberForm = ({
  defaultValues = {},
  ...props
}: IUseAddNewMemberFormProps) => {
  return useForm<TAddNewMemberFormSchema>({
    resolver: yupResolver(AddNewMemberFormSchema),
    defaultValues: getDefaultValues(defaultValues),
    ...props,
  })
}

export const useAddNewMemberFormContext = () => useFormContext<TAddNewMemberFormSchema>()
