import { FILTERS_EMPTY_VALUES_MAP } from '../constants'
import { IDataGridFilters, TDataGridFiltersConfig } from '../types'

interface IGetDataGridFiltersEmptyStateProps<TFilters extends IDataGridFilters> {
  /**
   * Configuration object for the filters
   */
  filtersConfig: TDataGridFiltersConfig<TFilters>
}

/**
 * Map of filter keys to empty values
 */
type TDataGridFiltersEmptyState<TFilters extends IDataGridFilters> = Record<keyof TFilters, any>

/**
 * Returns the empty state for the data grid filters
 */
export function getDataGridFiltersEmptyState<TFilters extends IDataGridFilters>({
  filtersConfig,
}: IGetDataGridFiltersEmptyStateProps<TFilters>): TDataGridFiltersEmptyState<TFilters> {
  const filterKeys: (keyof TFilters)[] = Object.keys(filtersConfig)

  return filterKeys.reduce((acc, filterKey) => {
    const { type } = filtersConfig[filterKey]
    return {
      ...acc,
      [filterKey]: FILTERS_EMPTY_VALUES_MAP[type],
    }
  }, {} as TDataGridFiltersEmptyState<TFilters>)
}
