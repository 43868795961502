import { makeStyles } from '../core/styles'
import { brandColors } from '../theme/colors'
import { boxShadowLevels } from '../theme/shadows'

export const useFlatpickrCalendarStyles = makeStyles(theme => ({
  flatpickrCalendar: {
    borderRadius: '2px',
    '&.animate.open': {
      border: `1px solid ${brandColors.coolGray3}`,
      boxShadow: boxShadowLevels.high,
      boxSizing: 'content-box',
      marginTop: '5px',
      padding: theme.spacing(2),
      '&.arrowTop:before, &.arrowTop:after': {
        borderBottomColor: brandColors.transparent,
        bottom: 0,
      },
    },
    '& .flatpickr-innerContainer': {
      marginTop: theme.spacing(2),
    },
    '& .flatpickr-current-month': {
      display: 'flex',
      fontSize: '1rem',
      fontWeight: 700,
      justifyContent: 'center',
      lineHeight: '1rem',
      paddingTop: 0,
      width: '100%',
      '& .numInputWrapper': {
        alignSelf: 'center',
        width: '40%', // Needed to accommodate flatpickr's markup & styles
        '& span.arrowUp, & span.arrowDown': {
          display: 'none',
        },
        '&:hover': {
          backgroundColor: brandColors.transparent,
        },
      },
      '& > span.cur-month': {
        alignSelf: 'center',
        lineHeight: '1rem',
        '&:hover': {
          backgroundColor: brandColors.transparent,
        },
      },
      '& .numInput.cur-year': {
        display: 'inline',
        fontWeight: 700,
        pointerEvents: 'none',
        '&[disabled]': {
          color: brandColors.coolGray8,
        },
      },
    },
    '& .flatpickr-next-month': {
      right: theme.spacing(2),
    },
    '& .flatpickr-prev-month': {
      left: theme.spacing(2),
    },
    '& span.flatpickr-next-month, & span.flatpickr-prev-month': {
      top: theme.spacing(1.25),
      '& svg': {
        height: theme.spacing(2),
        stroke: brandColors.skyBlue6,
        strokeLinecap: 'round',
        strokeWidth: '1px',
        width: theme.spacing(2),
        '&:hover': {
          fill: brandColors.skyBlue6,
        },
      },
      '&:hover': {
        color: brandColors.skyBlue6,
      },
    },
    '& .dayContainer > span.flatpickr-day': {
      margin: '0',
      border: `1px solid ${brandColors.transparent}`,
      borderColor: brandColors.transparent,
      borderRadius: '2px',
      display: 'block',
      flex: '1 0 auto',
      lineHeight: '35px',
      '&:hover': {
        backgroundColor: brandColors.white,
        borderColor: brandColors.skyBlue6,
      },
      '&.flatpickr-disabled': {
        color: brandColors.coolGray4,
        '&:hover': {
          borderColor: 'transparent',
        },
      },
      '&.today': {
        fontWeight: 700,
        '&:hover': {
          color: 'inherit',
        },
      },
      '&.inRange': {
        backgroundColor: brandColors.skyBlue2,
        boxShadow: 'none !important',
      },
      '&.startRange': {
        boxShadow: 'none !important',
        color: brandColors.coolGray8,
        '&.selected': {
          color: brandColors.white,
        },
      },
      '&.endRange': {
        boxShadow: 'none !important',
        backgroundColor: brandColors.skyBlue6,
        '&:hover': {
          backgroundColor: brandColors.white,
          borderRadius: '2px',
          color: brandColors.coolGray8,
        },
      },
      '&.selected': {
        backgroundColor: brandColors.skyBlue6,
        borderRadius: '2px',
        color: brandColors.white,
        '&:hover': {
          backgroundColor: brandColors.skyBlue6,
          borderColor: brandColors.skyBlue6,
          color: brandColors.white,
        },
      },
      '&.disabled:hover': {
        borderColor: brandColors.transparent,
      },
      '&.prevMonthDay, &.nextMonthDay': {
        '&:hover': {
          backgroundColor: brandColors.transparent,
          borderRadius: '2px',
        },
      },
    },
    'span.flatpickr-weekday': {
      color: brandColors.transparent,
      fontSize: '1px',
      '&::first-letter': {
        color: brandColors.coolGray5,
        fontSize: '0.875rem',
      },
    },
    '& .date-picker-clear-button': {
      zIndex: 9,
    },
    '& button.calendar-clear-button': {
      float: 'right',
      marginRight: theme.spacing(1.25),
      marginTop: theme.spacing(2),
      textAlign: 'right',
    },
  },
}))
