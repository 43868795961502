import { TEAM_KEYS } from '../../../../../../constants'
import { IOrganizationProductModule, IOrganizationTeam } from '../../../../../../types/graphqlTypes'

export interface IGetModulesProps {
  organizationModules: Pick<IOrganizationProductModule, 'productModule'>[]
  userTeams: Pick<IOrganizationTeam, 'key'>[]
}

export function getModules({ organizationModules, userTeams }: IGetModulesProps) {
  return organizationModules.map(({ productModule }) => {
    return {
      productModule,
      team: userTeams.find(({ key }) =>
        [`${productModule}_${TEAM_KEYS.ADMIN}`, `${productModule}_${TEAM_KEYS.USERS}`].includes(key)
      )?.key,
    }
  })
}
