import React from 'react'
import {
  PatternFormat as ReactPatternFormat,
  PatternFormatProps as ReactPatternFormatProps,
} from 'react-number-format'
import TextField, { ITextFieldProps } from '../TextField'

type TPickedProp =
  | 'displayType'
  | 'value'
  | 'mask'
  | 'placeholder'
  | 'type'
  | 'onChange'
  | 'disabled'
  | 'name'
  | 'onValueChange'
  | 'aria-label'
  | 'id'
  | 'className'
  | 'onBlur'

export interface IPatternFormatProps
  extends Partial<Pick<ReactPatternFormatProps, TPickedProp>>,
    Omit<ITextFieldProps, 'defaultValue' | 'inputRef' | 'onBlur' | 'onChange' | 'type' | 'value'> {
  format: ReactPatternFormatProps['format']
}

function PatternFormat(props: IPatternFormatProps) {
  return <ReactPatternFormat {...props} customInput={TextField} />
}

export default PatternFormat
