import React from 'react'
import { ConfirmationModal, IModalAction } from '../../components'
import { Box, Link, Typography } from '../../core'
import { DEFAULT_LINKS, MODAL_BODY } from './constants'
import { ISupportModalLink } from './types'

export interface ISupportModalProps {
  links?: ISupportModalLink[]
  onClose: () => void
  open: boolean
  /**
   * @default MODAL_BODY
   */
  modalBody?: string
}

export function SupportModal({
  links = DEFAULT_LINKS,
  onClose,
  open,
  modalBody = MODAL_BODY,
}: ISupportModalProps) {
  const actions: IModalAction[] = [
    {
      label: 'Close',
      type: 'confirm',
      action: onClose,
    },
  ]

  return (
    <ConfirmationModal
      dataTest='support-confirmation-modal'
      title='Support'
      maxWidth='xs'
      actions={actions}
      open={open}
      onClose={onClose}>
      <Typography variant='body2'>{modalBody}</Typography>
      <Box display='flex' gap={3} flexDirection='column' mt={4}>
        {links.map(link => {
          const IconComponent = link.icon

          return (
            <div key={link.url}>
              <Box display='flex' gap={2}>
                <IconComponent size='xlarge' color='coolGray5' />
                <Box display='flex' flexDirection='column' gap={0.5}>
                  <Link href={link.url} target='_blank' rel='noreferrer'>
                    <Typography color='primary' variant='body1'>
                      {link.label}
                    </Typography>
                  </Link>
                  <Typography variant='body1'>{link.description}</Typography>
                </Box>
              </Box>
            </div>
          )
        })}
      </Box>
    </ConfirmationModal>
  )
}

export default SupportModal
