import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation'
import { brandColors } from '../colors'
import { TYPOGRAPHY_THEME_OPTIONS } from '../typography'

export const MuiDateCalendarThemeOptions: PickerComponents['MuiDateCalendar'] = {
  styleOverrides: {
    root: {
      paddingBottom: '20px',

      '& .MuiPickersCalendarHeader-root': {
        paddingTop: '4px',
        paddingLeft: '20px',
      },

      '& .MuiPickersArrowSwitcher-button': {
        borderRadius: '50%',
        '&:focus, &:hover, &:active': {
          backgroundColor: brandColors.coolGray1,
        },
      },

      '& .MuiDayCalendar-weekDayLabel': {
        fontWeight: 600,
      },

      '& .MuiPickersCalendarHeader-label': {
        ...TYPOGRAPHY_THEME_OPTIONS.subtitle1,
      },

      '& .MuiPickersDay-root': {
        ...TYPOGRAPHY_THEME_OPTIONS.body2,
        transition: 'none',

        '&:focus, &:hover, &:active': {
          boxShadow: 'none',
          transition: 'none',
          backgroundColor: brandColors.coolGray1,
        },

        '&:hover': {
          border: `1px solid ${brandColors.coolGray8}`,
        },

        '&.MuiPickersDay-today': {
          border: `1px solid ${brandColors.coolGray8}`,
        },

        '&.Mui-selected': {
          '&:active, &:focus, &:hover': {
            transition: 'none',
            backgroundColor: brandColors.skyBlue6,
          },
        },
      },
    },
  },
}
