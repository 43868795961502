export interface IFormatPhoneArgs {
  removeCountryCode?: string
}

/**
 * Returns formatted phone number
 * @param {string} rawPhone - Phone number to format
 * @param {IFormatPhoneArgs} args
 */
export const formatPhone = (rawPhone?: string | number | null, args?: IFormatPhoneArgs) => {
  if (!rawPhone) return ''

  let phoneNumber = String(rawPhone)
  const { removeCountryCode } = args || {}
  if (removeCountryCode) {
    phoneNumber = phoneNumber.replace(removeCountryCode, '')
  }

  const cleaned = phoneNumber.replace(/\D/g, '')
  const zip = cleaned.substring(0, 3)
  const middle = cleaned.substring(3, 6)
  const last = cleaned.substring(6, 10)

  let result = ''
  if (cleaned.length > 6) {
    result = `(${zip}) ${middle}-${last}`
  } else if (cleaned.length > 3) {
    result = `(${zip}) ${middle}`
  } else if (cleaned.length > 0) {
    result = `(${zip}`
  }
  return result
}

export const FORMATTED_PHONE_REGEX = /^\(\d{3}\)\s\d{3}-\d{4}/

/**
 * Removes all non-digit characters from a string
 * @param {string} phoneNumber
 */
export const unformatPhone = (phoneNumber: string = ''): string => {
  return phoneNumber.replace(/[^\d]/g, '')
}

export default formatPhone
