import React, { memo, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import PatternFormat, { IPatternFormatProps } from '../PatternFormat'

export interface IDateProps extends Omit<IPatternFormatProps, 'format' | 'type' | 'displayType'> {}

const isValidDate = (value: string): boolean => {
  return !value || (!!value && value.indexOf('-') < 0)
}

export const DateInput = memo<IDateProps>(
  ({ mask = '-', id = uuid(), onValueChange: onValueChangeProp, value, clearable, ...props }) => {
    const hasValue = useMemo(() => !!value, [value])

    const valueChangeHandler: IPatternFormatProps['onValueChange'] = (values, sourceInfo) => {
      const { formattedValue } = values || {}
      if (isValidDate(formattedValue) && onValueChangeProp) {
        onValueChangeProp(values, sourceInfo)
      }
    }

    return (
      <PatternFormat
        id={id}
        type='text'
        displayType='input'
        format='##/##/####'
        mask={mask}
        value={value}
        clearable={clearable && hasValue}
        onValueChange={valueChangeHandler}
        {...props}
      />
    )
  }
)

export default DateInput
