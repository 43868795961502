import React, { useCallback, useMemo } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useAuthContext } from 'auth/b2b'
import { useAuthMember } from 'auth/b2b/hooks'
import { usePermissionsContext } from 'auth/common/context'
import DefaultCompany from 'dpl/assets/DefaultCompany.svg'
import { DashboardIcon, TransfixLogoIcon } from 'dpl/icons'
import { AdminLayout, IAdminLayoutProps } from 'dpl/templates'
import { EnvironmentBanner, TEnvironment } from 'dpl/templates/AppLayout/EnvironmentBanner'
import { getNavLists } from 'dpl/utils/getNavLists'
import { PROTECTED_PATHS } from '../routes/constants'
import { NAV_LISTS } from './constants'
import { useOrganizationNavQuery } from './graphql/OrganizationNav'

const { MODE, VITE_CONSOLE_URL, VITE_TFXADMIN_CONSOLE_URL } = import.meta.env

const SHOW_BANNER = ['branch', 'staging', 'uat'].includes(MODE)

export function RootLayout() {
  const { authClient } = useAuthContext()
  const navigate = useNavigate()
  const { userPermissions } = usePermissionsContext()
  const { member } = useAuthMember()
  const { email_address: email = '', name = '' } = member || {}
  const location = useLocation()
  const { pathname } = location || {}

  const navLists = useMemo(
    () => (member ? getNavLists({ navLists: NAV_LISTS, currentPathname: pathname }) : undefined),
    [pathname, member]
  )

  const logoutHandler = useCallback(async () => {
    await authClient.sessions.deleteSession()
    navigate(0)
  }, [authClient.sessions, navigate])

  const userInfo = useMemo<IAdminLayoutProps['userInfo']>(() => {
    if (!member) {
      return undefined
    }

    return {
      email,
      name,
      userProfileHref: PROTECTED_PATHS.userProfile,
    }
  }, [email, member, name])

  const { data } = useOrganizationNavQuery({
    skip: !member,
  })
  const { currentSession } = data || {}
  const { organizationUser } = currentSession || {}
  const { organization } = organizationUser || {}
  const { name: organizationName = '', thumbLogo } = organization || {}
  const { url: logoSrc = '' } = thumbLogo || {}

  const apps = [
    {
      Icon: DashboardIcon,
      link: VITE_CONSOLE_URL,
      name: 'Solutions Console',
      url: 'console.transfix.io',
    },
    ...(userPermissions['transfix_admin_app.read_app']
      ? [
          {
            Icon: TransfixLogoIcon,
            link: VITE_TFXADMIN_CONSOLE_URL,
            name: 'Transfix Admin Console',
            url: 'tfxadmin.transfix.io',
          },
        ]
      : []),
  ]

  return (
    <AdminLayout
      BannerComponent={<EnvironmentBanner environment={MODE as TEnvironment} />}
      showBanner={SHOW_BANNER}
      userInfo={userInfo}
      onLogout={logoutHandler}
      orgName={organizationName}
      apps={apps}
      navLists={navLists}
      logoSrc={logoSrc || DefaultCompany}
      title='Admin Console'>
      <Outlet />
    </AdminLayout>
  )
}
