import React, { memo } from 'react'
import PatternFormat, { IPatternFormatProps } from '../../PatternFormat'
import { DEFAULT_PHONE_EXTENSION_FORMAT } from './constants'

export interface IFormattedPhoneExtensionProps
  extends Omit<IPatternFormatProps, 'displayType' | 'format' | 'mask' | 'value'> {
  phoneExtension?: string | null
  /**
   * @default '(###)''
   */
  phoneExtensionFormat?: string
}

export const FormattedPhoneExtension = memo<IFormattedPhoneExtensionProps>(
  ({
    phoneExtension,
    phoneExtensionFormat = DEFAULT_PHONE_EXTENSION_FORMAT,
    ...patternFormatProps
  }) => {
    return (
      <PatternFormat
        {...patternFormatProps}
        displayType='text'
        format={phoneExtensionFormat}
        value={phoneExtension}
        // phone extensions are normally 3-6 digits long, so we set the mask to a blank string to remove
        // whitespace if the extension is on the shorter side and falls short of the number of pattern characters
        // in the provided format.
        mask=''
      />
    )
  }
)

export default FormattedPhoneExtension
