import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'

/**
 * @name InputLabel
 * @see https://mui.com/material-ui/api/input-label/
 */
export const MuiInputLabelThemeOptions: Components['MuiInputLabel'] = {
  styleOverrides: {
    sizeSmall: {
      '&.MuiInputLabel-outlined': {
        background: 'white',
        transform: `translate(14px, ${BASE_THEME.spacing(1.5)}) scale(1)`,
        '&.MuiInputLabel-shrink': {
          transform: `translate(14px, -${BASE_THEME.spacing(1.5)}) scale(0.75)`,
        },
      },
    },
  },
}
