import React, { useMemo, forwardRef, ReactNode } from 'react'
import { InputAdornment, MuiTextField, StandardTextFieldProps, TextFieldVariants } from '../../core'
import { ClearableAdornment } from './ClearableAdornment'

export interface ITextFieldProps extends Omit<StandardTextFieldProps, 'variant'> {
  /**
   * Used to display the autocomplete tags.
   * @internal This prop is intended for internal use only by the autocomplete component.
   */
  _autocompleteTags?: ReactNode
  /**
   * When set to true, a clear button will be displayed on the right side of the input.
   * @default true
   */
  clearable?: boolean
  /**
   * Callback fired when field is cleared
   */
  onClear?: () => void
  /**
   * @default 'outlined'
   */
  variant?: TextFieldVariants
  /**
   * @default 'TextField'
   */
  dataTest?: string
}

export const TextField = forwardRef<HTMLDivElement, ITextFieldProps>(
  (
    {
      _autocompleteTags: autocompleteTagsProp,
      // added props
      clearable = true,
      dataTest = 'TextField',
      onClear,
      // TextField props
      onChange,
      InputProps = {},
      variant = 'outlined',
      value,
      ...props
    },
    ref
  ) => {
    const { endAdornment, startAdornment } = InputProps || {}

    const startAdornmentElement = useMemo(() => {
      if (!startAdornment && !autocompleteTagsProp) return null

      if (!startAdornment && autocompleteTagsProp) return autocompleteTagsProp

      return (
        <>
          <InputAdornment position='start' data-test={`${dataTest}-start-adornment`}>
            {startAdornment}
          </InputAdornment>
          {autocompleteTagsProp}
        </>
      )
    }, [autocompleteTagsProp, dataTest, startAdornment])

    const endAdornmentElement = useMemo(() => {
      if (clearable && value && onClear) {
        return <ClearableAdornment onClear={onClear} dataTest={dataTest} />
      }

      return endAdornment
    }, [clearable, dataTest, endAdornment, onClear, value])

    return (
      <MuiTextField
        {...props}
        ref={ref}
        data-test={dataTest}
        variant={variant}
        value={value}
        onChange={onChange}
        InputProps={{
          ...InputProps,
          endAdornment: endAdornmentElement,
          startAdornment: startAdornmentElement,
        }}
      />
    )
  }
)

export default TextField
