import React, { memo } from 'react'
import { Typography } from '../../../core'
import { ConfirmationModal, IConfirmationModalProps } from '../ConfirmationModal'
import { IModalBaseProps } from '../ModalBase'

export interface IFeatureUnavailableModalProps
  extends Pick<IConfirmationModalProps, 'open' | 'onClose'> {
  /**
   * @default 'FeatureUnavailableModal'
   */
  dataTest?: string
}

export const FeatureUnavailableModal = memo<IFeatureUnavailableModalProps>(
  ({ dataTest = 'FeatureUnavailableModal', onClose, open }) => {
    const actions: IModalBaseProps['actions'] = [
      {
        label: 'Close',
        action: onClose,
        ButtonProps: {
          variant: 'text',
        },
      },
    ]
    return (
      <ConfirmationModal
        actions={actions}
        dataTest={dataTest}
        includeCloseIconButton={false}
        maxWidth='xs'
        onClose={onClose}
        open={open}
        title='Feature Unavailable'>
        <Typography>This feature is currently unavailable. Please try again later.</Typography>
      </ConfirmationModal>
    )
  }
)
