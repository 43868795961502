import { useCallback } from 'react'
import { useToastContext } from 'dpl/components/Toast'
import { useDeleteMemberUserMutation } from '../routes/graphql/DeleteMemberUser'
import { useReactivateUserMutation } from '../routes/graphql/ReactivateUserAccount'
import { useResendUserInvitationMutation } from '../routes/graphql/ResendUserInvitation'

export function useMemberActions(userId: string) {
  const [deleteMemberUser] = useDeleteMemberUserMutation()
  const [reactivateMemberUser] = useReactivateUserMutation()
  const [resendMemberInvitation] = useResendUserInvitationMutation()
  const { openToast } = useToastContext()

  const deleteUser = useCallback(async () => {
    try {
      const { data } = await deleteMemberUser({
        variables: { input: { organizationUserId: userId } },
      })

      const errors = data?.deleteUser?.errors ?? []
      if (errors.length > 0) {
        openToast({
          toastMessage: errors[0].message,
          toastType: 'alert',
        })
      } else {
        openToast({
          toastMessage: 'User deleted successfully',
        })
      }
    } catch {
      openToast({
        toastMessage: 'Failed to delete user',
        toastType: 'alert',
      })
    }
  }, [deleteMemberUser, openToast, userId])

  const resendInvitation = useCallback(async () => {
    try {
      const { data } = await resendMemberInvitation({
        variables: { input: { organizationUserId: userId } },
      })

      const errors = data?.reinviteUser?.errors ?? []
      if (errors.length > 0) {
        openToast({
          toastMessage: errors[0].message,
          toastType: 'alert',
        })
      } else {
        openToast({
          toastMessage: 'Invitation resent successfully',
        })
      }
    } catch {
      openToast({
        toastMessage: 'Failed to resend invitation',
        toastType: 'alert',
      })
    }
  }, [resendMemberInvitation, openToast, userId])

  const reactivateUser = useCallback(async () => {
    try {
      const { data } = await reactivateMemberUser({
        variables: { input: { organizationUserId: userId } },
      })

      const errors = data?.reactivateUser?.errors ?? []
      if (errors.length > 0) {
        openToast({
          toastMessage: errors[0].message,
          toastType: 'alert',
        })
      } else {
        openToast({
          toastMessage: 'Account reactivated successfully',
        })
      }
    } catch {
      openToast({
        toastMessage: 'Failed to reactivate user',
        toastType: 'alert',
      })
    }
  }, [reactivateMemberUser, openToast, userId])

  return {
    deleteUser,
    resendInvitation,
    reactivateUser,
  }
}
