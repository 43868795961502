import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'

/**
 * @name Autocomplete
 * @see https://mui.com/material-ui/api/autocomplete/
 */
export const MuiAutocompleteThemeOptions: Components['MuiAutocomplete'] = {
  defaultProps: {
    size: 'medium',
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          paddingTop: BASE_THEME.spacing(1),
          paddingBottom: BASE_THEME.spacing(1),
        },
        '& .MuiInputLabel-root[data-shrink="false"]': {
          transform: `translate(${BASE_THEME.spacing(1.75)}, ${BASE_THEME.spacing(1.5)}) scale(1)`,
        },
      },
    },
    {
      props: { size: 'medium' },
      style: {
        '& .MuiOutlinedInput-root': {
          paddingTop: BASE_THEME.spacing(1.5),
          paddingBottom: BASE_THEME.spacing(1.5),
        },
      },
    },
    {
      props: { multiple: true },
      style: {
        '& .MuiOutlinedInput-root': {
          minHeight: '3.5rem',
          paddingTop: BASE_THEME.spacing(1.25),
          paddingBottom: BASE_THEME.spacing(1.25),
        },
      },
    },
  ],
  styleOverrides: {
    root: {
      '& .MuiInputAdornment-root': {
        margin: '0 !important',
      },
      '& .MuiOutlinedInput-root': {
        paddingLeft: BASE_THEME.spacing(1.75),
      },
      '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
        paddingLeft: BASE_THEME.spacing(1.75),
      },
      '& .MuiFilledInput-root': {
        paddingLeft: BASE_THEME.spacing(1.5),
      },
    },
    input: {
      '&.MuiOutlinedInput-input': {
        paddingTop: `${BASE_THEME.spacing(0.625)} !important`,
        paddingBottom: `${BASE_THEME.spacing(0.625)} !important`,
      },
      '&.MuiInputBase-input.MuiInputBase-inputAdornedStart': {
        paddingLeft: BASE_THEME.spacing(1),
      },
      '&.MuiInputBase-input:not(.MuiInputBase-inputAdornedStart)': {
        paddingLeft: 0,
      },
    },
    listbox: {
      padding: 0,
      '& .MuiAutocomplete-option': {
        padding: BASE_THEME.spacing(1.5, 2),
      },
    },
    clearIndicator: {
      visibility: 'visible',
      padding: 0,
      marginRight: BASE_THEME.spacing(0.5),
      color: `rgba(0, 0, 0, 0.54)`,
    },
    popupIndicator: {
      color: `rgba(0, 0, 0, 0.54)`,
    },
    noOptions: {
      padding: `${BASE_THEME.spacing(1)} calc(${BASE_THEME.spacing(1)} + 2px)`, // 8px 10px
    },
    loading: {
      padding: `${BASE_THEME.spacing(1)} calc(${BASE_THEME.spacing(1)} + 2px)`, // 8px 10px
    },
    tag: {
      padding: BASE_THEME.spacing(0, 1),
      '& .MuiChip-label': {
        fontWeight: 400,
      },
      '& .MuiChip-deleteIcon': {
        color: brandColors.coolGray5,
        height: '1rem',
        width: '1rem',
        '&:hover': {
          color: brandColors.coolGray4,
        },
      },
    },
  },
}
