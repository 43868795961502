import React, { PropsWithChildren, useMemo, useRef } from 'react'
import { To } from 'react-router-dom'
import { StytchB2BProvider } from '@stytch/react/b2b'
import { makeAuthClient, IAuthClientOptions } from '../clients'
import { AuthContext, IAuthContextState } from '../context'
import { IPermissionsProviderProps, PermissionsProvider } from './PermissionsProvider'

export interface IAuthProviderProps {
  clientOptions: IAuthClientOptions
  discoveryRedirectTo: To
  signInRedirectTo: To
  usePermissionsLazyQuery: IPermissionsProviderProps['usePermissionsLazyQuery']
}

export function AuthProvider({
  children,
  clientOptions,
  discoveryRedirectTo,
  signInRedirectTo,
  usePermissionsLazyQuery,
}: PropsWithChildren<IAuthProviderProps>) {
  const { current: authClient } = useRef(makeAuthClient(clientOptions))

  const authContextState = useMemo<IAuthContextState>(
    () => ({
      authClient,
      discoveryRedirectTo,
      signInRedirectTo,
    }),
    [authClient, discoveryRedirectTo, signInRedirectTo]
  )

  return (
    <AuthContext.Provider value={authContextState}>
      <StytchB2BProvider stytch={authClient.stytch}>
        <PermissionsProvider usePermissionsLazyQuery={usePermissionsLazyQuery}>
          {children}
        </PermissionsProvider>
      </StytchB2BProvider>
    </AuthContext.Provider>
  )
}
