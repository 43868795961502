import React, { useCallback, useState } from 'react'
import {
  IOrganizationAppCardProps,
  IOrganizationProfileHeaderProps,
  OrganizationAppCard,
  OrganizationProfileHeader,
} from 'dpl/components'
import { makeStyles } from 'dpl/core'
import { PageTemplate } from 'dpl/templates'
import { OrganizationEditSidePanel } from './OrganizationEditSidePanel'

const useStyles = makeStyles(theme => ({
  header: {
    margin: theme.spacing(0, 0, 3, 0),
    width: '100%',
  },
  appCard: {
    padding: theme.spacing(1, 3),
  },
}))

export interface IHomeProps extends IOrganizationProfileHeaderProps {
  /**
   * If the current user is an admin, they will have access to edit the organization profile.
   * @default false
   */
  isAdmin: boolean
  /**
   * A list of applications the organization uses.
   * @default []
   */
  apps: IOrganizationAppCardProps[]
}

export function Home({
  apps,
  orgActiveDate,
  orgAdminNames,
  orgImgSrc = 'https://placehold.jp/150x150.png',
  orgName,
}: IHomeProps) {
  const classes = useStyles()
  const [isEditSidePanelOpen, setIsEditSidePanelOpen] = useState<boolean>(false)

  const toggleEditProfileSidePanel = useCallback(() => {
    setIsEditSidePanelOpen(prevState => !prevState)
  }, [])

  return (
    <PageTemplate
      pageTitle='Home'
      PageBodyTitleProps={{
        actions: [
          {
            label: 'Edit Profile',
            onClick: toggleEditProfileSidePanel,
          },
        ],
      }}
      noPadding>
      <div className={classes.header}>
        <OrganizationProfileHeader
          orgActiveDate={orgActiveDate}
          orgName={orgName}
          orgAdminNames={orgAdminNames}
          orgImgSrc={orgImgSrc}
          status='active'
        />
      </div>
      {apps.map(props => (
        <div className={classes.appCard} key={props.appName}>
          <OrganizationAppCard {...props} />
        </div>
      ))}
      <OrganizationEditSidePanel
        orgFormData={{ name: orgName, image: { file: orgImgSrc } }}
        open={isEditSidePanelOpen}
        onClose={toggleEditProfileSidePanel}
      />
    </PageTemplate>
  )
}
