import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { boxShadowLevels } from '../shadows'

/**
 * @name Card
 * @see https://mui.com/material-ui/api/card/
 */
export const MuiCardThemeOptions: Components['MuiCard'] = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: {
      borderRadius: BASE_THEME.spacing(0.5),
      boxShadow: boxShadowLevels.low,
    },
  },
}
