import * as yup from 'yup'

export const AddNewMemberFormSchema = yup.object({
  emails: yup.array(yup.string()).min(1, 'At least one email is required').required(),
  modules: yup.array(
    yup.object({
      productModule: yup.string(),
      team: yup.string(),
    })
  ),
})

export type TAddNewMemberFormSchema = yup.InferType<typeof AddNewMemberFormSchema>
