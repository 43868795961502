import React, { useCallback } from 'react'
import { InputAdornment, StandardTextFieldProps } from '../../core'
import CloseIcon from '../../icons/build/CloseIcon'
import { IconButton } from '../IconButton'

export interface IClearableAdornmentProps extends Pick<StandardTextFieldProps, 'onChange'> {
  /**
   * Callback fired when field is cleared
   */
  onClear?: () => void
  /**
   * @default 'ClearableAdornment'
   */
  dataTest?: string
}

export function ClearableAdornment({ dataTest, onClear }: IClearableAdornmentProps) {
  const clearValue = useCallback(() => {
    if (onClear) {
      onClear()
    }
  }, [onClear])

  if (!onClear) return null

  return (
    <InputAdornment position='end'>
      <IconButton
        Icon={CloseIcon}
        onClick={clearValue}
        size='xlarge'
        color='secondary'
        dataTest={`${dataTest}-clear`}
        noPadding
      />
    </InputAdornment>
  )
}
