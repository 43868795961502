import React, { memo } from 'react'
import { Typography } from '../../../core'
import { ConfirmationModal, IConfirmationModalProps } from '../ConfirmationModal'
import { IModalBaseProps } from '../ModalBase'

export interface IAccessRestrictedModalProps
  extends Pick<IConfirmationModalProps, 'open' | 'onClose'> {
  /**
   * @default 'AccessRestrictedModal'
   */
  dataTest?: string
}

export const AccessRestrictedModal = memo<IAccessRestrictedModalProps>(
  ({ dataTest = 'AccessRestrictedModal', onClose, open }) => {
    const actions: IModalBaseProps['actions'] = [
      {
        label: 'Close',
        action: onClose,
        ButtonProps: {
          variant: 'text',
        },
      },
    ]
    return (
      <ConfirmationModal
        actions={actions}
        dataTest={dataTest}
        includeCloseIconButton={false}
        maxWidth='xs'
        onClose={onClose}
        open={open}
        title='Access Restricted'>
        <Typography>
          You currently do not have access to this feature. This may be due to your permissions or
          the feature not being enabled for your account.
        </Typography>
      </ConfirmationModal>
    )
  }
)
