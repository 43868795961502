import React, { memo } from 'react'
import PatternFormat, { IPatternFormatProps } from '../PatternFormat'
import FormattedPhoneExtension, { DEFAULT_PHONE_EXTENSION_FORMAT } from './FormattedPhoneExtension'
import { DEFAULT_PHONE_NUMBER_FORMAT } from './constants'

export interface IFormattedPhoneNumberProps {
  dataTest?: string
  phoneExtension?: string | null
  /**
   * @default '(###)''
   */
  phoneExtensionFormat?: string
  phoneNumber: Nullable<string>
  /**
   * @default '(###) ###-####''
   */
  phoneNumberFormat?: IPatternFormatProps['format']
}

export const FormattedPhoneNumber = memo<IFormattedPhoneNumberProps>(
  ({
    dataTest,
    phoneExtension,
    phoneExtensionFormat = DEFAULT_PHONE_EXTENSION_FORMAT,
    phoneNumber,
    phoneNumberFormat = DEFAULT_PHONE_NUMBER_FORMAT,
  }) => {
    return (
      <span data-test={dataTest}>
        <PatternFormat displayType='text' format={phoneNumberFormat} value={phoneNumber} />
        {phoneExtension && (
          <>
            &nbsp;
            <FormattedPhoneExtension
              className='text-secondary'
              phoneExtension={phoneExtension}
              phoneExtensionFormat={phoneExtensionFormat}
            />
          </>
        )}
      </span>
    )
  }
)

export default FormattedPhoneNumber
