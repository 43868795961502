import {
  BooleanParam,
  DelimitedArrayParam,
  QueryParamConfig,
  StringParam,
  withDefault,
} from 'use-query-params'
import { SORT_QUERY_PARAM_KEY, FILTERS_QUERY_PARAM_MAP } from '../constants'
import { IDataGridFilters, TDataGridFiltersConfig } from '../types'

interface IGetDataGridQueryParamConfigMapProps<
  TFilters extends IDataGridFilters,
  TSortValue extends string,
> {
  /**
   * Configuration object for the filters
   * @example { userSearch: { type: 'textInput' }, isActive: { type: 'switch' }, }
   */
  filtersConfig: TDataGridFiltersConfig<TFilters>
  /**
   * Default values for the filters
   * @note Default values do not change the url. They are only used to initialize the filters
   * @example { userSearch: 'John Doe' }
   * @default {}
   */
  defaultFilterValues?: Partial<TFilters>
  /**
   * Default sort option value for the data grid
   * @note Default values do not change the url. They are only used to initialize the sort dropdown
   */
  defaultSortValue?: TSortValue
}

/**
 * Map of filter keys to query param types
 */
type TDataGridParamConfigMap<TFilters extends IDataGridFilters> = Record<
  keyof TFilters | typeof SORT_QUERY_PARAM_KEY,
  typeof StringParam | typeof DelimitedArrayParam | typeof BooleanParam
>

/**
 * Returns the query param config map for the data grid filters
 * @param filtersConfig Configuration for the filters
 * @param defaultFilterValues Default values for the filters (optional)
 */
export function getDataGridQueryParamConfigMap<
  TFilters extends IDataGridFilters,
  TSortValue extends string,
>({
  defaultFilterValues = {},
  filtersConfig,
  defaultSortValue,
}: IGetDataGridQueryParamConfigMapProps<TFilters, TSortValue>): TDataGridParamConfigMap<TFilters> {
  const filterKeys: (keyof TFilters)[] = Object.keys(filtersConfig)

  const filterQueryParamConfig = filterKeys.reduce((acc, filterKey) => {
    const { type } = filtersConfig[filterKey]
    const defaultValue = defaultFilterValues[filterKey]
    const hasDefaultValue = defaultValue !== undefined
    return {
      ...acc,
      [filterKey]: hasDefaultValue
        ? withDefault(FILTERS_QUERY_PARAM_MAP[type] as QueryParamConfig<any, any>, defaultValue)
        : FILTERS_QUERY_PARAM_MAP[type],
    }
  }, {} as TDataGridParamConfigMap<TFilters>)

  return {
    ...filterQueryParamConfig,
    [SORT_QUERY_PARAM_KEY]: defaultSortValue
      ? withDefault(StringParam, defaultSortValue)
      : StringParam,
  }
}
