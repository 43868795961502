import React, { ReactNode } from 'react'
import {
  Box,
  ToggleButton as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
} from '../../core'
import { TIconComponent } from '../../icons/types'

export interface IToggleButtonProps extends Omit<MuiToggleButtonProps, 'children'> {
  /**
   * The icon component to be displayed.
   */
  startIcon?: TIconComponent
  /**
   * The label text to be displayed.
   */
  label: ReactNode
  /**
   * The data test attribute value.
   * @default 'ToggleButton'
   */
  dataTest?: string
  /**
   * The icon component to be displayed to the right.
   */
  endIcon?: TIconComponent
}

export function ToggleButton({
  dataTest = 'ToggleButton',
  endIcon: EndIconComponent,
  label,
  startIcon: IconComponent,
  ...rest
}: IToggleButtonProps) {
  return (
    <MuiToggleButton data-test={dataTest} {...rest}>
      <Box display='flex' alignItems='center' gap={1}>
        {IconComponent && <IconComponent size='large' />}
        {label}
        {EndIconComponent && <EndIconComponent size='large' color='info' />}
      </Box>
    </MuiToggleButton>
  )
}
