import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { calcRem } from '../utils/calcRem'

export const MuiTooltipThemeOptions: Components['MuiTooltip'] = {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      fontSize: calcRem(12),
      fontWeight: BASE_THEME.typography.fontWeightMedium,
      padding: BASE_THEME.spacing(1.5, 1.25),
      borderRadius: BASE_THEME.spacing(0.5),
      opacity: `0.9 !important`,
    },
    arrow: {
      opacity: 0.9,
    },
  },
}
