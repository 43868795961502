import React from 'react'
import classNames from 'classnames'
import Color from 'color'
import { Avatar } from '../../../../components/Avatar'
import { Popper } from '../../../../components/Popper'
import { ITooltipProps } from '../../../../components/Tooltip'
import { ClickAwayListener, makeStyles, ButtonBase, Dialog } from '../../../../core'
import { useBreakpoint } from '../../../../hooks'
import { boxShadowLevels, brandColors } from '../../../../theme'
import { useMenuToggle } from '../../../../utils/hooks/useMenuToggle'
import { IUserAppBarMenuContentsProps, UserAppBarMenuContents } from './UserAppBarMenuContents'

export interface IUserAppBarMenuProps
  extends Pick<IUserAppBarMenuContentsProps, 'name' | 'email' | 'userProfileHref' | 'onLogout'> {
  /**
   * Props for Tooltip
   */
  TooltipProps?: Partial<ITooltipProps>
}

const useUserAppBarMenuStyles = makeStyles(theme => ({
  avatarButton: ({ color }: Pick<ITooltipProps, 'color'>) => ({
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    '&:hover, &:focus, &:active': {
      boxShadow:
        color === 'light'
          ? `0 0 0 4px ${Color(brandColors.white).fade(0.6)}`
          : `0 0 0 4px ${Color(brandColors.black).fade(0.6)}`,
    },
  }),
  avatar: ({ color }: Pick<ITooltipProps, 'color'>) => ({
    cursor: 'pointer',
    ...(color === 'dark' ? { outline: 'none !important' } : {}),
  }),
  avatarButtonActive: ({ color }: Pick<ITooltipProps, 'color'>) => ({
    boxShadow:
      color === 'light'
        ? `0 0 0 4px ${Color(brandColors.white).fade(0.4)}`
        : `0 0 0 4px ${Color(brandColors.black).fade(0.4)}`,
  }),
  menuDesktop: {
    width: '254px', // from design spec
    border: `1px solid ${brandColors.coolGray3}`,
    boxShadow: boxShadowLevels.pop,
    borderRadius: theme.spacing(1),
    backgroundColor: brandColors.coolGray0,
    marginTop: theme.spacing(1),
    '& .MuiListItem-root, & .MuiMenuItem-root': {
      padding: theme.spacing(1.5),
    },
  },
  menuMobile: {
    backgroundColor: brandColors.coolGray0,
    height: '100%',
    paddingTop: theme.spacing(2),
    '& .MuiMenuItem-root': {
      padding: theme.spacing(2, 3),
    },
    '& .MuiListItem-root': {
      padding: theme.spacing(0.5, 2, 2),
    },
  },
}))

function UserAppBarMenu({
  email,
  name,
  onLogout,
  userProfileHref,
  TooltipProps = {
    placement: 'left',
    color: 'light',
  },
}: IUserAppBarMenuProps) {
  const { isMobile } = useBreakpoint()
  const { anchorEl, expanded: open, handleClick, handleClose } = useMenuToggle()
  const classes = useUserAppBarMenuStyles(TooltipProps)
  const avatarButtonClassNames = classNames(classes.avatarButton, {
    [classes.avatarButtonActive]: open,
  })

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div data-test='userAppBarMenu'>
        <ButtonBase
          className={avatarButtonClassNames}
          data-test='user-menu-button'
          onClick={handleClick}
          id='open-user-menu-button'
          aria-controls={open ? 'user-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'>
          <Avatar
            dataTest='app-bar-user-avatar'
            className={classes.avatar}
            size='xsmall'
            name={name}
            tooltip={<strong>{name || email}</strong>}
            tooltipProps={TooltipProps}
            hideTooltip={open || isMobile}
          />
        </ButtonBase>
        {isMobile ? (
          <Dialog fullScreen open={open}>
            <UserAppBarMenuContents
              name={name}
              email={email}
              userProfileHref={userProfileHref}
              onClick={handleClose}
              showCloseButton
              className={classes.menuMobile}
              onLogout={onLogout}
            />
          </Dialog>
        ) : (
          <Popper open={open} anchorEl={anchorEl} placement='bottom-end' dataTest='user-menu'>
            <UserAppBarMenuContents
              name={name}
              email={email}
              userProfileHref={userProfileHref}
              onClick={handleClose}
              className={classes.menuDesktop}
              onLogout={onLogout}
            />
          </Popper>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default UserAppBarMenu
