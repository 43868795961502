import React from 'react'
import SidePanel, { ISidePanelProps } from 'dpl/components/SidePanel'
import { OrganizationEditForm } from './OrganizationEditForm'
import { TOrganizationEditFormSchema } from './schema'

export interface IOrganizationEditSidePanelProps extends Pick<ISidePanelProps, 'open' | 'onClose'> {
  orgFormData: TOrganizationEditFormSchema
}

export function OrganizationEditSidePanel({
  onClose,
  open,
  orgFormData,
}: IOrganizationEditSidePanelProps) {
  return (
    <SidePanel title='Edit Organization Profile' open={open} onClose={onClose} anchor='right'>
      <OrganizationEditForm orgFormData={orgFormData} onClose={onClose} />
    </SidePanel>
  )
}
