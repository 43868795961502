import {
  DefaultTheme,
  makeStyles as muiMakeStyles,
  ClassNameMap,
  Styles,
  WithStylesOptions,
} from '@mui/styles'

/**
 * A thin abstraction of MUI's makeStyles
 * @see https://v4.mui.com/styles/api/#makestyles-styles-options-hook
 */
function makeStyles<
  Theme = DefaultTheme,
  Props extends object = {},
  ClassKey extends string = string,
>(
  styles: Styles<Theme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>
): keyof Props extends never // `makeStyles` where the passed `styles` do not depend on props
  ? (props?: any) => ClassNameMap<ClassKey> // `makeStyles` where the passed `styles` do depend on props
  : (props: Props) => ClassNameMap<ClassKey> {
  return muiMakeStyles(styles, options)
}

export default makeStyles
