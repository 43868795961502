import React, { ReactNode, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useEffectOnce, useUpdate } from 'react-use'
import classNames from 'classnames'
import { makeStyles, Tabs as MuiTabs, TabsProps as MuiTabsProps } from '../../core'
import { ITabProps, Tab } from './components/Tab'
import { TabPanel } from './components/TabPanel'
import { getTabIdentifiers } from './utils/getTabIdentifiers'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  tab: {
    minWidth: '72px',
    maxWidth: '264px',
    padding: theme.spacing(2, 3),
  },
}))

type TOmittedProp = 'value' | 'onChange' | 'children'

export interface ITabsProps extends Omit<MuiTabsProps, TOmittedProp> {
  children: ReactNode
  /**
   * @example 'home'
   */
  activeTabId: string
  onChange: (newActiveTabId: string) => void
  dataTest?: string
  tabList: ITabProps[]
  className?: string
  tabClassName?: string
  tabPanelClassName?: string
}

/**
 * Based on MUI's v4 scroll tabs example
 * @see https://v4.mui.com/components/tabs/#automatic-scroll-buttons
 */
export function Tabs({
  activeTabId,
  children,
  className,
  onChange,
  tabClassName,
  tabList,
  tabPanelClassName,
  ...otherProps
}: ITabsProps) {
  const tabIds = useMemo<string[]>(() => tabList.map(({ tabId }) => tabId), [tabList])

  /**
   * Since MUI's Tabs rely on an index based approach to figure active state and
   * panel, we convert it here to make it compatible with our id based approach.
   */
  const activeTabIndex = tabIds.indexOf(activeTabId)

  const tabChangeHandler = useCallback(
    (_: React.ChangeEvent<{}>, newActiveTabIndex: number) => {
      const newActiveTabId = tabIds[newActiveTabIndex]

      onChange(newActiveTabId)
    },
    [onChange, tabIds]
  )

  const classes = useStyles()
  const { tabPanelIdentifier } = getTabIdentifiers(activeTabId)
  const activePanelElement = document.getElementById(tabPanelIdentifier)

  const update = useUpdate()
  /**
   * useEffectOnce to re-render the component after the initial render.
   * This helps us ensure the activePanelElement exists when creating the
   * portal on the second render.
   */
  useEffectOnce(() => {
    update()
  })

  return (
    <div className={classNames(classes.root, className)}>
      <MuiTabs {...otherProps} value={activeTabIndex} onChange={tabChangeHandler}>
        {tabList.map(tab => (
          <Tab className={classNames(classes.tab, tabClassName)} key={tab.tabId} {...tab} />
        ))}
      </MuiTabs>
      {tabIds.map(tabId => (
        <TabPanel
          key={tabId}
          tabId={tabId}
          activeTabId={activeTabId}
          className={tabPanelClassName}
        />
      ))}
      {activePanelElement && createPortal(children, activePanelElement)}
    </div>
  )
}
