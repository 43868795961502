import React, { NamedExoticComponent, memo, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { Button, makeStyles, createStyles } from '../../core'
import { brandColors } from '../../theme/colors'
import { pagination } from '../../utils/paginationHelper'

export interface IPaginationProps {
  currentPage: number
  /**
   * @default true
   */
  displayPageNumbers?: boolean
  /**
   * @description Results for the next page are being loaded
   * @default false
   */
  loading?: boolean
  numberOfPages: number
  onPageChange: (pageNumber: number) => Promise<void> | void
}

const __UUID__ = uuid()

const usePaginationStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiButton-root, & button': {
        width: 'auto !important',
        minWidth: 'auto',
      },
    },
    paginationEllipsis: {
      fontSize: '0.75rem',
      verticalAlign: 'sub',
      color: brandColors.coolGray5,
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      display: 'inline',
      fontWeight: 'bold',
    },
    baseLayout: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      borderRadius: theme.shape.borderRadius,
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
  })
)

export const Pagination: NamedExoticComponent<IPaginationProps> = memo(
  ({ currentPage, displayPageNumbers = true, loading = false, numberOfPages, onPageChange }) => {
    const disablePrev = loading || currentPage === 1
    const disableNext = loading || currentPage === numberOfPages || currentPage > numberOfPages

    const paginationElements = useMemo(
      () => (displayPageNumbers && numberOfPages > 1 ? pagination(currentPage, numberOfPages) : []),
      [currentPage, displayPageNumbers, numberOfPages]
    )

    const createPageClickHandler = (elementIndex: number) => () => {
      if (elementIndex && elementIndex !== currentPage) {
        onPageChange(elementIndex)
      }
    }

    const handleNextPage = () => {
      onPageChange(currentPage + 1)
    }

    const handlePrevPage = () => {
      onPageChange(currentPage - 1)
    }

    const classes = usePaginationStyles()

    return (
      <div data-test='pagination' className={classes.root}>
        <Button
          className={`${classes.baseLayout}`}
          onClick={handlePrevPage}
          disabled={disablePrev}
          variant='text'
          size='small'
          data-test='pagination-prev-button'>
          Previous
        </Button>
        {paginationElements.map(element => {
          if (element < 0) {
            return (
              <span
                key={`${__UUID__}${element}`}
                className={classes.paginationEllipsis}
                data-test='pagination-ellipsis'>
                &bull;&bull;&bull;
              </span>
            )
          }

          const clickHandler = createPageClickHandler(element)
          return (
            <Button
              className={`${classes.baseLayout} ${classes.marginLeft}`}
              value={element}
              key={`${__UUID__}${element}`}
              onClick={clickHandler}
              color={element === currentPage ? 'primary' : 'secondary'}
              size='small'
              data-test={`pagination-${element}-button`}>
              {element}
            </Button>
          )
        })}
        <Button
          className={`${classes.baseLayout} ${classes.marginLeft} `}
          onClick={handleNextPage}
          disabled={disableNext}
          variant='text'
          size='small'
          data-test='pagination-next-button'>
          Next
        </Button>
      </div>
    )
  }
)

export default Pagination
