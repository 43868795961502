import React, { useMemo } from 'react'
import { Stepper as MUIStepper, makeStyles } from '../../core'
import { brandColors } from '../../theme/colors'
import { Step } from './Step'
import { StepConnector } from './StepConnector'

const useStepperStyles = makeStyles(({ spacing, typography }) => ({
  root: {
    '& .MuiStepLabel-alternativeLabel.MuiStepLabel-label': {
      marginTop: spacing(1.5),
      fontWeight: 600,
      fontSize: typography.overline.fontSize,
    },
    '& .MuiStepLabel-active, & .MuiStepLabel-label.MuiStepLabel-completed': {
      color: brandColors.skyBlue6,
    },
    '& .MuiStepLabel-root.Mui-disabled': {
      cursor: 'not-allowed',
    },
  },
}))

interface IStep {
  name: string
  isComplete: boolean
}

export interface IStepperProps {
  steps: IStep[]
  activeStepIndex: number
  setActiveStepIndex: (step: number) => void
}

export function Stepper({ activeStepIndex, setActiveStepIndex, steps }: IStepperProps) {
  const classes = useStepperStyles()
  const firstIncompleteStep = useMemo(() => {
    return steps.findIndex(({ isComplete }) => !isComplete)
  }, [steps])

  return (
    <MUIStepper
      data-test='stepper'
      className={classes.root}
      activeStep={activeStepIndex}
      alternativeLabel
      connector={<StepConnector data-test='step-connector' />}>
      {steps.map((step, index) => (
        <Step
          key={step.name}
          isActive={activeStepIndex === index}
          isCompleted={step.isComplete}
          isHighlighted={activeStepIndex > index}
          firstIncompleteStep={firstIncompleteStep}
          name={step.name}
          index={index}
          stepClickHandler={setActiveStepIndex}
        />
      ))}
    </MUIStepper>
  )
}

export default Stepper
