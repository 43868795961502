import { REQUIRED_MESSAGE } from 'forms/utils/validationMessages'
import * as yup from 'yup'

const matchUppercaseAndLowercase = /(?=.*?[A-Z])(?=.*?[a-z])/
const matchNumber = /(?=.*?[0-9])/
const matchSpecialCharacters = /(?=.*?[\W_])/

export const PasswordSchema = yup
  .string()
  .required(REQUIRED_MESSAGE)
  .min(8, 'length')
  .matches(matchUppercaseAndLowercase, {
    excludeEmptyString: true,
    message: 'letters',
  })
  .matches(matchNumber, { excludeEmptyString: true, message: 'numbers' })
  .matches(matchSpecialCharacters, {
    excludeEmptyString: true,
    message: 'special',
  })
