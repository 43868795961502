import React from 'react'
import classNames from 'classnames'
import { Box, Typography, makeStyles } from '../../../core'
import { CloseIcon, LeftArrowIcon } from '../../../icons'
import { IconButton } from '../../IconButton'
import { InlineMessage, IInlineMessageProps } from '../../InlineMessage'

const useHeaderStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxShadow: '0 1px 2px rgba(0,0,0,0.15)', // .box-shadow-low
    alignItems: 'center',
    zIndex: 100, // .z-top
  },
  headerMain: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  currentStepMessaging: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  message: {
    marginTop: theme.spacing(0.5),
  },
}))

interface IHeaderProps {
  currentStep: number
  totalSteps: number
  onClose: () => void
  onPressBackButton: () => void
  headerConfig: {
    title: string
    subtitle?: string
  }
  messages: IInlineMessageProps[]
  className?: string
}

export function Header({
  className = '',
  currentStep,
  headerConfig,
  messages,
  onClose,
  onPressBackButton,
  totalSteps,
}: IHeaderProps) {
  const classes = useHeaderStyles()
  const { subtitle: currentStepSubtitle, title: currentStepTitle } = headerConfig || {}
  return (
    <Box className={classNames(classes.root, className)} data-test='full-page-overlay-header'>
      <div className={classes.headerMain}>
        {currentStep > 0 && (
          <div>
            <IconButton
              size='large'
              label='go back'
              Icon={LeftArrowIcon}
              onClick={onPressBackButton}
            />
          </div>
        )}
        <div className={classes.currentStepMessaging}>
          {totalSteps > 1 && (
            <Typography variant='body2'>{`Step ${currentStep + 1} of ${totalSteps}`}</Typography>
          )}
          <Typography variant='h3' data-test='current-step-title'>
            {currentStepTitle}
          </Typography>
          {totalSteps === 1 && currentStepSubtitle && <p>{currentStepSubtitle}</p>}
          {messages.map(({ message, type }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={classes.message} key={index}>
              <InlineMessage
                dataTest='overlay-inline-message'
                message={message || ''}
                type={type}
              />
            </div>
          ))}
        </div>
      </div>
      <div>
        <IconButton
          color='secondary'
          size='large'
          label='close overlay'
          onClick={onClose}
          Icon={CloseIcon}
        />
      </div>
    </Box>
  )
}
