import { KyInstance } from 'ky'
import {
  ICheckStrengthRequestBody,
  ICheckStrengthResponseBody,
  IRequestMagicLinkRequestBody,
  IResetWithExistingPasswordRequestBody,
  IResetWithMagicLinkTokenRequestBody,
  IResetWithSessionRequestBody,
  IResetWithMagicLinkTokenResponseBody,
} from './types'

export interface IPasswordsClientOptions {
  connection: KyInstance
}

export interface IPasswordsClient {
  requestMagicLink: (data: IRequestMagicLinkRequestBody) => Promise<{}>
  resetWithMagicLinkToken: (
    data: IResetWithMagicLinkTokenRequestBody
  ) => Promise<IResetWithMagicLinkTokenResponseBody>
  resetWithExistingPassword: (data: IResetWithExistingPasswordRequestBody) => Promise<{}>
  resetWithSession: (data: IResetWithSessionRequestBody) => Promise<{}>
  checkStrength: (data: ICheckStrengthRequestBody) => Promise<ICheckStrengthResponseBody>
}

export const PASSWORDS_ENDPOINTS = {
  requestMagicLink: 'passwords/request_magic_link',
  resetWithMagicLinkToken: 'passwords/reset_with_magic_link_token',
  resetWithExistingPassword: 'passwords/reset_with_existing_password',
  resetWithSession: 'passwords/reset_with_session',
  checkStrength: 'passwords/check_strength',
}

export const makePasswordsClient = ({ connection }: IPasswordsClientOptions): IPasswordsClient => {
  return {
    requestMagicLink: data => {
      return connection.post(PASSWORDS_ENDPOINTS.requestMagicLink, { json: data }).json()
    },
    resetWithMagicLinkToken: data => {
      return connection.post(PASSWORDS_ENDPOINTS.resetWithMagicLinkToken, { json: data }).json()
    },
    resetWithExistingPassword: data => {
      return connection.post(PASSWORDS_ENDPOINTS.resetWithExistingPassword, { json: data }).json()
    },
    resetWithSession: data => {
      return connection.post(PASSWORDS_ENDPOINTS.resetWithSession, { json: data }).json()
    },
    checkStrength: data => {
      return connection.post(PASSWORDS_ENDPOINTS.checkStrength, { json: data }).json()
    },
  }
}
