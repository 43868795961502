import React, { memo } from 'react'
import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  ToggleButton as MuiToggleButton,
  makeStyles,
} from '../../core'
import { brandColors } from '../../theme/colors'
import { boxShadowLevels } from '../../theme/shadows'

export interface ISegmentedToggleButtonOption<TValue = string> {
  value: TValue
  label: string
  dataTest?: string
}

export interface ISegmentedToggleButtonProps<TValue = string>
  extends Pick<MuiToggleButtonGroupProps, 'value' | 'onChange' | 'disabled'> {
  /**
   * The options to be displayed
   * @example [{ label: 'Red', value: 'red' }, { label: 'Orange', value: 'orange' }]
   * @default []
   */
  options: ISegmentedToggleButtonOption<TValue>[]
  /**
   * @default 'SegmentedToggleButton'
   */
  dataTest?: string
}

const useSegmentedToggleButtonStyles = makeStyles(theme => ({
  container: {
    backgroundColor: brandColors.coolGray3,
    borderRadius: theme.spacing(5),
    padding: theme.spacing(0.5),
    minWidth: theme.spacing(21),
  },
  buttonGroup: {
    display: 'flex',
  },
  button: {
    backgroundColor: 'transparent',
    borderWidth: '0px !important',
    flexGrow: 1,
    fontSize: '0.875rem !important',
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: `${theme.spacing(2.5)} !important`,
    padding: theme.spacing(0.375, 2),
    '&:hover': {
      backgroundColor: brandColors.coolGray2,
    },
    '&:focus': {
      boxShadow: `0 0 0 3px ${brandColors.skyBlue4}`,
    },
    '&.Mui-selected': {
      backgroundColor: brandColors.white,
      boxShadow: boxShadowLevels.low,
      '&:hover': {
        backgroundColor: brandColors.coolGray0,
      },
    },
  },
}))

export const SegmentedToggleButton = memo<ISegmentedToggleButtonProps>(
  ({ dataTest = 'SegmentedToggleButton', disabled, onChange, options, value }) => {
    const classes = useSegmentedToggleButtonStyles()
    return (
      <div className={classes.container} data-test={dataTest}>
        <MuiToggleButtonGroup
          className={classes.buttonGroup}
          onChange={onChange}
          value={value}
          disabled={disabled}
          exclusive>
          {options.map(({ dataTest: optionDataTest, label, value: optionValue }) => (
            <MuiToggleButton
              key={optionValue}
              value={optionValue}
              className={classes.button}
              data-test={optionDataTest || `${dataTest}-${optionValue}`}>
              {label}
            </MuiToggleButton>
          ))}
        </MuiToggleButtonGroup>
      </div>
    )
  }
)
