import React, { useMemo } from 'react'
import kebabCase from 'lodash/kebabCase'
import { DrawerProps as MuiDrawerProps, Stack as MuiStack, Theme, makeStyles } from '../../../core'
import { brandColors } from '../../../theme'
import {
  ApplicationSelector,
  IApplicationSelectorProps,
} from '../../AdminLayout/ApplicationSelector'
import { NAV_DRAWER_WIDTH_COLLAPSED, NAV_DRAWER_WIDTH_EXPANDED } from '../constants'
import { INavListProps, NavList } from './NavList'
import { IOrgNavButtonProps, OrgNavButton } from './OrgNavButton'
import { IOrgNavItemProps, OrgNavItem } from './OrgNavItem'

export interface INavProps {
  /**
   * Used to render navigation links by section
   * @default []
   */
  navLists?: INavListProps[]
  /**
   * Used to render the organization nav button
   * @default false
   */
  isOrgNavButtonEnabled?: boolean
  /**
   * Props used to render the organization nav button pinned to the bottom of the nav drawer
   * OrgNavButton is only rendered if isOrgNavButtonEnabled is true
   * @default undefined
   */
  orgNavButtonProps?: Omit<IOrgNavButtonProps, 'isCollapsed'>
  /**
   * Used to render the organization nav item pinned to the bottom of the nav drawer
   * OrgNavItem is only rendered if isOrgNavButtonEnabled is false
   * @default undefined
   */
  orgNavItem?: Pick<IOrgNavItemProps, 'name' | 'imgSrc' | 'href' | 'isAdmin'>
  /**
   * Used to indicate if the nav bar should display open or closed
   * @default true
   */
  isOpen?: MuiDrawerProps['open']
  /**
   * @default 'Nav'
   */
  dataTest?: string
  /**
   * Set the color of the drawer
   * @default 'dark'
   */
  color?: 'dark' | 'light'
  /**
   * Transfix applications the user has access
   */
  apps?: IApplicationSelectorProps['apps']
  /**
   * Function that triggers when user clicks on admin console link
   */
  onClickAdminConsoleLink?: IOrgNavItemProps['onClickAdminConsoleLink']
  /**
   * Function to close the menu
   */
  onClose?: () => void
}

const useNavStyles = makeStyles<
  Theme,
  Pick<INavProps, 'isOpen' | 'isOrgNavButtonEnabled'> & Pick<INavProps, 'color'>
>(theme => ({
  list: {
    padding: theme.spacing(0, 1.5),
  },
  divider: {
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },
  bottomContainer: {
    backgroundColor: ({ color }) =>
      color === 'dark' ? brandColors.coolGray8 : brandColors.coolGray2,
    borderTop: ({ color }) =>
      color === 'dark'
        ? '1px solid rgba(255, 255, 255, 0.3)'
        : `1px solid ${brandColors.coolGray3}`,
    padding: ({ isOrgNavButtonEnabled }) =>
      isOrgNavButtonEnabled ? theme.spacing(1) : theme.spacing(1.5),
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,

    [theme.breakpoints.up('md')]: {
      padding: ({ isOrgNavButtonEnabled }) =>
        isOrgNavButtonEnabled ? theme.spacing(0.5, 1, 1, 1) : theme.spacing(1.5),
      width: ({ isOpen }) => (isOpen ? NAV_DRAWER_WIDTH_EXPANDED : NAV_DRAWER_WIDTH_COLLAPSED),

      '&.collapsed': {
        padding: ({ isOrgNavButtonEnabled }) =>
          isOrgNavButtonEnabled ? theme.spacing(0.5, 0.5, 1, 0.5) : theme.spacing(1.5),
      },
    },
  },
}))

export function Nav({
  isOpen = true,
  navLists = [],
  dataTest = 'Nav',
  orgNavItem = undefined,
  apps = undefined,
  color = 'dark',
  onClickAdminConsoleLink,
  onClose,
  isOrgNavButtonEnabled = false,
  orgNavButtonProps = undefined,
}: INavProps) {
  const classes = useNavStyles({ isOrgNavButtonEnabled, isOpen, color })
  const orderedNavLists = useMemo(() => {
    return [...navLists].sort((first, second) => {
      const { position: firstPosition } = first
      const { position: secondPosition } = second
      const areBothPositioned = firstPosition && secondPosition
      const areNonePositioned = !(firstPosition || secondPosition)

      if (areBothPositioned) {
        return firstPosition - secondPosition
      }

      if (areNonePositioned) {
        return 0
      }

      // any present value takes precedence in ordering
      return secondPosition ? 1 : -1
    })
  }, [navLists])

  return (
    <div data-test={dataTest}>
      <MuiStack>
        {orderedNavLists.map(({ navListTitle, ...navListProps }, i) => {
          const key = [dataTest, navListTitle ? kebabCase(navListTitle) : i].join('-')
          return (
            <div key={key} className={classes.list}>
              <NavList
                dataTest={key}
                navListTitle={navListTitle}
                isOpen={isOpen}
                color={color}
                onClose={onClose}
                {...navListProps}
              />
            </div>
          )
        })}
      </MuiStack>
      <div className={`${classes.bottomContainer} ${isOpen ? '' : 'collapsed'}`}>
        {!isOrgNavButtonEnabled && orgNavItem && (
          <OrgNavItem
            isOpen={isOpen}
            onClose={onClose}
            {...orgNavItem}
            onClickAdminConsoleLink={onClickAdminConsoleLink}
          />
        )}
        {isOrgNavButtonEnabled && orgNavButtonProps && (
          <OrgNavButton {...orgNavButtonProps} isCollapsed={!isOpen} />
        )}
        {apps && <ApplicationSelector apps={apps} />}
      </div>
    </div>
  )
}
