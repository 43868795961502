import React, { PropsWithChildren } from 'react'
import { PermissionsProvider as _PermissionsProvider } from '../../common'
import { TUsePermissionsLazyQuery } from '../../common/context/PermissionsContext/types'
import { useAuthMember } from '../hooks'

export interface IPermissionsProviderProps {
  usePermissionsLazyQuery: TUsePermissionsLazyQuery
}

export function PermissionsProvider({
  children,
  usePermissionsLazyQuery,
}: PropsWithChildren<IPermissionsProviderProps>) {
  const { member } = useAuthMember()

  return (
    <_PermissionsProvider user={member} usePermissionsLazyQuery={usePermissionsLazyQuery}>
      {children}
    </_PermissionsProvider>
  )
}
