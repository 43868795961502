import React, { ReactNode } from 'react'
import {
  FormControlLabel,
  makeStyles,
  createStyles,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  RadioClassKey,
  Typography,
  Theme,
} from '../../core'
import { brandColors } from '../../theme'
import { FormHelperText } from '../FormHelperText'

const useFormControlLabelStyles = makeStyles(() =>
  createStyles<Extract<RadioClassKey, 'root'>, Partial<IRadioProps>>({
    root: {
      alignItems: ({ composed }) => (composed ? 'flex-start' : 'center'),
    },
  })
)

const useRadioContainerStyles = makeStyles<Theme, Pick<IRadioProps, 'disabled' | 'checked'>>(
  theme => ({
    root: {
      backgroundColor: ({ checked, disabled }) => {
        if (!checked && disabled) {
          return brandColors.coolGray3
        }
        if (!checked) {
          return brandColors.coolGray1
        }
        return brandColors.skyBlue1
      },
      borderRadius: '12px',
      maxWidth: 250,
      marginRight: theme.spacing(2),
      '& label': {
        padding: theme.spacing(2, 3, 2, 2),
        width: '100%',
      },
    },
  })
)

const useRadioStyles = makeStyles(() =>
  createStyles<Extract<RadioClassKey, 'root'>, Partial<IRadioProps>>({
    root: {
      color: brandColors.coolGray5,
      /**
       * Since button has padding top/bottom of 9px
       * this offsets the vertical space that the button creates
       */
      margin: ({ composed }) => (composed ? '-7px 0' : '-9px 0'),
      /**
       * This overrides the global MuiIconButton theme value of 4px
       * and instead makes the button circular
       */
      borderRadius: '50%',
    },
  })
)

type TOmittedProps =
  | 'label'
  | 'id'
  | 'name'
  | 'value'
  | 'className'
  | 'checked'
  | 'disabled'
  | 'onChange'

export interface IRadioProps {
  label: ReactNode
  id?: string
  name?: string
  value?: string
  className?: string
  /**
   * @default false
   */
  checked?: boolean
  /**
   * @default false
   */
  disabled?: boolean
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange?: (event: React.ChangeEvent<any>, checked: boolean) => void
  /**
   * Allow passing custom props to the <MuiRadio /> component
   * but exclude any props we are already passing to the root
   * @example `checked` should not be passed to both root and <MuiRadio />
   */
  RadioProps?: Partial<Omit<MuiRadioProps, TOmittedProps>>
  dataTest?: string
  /**
   * This flag should be true when you need to add a complex node to the Radio, and you want to have an input value at the top and not at the center (which is the default value)
   *
   * @default false
   */
  composed?: boolean
  helperText?: string
  error?: boolean
  /**
   * label description
   */
  description?: string
  /**
   * If true, adds a rounded background to the Radio button
   */
  big?: boolean
}

/**
 * @name Radio
 * @see https://material-ui.com/api/radio/
 */
export function Radio({
  id: idProp,
  label,
  value,
  onChange,
  name,
  disabled = false,
  checked = false,
  RadioProps = {},
  dataTest = 'radio-input',
  composed = false,
  className,
  description = '',
  big = false,
  helperText,
  error,
}: IRadioProps) {
  const containerClasses = useRadioContainerStyles({ disabled, checked })
  const classes = useRadioStyles({ composed })
  const formClasses = useFormControlLabelStyles({ composed })

  return (
    <div className={big ? containerClasses.root : ''}>
      <FormControlLabel
        data-test={dataTest}
        name={name}
        className={className}
        id={idProp}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        classes={formClasses}
        control={
          <MuiRadio
            classes={classes}
            data-test={`${dataTest}-input`}
            size='small'
            color='primary'
            {...RadioProps}
          />
        }
        label={
          <>
            <Typography variant={checked && big ? 'subtitle1' : 'body1'}>{label}</Typography>
            {description && <Typography variant='body2'>{description}</Typography>}
          </>
        }
      />
      {helperText && (
        <div data-test='form-helper-text'>
          <FormHelperText hasError={error}>{helperText}</FormHelperText>
        </div>
      )}
    </div>
  )
}

export default Radio
