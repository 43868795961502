import React, { memo } from 'react'
import { ApolloError } from '@apollo/client'
import { Stack } from '../../core'
import { INLINE_MESSAGE_VARIANTS, InlineMessage } from '../InlineMessage'
import { IError } from './types'
import { getApolloErrorMessages } from './utils'

export interface IApolloErrorMessagesProps {
  /**
   * Errors returned from the Apollo client
   */
  apolloErrors: Nullable<ApolloError>
  /**
   * Errors returned from a mutation
   */
  mutationErrors?: Nullable<IError[]>
  /**
   * @default 'ApolloErrorMessages'
   */
  dataTest?: string
}

export const ApolloErrorMessages = memo<IApolloErrorMessagesProps>(
  ({ apolloErrors, dataTest = 'ApolloErrorMessages', mutationErrors }) => {
    const errors = getApolloErrorMessages({
      apolloErrors,
      mutationErrors,
    })

    return (
      <Stack data-test={dataTest} gap={1}>
        {errors.map(error => (
          <InlineMessage
            key={error}
            message={error}
            type={INLINE_MESSAGE_VARIANTS.ALERT}
            fullWidth
          />
        ))}
      </Stack>
    )
  }
)
