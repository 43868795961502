import React, { useCallback } from 'react'
import { makeStyles } from '../../../../../../core'
import { ISelectFieldProps, SelectField } from '../../../../../SelectField'
import { IDataGridSortOption } from '../types'

export interface IDataGridSortSelectInputProps<TSortValue extends string> {
  /**
   * An array of options for the sort select
   */
  options: IDataGridSortOption<TSortValue>[]
  /**
   * The current value of the sort select
   */
  value?: TSortValue
  /**
   * Callback function that is called when the sort option changes
   */
  onChange: (sortOption: TSortValue) => void
  /**
   * The variant of the sort select
   */
  variant?: 'header' | 'menu'
  /**
   * @default 'DataGridSortSelectInput'
   */
  dataTest?: string
}

const useDataGridSortSelectInputStyles = makeStyles(() => ({
  select: {
    '& .MuiSelect-select, & fieldset': {
      border: 'none',
    },
  },
}))

export function DataGridSortSelectInput<TSortValue extends string>({
  dataTest = 'DataGridSortSelectInput',
  onChange,
  options,
  value,
  variant,
}: IDataGridSortSelectInputProps<TSortValue>) {
  const classes = useDataGridSortSelectInputStyles()

  const changeHandler: ISelectFieldProps['onChange'] = useCallback(
    event => {
      onChange(event.target.value)
    },
    [onChange]
  )
  return (
    <SelectField
      options={options}
      value={value}
      onChange={changeHandler}
      dataTest={dataTest}
      label={variant === 'menu' ? 'Sort by' : ''}
      size={variant === 'menu' ? 'medium' : 'small'}
      className={variant === 'header' ? classes.select : ''}
    />
  )
}
