import { Components } from '@mui/material/styles'

/**
 * @name Link
 * @see https://mui.com/material-ui/api/link/
 */
export const MuiLinkThemeOptions: Components['MuiLink'] = {
  defaultProps: {
    underline: 'hover',
  },
}
