import React, { ReactNode } from 'react'
import { Slide, makeStyles } from '../../core'
import { CarouselHeader } from './components/CarouselHeader'
import { useCarousel } from './hooks/useCarousel'

type TContainerProps = {
  perSlide: number
}

export interface ICarouselProps<T> {
  /**
   * @default carousel
   * A data-test attribute to identify the carousel in tests
   */
  dataTest?: string
  /**
   * @default 3
   * The number of items to display per page.
   */
  itemsPerSlide?: number
  /**
   * The items to display in the carousel.
   */
  items: T[]
  /**
   * A function that renders each carousel item.
   * @param itemProps The props for the item to render.
   *
   * @example (itemProps) => (<div>{itemProps.text}</div>)
   */
  renderItem: (itemProps: T) => ReactNode
  /**
   * @default default
   */
  variant?: 'default' | 'withTitle'
  /**
   * The title to display above the carousel.
   */
  title?: ReactNode | string
}

const useCarouselStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 2,
  },
  container: ({ perSlide }: TContainerProps) => ({
    width: '100%',
    gap: 2,
    display: 'grid',
    gridTemplateColumns: `repeat(${perSlide}, 1fr)`,
  }),
  sliderWrapper: {
    overflow: 'hidden',
  },
}))

export function Carousel<T extends { id: string | number }>({
  dataTest = 'carousel',
  items = [],
  itemsPerSlide = 3,
  renderItem,
  variant = 'default',
  title,
}: ICarouselProps<T>) {
  const canFitAllItems = itemsPerSlide >= items.length
  const perSlide = canFitAllItems ? items.length : itemsPerSlide
  const classes = useCarouselStyles({ perSlide: itemsPerSlide })

  const {
    activePage,
    displayedItems,
    goToNextPageHandler,
    goToPreviousPageHandler,
    slideDirection,
  } = useCarousel(items, perSlide)

  return (
    <div className={classes.root} data-test={dataTest}>
      <CarouselHeader
        showControls={!canFitAllItems}
        onNextPage={goToNextPageHandler}
        onPreviousPage={goToPreviousPageHandler}
        variant={variant}
        title={title}
        amountItems={String(items.length)}
      />

      <div className={classes.sliderWrapper}>
        {/* The `key` prop of `Slide` is set to `activePage` from `useCarousel`, triggering a re-render and sliding animation. */}
        <Slide
          in
          appear={!canFitAllItems} // Avoid animation if all items can fit on a single page.
          direction={slideDirection}
          key={activePage}
          data-test='carousel-slide-transition'>
          <div className={classes.container}>
            {displayedItems.map(item => {
              const { id } = item || {}
              return (
                <div key={id} data-test='carousel-item'>
                  {renderItem(item)}
                </div>
              )
            })}
          </div>
        </Slide>
      </div>
    </div>
  )
}

export default Carousel
