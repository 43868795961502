import React from 'react'
import {
  GridColumnMenu as MuiGridColumnMenu,
  GRID_CHECKBOX_SELECTION_FIELD,
  useGridApiContext,
  GridSlots as MuiGridSlots,
  PropsFromSlot as MuiDataGridPropsFromSlot,
} from '../../../../core'
import { getBulkActionSlotsAndProps } from '../../utils'
import { DataGridColumnEmptyMenu } from '../DataGridColumnEmptyMenu'

export interface IDataGridColumnMenuProps
  extends MuiDataGridPropsFromSlot<MuiGridSlots['columnMenu']> {}

/**
 * This component makes use of existing slot definitions given by MUI that allow to customize or hide the default menu items in the column menu
 * For example, columnMenuFilterItem is a slot used to customize the filter item, since we won't use MUIs filtering we need to set it always to null to hide it
 * See: https://mui.com/x/react-data-grid/column-menu/#default-column-menu-items for the full list of default column menu items
 */
const DEFAULT_COLUMN_HIDDEN_SLOTS = {
  columnMenuFilterItem: null,
}

const DEFAULT_CHECKBOX_COLUMN_HIDDEN_SLOTS = {
  columnMenuFilterItem: null,
  columnMenuColumnsItem: null,
}

export function DataGridColumnMenu({ bulkActions, ...props }: IDataGridColumnMenuProps) {
  const { current } = useGridApiContext()
  const selectedRows = current.getSelectedRows()
  const hasSelectedRows: boolean = selectedRows.size > 0

  const isCheckboxColumn = props.colDef.field === GRID_CHECKBOX_SELECTION_FIELD

  if (isCheckboxColumn && !hasSelectedRows) {
    // If current column is checkbox column and there are no rows selected
    return (
      <MuiGridColumnMenu
        {...props}
        slots={{
          ...DEFAULT_CHECKBOX_COLUMN_HIDDEN_SLOTS,
          columnMenuEmptyItem: DataGridColumnEmptyMenu,
        }}
        data-test='DataGridCheckboxColumnMenuEmpty'
      />
    )
  }
  if (isCheckboxColumn && hasSelectedRows) {
    // If current column is checkbox column and there are rows selected
    const { slotProps, slots } = getBulkActionSlotsAndProps(selectedRows, bulkActions)

    return (
      <MuiGridColumnMenu
        {...props}
        slots={{
          ...DEFAULT_CHECKBOX_COLUMN_HIDDEN_SLOTS,
          ...slots,
        }}
        slotProps={{
          ...slotProps,
        }}
        data-test='DataGridCheckboxColumnMenu'
      />
    )
  }

  return (
    <MuiGridColumnMenu
      {...props}
      slots={DEFAULT_COLUMN_HIDDEN_SLOTS}
      data-test='DataGridDefaultColumnMenu'
    />
  )
}
