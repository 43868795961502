import React, { memo, useMemo } from 'react'
import classnames from 'classnames'
import { v4 as uuid } from 'uuid'
import { makeStyles, Typography, ClickAwayListener } from '../../core'
import { DownArrowIcon, CloseIcon } from '../../icons'
import { brandColors } from '../../theme/colors'
import { boxShadowLevels } from '../../theme/shadows'
import { useMenuToggle } from '../../utils/hooks/useMenuToggle'
import Facet, { IFacetProps } from '../Facet'
import { IconButton } from '../IconButton'
import { Popper } from '../Popper'
import { SingleSelectOption } from './SingleSelectOption'
import { ISingleSelectOption } from './types'

const MAX_ITEMS = 8
const OPTION_HEIGHT = 28

const noOp = () => {}

export interface ISingleSelectProps {
  id: string
  dataTest: string
  icon?: JSX.Element
  label: string
  options: ISingleSelectOption[]
  onSelect: (selected?: ISingleSelectOption) => void
  className?: string
  facet?: boolean
  selected?: ISingleSelectOption | string | number
  /**
   * When `options` array is empty, value of
   * `emptyStateLabel` will display in an empty option element
   * @example 'No saved customers'
   */
  emptyStateLabel?: string
  /**
   * @default true
   */
  clearable?: boolean
  /**
   * @default true
   */
  showSelectedOptionLabel?: boolean
  /**
   * Text that shows above all the options in the dropdown
   * @optional
   * @example 'Ops Groups'
   */
  optionsLabel?: string
}

const useSingleSelectStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
  },
  displayValueContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    gap: theme.spacing(1),
    '& .prefix-icon': {
      color: brandColors.coolGray8,
      display: 'flex',
    },
    '& > b': {
      textWrap: 'nowrap',
    },
  },
  optionsLabel: {
    padding: theme.spacing(1.5, 2, 0),
    display: 'block',
  },
  optionsListContainer: {
    minWidth: '195px',
    display: 'block',
    margin: 0,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: 0,
    boxShadow: boxShadowLevels.high,
    backgroundColor: brandColors.white,
    border: `1px solid ${brandColors.coolGray3}`,
    borderRadius: '0 0 2px 2px',
    boxSizing: 'border-box',
    maxHeight: `${MAX_ITEMS * OPTION_HEIGHT}px`,
    zIndex: 4,
    width: '100%',
  },
  applied: {
    borderColor: `${brandColors.skyBlue6} !important`,
  },
}))

/**
 * @deprecated Use the `PillSelect` component instead.
 */
export const SingleSelect = memo<ISingleSelectProps>(
  ({
    icon,
    label,
    options = [],
    id,
    dataTest,
    onSelect,
    className,
    selected: value,
    facet,
    emptyStateLabel,
    clearable = true,
    showSelectedOptionLabel = true,
    optionsLabel,
  }) => {
    const styles = useSingleSelectStyles()

    const {
      anchorEl,
      expanded: open,
      handleClick: openFilter,
      handleClose: closeFilter,
    } = useMenuToggle()

    const openFilterHandler: IFacetProps['onClick'] = (selected, id, e) => {
      if (e) openFilter(e)
    }

    const clearSelection = (e: React.MouseEvent) => {
      e.stopPropagation()
      onSelect(undefined)
      if (open) closeFilter()
    }

    const InteractionIcon =
      clearable && value ? (
        <IconButton
          onClick={clearSelection}
          Icon={CloseIcon}
          color='secondary'
          noPadding
          label='clear filter'
          data-test='clear-filter'
          size='large'
        />
      ) : (
        <DownArrowIcon size='medium' />
      )

    const selectOptionHandler = (newSelectedValue: ISingleSelectOption) => {
      onSelect(newSelectedValue)
      closeFilter()
    }

    const selectedOption = useMemo(() => {
      if (typeof value === 'string' || typeof value === 'number') {
        return options.find(o => o.value === value)
      }
      return value
    }, [options, value])

    const selectedOptionLabel = useMemo(
      () => selectedOption?.label ?? label,
      [label, selectedOption]
    )

    const containerClassNames = classnames(styles.root, className)

    const facetClassNames = classnames({
      [styles.applied]: value || open,
    })

    const shouldUseFacetSelectedStyle = !!(facet && value)

    return (
      <ClickAwayListener onClickAway={closeFilter}>
        <div className={containerClassNames} data-test={`${dataTest}-container`}>
          <Facet
            id={id}
            dataTest={dataTest}
            className={facetClassNames}
            label={
              <div className={styles.displayValueContainer}>
                <div className='prefix-icon'>{icon}</div>
                <b>{showSelectedOptionLabel ? selectedOptionLabel : label}</b>
                {InteractionIcon}
              </div>
            }
            onClick={openFilterHandler}
            data-test={dataTest}
            selected={shouldUseFacetSelectedStyle}
          />

          <Popper id={id} open={open} anchorEl={anchorEl}>
            <div className={styles.optionsListContainer} role='listbox'>
              {optionsLabel && (
                <Typography variant='caption' className={styles.optionsLabel} color='textSecondary'>
                  {optionsLabel}
                </Typography>
              )}
              {options.length ? (
                options.map(
                  ({
                    disabled,
                    icon: optionIcon,
                    isLink: linkValue,
                    label: optionLabel,
                    value: optionValue,
                  }) => (
                    <SingleSelectOption
                      key={uuid()}
                      label={optionLabel}
                      selected={selectedOption?.value === optionValue}
                      value={optionValue}
                      onSelect={selectOptionHandler}
                      dataTest={`${dataTest}-option-${optionValue}`}
                      icon={optionIcon}
                      isLink={linkValue}
                      disabled={disabled}
                    />
                  )
                )
              ) : (
                <SingleSelectOption
                  key={uuid()}
                  disabled
                  label={emptyStateLabel || ''}
                  selected={false}
                  value='-1'
                  onSelect={noOp}
                  dataTest={`${dataTest}-empty-state`}
                  isEmptyState
                />
              )}
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    )
  }
)

export default SingleSelect
