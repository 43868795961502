import React, { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { INLINE_MESSAGE_VARIANTS, InlineMessage, useToastContext } from 'dpl'
import SidePanel from 'dpl/components/SidePanel'
import compact from 'lodash/compact'
import { IError } from 'src/types/graphqlTypes'
import { TEAM_KEYS } from '../../../../constants'
import { AddNewMemberForm } from './AddNewMemberForm'
import { isValidEmail } from './components/ControlledAddNewMemberAutocomplete/utils'
import { useProductModulesQuery } from './components/ControlledModuleAccessRolesAutocomplete/graphql/ProductModules'
import { getModules } from './components/ControlledModuleAccessRolesAutocomplete/utils'
import { useAddMembersMutation } from './graphql/AddMembers'
import { useAddNewMemberForm } from './useAddNewMemberForm'

export interface IAddNewMemberDrawerProps {
  open: boolean
  onClose: () => void
}

export function getErrorMessage({ key, message }: IError) {
  const [, email] = /\((.+)\)/i.exec(message) || []
  if (key === 'user_creation') {
    return `Error creating this user: ${email}`
  }

  if (key === 'team_assignment') {
    return `Error assigning team to this user: ${email}`
  }

  return message
}

export function AddNewMemberDrawer({ onClose, open }: IAddNewMemberDrawerProps) {
  const { openToast } = useToastContext() || {}
  const { data } = useProductModulesQuery()
  const { currentSession } = data || {}
  const { organizationUser } = currentSession || {}
  const { organization } = organizationUser || {}
  const { productModules = [] } = organization || {}
  const defaultUserTeams = productModules.map(({ productModule }) => ({
    key: `${productModule}_${TEAM_KEYS.USERS}`,
  }))
  const methods = useAddNewMemberForm({
    defaultValues: {
      emails: [],
      modules: getModules({ organizationModules: productModules, userTeams: defaultUserTeams }),
    },
  })
  const [apiErrors, setApiErrors] = useState<string[]>([])
  const [addMembers] = useAddMembersMutation()
  const { handleSubmit } = methods || {}

  useEffect(() => {
    if (productModules.length > 0) {
      methods.reset({
        emails: [],
        modules: getModules({ organizationModules: productModules, userTeams: defaultUserTeams }),
      })
    }
  }, [methods.reset, productModules])

  const saveHandler = handleSubmit(async ({ emails = [], modules }) => {
    setApiErrors([])
    try {
      const { data } = await addMembers({
        variables: {
          input: {
            users: compact(emails)
              .filter(isValidEmail)
              .map(email => ({ email })),
            organizationTeamKeys: compact(modules?.map(({ team }) => team)),
          },
        },
        refetchQueries: ['OrganizationUsers'],
      })

      const { createUsers } = data || {}
      const { errors = [] } = createUsers || {}

      const notBlockingErrors = errors.filter(({ key }) =>
        ['user_creation', 'team_assignment'].includes(key)
      )
      const blockingErrors = errors.filter(
        ({ key }) => !['user_creation', 'team_assignment'].includes(key)
      )

      if (blockingErrors?.length || notBlockingErrors.length === emails.length) {
        setApiErrors(errors?.map(getErrorMessage))
      } else {
        if (notBlockingErrors.length) {
          openToast({
            toastMessage: notBlockingErrors.map(getErrorMessage).join('\n'),
            toastType: 'alert',
            multiLine: true,
          })
        }

        onClose()
      }
    } catch (e) {
      setApiErrors([e.message])
    }
  })

  return (
    <SidePanel open={open} onClose={onClose} anchor='right' title='Add Member'>
      <FormProvider {...methods}>
        {apiErrors.map(error => (
          <InlineMessage
            message={error}
            key={error}
            fullWidth
            type={INLINE_MESSAGE_VARIANTS.ALERT}
          />
        ))}
        <AddNewMemberForm onClose={onClose} onSubmit={saveHandler} />
      </FormProvider>
    </SidePanel>
  )
}
