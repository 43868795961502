import React, { MouseEvent } from 'react'
import { Button, Card, CardContent, CardHeader, Typography } from '../../../core'

export interface IResendInvitationCardProps {
  onClick: (e: MouseEvent<HTMLButtonElement>, resend?: boolean) => void
}

export function ResendInvitationCard({ onClick }: IResendInvitationCardProps) {
  return (
    <Card>
      <CardHeader title='Resend Invitation' />
      <CardContent>
        <Typography variant='body2' mb={2.5}>
          This will send an invitation email to the member.
        </Typography>
        <Button variant='outlined' onClick={onClick} data-test='resend-invitation-button'>
          Resend Invitation
        </Button>
      </CardContent>
    </Card>
  )
}
