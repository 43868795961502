import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'

/**
 * @name ListItemButton
 * @see https://mui.com/material-ui/api/list-item-button/
 */
export const MuiListItemButtonThemeOptions: Components['MuiListItemButton'] = {
  styleOverrides: {
    root: {
      padding: BASE_THEME.spacing(1.5, 1),
      gap: BASE_THEME.spacing(1.5),

      '& .MuiListItemText-root': {
        margin: 0,
      },
    },
  },
}
