import React, { MouseEvent } from 'react'
import { Button, Card, CardContent, CardHeader, Typography } from '../../../core'

export interface IResetPasswordCardProps {
  onClick: (e: MouseEvent<HTMLButtonElement>, resend?: boolean) => void
}

export function ResetPasswordCard({ onClick }: IResetPasswordCardProps) {
  return (
    <Card>
      <CardHeader title='Password Reset' />
      <CardContent>
        <Typography variant='body2' mb={2.5}>
          Choose a strong password to keep your account secure.
        </Typography>
        <Button variant='outlined' onClick={onClick} data-test='reset-password-button'>
          Reset Password
        </Button>
      </CardContent>
    </Card>
  )
}
