import { getValidInputMessage, REQUIRED_MESSAGE } from 'forms/utils/validationMessages'
import * as yup from 'yup'

export const PasswordOrMagicLinkLoginFormSchema = yup.object({
  email: yup.string().email(getValidInputMessage('email')).required(REQUIRED_MESSAGE),
  password: yup.string().required(REQUIRED_MESSAGE),
})

export type TPasswordOrMagicLinkLoginFormSchema = yup.InferType<
  typeof PasswordOrMagicLinkLoginFormSchema
>
