import React, {
  memo,
  ReactElement,
  MouseEvent,
  Children,
  cloneElement,
  MouseEventHandler,
} from 'react'
import classNames from 'classnames'
import { IButtonProps, createStyles, makeStyles } from '../../core'
import { brandColors } from '../../theme/colors'
import { borderLeftRadius, borderRightRadius } from '../../theme/utils'

type TButtonGroupClassKey = 'root' | 'rootInline' | 'buttonGroup' | 'buttonGroupInline'

/**
 * @note
 * `.active` is a legacy class name that supports using native <button />
 * elements as valid children for <ButtonGroup />
 * @todo
 * Replace any <button /> elements with dpl <Button /> component
 * and remove `.active` class
 */
const useButtonGroupStyles = makeStyles(theme =>
  createStyles<TButtonGroupClassKey, {}>({
    root: {
      '& .label': {
        fontSize: '0.75rem',
        fontWeight: 'normal',
        lineHeight: 1.6,
        marginBottom: 1,
      },
    },
    rootInline: {
      display: 'inline-block',
    },
    buttonGroup: {
      display: 'flex',
      width: '100%',

      '& .MuiButtonBase-root, & button': {
        flexGrow: 1,
        borderColor: brandColors.coolGray4,
        borderRadius: 0,
        margin: 0,

        // Prevents hover from affecting currently selected/active button
        '&:not([disabled]):not(.MuiButton-containedPrimary)': {
          '&:hover': {
            backgroundColor: brandColors.white,
            color: brandColors.skyBlue6,
          },

          '&.MuiButton-selected, &.active': {
            backgroundColor: brandColors.skyBlue2,
            color: brandColors.skyBlue7,
            borderColor: brandColors.skyBlue6,
            zIndex: 1,

            '&:hover': {
              backgroundColor: brandColors.skyBlue2,
              color: brandColors.skyBlue7,
            },
          },
        },

        '&:not(:first-child)': {
          marginLeft: -1,
        },

        '&:first-child': {
          ...borderLeftRadius(theme.shape.borderRadius),
          borderLeftColor: brandColors.coolGray4,

          '&.MuiButton-selected, &.active': {
            color: brandColors.skyBlue7,
            borderLeftColor: brandColors.skyBlue6,
          },
        },

        '&:last-child': {
          ...borderRightRadius(theme.shape.borderRadius),
          borderRightColor: brandColors.coolGray4,

          '&.MuiButton-selected, &.active': {
            borderRightColor: brandColors.skyBlue6,
          },
        },

        '&:only-child': {
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
    buttonGroupInline: {
      display: 'inline-flex',
      width: 'auto',
    },
  })
)

export interface IButtonGroupProps extends Pick<IButtonProps, 'color' | 'size'> {
  children: ReactElement<IButtonProps>[]
  /**
   * Current value of the input
   * @example 'on'
   */
  value?: string
  dataTest?: string
  onChange?: (value: string, name: string) => void
  label?: string
  hideLabel?: boolean
  /**
   * The input name
   * @example 'lightToggle'
   */
  name?: string
  className?: string
  /**
   * @default false
   */
  inline?: boolean
}

export const ButtonGroup = memo<IButtonGroupProps>(
  ({
    children,
    className: classNameProp = '',
    dataTest = 'ButtonGroup-root',
    hideLabel = false,
    inline = false,
    label,
    name = '',
    onChange,
    size: sizeProp,
    value,
  }) => {
    const clickHandler: MouseEventHandler<HTMLButtonElement> = ({
      currentTarget: { value: clickedValue },
    }) => {
      if (onChange) {
        onChange(clickedValue, name)
      }
    }

    const classes = useButtonGroupStyles()

    const rootClasses = classNames(
      classes.root,
      {
        [classes.rootInline]: inline,
      },
      classNameProp
    )

    const btnGroupClasses = classNames(classes.buttonGroup, {
      [classes.buttonGroupInline]: inline,
    })

    return (
      <div className={rootClasses} data-test={dataTest}>
        {!hideLabel && label && <legend className='label'>{label}&nbsp;</legend>}
        <div className={btnGroupClasses}>
          {Children.map(children, (child: ReactElement<IButtonProps>) =>
            cloneElement(child, {
              variant: child.props.value === value ? 'contained' : 'outlined',
              size: child.props.size || sizeProp,
              onClick: (e: MouseEvent<HTMLButtonElement>) => {
                if (child.props.onClick) child.props.onClick(e)
                clickHandler(e)
              },
            })
          )}
        </div>
      </div>
    )
  }
)

export default ButtonGroup
