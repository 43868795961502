import React, { ReactNode } from 'react'
import { Button } from '../../../core'
import Typography from '../../../core/Typography'
import { makeStyles, createTheme } from '../../../core/styles'
import LeftArrowIcon from '../../../icons/build/LeftArrowIcon'
import RightArrowIcon from '../../../icons/build/RightArrowIcon'
import Badge from '../../Badge'

export interface CarouselHeader {
  /**
   * @default carousel
   * A data-test attribute to identify the carousel in tests
   */
  dataTest?: string
  onNextPage: () => void
  onPreviousPage: () => void
  variant: 'default' | 'withTitle'
  title?: ReactNode | string
  amountItems: string
  showControls?: boolean
}

const useCarouselHeaderStyles = makeStyles(() => {
  const theme = createTheme()
  return {
    controlsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(1),
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      width: '100%',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    iconButton: {
      minWidth: theme.spacing(4),
      width: theme.spacing(4),
    },
  }
})

export function CarouselHeader({
  amountItems,
  dataTest = 'carousel',
  onNextPage,
  onPreviousPage,
  showControls = true,
  title,
  variant,
}: CarouselHeader) {
  const classes = useCarouselHeaderStyles()
  const titleIsString = typeof title === 'string'

  return (
    <>
      {variant === 'default' && showControls && (
        <div className={classes.controlsContainer}>
          <Button onClick={onPreviousPage} data-test={`${dataTest}-prev-button`}>
            Previous
          </Button>
          <Button onClick={onNextPage} data-test={`${dataTest}-next-button`}>
            Next
          </Button>
        </div>
      )}
      {variant === 'withTitle' && (
        <div className={classes.headerContainer}>
          <div className={classes.titleWrapper}>
            {Boolean(title) && titleIsString && (
              <>
                <Typography variant='h3' component='div' data-test={`${dataTest}-title`}>
                  {title}
                </Typography>

                <Badge label={amountItems} />
              </>
            )}
            {Boolean(title) && !titleIsString && title}
          </div>

          {showControls && (
            <div className={classes.controlsContainer}>
              <Button
                className={classes.iconButton}
                onClick={onPreviousPage}
                variant='outlined'
                data-test='carousel-prev-button'>
                <LeftArrowIcon size='large' />
              </Button>

              <Button
                className={classes.iconButton}
                onClick={onNextPage}
                variant='outlined'
                data-test='carousel-next-button'>
                <RightArrowIcon size='large' />
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CarouselHeader
