import React, { forwardRef } from 'react'
import {
  DrawerProps as MuiDrawerProps,
  Drawer as MuiDrawer,
  Theme,
  makeStyles,
} from '../../../core'
import { brandColors } from '../../../theme'
import { Nav, INavProps } from '../Nav'
import { NAV_DRAWER_WIDTH_EXPANDED } from '../constants'

export interface INavDrawerProps
  extends Omit<MuiDrawerProps, 'children' | 'className' | 'variant' | 'open'> {
  /**
   * Used to render navigation links by section
   * @default []
   */
  navLists?: INavProps['navLists']
  /**
   * Used to render the organization nav button
   * @default false
   */
  isOrgNavButtonEnabled?: INavProps['isOrgNavButtonEnabled']
  /**
   * Props used to render the organization nav button pinned to the bottom of the nav drawer
   * OrgNavButton is only rendered if isOrgNavButtonEnabled is true
   * @default undefined
   */
  orgNavButtonProps?: INavProps['orgNavButtonProps']
  /**
   * Used to render the organization nav item pinned to the bottom of the nav drawer
   * OrgNavItem is only rendered if isOrgNavButtonEnabled is false
   * @default undefined
   */
  orgNavItem?: INavProps['orgNavItem']
  /**
   * Used to indicate if the nav bar should display open or closed
   * @default true
   */
  isOpen?: MuiDrawerProps['open']
  /**
   * @default 'NavDrawer'
   */
  dataTest?: string
  /**
   * Set the color of the drawer
   * @default 'dark'
   */
  color?: INavProps['color']
  /**
   * Transfix applications the user has access
   */
  apps?: INavProps['apps']
  /**
   * Function that triggers when user clicks on admin console link
   */
  onClickAdminConsoleLink?: INavProps['onClickAdminConsoleLink']
}

const useNavDrawerStyles = makeStyles<
  Theme,
  Pick<INavDrawerProps, 'isOpen'> & Pick<INavDrawerProps, 'color'>
>(theme => ({
  root: {
    backgroundColor: ({ color }) =>
      color === 'dark' ? brandColors.coolGray8 : brandColors.coolGray2,
    width: ({ isOpen }) => (isOpen ? NAV_DRAWER_WIDTH_EXPANDED : theme.spacing(8.5)),
    position: 'relative',

    '& .MuiDrawer-paper': {
      display: 'block',
      backgroundColor: ({ color }) =>
        color === 'dark' ? brandColors.coolGray8 : brandColors.coolGray2,
      border: 0,
      height: 'auto',
      width: ({ isOpen }) => (isOpen ? NAV_DRAWER_WIDTH_EXPANDED : theme.spacing(8.5)),
      overflowX: 'hidden',

      '&::-webkit-scrollbar': {
        width: theme.spacing(0.75),
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderRadius: theme.spacing(1),
      },
    },
  },
}))

export function NavDrawerComponent(
  {
    isOpen = true,
    navLists = [],
    dataTest = 'NavDrawer',
    orgNavItem = undefined,
    color = 'dark',
    apps = undefined,
    onClickAdminConsoleLink,
    isOrgNavButtonEnabled,
    orgNavButtonProps,
    ...props
  }: INavDrawerProps,
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | React.MutableRefObject<HTMLDivElement | null>
    | null
) {
  const classes = useNavDrawerStyles({ isOpen, color })
  return (
    <MuiDrawer
      ref={ref}
      className={classes.root}
      variant='permanent'
      open={isOpen}
      data-test={dataTest}
      {...props}>
      <Nav
        navLists={navLists}
        orgNavItem={orgNavItem}
        orgNavButtonProps={orgNavButtonProps}
        isOrgNavButtonEnabled={isOrgNavButtonEnabled}
        isOpen={isOpen}
        apps={apps}
        color={color}
        onClickAdminConsoleLink={onClickAdminConsoleLink}
      />
    </MuiDrawer>
  )
}

/**
 *
 * @name NavDrawer
 * @description Styled abstraction of MuiDrawer to be used in AppLayout
 * @see https://mui.com/material-ui/api/drawer/
 */
export const NavDrawer = forwardRef(NavDrawerComponent)
