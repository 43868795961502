import React from 'react'
import classNames from 'classnames'
import { ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from 'dpl/core'
import CheckFilledIcon from 'dpl/icons/build/CheckFilledIcon'
import CheckOutlineIcon from 'dpl/icons/build/CheckOutlineIcon'
import { brandColors } from 'dpl/theme/colors'

const usePasswordRequirementStyles = makeStyles(theme => ({
  root: {
    gap: theme.spacing(1),
  },
  failingRequirement: {
    color: brandColors.coolGray6,
  },
}))

interface IPasswordRequirementProps {
  label: string
  isPassing: boolean
  dataTest: string
}

export function PasswordRequirement({ dataTest, isPassing, label }: IPasswordRequirementProps) {
  const classes = usePasswordRequirementStyles()
  const textClassnames = classNames({
    [classes.failingRequirement]: !isPassing,
  })

  return (
    <ListItem dense disableGutters disablePadding className={classes.root} data-test={dataTest}>
      <ListItemIcon data-test={`${dataTest}-${isPassing ? 'pass' : 'fail'}`}>
        {isPassing ? (
          <CheckFilledIcon size='large' color='green2' />
        ) : (
          <CheckOutlineIcon size='large' color='coolGray4' />
        )}
      </ListItemIcon>
      <ListItemText className={textClassnames}>
        <Typography variant='body2' fontWeight={600}>
          {label}
        </Typography>
      </ListItemText>
    </ListItem>
  )
}
