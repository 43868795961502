import { useCallback, useEffect, useMemo, useState } from 'react'
import { Member, User } from '@stytch/vanilla-js'
import { IUserPermissions, TUsePermissionsLazyQuery, IPermission } from './types'

export interface IUseUserPermissionsProps {
  /**
   * The `currentSession` graphql lazy query used to fetch permissions
   */
  usePermissionsLazyQuery: TUsePermissionsLazyQuery
  /**
   * The Stytch active User (consumer) or Member (b2b)
   */
  user: Member | User | null
}

export const useUserPermissions = ({ usePermissionsLazyQuery, user }: IUseUserPermissionsProps) => {
  const [permissions, setPermissions] = useState<IPermission[]>()
  const [hasLoadedPermissions, setHasLoadedPermissions] = useState(false)

  const [fetchPermissions] = usePermissionsLazyQuery()

  const getPermissionsData = useCallback(
    async (user: IUseUserPermissionsProps['user']) => {
      setHasLoadedPermissions(false)
      try {
        if (user) {
          const { data } = await fetchPermissions()
          const { currentSession } = data || {}
          const { permissions } = currentSession || {}
          setPermissions(permissions)
        } else {
          setPermissions(undefined)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error)
      } finally {
        setHasLoadedPermissions(true)
      }
    },
    [fetchPermissions]
  )

  const userPermissions: IUserPermissions = useMemo(() => {
    const allPermissions = {} as IUserPermissions
    permissions?.forEach(permission => {
      const { name, value } = permission || {}
      allPermissions[name as keyof IUserPermissions] = value
    })
    return allPermissions
  }, [permissions])

  /**
   * Fetches permission data when Stytch user changes,
   * or resets permission state if there is no user.
   */
  useEffect(() => {
    getPermissionsData(user)
  }, [getPermissionsData, user])

  const userPermissionsState = useMemo(() => {
    return {
      userPermissions,
      hasLoadedPermissions,
    }
  }, [hasLoadedPermissions, userPermissions])

  return userPermissionsState
}
