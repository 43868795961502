import React, { memo, ComponentType } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { IPasswordFieldProps, PasswordField } from 'dpl/components'

type IBaseControllerProps = ControllerProps<ComponentType<IPasswordFieldProps>>

export interface IControlledPasswordFieldProps
  extends Pick<IBaseControllerProps, 'control' | 'name'> {
  defaultValue?: string
  PasswordFieldProps?: Omit<IPasswordFieldProps, 'value'>
}

export const ControlledPasswordField = memo<IControlledPasswordFieldProps>(
  ({ control, defaultValue, name, PasswordFieldProps = {} }) => {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onBlur, onChange, value: val }) => {
          const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
            const {
              target: { value = '' },
            } = e || {}
            const { onChange: onChangeProp } = PasswordFieldProps
            if (onChangeProp) {
              onChangeProp(e)
            }
            onChange(value)
          }

          const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur()
            const { onBlur: onBlurProp } = PasswordFieldProps
            if (onBlurProp) {
              onBlurProp(e)
            }
          }

          return (
            <PasswordField
              name={name}
              id={name}
              {...PasswordFieldProps}
              value={val}
              onBlur={blurHandler}
              onChange={changeHandler}
            />
          )
        }}
      />
    )
  }
)
