import React, { useCallback } from 'react'
import {
  GridColumnMenuItemProps as MuiGridColumnMenuItemProps,
  GridValidRowModel as MuiGridValidRowModel,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  MenuItem as MuiMenuItem,
} from '../../../../core'
import { TIconComponent } from '../../../../icons/types'

export interface IDataGridColumnBulkActionProps<TRowModel extends MuiGridValidRowModel> {
  /**
   * @description Map of currently selected rows, this will be passed to callback function on click
   * @example UserIcon
   */
  selectedRows: Map<TRowModel['id'], TRowModel>
  /**
   * @description Icon to be displayed on menu item
   * @example UserIcon
   */
  icon?: TIconComponent
  /**
   * @description Label to be displayed on menu item
   * @example 'Submit rows'
   */
  label: string
  /**
   * @description Function to be called when menu item is clicked.
   */
  onClickBulkAction: (selectedRows: Map<TRowModel['id'], TRowModel>) => void
}

export interface IDataGridColumnBulkActionMenuItemProps<TRowModel extends MuiGridValidRowModel>
  extends MuiGridColumnMenuItemProps,
    IDataGridColumnBulkActionProps<TRowModel> {}

export function DataGridColumnBulkActionMenuItem<TRowModel extends MuiGridValidRowModel>({
  icon: Icon,
  label,
  onClick,
  onClickBulkAction,
  selectedRows,
}: IDataGridColumnBulkActionMenuItemProps<TRowModel>) {
  const clickHandler = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      onClick(event)
      onClickBulkAction(selectedRows)
    },
    [onClick, onClickBulkAction, selectedRows]
  )

  return (
    <MuiMenuItem data-test='DataGridMenuItem' onClick={clickHandler}>
      {!!Icon && (
        <MuiListItemIcon>
          <Icon size='large' />
        </MuiListItemIcon>
      )}
      <MuiListItemText>{label}</MuiListItemText>
    </MuiMenuItem>
  )
}
