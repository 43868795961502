import React from 'react'
import Color from 'color'
import SupportModal from 'dpl/templates/SupportModal/SupportModal'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { Avatar } from '../../../components'
import { IconButton } from '../../../components/IconButton'
import { AppBar, makeStyles, Toolbar, Typography, Link, Tooltip } from '../../../core'
import { HelpIcon, MenuIcon } from '../../../icons'
import { useBreakpointContext } from '../../../providers/BreakpointProvider'
import { brandColors } from '../../../theme'
import { AppBarLink } from '../../AppLayout/AppBar/AppBarLink'
import { UserAppBarMenu, IUserAppBarMenuProps } from '../../AppLayout/AppBar/UserAppBarMenu'
import { INavProps } from '../../AppLayout/Nav'
import {
  ApplicationSelector,
  IApplicationSelectorProps,
} from '../ApplicationSelector/ApplicationSelector'

const useStyles = makeStyles(theme => ({
  toolbar: {
    padding: theme.spacing(0, 2, 0, 2.5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
    },
  },
  orgNameContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginRight: theme.spacing(4),
    color: brandColors.black,
  },
  links: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
    },
  },
  hamburger: {
    display: 'block',
    marginRight: theme.spacing(2),
    width: 24,
    height: 24,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    width: 32,
    height: 32,
    borderRadius: 4,
  },
  iconButton: {
    width: '20px',
    height: '20px',
    '&:hover, &:focus, &:active': {
      backgroundColor: `${Color(brandColors.black).fade(0.8)} !important`,
      borderColor: 'transparent !important',
      boxShadow: `0 0 0 4px ${Color(brandColors.black).fade(0.8)}`,
      borderRadius: '50%',
    },
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  navContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  linksContainer: {
    display: 'flex',
  },
}))

export interface IAdminAppBarProps {
  /**
   * Used to render user avatar and menu in AppBar
   */
  userInfo?: Pick<IUserAppBarMenuProps, 'name' | 'email' | 'userProfileHref'> & {
    /**
     * @default false
     */
    isAdmin?: boolean
  }
  /**
   * Callback that handles logging out from User Menu
   */
  onLogout: IUserAppBarMenuProps['onLogout']
  /**
   * Array of available apps
   */
  apps: IApplicationSelectorProps['apps']
  /**
   * Click action for hamburger menu
   * Used to toggle side nav
   */
  onMenuClick?: () => void
  /**
   * Navigation items that display in the sidebar
   * @default []
   */
  navLists?: INavProps['navLists']
  /**
   * URL of the logo of the organization
   */
  logoSrc: string
  /**
   * Name of the organization
   */
  orgName: string
  /**
   * Used to disable hamburger menu in top left
   * @default false
   */
  disableMenuButton?: boolean
  /**
   * Title of the nav bar
   */
  title: string
}

export function AdminAppBar({
  apps,
  disableMenuButton,
  logoSrc,
  navLists,
  onLogout,
  onMenuClick,
  orgName,
  title,
  userInfo,
}: IAdminAppBarProps) {
  const { isMobile } = useBreakpointContext()
  const { createToggleHandler, modalState } = useModalState({
    support: false,
  })
  const toggleSupportModal = createToggleHandler('support')

  const classes = useStyles()

  return (
    <AppBar position='static'>
      <div className={classes.toolbar}>
        <Toolbar disableGutters>
          {!disableMenuButton && (
            <Tooltip title='Show/hide menu' placement='left' color='dark'>
              <IconButton
                Icon={MenuIcon}
                size='large'
                onClick={onMenuClick}
                noPadding
                dataTest='appbar-menu-button'
                className={classes.hamburger}
              />
            </Tooltip>
          )}
          <div className={classes.navContainer}>
            <div className={classes.linksContainer}>
              <div className={classes.orgNameContainer}>
                <Avatar variant='rounded' name={orgName} size='small' src={logoSrc} hideTooltip />
                <Typography variant='subtitle1'>{title}</Typography>
              </div>

              <div className={classes.links}>
                {navLists
                  ?.flatMap(({ navListItems }) => navListItems)
                  .map(({ href, selected, title }) => (
                    <Link
                      sx={{
                        color: selected ? brandColors.black : undefined,
                      }}
                      href={href}
                      key={href}>
                      <Typography variant='button' sx={{ fontWeight: selected ? 700 : 400 }}>
                        {title}
                      </Typography>
                    </Link>
                  ))}
              </div>
            </div>
            <div className={classes.userInfo}>
              {!isMobile && <ApplicationSelector apps={apps} />}
              <AppBarLink
                tooltipText='Support'
                IconButtonProps={{
                  Icon: HelpIcon,
                  dataTest: 'appbar-support-button',
                  onClick: toggleSupportModal,
                  className: classes.iconButton,
                }}
                TooltipProps={{ placement: 'left', color: 'dark' }}
              />
              {userInfo && (
                <UserAppBarMenu
                  {...userInfo}
                  onLogout={onLogout}
                  TooltipProps={{
                    placement: 'left',
                    color: 'dark',
                  }}
                />
              )}
            </div>
          </div>
          <SupportModal open={modalState.support} onClose={toggleSupportModal} />
        </Toolbar>
      </div>
    </AppBar>
  )
}
