/* eslint-disable */

/**
 * Do not modify it directly.
 * @see https://devdocs.transfix.io/graphql
 *
 * If you need to update the definitions in this file,
 * Run `npm run generate:graphql:mp` or `npm run generate:graphql:mp:local`
 * in the terminal
 */

export type Maybe<T> = T | null | undefined
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string }
  /** Represents untyped JSON */
  JSON: { input: any; output: any }
}

/** Autogenerated input type of AssignTeamsUser */
export type IAssignTeamsUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  organizationTeamKeys?: InputMaybe<Array<Scalars['String']['input']>>
  organizationUserId: Scalars['ID']['input']
}

/** Autogenerated return type of AssignTeamsUser. */
export type IAssignTeamsUserPayload = {
  __typename?: 'AssignTeamsUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationUser?: Maybe<IOrganizationUser>
}

export type IAttachedFile = {
  __typename?: 'AttachedFile'
  contentType: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  filename: Scalars['String']['output']
  id: Scalars['ID']['output']
  url: Scalars['String']['output']
}

/** Autogenerated input type of CreateUser */
export type ICreateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateUser. */
export type ICreateUserPayload = {
  __typename?: 'CreateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  user?: Maybe<IUser>
}

/** Autogenerated input type of CreateUsers */
export type ICreateUsersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  organizationTeamKeys?: InputMaybe<Array<Scalars['String']['input']>>
  users: Array<IUserInput>
}

/** Autogenerated return type of CreateUsers. */
export type ICreateUsersPayload = {
  __typename?: 'CreateUsersPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  users?: Maybe<Array<IUser>>
}

/** Autogenerated input type of DeleteUser */
export type IDeleteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  organizationUserId: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteUser. */
export type IDeleteUserPayload = {
  __typename?: 'DeleteUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationUser?: Maybe<IOrganizationUser>
}

export type IError = {
  __typename?: 'Error'
  key: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type IMutation = {
  __typename?: 'Mutation'
  assignTeamsUser?: Maybe<IAssignTeamsUserPayload>
  createUser?: Maybe<ICreateUserPayload>
  createUsers?: Maybe<ICreateUsersPayload>
  deleteUser?: Maybe<IDeleteUserPayload>
  reactivateUser?: Maybe<IReactivateUserPayload>
  reinviteUser?: Maybe<IReinviteUserPayload>
  unassignTeamsUser?: Maybe<IUnassignTeamsUserPayload>
  updateOrganization?: Maybe<IUpdateOrganizationPayload>
  updateUser?: Maybe<IUpdateUserPayload>
  /**
   * Permits uploading a logo for the organization of the currently-logged-in user
   * @deprecated Use updateOrganization instead
   */
  uploadOrganizationLogo?: Maybe<IUploadLogoPayload>
}

export type IMutationAssignTeamsUserArgs = {
  input: IAssignTeamsUserInput
}

export type IMutationCreateUserArgs = {
  input: ICreateUserInput
}

export type IMutationCreateUsersArgs = {
  input: ICreateUsersInput
}

export type IMutationDeleteUserArgs = {
  input: IDeleteUserInput
}

export type IMutationReactivateUserArgs = {
  input: IReactivateUserInput
}

export type IMutationReinviteUserArgs = {
  input: IReinviteUserInput
}

export type IMutationUnassignTeamsUserArgs = {
  input: IUnassignTeamsUserInput
}

export type IMutationUpdateOrganizationArgs = {
  input: IUpdateOrganizationInput
}

export type IMutationUpdateUserArgs = {
  input: IUpdateUserInput
}

export type IMutationUploadOrganizationLogoArgs = {
  input: IUploadLogoInput
}

export type IOrganization = {
  __typename?: 'Organization'
  createdAt: Scalars['ISO8601DateTime']['output']
  fullLogo?: Maybe<IAttachedFile>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  navLogo?: Maybe<IAttachedFile>
  organizationTeams: Array<IOrganizationTeam>
  productModules: Array<IOrganizationProductModule>
  thumbLogo?: Maybe<IAttachedFile>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type IOrganizationProductModulesArgs = {
  statuses?: InputMaybe<Array<IOrganizationProductModuleStatus>>
}

export type IOrganizationProductModule = {
  __typename?: 'OrganizationProductModule'
  accessGrantedAt: Scalars['ISO8601DateTime']['output']
  accessGrantedByOrganizationUser: IOrganizationUser
  accessRevokedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  accessRevokedByOrganizationUser?: Maybe<IOrganizationUser>
  organizationAdmins: Array<IOrganizationUser>
  organizationUsers: Array<IOrganizationUser>
  productModule: IProductModules
  status: IOrganizationProductModuleStatus
}

export enum IOrganizationProductModuleStatus {
  active = 'active',
  inactive = 'inactive',
}

export type IOrganizationTeam = {
  __typename?: 'OrganizationTeam'
  id: Scalars['ID']['output']
  key: Scalars['String']['output']
  name: Scalars['String']['output']
  system: Scalars['Boolean']['output']
}

/** The connection type for OrganizationTeam. */
export type IOrganizationTeamConnection = {
  __typename?: 'OrganizationTeamConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IOrganizationTeamEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IOrganizationTeam>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IOrganizationTeamEdge = {
  __typename?: 'OrganizationTeamEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IOrganizationTeam>
}

export type IOrganizationUser = {
  __typename?: 'OrganizationUser'
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  externalId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  lastLogInAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  organization: IOrganization
  organizationTeams: Array<IOrganizationTeam>
  status: IOrganizationUserStatus
  statusChangedAt: Scalars['ISO8601DateTime']['output']
  user: IUser
}

/** The connection type for OrganizationUser. */
export type IOrganizationUserConnection = {
  __typename?: 'OrganizationUserConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IOrganizationUserEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IOrganizationUser>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IOrganizationUserEdge = {
  __typename?: 'OrganizationUserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IOrganizationUser>
}

export enum IOrganizationUserSortBy {
  firstName = 'firstName',
  lastLogInAt = 'lastLogInAt',
}

export enum IOrganizationUserStatus {
  active = 'active',
  deleted = 'deleted',
  invited = 'invited',
}

/** Information about pagination in a connection. */
export type IPageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type IPermission = {
  __typename?: 'Permission'
  description: Scalars['String']['output']
  name: Scalars['String']['output']
  value: Scalars['Boolean']['output']
}

export type IProductModule = {
  __typename?: 'ProductModule'
  key: IProductModules
  name: Scalars['String']['output']
}

export enum IProductModules {
  /** Autobidder */
  autobidder = 'autobidder',
  /** Contract Rates Tool */
  contract_rates_tool = 'contract_rates_tool',
  /** Contract RFP Workflow */
  contract_rfp = 'contract_rfp',
  /** Quotes Tool */
  quotes = 'quotes',
  /** Spot Rates Tool */
  rates_tool = 'rates_tool',
  /** Reporting */
  reporting = 'reporting',
  /** Routing Guide */
  routing_guide = 'routing_guide',
  /** Shipper Management */
  shipper_management = 'shipper_management',
}

export type IQuery = {
  __typename?: 'Query'
  currentSession: ISession
  organizationTeams: IOrganizationTeamConnection
  organizationUser?: Maybe<IOrganizationUser>
  productModules: Array<IProductModule>
  searchOrganizationUsers: IOrganizationUserConnection
  userVersions: IUserVersionConnection
}

export type IQueryOrganizationTeamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  keys?: InputMaybe<Array<Scalars['String']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type IQueryOrganizationUserArgs = {
  id: Scalars['ID']['input']
}

export type IQuerySearchOrganizationUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  ids?: InputMaybe<Array<Scalars['ID']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<IOrganizationUserSortBy>
  sortOrder?: InputMaybe<ISortOrder>
  statuses?: InputMaybe<Array<IOrganizationUserStatus>>
  teamKeys?: InputMaybe<Array<Scalars['String']['input']>>
}

export type IQueryUserVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  changedFields?: InputMaybe<Array<Scalars['String']['input']>>
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** Autogenerated input type of ReactivateUser */
export type IReactivateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  organizationUserId: Scalars['ID']['input']
}

/** Autogenerated return type of ReactivateUser. */
export type IReactivateUserPayload = {
  __typename?: 'ReactivateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationUser?: Maybe<IOrganizationUser>
}

/** Autogenerated input type of ReinviteUser */
export type IReinviteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  organizationUserId: Scalars['ID']['input']
}

/** Autogenerated return type of ReinviteUser. */
export type IReinviteUserPayload = {
  __typename?: 'ReinviteUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationUser?: Maybe<IOrganizationUser>
}

export type ISession = {
  __typename?: 'Session'
  organizationUser: IOrganizationUser
  permissions: Array<IPermission>
  user: IUser
}

export enum ISortOrder {
  asc = 'asc',
  desc = 'desc',
}

/** Autogenerated input type of UnassignTeamsUser */
export type IUnassignTeamsUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** List of product module keys that will be used to unassgin the related admins|users teams from organization user */
  organizationProductModuleKeys?: InputMaybe<Array<Scalars['String']['input']>>
  /** List of team keys to be unassigned from the organization user */
  organizationTeamKeys?: InputMaybe<Array<Scalars['String']['input']>>
  organizationUserId: Scalars['ID']['input']
}

/** Autogenerated return type of UnassignTeamsUser. */
export type IUnassignTeamsUserPayload = {
  __typename?: 'UnassignTeamsUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationUser?: Maybe<IOrganizationUser>
}

/** Autogenerated input type of UpdateOrganization */
export type IUpdateOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateOrganization. */
export type IUpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organization?: Maybe<IOrganization>
}

/** Autogenerated input type of UpdateUser */
export type IUpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  organizationUserId: Scalars['ID']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateUser. */
export type IUpdateUserPayload = {
  __typename?: 'UpdateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  user?: Maybe<IUser>
}

/** Autogenerated input type of UploadLogo */
export type IUploadLogoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Base64-encoded logo image */
  logo: Scalars['String']['input']
}

/** Autogenerated return type of UploadLogo. */
export type IUploadLogoPayload = {
  __typename?: 'UploadLogoPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organization?: Maybe<IOrganization>
}

export type IUser = {
  __typename?: 'User'
  emailAddress: Scalars['String']['output']
  firstName?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
}

export type IUserInput = {
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type IUserVersion = {
  __typename?: 'UserVersion'
  createdAt: Scalars['ISO8601DateTime']['output']
  emailAddress?: Maybe<Scalars['String']['output']>
  event: Scalars['String']['output']
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemId: Scalars['String']['output']
  itemType: Scalars['String']['output']
  lastName?: Maybe<Scalars['String']['output']>
  objectChanges?: Maybe<Scalars['JSON']['output']>
}

/** The connection type for UserVersion. */
export type IUserVersionConnection = {
  __typename?: 'UserVersionConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IUserVersionEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IUserVersion>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IUserVersionEdge = {
  __typename?: 'UserVersionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IUserVersion>
}
