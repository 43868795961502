import { makeStyles } from '../../../core/styles'
import { brandColors } from '../../../theme/colors'
import { calcRem } from '../../../theme/utils'

export const useDateRangePickerStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
  },
  triggerButton: {
    padding: theme.spacing(0.75, 1.5),
    borderRadius: theme.spacing(5),
    fontSize: calcRem(16),
    lineHeight: calcRem(21),
  },
  triggerButtonClose: {
    backgroundColor: brandColors.white,
    borderColor: brandColors.coolGray4,
    color: brandColors.coolGray8,
    '&:hover': {
      backgroundColor: brandColors.coolGray1,
      color: brandColors.coolGray8,
    },
    '&:focus': {
      backgroundColor: brandColors.white,
      outline: 'none',
      boxShadow: 'none',
      color: brandColors.skyBlue6,
      borderColor: brandColors.skyBlue6,
    },
  },
  triggerButtonOpen: {
    borderColor: brandColors.skyBlue7,
    color: brandColors.skyBlue7,
    backgroundColor: brandColors.skyBlue2,
  },
  optionsWrapper: {
    fontFamily: '"Nunito Sans"',
    backgroundColor: brandColors.white,
    border: `1px solid ${brandColors.coolGray4}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    minWidth: 180,
    position: 'absolute',
    zIndex: theme.zIndex.tooltip,
  },
}))
