import { Components } from '@mui/material/styles'
import { brandColors } from '../colors'

/**
 * @name Checkbox
 * @see https://mui.com/material-ui/api/checkbox/
 */
export const MuiCheckboxThemeOptions: Components['MuiCheckbox'] = {
  defaultProps: {
    disableRipple: false,
  },
  styleOverrides: {
    root: {
      color: brandColors.coolGray5,
      '&:focus': {
        boxShadow: `0 0 0 2px ${brandColors.skyBlue2}`,
        borderRadius: '2px',
      },
      borderRadius: '50%',
    },
    indeterminate: {
      color: brandColors.skyBlue6,
    },
    colorPrimary: {
      '&.Mui-disabled': {
        color: brandColors.coolGray4,
      },
      '&.Mui-checked': {
        '&:hover': {
          backgroundColor: brandColors.coolGray1,
        },
      },
    },
  },
}
