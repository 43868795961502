export const hourRange = Array.from(Array(24).keys())
const minuteRange = Array.from(Array(60).keys())

export const FORMATS = {
  defaultTimeZone: 'America/New_York',
  dayOfWeek: 'ddd', // Tue
  day: 'DD', // 11
  dayYear: 'DD, YYYY', // 11, 2020
  monthDay: 'MMM D', // Aug 11
  monthDayYear: 'MMM D, YYYY', // Aug 11, 2020
  monthDayTimeFormatShort12h: 'MMM DD, h:mma z', // Aug 15, 3:10pm EDT
  monthDayYearTimeFormat12hUppercase: 'MMM D, YYYY h:mmA z', // Aug 15, 2023 3:10PM EDT
  monthDayTimeFormatShort24h: 'MMM DD, HH:mm z', // Aug 15, 15:10 EDT
  monthDayYearTimeFormat24h: 'MMM DD, YYYY HH:mm z', // Aug 15, 2023 15:10 EDT
  monthYear: 'MMM YYYY', // Jan 2021
  monthYearShort: 'MMM ‘YY', // Apr ‘25
  dayFormat: 'ddd MM/DD', // Tue 08/11
  dayFormatShort: 'ddd, M/D', // Tue, 8/11
  dayFormatYear: 'ddd, M/D/YYYY', // Tue, 8/11/2023
  dayShort: 'M/D', // 8/11
  dayFormatFull: 'ddd, MMM D, YYYY', // Tue, Aug 11, 2020
  dayMonth: 'ddd, MMM D', // Tue, Aug 11
  dayMonthTime: 'ddd, MMM DD, h:mma', // Tue, Aug 11, 12:00am
  dayMonthTimeOnlyHours: 'ddd, MMM DD, ha', // Tue, Aug 11, 12am
  dayMonthTimeShort: 'ddd, M/D, h:mma', // Tue, 8/11, 12:00am
  dayMonthTimeShortOnlyHours: 'ddd, M/D, ha', // Tue, 8/11, 12am
  dayMonthTimeShortOnlyHoursWithTimezone: 'ddd, M/D, ha z', // Tue, 8/11, 12am EST
  dayMonthTimeNoAmPm: 'ddd, MMM DD, h:mm', // Tue, Aug 11, 3:00
  dayMonthTimeNoAmPmOnlyHours: 'ddd, MMM DD, h', // Tue, Aug 11, 12
  dayMonthTimeNoAmPmShort: 'ddd, M/D, h:mm', // Tue, 8/11, 12:00
  dayMonthTimeNoAmPmShortOnlyHours: 'ddd, M/D, h:mm', // Tue, 8/11, 12
  dayMonthMilitaryTime: 'ddd M/D [at] HH:mm', // Tue 8/11 at 12:00
  dayMonthYear: 'ddd, MMM DD YYYY', // Tue, Aug 11 2020, 12:00am
  dayMonthYearTime: 'ddd, MMM DD YYYY, h:mma', // Tue, Aug 11 2020, 12:00am
  dayMonthYear24Time: 'ddd MMM DD, YYYY HH:mm', // Tue, Aug 11 2020, 14:00
  dayMonthYear24TimeTZ: 'ddd MMM DD, YYYY HH:mm z', // Tue, Aug 11 2020, 14:00 EDT
  dayMonthYearTimeOnlyHours: 'ddd, MMM DD YYYY, ha', // Tue, Aug 11 2020, 12am
  dayMonthYearTimeShort: 'ddd, M/D/YY h:mma', // Tue, 8/11/2020, 12:00am
  etaFormat: 'ddd M/DD HH:mm z', // Tue 8/11 00:00 EDT
  etaFormatWithoutTZ: 'ddd M/DD HH:mm', // Tue 8/11 00:00
  timeFormat: 'HH:mm z', // 00:00 EDT
  timeFormatShort: 'H:mm z', // 0:00 EDT
  timeFormatShort12HNoTimeZone: 'h:mma', // 0:00am
  timeFormat12HNoTimeZone: 'hh:mmA', // 09:00am
  timeFormatShort12H: 'h:mma z', // 12:00am EDT
  militaryTimeFormat: 'HH:mm', // 00:00
  militaryTimeFormatShort: 'H:mm', // 0:00
  militaryTimeFormatShort12H: 'h:mma', // 12:00am
  militaryTimeFormatShort12HUpperCase: 'h:mmA', // 12:00AM
  militaryTimeFormatShortHour12H: 'ha', // 12am
  militaryTimeFormatShortHour12HUpperCase: 'hA', // 12AM
  monthDateFormat: 'MMMM D, YYYY', // August 11, 2020
  monthDateShort: 'MMMM D', // August 11
  dateFormat: 'MM/DD/YYYY', // 08/11/2020
  dateFormatShort: 'M/D/YY', // 8/11/20
  singleDateFormat: 'M/D/Y', // 1/2/2020
  yearFirst: 'YYYY-MM-DD', // 2020-08-11
  urlDateFormat: 'MM-DD-YYYY', // 08-11-2020
  readableFormat: 'MMM D, YYYY', // Aug 11, 2020
  readableDateTimeFormatWithYear: 'MMM D, YYYY, h:mm a', // Aug 11, 2020, 12:00 am
  readableDateTimeFormat: 'MMM D, h:mm a', // Aug 11, 12:00 am
  readableDateTimeShortHourFormatWithYear: 'MMM DD, YYYY, ha', // Aug 11, 2020, 12am
  readableDateTimeShortHourFormat: 'MMM DD, ha', // Aug 11, 12am
  datetime12HInputFormat: 'MM/DD/YYYY h:mma', // 08/11/2020 12:00am
  datetime12HInputFormatWithTimezone: 'MM/DD/YYYY h:mma z', // 08/11/2020 12:00am EST
  datetime12HshortHourInputFormat: 'MM/DD/YYYY ha', // 08/11/2020 12am
  datetimeInputFormat: 'MM/DD/YYYY HH:mm', // 08/11/2020 00:00
  datetimeFormat: 'MM/DD/YYYY HH:mm z', // 08/11/2020 00:00 EDT
  shortDatetimeFormat: 'MM/DD/YY HH:mm z', // 08/11/20 00:00 EDT
  datetimeShort: 'M/D HH:mm z', // 8/11 00:00 EST
  datetimeShort12H: 'M/D h:mma z', // 8/11 12:00am EST
  datetimeShortNoTimezone: 'MM/DD HH:mm', // 08/11 00:00
  datetime12HShortNoTimezone: 'M/D h:mma', // 8/1 1:00am
  datetime12HShortHourNoTimezone: 'M/D ha', // 8/1 1am
  datetimeFormatApi: 'YYYY-MM-DDTHH:mm', // 2020-08-11T00:00
  flatpickrDateTimeFormat: 'ddd MMM DD YYYY HH:mm:ss', // Tue Aug 11 2020 00:00:00
  iso8601Format: 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]', // 2020-08-11T00:00:00.000Z
  fleetPlannerApiFormat: 'YYYY-MM-DD[T]HH:mm:ss.SSS', // 2020-08-11T00:00:00.000
  driverLocation: 'YYYY-MM-DD HH:mm:ss ZZ', // 2020-08-11 00:00:00 -0400
  driverLastLocation: 'ddd MMM DD YYYY, HH:mm z', // Tue Aug 11 2020, 00:00 EDT
  weekdaysList: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
  fullWeekList: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
  hours: hourRange.map(hour => (hour < 10 ? `0${hour}` : String(hour))),
  minutes: minuteRange.map(minute => (minute < 10 ? `0${minute}` : String(minute))),
  stopAppointmentStandardFormat: 'ddd MM/DD, h:mma', // Tue 08/11, 9:00am
  stopAppointmentStandardFormatFull: 'ddd MM/DD/YYYY, h:mma', // Tue 08/11/2020, 9:00am
  stopAppointmentMilitaryFormat: 'ddd MM/DD, HH:mm', // Tue 08/11, 00:00
  stopAppointmentMilitaryFormatFull: 'ddd MM/DD/YYYY, HH:mm', // Tue 08/11, 00:00
  appointmentTagMilitaryFormat: 'ddd MM/DD HH:mm', // Tue 08/11 00:00
  appointmentTagMilitaryFormatFull: 'ddd MM/DD/YYYY HH:mm', // Tue 08/11 00:00
  fpSMSDate: 'ddd M/D, h:mma z', // Tue 8/11, 12:00am EDT
  fpSMSDateMilitary: 'ddd M/D, HH:mm z', // Tue 8/11, 12:00am EDT
  stopAppointmentSameDayEndAtStandardFormat: 'h:mma', // 9:00am
  iso8601FormatTimezone: 'YYYY-MM-DD[T]HH:mm:ssZ', // 2020-08-11T00:00:00-7:00
  ceTaskGroupFormat: 'ddd, MMM DD YYYY', // Tue, Jun 20 2022
  singleYearFirst: 'YYYY/MM/DD', // 2022/08/22
  dayMonthYearTimeOnlyHoursT24: 'ddd, MMM DD YYYY, H', // Tue, Aug 11 2020, 15
  dayMonthYearTimeT24: 'ddd, MMM DD YYYY, H:mm', // Tue, Aug 11 2020, 15:00
  dayMonthTimeNoAmPmOnlyHoursT24: 'ddd, MMM DD, H', // Tue, Aug 11, 15
  dayMonthTimeNoAmPmT24: 'ddd, MMM DD, H:mm', // Tue, Aug 11, 15:00
  dayMonthTimeOnlyHoursT24: 'ddd, MMM DD, H', // Tue, Aug 11, 15
  dayMonthTimeT24: 'ddd, MMM DD, H:mm', // Tue, Aug 11, 15:00
  dayMonthTimeNoAmPmShortOnlyHoursT24: 'ddd, M/D, H:mm', // Tue, 8/11, 15
  dayMonthTimeNoAmPmShortT24: 'ddd, M/D, H:mm', // Tue, 8/11, 15:00
  dayMonthTimeShortOnlyHoursT24: 'ddd, M/D, H', // Tue, 8/11, 15
  dayMonthTimeShortT24: 'ddd, M/D, H:mm', // Tue, 8/11, 15:00
  trailerArrivedAtFormat: 'ddd MM/DD/YYYY HH:mm z', // Tue 08/11/2020, 12:00am EDT
  monthDayNumberFormat: 'MM/DD', // 03/14
  monthDayYearAtTimeShort: 'M/D/YY [at] h:mmA', // 11/2/23 at 3:45pm
  monthDayYearAtTimeShort2: 'M/DD/YYYY - h:mmA', // 8/11/2020 - 12:00AM'
} as const

export type TFormat = typeof FORMATS
export type TFormatKey = keyof TFormat

/**
 * Returns typed property from FORMATS
 * @example getFormat('fpSMSdate') // 'ddd M/D, h:mma z'
 */
export function getFormat<K extends TFormatKey>(keyName: K): TFormat[K] {
  return FORMATS[keyName]
}

export default FORMATS
