import React from 'react'
import { GridColTypeDef as MuiGridColTypeDef } from '../../../core'
import { DataGridRadioCell } from '../cells/DataGridRadioCell'

export const getRadioGridColTypeDef = (label?: string): MuiGridColTypeDef => ({
  type: 'boolean',
  renderCell: ({ value, ...rest }) => {
    return <DataGridRadioCell label={label} checked={value} {...rest} />
  },
  renderEditCell: ({ value, ...rest }) => {
    return <DataGridRadioCell label={label} checked={value} {...rest} />
  },
  editable: true,
})
