import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'

/**
 * @name CardContent
 * @see https://mui.com/material-ui/api/card-content/
 */
export const MuiCardContentThemeOptions: Components['MuiCardContent'] = {
  styleOverrides: {
    root: {
      padding: BASE_THEME.spacing(3),
    },
  },
}
