import React, { ComponentType } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { UploadInputButton, IUploadButtonInputProps } from 'dpl/components/UploadInputButton'

type IBaseControllerProps = ControllerProps<ComponentType<IUploadButtonInputProps>>

export interface IControlledUploadInputButtonProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  UploadInputProps?: Omit<IUploadButtonInputProps, 'onUpload'>
}

function ControlledUploadInputButton({
  UploadInputProps,
  control,
  name,
}: IControlledUploadInputButtonProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange }) => {
        return <UploadInputButton onUpload={([file]) => onChange(file)} {...UploadInputProps} />
      }}
    />
  )
}

export default ControlledUploadInputButton
