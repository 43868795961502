import React, { useMemo } from 'react'
import { DISPLAY } from 'dpl/constants'
import { IReturnGetRows } from '../utils/grid'

type IDataGridProductAccessCellProps = {
  productModuleRoles: IReturnGetRows['productModuleRoles']
}

export function DataGridProductAccessCell({ productModuleRoles }: IDataGridProductAccessCellProps) {
  const formatted = useMemo(
    () =>
      productModuleRoles
        .filter(({ role }) => Boolean(role))
        .map(({ moduleName }) => moduleName)
        .sort()
        .join(', ') || DISPLAY.empty,
    [productModuleRoles]
  )

  return <div>{formatted}</div>
}

export default DataGridProductAccessCell
