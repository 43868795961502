import React from 'react'
import { makeStyles } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'
import { PasswordRequirementList, PasswordStrengthMeter } from './components'
import { TPasswordValidationState } from './types'
import { getPasswordStrengthLevel } from './utils/getPasswordStrengthLevel'

const usePasswordStrengthValidationStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    backgroundColor: brandColors.coolGray0,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
  },
}))

export interface IPasswordStrengthValidationProps {
  /**
   * Strength of password as determined by Stytch, from 0 to 4
   * @see https://stytch.com/docs/guides/passwords/strength-policy
   * @example 2
   */
  score?: number
  /**
   * Object where key represents the password requirement and value is if that requirement has been met.
   * @example {length: true, numbers: true, letters: false, special: true, score: false}
   */
  validationState: TPasswordValidationState
}

export function PasswordStrengthValidation({
  score,
  validationState,
}: IPasswordStrengthValidationProps) {
  const classes = usePasswordStrengthValidationStyles()

  const level = getPasswordStrengthLevel(validationState, score)

  return (
    <div className={classes.root} data-test='password-strength-validation'>
      <PasswordStrengthMeter level={level} />
      <PasswordRequirementList validationState={validationState} />
    </div>
  )
}
