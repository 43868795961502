import { capitalize } from 'dpl/utils/capitalize'

export const getValidInputMessage = (field: string) => {
  return `Please enter a valid ${field}.`
}

export const getCharacterLengthMessage = (field: string, length: number) => {
  return `${capitalize({ str: field })} must be at least ${length} characters.`
}

export const REQUIRED_MESSAGE = 'Please fill out this field.'
