import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation'
import { BASE_THEME } from '../baseTheme'
import { TYPOGRAPHY_THEME_OPTIONS } from '../typography'
import { calcRem } from '../utils'

export const MuiDateTimePickerToolbarThemeOptions: PickerComponents['MuiDateTimePickerToolbar'] = {
  defaultProps: {
    toolbarFormat: 'MMM DD',
  },
  styleOverrides: {
    root: {
      '& > span': {
        ...TYPOGRAPHY_THEME_OPTIONS.caption,
        textTransform: 'capitalize',
        fontSize: calcRem(12),
      },
      '& .MuiPickersToolbar-content': {
        fontWeight: 500,
        '& .MuiDateTimePickerToolbar-dateContainer': {
          '& span': {
            ...TYPOGRAPHY_THEME_OPTIONS.h3,
          },
        },
        '& .MuiDateTimePickerToolbar-timeContainer': {
          '& .MuiDateTimePickerToolbar-timeDigitsContainer': {
            alignItems: 'center',
          },
        },
      },
      '& .MuiDateTimePickerToolbar-content': {
        marginTop: BASE_THEME.spacing(1),
      },
    },
  },
}
