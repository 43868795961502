import React, { ReactNode, useMemo } from 'react'
import { DISPLAY } from '../../constants'
import { Box, Divider, Card, CardContent, Typography, makeStyles, AvatarGroup } from '../../core'
import { brandColors } from '../../theme'
import { Avatar } from '../Avatar'
import {
  IOrganizationModulesTableProps,
  OrganizationModulesTable,
} from './OrganizationModulesTable'

const MAX_SHOWN_ADMIN = 5

export interface IOrganizationAppCardProps extends IOrganizationModulesTableProps {
  /**
   * The name of the application.
   * @example 'Solutions Console'
   */
  appName: string
  /**
   * The URL of the application.
   * @example 'console.transfix.io'
   */
  appUrl: string
  /**
   * The total number of users in the application.
   * @example 25
   */
  totalUsers?: ReactNode
  /**
   * The total number of teams in the application.
   * @example 8
   */
  totalTeams?: ReactNode
  /**
   * A list of admin names for the application.
   * @default []
   * @example ['Alice Johnson', 'Bob Smith', 'Charlie Davis']
   */
  adminNames?: string[]
  /**
   * @default 'OrganizationAppCard'
   */
  dataTest?: string
}

const useOrganizationAppCardStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
}))

export function OrganizationAppCard({
  adminNames = [],
  appName,
  appUrl,
  modules,
  totalTeams,
  totalUsers,
  dataTest = 'OrganizationAppCard',
}: IOrganizationAppCardProps) {
  const classes = useOrganizationAppCardStyles()
  const shownAdmin = useMemo(() => adminNames.slice(0, MAX_SHOWN_ADMIN), [adminNames])
  const remainingAdminsCount = useMemo(() => adminNames.length - MAX_SHOWN_ADMIN, [adminNames])
  return (
    <Card className={classes.card} data-test={dataTest}>
      <CardContent>
        <Box display='flex' flexDirection='column' gap={3}>
          <div>
            <Typography variant='h4' data-test='app-name'>
              {appName}
            </Typography>
            <Typography variant='body1' color={brandColors.coolGray5} data-test='app-url'>
              {appUrl}
            </Typography>
          </div>

          <Box display='flex' gap={3}>
            {totalUsers ? (
              <div>
                <Typography variant='caption' color={brandColors.coolGray5}>
                  Total Members
                </Typography>
                <Typography variant='body1'>{totalUsers || DISPLAY.empty}</Typography>
              </div>
            ) : null}

            {totalTeams ? (
              <div>
                <Typography variant='caption' color={brandColors.coolGray5}>
                  Teams
                </Typography>
                <Typography variant='body1'>{totalTeams || DISPLAY.empty}</Typography>
              </div>
            ) : null}

            {adminNames.length ? (
              <div>
                <Typography variant='caption' color={brandColors.coolGray5}>
                  Admins
                </Typography>
                <Box display='flex' alignItems='center' gap={0.5} data-test='app-admin'>
                  <AvatarGroup max={MAX_SHOWN_ADMIN}>
                    {shownAdmin.map(admin => (
                      <Avatar key={admin} name={admin} size='xsmall' data-test='shown-app-admin' />
                    ))}
                  </AvatarGroup>
                  {adminNames.length <= 0 ? DISPLAY.empty : ''}

                  {remainingAdminsCount > 0 && (
                    <Typography variant='body2' component='div' data-test='remaining-app-admin'>
                      +{remainingAdminsCount}
                    </Typography>
                  )}
                </Box>
              </div>
            ) : null}
          </Box>

          <Divider />

          <OrganizationModulesTable modules={modules} />
        </Box>
      </CardContent>
    </Card>
  )
}
