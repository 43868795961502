import React from 'react'
import { Box, makeStyles, Typography } from '../../core'
import { brandColors } from '../../theme'
import { TSelectOpt } from './types'

export type SelectOptionRenderType =
  | 'default'
  | 'withDescription'
  | 'useWithWrappedDescription'
  | 'withDescriptionOnRight'

export type SelectOptionRenderFunc<T> = (option: T) => JSX.Element

const useWithWrappedDescriptionStyles = makeStyles(() => ({
  label: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,
  },
  wrappedDescription: {
    width: '40vw',
    minWidth: '300px',
    maxWidth: '600px',
    fontSize: 12,
    color: brandColors.coolGray5,
    whiteSpace: 'normal',
  },
}))

const withDescriptionOnRightStyles = makeStyles(() => ({
  rightDescription: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '16vw',
  },
}))
/**
 * Gets custom-rendered option for <MuiSelect />
 * @param {RenderOptionType} [type] - Optional param
 */
export function getSelectOptionRenderProps(
  type?: SelectOptionRenderType
): SelectOptionRenderFunc<TSelectOpt> {
  const RENDER_OPTIONS: Record<string, SelectOptionRenderFunc<TSelectOpt>> = {
    default: ({ label }) => <span>{label}</span>,
    /**
     * Render option with description, depending on value
     */
    withDescription: ({ description, label }) => (
      <Box>
        <Box>{label}</Box>
        <Box fontSize={12} color={brandColors.coolGray5}>
          {description}
        </Box>
      </Box>
    ),
    useWithWrappedDescription: ({ description, label }) => {
      const classes = useWithWrappedDescriptionStyles()
      return (
        <div>
          <Typography className={classes.label}>{label}</Typography>
          <Typography className={classes.wrappedDescription}>{description}</Typography>
        </div>
      )
    },
    withDescriptionOnRight: ({ description, label }) => {
      const classes = withDescriptionOnRightStyles()
      return (
        <div className={classes.rightDescription}>
          <div>{label}</div>
          <Typography variant='caption' color='textSecondary'>
            {description}
          </Typography>
        </div>
      )
    },
  }

  if (!type || !RENDER_OPTIONS[type]) return RENDER_OPTIONS.default

  return RENDER_OPTIONS[type]
}
