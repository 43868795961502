import React, { useMemo, useRef } from 'react'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import {
  createStyles,
  FormControlProps as MuiFormControlProps,
  FormHelperTextProps as MuiFormHelperTextProps,
  InputLabelProps as MuiInputLabelProps,
  ListSubheader,
  makeStyles,
  MenuItem,
  PaperClassKey,
  PopoverClassKey,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Theme,
} from '../../core'
import { brandColors } from '../../theme'
import { FormControl } from '../FormControl'
import SelectToggleIcon from './SelectToggleIcon'
import { getSelectOptionRenderProps, SelectOptionRenderType } from './getSelectOptionRenderProps'
import { TSelectOpt } from './types'

/**
 * Config values for calculating height of menu
 */
const CONFIG = {
  itemHeight: 33,
  itemPaddingTop: 8,
  maxItemsInDropdown: 7.5,
}

const MAX_HEIGHT = CONFIG.itemHeight * CONFIG.maxItemsInDropdown + CONFIG.itemPaddingTop

export interface ISelectOptGroup<T extends TSelectOpt = TSelectOpt> {
  label?: string
  options: T[]
}

export type ISelectProps<T extends TSelectOpt = TSelectOpt> = {
  options: T[] | ISelectOptGroup<T>[]
  /**
   * @default false
   */
  large?: boolean
  validationMessage?: string
  placeholder?: string
  FormControlProps?: MuiFormControlProps
  InputLabelProps?: MuiInputLabelProps
  FormHelperTextProps?: MuiFormHelperTextProps
  dataTest?: string
  renderOptionType?: SelectOptionRenderType
} & MuiSelectProps

/**
 * Returns a style object based on `large` argument
 * @note This enables `small/large` sizes since MUI doesn't have one for <Select />
 */
const getSizeStyles = (theme: Theme, large?: boolean) => {
  if (large) {
    return {
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(4),
      lineHeight: 1.9,
    }
  }
  return {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(4),
    lineHeight: 1.6,
  }
}

interface IMakeStylesProps extends Pick<ISelectProps<any>, 'large'> {}

type TClassKey = 'outlined'

const useSelectStyles = makeStyles(theme =>
  createStyles<TClassKey, IMakeStylesProps>({
    outlined: ({ large }) => getSizeStyles(theme, large),
  })
)

const useStyles = makeStyles(theme => ({
  empty: {
    color: brandColors.coolGray5,
  },
  optionSubheader: {
    padding: theme.spacing(0.5, 1.5),
  },
}))

const useMenuItemStyles = makeStyles(theme => ({
  menuItem: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}))

const usePopoverStyles = makeStyles(() =>
  createStyles<Extract<PopoverClassKey, 'root'>, IMakeStylesProps>({
    root: {
      // Removes extra top/bottom padding on this element
      '& .MuiList-root': {
        padding: 0,
      },
    },
  })
)

const usePaperStyles = makeStyles(theme =>
  createStyles<Extract<PaperClassKey, 'rounded'>, IMakeStylesProps>({
    rounded: {
      borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px !important`,
    },
  })
)

const isOptionGroup = (options: ISelectProps['options']): options is ISelectOptGroup[] => {
  const [firstOption] = options || []
  return !!(firstOption as ISelectOptGroup)?.options
}

const getOptions = (optionsProp: ISelectProps['options']): ISelectOptGroup[] => {
  if (isOptionGroup(optionsProp)) {
    return optionsProp
  }

  return [{ options: optionsProp }]
}

const getSelectedOption = (
  groups: ISelectOptGroup[],
  currentValue: unknown
): TSelectOpt | undefined => {
  for (const group of groups) {
    const selectedOption = group.options.find(({ value }) => value === currentValue)
    if (selectedOption) {
      return selectedOption
    }
  }
  return undefined
}

/**
 * @deprecated Use `SelectField` instead
 * @name Select
 * @see https://material-ui.com/api/select/
 * @example
 * <Select
 *   options={[
 *     { label: 'One', value: 1 },
 *     { label: 'Two', value: 2 }
 *   ]}
 * />
 */
export function Select<T extends TSelectOpt>({
  value: valueProp,
  label: labelProp,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  options: optionsProp = [],
  id: idProp = uuid(),
  disabled: disabledProp = false,
  required: requiredProp = false,
  error: errorProp = false,
  large = false,
  validationMessage = '',
  placeholder = 'Select one',
  FormControlProps = {},
  InputLabelProps = {},
  FormHelperTextProps = {},
  MenuProps = {},
  dataTest,
  renderOptionType = 'default',
  inputRef,
  renderValue: renderValueProp,
  ...SelectProps
}: ISelectProps<T>) {
  /**
   * Store ID in a ref so it only renders once on mount
   */
  const id = useRef(idProp)
  const classes = useStyles()
  const SelectClasses = useSelectStyles({ large })
  const PopoverClasses = usePopoverStyles({ large })
  const PaperClasses = usePaperStyles({ large })
  const MenuItemClasses = useMenuItemStyles()
  const RenderComponent = useMemo(
    () => getSelectOptionRenderProps(renderOptionType),
    [renderOptionType]
  )
  const optionsGroups = getOptions(optionsProp)
  const selectedOption = getSelectedOption(optionsGroups, valueProp)

  return (
    <FormControl
      required={requiredProp}
      disabled={disabledProp}
      error={errorProp}
      label={labelProp}
      FormHelperTextProps={FormHelperTextProps}
      validationMessage={validationMessage}
      InputLabelProps={{
        htmlFor: id.current,
        ...InputLabelProps,
      }}
      {...FormControlProps}>
      <MuiSelect
        data-test={dataTest}
        classes={SelectClasses}
        variant='outlined'
        labelId={`${idProp}-label`}
        id={id.current}
        value={valueProp}
        error={errorProp}
        displayEmpty
        renderValue={value => {
          return renderValueProp ? (
            renderValueProp(value)
          ) : (
            <span className={!selectedOption ? classes.empty : ''}>
              {selectedOption ? selectedOption.label : placeholder}
            </span>
          )
        }}
        onChange={onChangeProp}
        onBlur={onBlurProp}
        inputRef={inputRef}
        IconComponent={SelectToggleIcon}
        MenuProps={{
          PopoverClasses,
          variant: 'menu',
          transitionDuration: 0,
          PaperProps: {
            classes: PaperClasses,
            variant: 'elevation',
            elevation: 5,
            style: {
              maxHeight: MAX_HEIGHT,
            },
          },
          ...MenuProps,
        }}
        {...SelectProps}>
        {optionsGroups.map(({ label, options }) => [
          label && (
            <ListSubheader key={label} disableSticky className={classes.optionSubheader}>
              <strong>{label}</strong>
            </ListSubheader>
          ),
          options.map(props => (
            <MenuItem
              key={props.value}
              value={props.value}
              data-test={`select-option-${props.value}`}
              className={classNames(MenuItemClasses.menuItem, props.className)}>
              <RenderComponent {...props} />
            </MenuItem>
          )),
        ])}
      </MuiSelect>
    </FormControl>
  )
}

export default Select
