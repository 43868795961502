import React from 'react'
import classNames from 'classnames'
import truncate from 'lodash/truncate'
import { makeStyles } from '../../core/styles'
import CloseIcon from '../../icons/build/CloseIcon'
import { brandColors } from '../../theme/colors'

export interface IInputChipProps {
  /**
   * Displayed on the chip
   */
  label: string
  /**
   * Callback triggered when clicking on the 'x' icon of the chip
   * @note Can be used to remove the chip
   */
  onClose: () => void
  /**
   * Trigger callback when keys are pressed and chip is active
   */
  onKeyPress?: (e: React.KeyboardEvent) => void
  /**
   * Limit of the number of characters than can be displayed on
   * a chip before the string is truncated with '...'
   * @default 20
   */
  characterLimit?: number
  /**
   * Set different styles if error is true
   * @default false
   */
  error?: boolean
  /**
   * error message to display for any error chips, shows as alt text
   * on hover
   */
  errorMessage?: string
  dataTest?: string
}

export const useInputChipStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
    fontSize: 12,
    fontWeight: 600,
    height: theme.spacing(3),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  closeButton: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(1),
  },
  infoClose: {
    color: brandColors.skyBlue6,
    verticalAlign: 'middle',
    display: 'inline-block',
    marginBottom: theme.spacing(0.25),
    marginLeft: theme.spacing(0.5),
  },
  chip: {
    border: `1px solid ${brandColors.skyBlue6}`,
    borderRadius: theme.spacing(1.5),
    color: 'black',
    '&:hover': {
      backgroundColor: brandColors.skyBlue2,
    },
  },
  errorChip: {
    border: `1px solid ${brandColors.error}`,
    borderRadius: theme.spacing(1.5),
    color: 'black',
    backgroundColor: brandColors.errorLight,
  },
  errorClose: {
    color: brandColors.error,
    verticalAlign: 'middle',
    display: 'inline-block',
    marginBottom: theme.spacing(0.25),
    marginLeft: theme.spacing(0.5),
  },
}))

export function InputChip({
  characterLimit = 20,
  dataTest,
  error = false,
  errorMessage,
  label,
  onClose,
  onKeyPress,
}: IInputChipProps) {
  const classes = useInputChipStyles()

  const chipClassName = classNames(classes.root, {
    [classes.errorChip]: !!error,
    [classes.chip]: !error,
  })

  return (
    <div
      className={chipClassName}
      data-test={dataTest}
      title={errorMessage !== undefined ? `${label} ${errorMessage}` : label}>
      <div>
        {truncate(label, { length: characterLimit })}
        <span
          data-test='remove-chip-button'
          aria-label='Close chip'
          role='button'
          title='Close chip'
          tabIndex={0}
          className={classes.closeButton}
          onClick={onClose}
          onKeyDown={onKeyPress}>
          <CloseIcon className={error ? classes.errorClose : classes.infoClose} />
        </span>
      </div>
    </div>
  )
}

export default InputChip
