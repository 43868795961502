import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'

/**
 * @name OutlinedInput
 * @see https://mui.com/material-ui/api/outlined-input/
 */
export const MuiOutlinedInputThemeOptions: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      '& fieldset': {
        borderColor: brandColors.coolGray5,
      },
      '&.Mui-disabled': {
        '& .MuiInputAdornment-root': {
          color: brandColors.gray2,
        },
      },
    },
    input: {
      height: '1.375rem',
      minHeight: 'auto !important',
      paddingTop: BASE_THEME.spacing(2.125),
      paddingBottom: BASE_THEME.spacing(2.125),
    },
    inputSizeSmall: {
      paddingTop: BASE_THEME.spacing(1.625),
      paddingBottom: BASE_THEME.spacing(1.625),
    },
  },
}
