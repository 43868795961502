import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { Box, ButtonProps } from 'dpl/core'
import compact from 'lodash/compact'
import {
  AddNewMemberAutocomplete,
  IAutocompleteOrganizationUserProps,
} from './AddNewMemberAutocomplete'
import { EmailToInvite } from './EmailToInvite'
import { TAutocompleteAddNewMemberSchema } from './schema'

type IBaseControllerProps = ControllerProps<
  React.ComponentType<IAutocompleteOrganizationUserProps>,
  TAutocompleteAddNewMemberSchema
>

export interface IControlledAddNewMemberAutocompleteProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  AutocompleteOrganizationUserProps?: Omit<IAutocompleteOrganizationUserProps, 'onChange' | 'value'>
}

function getEmailsByString(input: string | undefined) {
  return compact(input?.split(/[,\s]+/))
}

export const ControlledAddNewMemberAutocomplete = memo<IControlledAddNewMemberAutocompleteProps>(
  ({ AutocompleteOrganizationUserProps, control, defaultValue, name }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          const emails: string[] = value
          const changeHandler: IAutocompleteOrganizationUserProps['onChange'] = (_, newValue) => {
            if (typeof newValue !== 'string') {
              const newEmails = [...emails, ...getEmailsByString(newValue?.user.emailAddress)]
              onChange([...new Set(newEmails)])
            }
          }

          const handleDelete: (targetEmail: string) => ButtonProps['onClick'] =
            targetEmail => () => {
              const newEmails = emails.filter(email => email !== targetEmail)

              onChange([...new Set(newEmails)])
            }

          const handleEnter: IAutocompleteOrganizationUserProps['onEnterPress'] = newValue => {
            const newEmails = [...emails, ...getEmailsByString(newValue)]
            onChange([...new Set(newEmails)])
          }

          return (
            <>
              <AddNewMemberAutocomplete
                {...AutocompleteOrganizationUserProps}
                onChange={changeHandler}
                onEnterPress={handleEnter}
                value={value}
              />
              <Box marginTop={2} maxHeight='354px' overflow='scroll'>
                {emails.map(email => (
                  <EmailToInvite email={email} onDelete={handleDelete(email)} />
                ))}
              </Box>
            </>
          )
        }}
      />
    )
  }
)
