import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { PopperProps } from '../../core'
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  MaterialUiPickersDate,
} from '../../core/pickers'
import { brandColors } from '../../theme'
import { ITextFieldProps } from '../TextField'
import { DatePickerActionBar } from './components/DatePickerActionBar'
import { DatePickerTextField } from './components/DatePickerTextField'
import { getParsedValue } from './utils'

type TMuiDatePickerProps = MuiDatePickerProps<MaterialUiPickersDate>

export interface IDatePickerProps
  extends Pick<
      ITextFieldProps,
      | 'clearable'
      | 'onClear'
      | 'onBlur'
      | 'dataTest'
      | 'disabled'
      | 'label'
      | 'required'
      | 'error'
      | 'fullWidth'
      | 'size'
      | 'placeholder'
      | 'helperText'
      | 'InputLabelProps'
    >,
    Pick<
      TMuiDatePickerProps,
      | 'format'
      | 'readOnly'
      | 'value'
      | 'views'
      | 'minDate'
      | 'maxDate'
      | 'onClose'
      | 'orientation'
      | 'timezone'
    > {
  onChange: (newVal: MaterialUiPickersDate) => void
}

/**
 * @name DatePicker
 * @see https://material-ui-pickers.dev/demo/datepicker
 */
export const DatePicker = forwardRef<HTMLDivElement, IDatePickerProps>(
  (
    {
      clearable,
      disabled,
      error,
      fullWidth,
      helperText,
      label,
      onClear,
      required,
      format = 'MM/DD/YYYY',
      onChange: onChangeProp,
      readOnly: readOnlyProp = false,
      value: valueProp,
      views: viewsProp,
      size,
      dataTest,
      minDate: minDateProp,
      maxDate: maxDateProp,
      placeholder = format,
      onClose: onCloseProp,
      orientation,
      timezone,
    }: IDatePickerProps,
    ref
  ) => {
    const changeHandler = useCallback(
      (newVal: MaterialUiPickersDate) => {
        if (typeof newVal === 'undefined' || typeof newVal === 'number') {
          // nothing
        } else {
          onChangeProp(moment(newVal))
        }
      },
      [onChangeProp]
    )

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const value = useMemo(() => getParsedValue(valueProp), [valueProp])
    const minDate = useMemo(() => getParsedValue(minDateProp), [minDateProp])
    const maxDate = useMemo(() => getParsedValue(maxDateProp), [maxDateProp])

    return (
      <MuiDatePicker
        disabled={disabled}
        open={isOpen}
        format={format}
        value={value || null}
        readOnly={readOnlyProp}
        views={viewsProp}
        ref={ref}
        maxDate={maxDate ?? undefined}
        minDate={minDate ?? undefined}
        onChange={changeHandler}
        orientation={orientation}
        timezone={timezone}
        onClose={() => {
          if (onCloseProp) onCloseProp()
          setIsOpen(false)
        }}
        slots={{
          textField: DatePickerTextField,
          actionBar: DatePickerActionBar,
        }}
        slotProps={{
          textField: {
            clearable,
            dataTest,
            disabled,
            label,
            required,
            error,
            onClick: () => setIsOpen(true),
            onClear,
            fullWidth,
            size,
            placeholder,
            helperText,
          } as ITextFieldProps,
          popper: {
            'data-test': `${dataTest}-popper`,
          } as Partial<PopperProps>,
          actionBar: ({ className, wrapperVariant }) => ({
            actions: wrapperVariant === 'desktop' ? [] : ['cancel', 'accept'],
            className,
          }),
          layout: {
            sx: {
              '& .MuiPickersLayout-contentWrapper:has(+ .MuiPickersLayout-actionBar)': {
                borderBottom: `1px solid ${brandColors.coolGray4}`,
              },
            },
          },
        }}
      />
    )
  }
)

export default DatePicker
