import * as yup from 'yup'

export const UploadInputSchema = yup.object({
  /**
   * File Blob
   */
  file: yup.string().optional(),
  /**
   * File Name
   */
  fileFileName: yup.string().optional(),
  /**
   * File Size
   */
  fileSize: yup.number().optional(),
  /**
   * Unique Identifier
   */
  uuid: yup.string().optional(),
})

export type TUploadInputSchema = yup.InferType<typeof UploadInputSchema>
