import React, { memo } from 'react'
import { Theme } from '../../core'
import { makeStyles } from '../../core/styles'
import { brandColors } from '../../theme/colors'

export interface ISpinnerProps {
  position?: 'static' | 'absolute' | 'relative'
}

const useSpinnerStyle = makeStyles<Theme>(theme => ({
  '@keyframes spinnerScale': {
    '0%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0,
    },
  },
  spinner: {
    animation: '$spinnerScale 1s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    backgroundBlendMode: 'screen',
    backgroundColor: brandColors.skyBlue7,
    borderRadius: '50%',
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    margin: '0 auto',
    '&.spinner-static': {
      position: 'static',
    },
    '&.spinner-absolute': {
      position: 'absolute',
      bottom: 'auto',
      left: '50%',
      right: 'auto',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
    },
    '&.spinner-relative': {
      position: 'absolute',
    },
  },
}))

export const Spinner = memo<ISpinnerProps>(({ position = 'absolute' }) => {
  const classes = useSpinnerStyle()
  return <div data-test='spinner' className={`${classes.spinner} spinner-${position}`} />
})

export default Spinner
