import React, { DetailedHTMLProps, HTMLAttributes } from 'react'
import { createStyles, makeStyles } from '../../core/styles'

type TBoxVariant =
  | 'Mt1'
  | 'Mt2'
  | 'Mt3'
  | 'Mt4'
  | 'Mb1'
  | 'Mb2'
  | 'Mb3'
  | 'Mb4'
  | 'Flex'
  | 'FlexItemsCenter'

const useBoxStyles = makeStyles(theme =>
  createStyles<TBoxVariant, {}>({
    Mt1: {
      marginTop: theme.spacing(1),
    },
    Mt2: {
      marginTop: theme.spacing(2),
    },
    Mt3: {
      marginTop: theme.spacing(3),
    },
    Mt4: {
      marginTop: theme.spacing(4),
    },
    Mb1: {
      marginBottom: theme.spacing(1),
    },
    Mb2: {
      marginBottom: theme.spacing(2),
    },
    Mb3: {
      marginBottom: theme.spacing(3),
    },
    Mb4: {
      marginBottom: theme.spacing(4),
    },
    Flex: {
      display: 'flex',
    },
    FlexItemsCenter: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

export interface IBoxProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  /**
   * @example 'mt1
   */
  variant: TBoxVariant
}

function Box({ children, variant, ...props }: IBoxProps) {
  const classes = useBoxStyles()
  return (
    <div className={classes[variant]} {...props}>
      {children}
    </div>
  )
}

function Mt1(props: Omit<IBoxProps, 'variant'>) {
  return <Box variant='Mt1' {...props} />
}
function Mt2(props: Omit<IBoxProps, 'variant'>) {
  return <Box variant='Mt2' {...props} />
}
function Mt3(props: Omit<IBoxProps, 'variant'>) {
  return <Box variant='Mt3' {...props} />
}
function Mt4(props: Omit<IBoxProps, 'variant'>) {
  return <Box variant='Mt4' {...props} />
}
function Mb1(props: Omit<IBoxProps, 'variant'>) {
  return <Box variant='Mb1' {...props} />
}
function Mb2(props: Omit<IBoxProps, 'variant'>) {
  return <Box variant='Mb2' {...props} />
}
function Mb3(props: Omit<IBoxProps, 'variant'>) {
  return <Box variant='Mb3' {...props} />
}
function Mb4(props: Omit<IBoxProps, 'variant'>) {
  return <Box variant='Mb4' {...props} />
}
function Flex(props: Omit<IBoxProps, 'variant'>) {
  return <Box variant='Flex' {...props} />
}
function FlexItemsCenter(props: Omit<IBoxProps, 'variant'>) {
  return <Box variant='FlexItemsCenter' {...props} />
}

export const Boxes = {
  Mt1,
  Mt2,
  Mt3,
  Mt4,
  Mb1,
  Mb2,
  Mb3,
  Mb4,
  Flex,
  FlexItemsCenter,
}
