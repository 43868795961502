import React, { memo, useRef, useLayoutEffect, useMemo, useCallback } from 'react'
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr'
import format from 'date-fns/format'
import { Hook } from 'flatpickr/dist/types/options'
import get from 'lodash/get'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { FLATPICKR_DEFAULTS } from '../../constants/flatpickr'
import { useFlatpickrCalendarStyles } from '../../hooks/useFlatpickrCalendarStyles'
import { CalendarIcon } from '../../icons'
import { TextField, ITextFieldProps } from '../TextField'
import 'flatpickr/dist/themes/airbnb.css'

export interface IRangeDatePickerProps
  extends Pick<
    ITextFieldProps,
    'dataTest' | 'required' | 'label' | 'helperText' | 'error' | 'placeholder' | 'disabled' | 'size'
  > {
  id?: string
  dates?: Date[]
  onDateChange: (dates: Date[]) => void
  maxDate?: Date
  minDate?: Date
  dateFormat?: string
  dateSeparator?: string
}

const formatDate = (date: Date, dateFormat: string) => {
  if (date.toString() === 'Invalid Date') return ''

  return moment(date).format(dateFormat)
}

/**
 * Adds a test id to every day element in the calendar.
 * @see https://flatpickr.js.org/events/
 */
const dayCreateHandler: Hook = (_dObj, _dStr, _fp, dayElem) => {
  const date = new Date(dayElem.dateObj.getTime())
  const formattedDate = format(date, 'yyyy-MM-dd')

  dayElem.setAttribute('data-test', `day-${formattedDate}`)
}

export const RangeDatePicker = memo<IRangeDatePickerProps>(
  ({
    // TextFieldProps
    disabled = false,
    dataTest,
    error,
    required,
    helperText,
    size,
    placeholder = 'Pick a date range',
    label = '',
    // Flatpickr props
    id,
    dates = [],
    onDateChange,
    maxDate,
    minDate,
    dateFormat = 'M/D/Y',
    // added props
    dateSeparator = ' to ',
  }) => {
    const flatpickrClasses = useFlatpickrCalendarStyles()
    const calendarRef = useRef(null)

    const clearHandler = useCallback(() => {
      onDateChange([])
    }, [onDateChange])

    const visualValueParser = useCallback(
      value => {
        const [startDate = '', endDate = ''] = value.map((date: Date) =>
          formatDate(date, dateFormat)
        )

        if (!(startDate || endDate)) return ''

        return `${startDate}${dateSeparator}${endDate}`
      },
      [dateFormat, dateSeparator]
    )

    const flatpickrOptions = useMemo<DateTimePickerProps['options']>(() => {
      return {
        ...FLATPICKR_DEFAULTS,
        maxDate,
        minDate,
        dateFormat,
        mode: 'range',
        showMonths: 2,
      }
    }, [dateFormat, maxDate, minDate])

    const flatpickrId = useMemo(() => {
      return id ?? uuid()
    }, [id])

    useLayoutEffect(() => {
      const flatpickrContainer = get(calendarRef.current, 'flatpickr.calendarContainer') as
        | HTMLElement
        | undefined

      if (!flatpickrContainer) return

      flatpickrContainer.classList.add(flatpickrClasses.flatpickrCalendar)
    }, [flatpickrClasses.flatpickrCalendar])

    return (
      <Flatpickr
        id={flatpickrId}
        ref={calendarRef}
        value={dates}
        options={flatpickrOptions}
        onChange={onDateChange}
        onDayCreate={dayCreateHandler}
        render={({ value }, ref) => (
          <TextField
            ref={ref}
            dataTest={dataTest}
            value={visualValueParser(value)}
            required={required}
            label={label}
            helperText={helperText}
            error={error}
            placeholder={placeholder}
            disabled={disabled}
            size={size}
            onClear={clearHandler}
            InputProps={{
              startAdornment: <CalendarIcon size='large' />,
            }}
          />
        )}
      />
    )
  }
)

export default RangeDatePicker
