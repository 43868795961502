import React, { useMemo } from 'react'
import DefaultCompany from 'dpl/assets/DefaultCompany.svg'
import { Spinner } from 'dpl/components/Spinner'
import { Link } from 'dpl/core'
import compact from 'lodash/compact'
import { Home } from './Home'
import { useOrganizationProfileQuery } from './graphql/OrganizationProfile'

export function HomeContainer() {
  const { data, loading } = useOrganizationProfileQuery()
  const { currentSession, productModules: productModulesList, searchOrganizationUsers } = data || {}
  const { nodes, totalCount: totalUsers = 0 } = searchOrganizationUsers || {}
  const { organizationUser } = currentSession || {}
  const { organization } = organizationUser || {}
  const { createdAt, fullLogo, name = '', productModules } = organization || {}
  const members = compact(nodes)
  const orgAdmins = members.filter(({ organizationTeams }) =>
    organizationTeams.some(({ key }) => key === 'organization_admins')
  )
  const orgAdminNames = orgAdmins.map(({ user }) => `${user.firstName} ${user.lastName}`)
  const apps = useMemo(
    () => [
      {
        appName: 'Solutions Console',
        appUrl: 'console.transfix.io',
        totalUsers: <Link href='/members'>{totalUsers}</Link>,
        adminNames: orgAdminNames,
        modules: compact(productModules).map(
          ({ accessGrantedAt, organizationAdmins, organizationUsers, productModule }) => ({
            moduleName: productModulesList?.find(({ key }) => key === productModule)?.name || '',
            activatedAt: accessGrantedAt,
            totalMembers: organizationUsers.length,
            adminNames: organizationAdmins.map(
              ({ user: { emailAddress, firstName, lastName } }) =>
                `${firstName || ''} ${lastName || ''}`.trim() || emailAddress
            ),
          })
        ),
      },
    ],
    [members, orgAdminNames, productModules]
  )

  if (loading) {
    return <Spinner />
  }

  return (
    <Home
      orgActiveDate={createdAt || ''}
      orgName={name}
      orgImgSrc={fullLogo?.url || DefaultCompany}
      orgAdminNames={orgAdminNames}
      isAdmin
      apps={apps}
      status='active'
    />
  )
}
