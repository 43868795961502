import React, { MouseEvent, memo, useCallback } from 'react'
import { Button, IButtonProps } from '../../../../core'
import { useBreakpoint } from '../../../../hooks'

export interface IModalAction {
  /**
   * The callback function to be executed when the button is clicked
   * @returns
   */
  action?: () => any
  /**
   * The data-test attribute
   */
  dataTest?: string
  /**
   * If true, the button will be disabled
   * @default false
   */
  disabled?: IButtonProps['disabled']
  /**
   * The label to display on the button
   */
  label: string
  /**
   * If true, the button will be in a loading state
   * @default false
   */
  loading?: boolean
  /**
   * The props to pass to the button
   */
  ButtonProps?: Omit<IButtonProps, 'disabled'> & { download?: string }
  /**
   * @deprecated Use `color` and `variant` in `ButtonProps` instead
   */
  type?: 'confirm' | 'dismiss' | 'delete'
}

const BUTTON_COLOR_VARIANT: Record<
  'confirm' | 'dismiss' | 'delete',
  Pick<IButtonProps, 'color' | 'variant'>
> = {
  confirm: {
    color: 'primary',
    variant: 'contained',
  },
  dismiss: {
    color: 'secondary',
    variant: 'text',
  },
  delete: {
    color: 'error',
    variant: 'contained',
  },
}

export const ModalAction = memo<IModalAction>(
  ({ ButtonProps, action, dataTest = 'ModalAction', disabled, label, type }) => {
    const { isMobile } = useBreakpoint()

    const handleButtonClick: IButtonProps['onClick'] = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        if (action) {
          action()
        }
      },
      [action]
    )

    const buttonColorVariant = type ? BUTTON_COLOR_VARIANT[type] : {}

    return (
      <Button
        fullWidth={isMobile}
        disabled={disabled}
        {...buttonColorVariant}
        value={type}
        onClick={handleButtonClick}
        data-test={dataTest}
        {...ButtonProps}>
        {label}
      </Button>
    )
  }
)
