import React, { memo } from 'react'
import { List } from 'dpl/core'
import { TPasswordRequirementKeys, REQUIREMENT_KEYS, TPasswordValidationState } from '../../types'
import { PasswordRequirement } from './PasswordRequirement'

const PASSWORD_REQUIREMENTS: Record<TPasswordRequirementKeys, string> = {
  length: 'At least 8 characters',
  letters: 'Uppercase and lowercase letters',
  numbers: 'At least one number',
  special: 'At least one special character',
  score: 'Not a common password',
}

interface IPasswordRequirementListProps {
  /**
   * Object where key represents the password requirement and value is if that requirement has been met.
   * @example {length: true, numbers: true, letters: false, special: true, score: false}
   */
  validationState: TPasswordValidationState
}

function PasswordRequirementListComponent({ validationState }: IPasswordRequirementListProps) {
  return (
    <List disablePadding dense>
      {REQUIREMENT_KEYS.map(key => (
        <PasswordRequirement
          dataTest={key}
          key={key}
          label={PASSWORD_REQUIREMENTS[key]}
          isPassing={validationState[key]}
        />
      ))}
    </List>
  )
}

export const PasswordRequirementList = memo<IPasswordRequirementListProps>(
  PasswordRequirementListComponent
)
