import React, { memo, MouseEventHandler, ChangeEventHandler } from 'react'
import { Button, makeStyles, Theme } from '../../../core'
import { buttonColorsByVariant, INLINE_MESSAGE_VARIANTS } from '../constants'

const useStyles = makeStyles<Theme, { type: INLINE_MESSAGE_VARIANTS }>(() => ({
  ctaLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  ctaLabel: {
    display: 'inline',

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },

    '& input': {
      display: 'none',
    },
  },
}))

export interface IActionProps {
  label?: string
  url?: string
  action?: MouseEventHandler<HTMLButtonElement>
  uploadAction?: ChangeEventHandler<HTMLInputElement>
  type?: INLINE_MESSAGE_VARIANTS
}

export const Action = memo<IActionProps>(
  ({ action, label = '', type = INLINE_MESSAGE_VARIANTS.INFO, uploadAction, url }) => {
    const classes = useStyles({ type })
    const buttonColor = buttonColorsByVariant[type]

    if (url) {
      return (
        <a
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          data-test='inline-message-cta-link'
          className={classes.ctaLink}>
          <strong>{label}</strong>
        </a>
      )
    }

    if (uploadAction) {
      return (
        <label
          htmlFor='fileUpload'
          className={classes.ctaLabel}
          data-test='inline-message-cta-label'>
          <input id='fileUpload' type='file' onChange={uploadAction} />
          <strong>{label}</strong>
        </label>
      )
    }

    if (action) {
      return (
        <Button
          variant='text'
          color={buttonColor}
          sx={{ padding: 0 }}
          onClick={action}
          data-test='inline-message-cta-button'>
          {label}
        </Button>
      )
    }

    return null
  }
)
