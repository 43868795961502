import * as yup from 'yup'

export function isValidEmail(email: string) {
  try {
    yup.string().email().required().validateSync(email)
    return true
  } catch (err) {
    return false
  }
}
