import React, { memo, useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import Facet, { IFacetProps } from '../Facet'

export interface IFacetRadioProps<T = string> extends Omit<IFacetProps, 'id' | 'onClick'> {
  /**
   * @example 'dry_van'
   */
  value: T
  onClick: (value: T) => void
  id?: string
}

/**
 * @deprecated Use the `ToggleButton` component instead.
 */
const FacetRadio = memo<IFacetRadioProps>(({ onClick, value, id = uuid(), ...props }) => {
  const buttonClickHandler = useCallback(() => {
    onClick(value)
  }, [onClick, value])

  return <Facet id={id} {...props} onClick={buttonClickHandler} />
})

export default FacetRadio
