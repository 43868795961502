import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'

export const MuiCardHeaderThemeOptions: Components['MuiCardHeader'] = {
  defaultProps: {
    titleTypographyProps: {
      variant: 'h4',
    },
    subheaderTypographyProps: {
      variant: 'body2',
      marginTop: BASE_THEME.spacing(1),
    },
  },
  styleOverrides: {
    root: {
      padding: BASE_THEME.spacing(3, 3, 0, 3),

      '& .MuiCardHeader-subheader': {
        marginTop: BASE_THEME.spacing(1),
      },
    },
    action: {
      margin: 0,
    },
  },
}
