import React, { ReactNode, ReactElement, memo, useState, useMemo, useCallback } from 'react'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import { makeStyles, Typography } from '../../core'
import EditIcon from '../../icons/build/EditIcon'
import { boxShadowLevels, brandColors } from '../../theme'
import { IconButton } from '../IconButton'

type TCardSize = 'large' | 'small' | 'mini'

export interface ICardProps {
  /**
   * @default 'large'
   */
  size?: TCardSize
  collapsedByDefault?: boolean
  collapsible?: boolean
  transparent?: boolean
  dataTest?: string
  maxHeight?: boolean
  title?: ReactNode
  callToAction?: ReactElement
  noPadding?: boolean
  className?: string
  dragging?: boolean
  info?: ReactElement
  /**
   * When set to true, the Card will expand vertically to fill it's container.
   * This is useful when rendering two cards side by side, where the content
   * may differ in height, but you want to maintain a consistent height across
   * both cards.
   *
   * @default false
   */
  fillContainer?: boolean
  editable?: boolean
  onEdit?: () => void
  children?: ReactNode
}

const useCardStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    boxShadow: 'none',
    padding: theme.spacing(2),
    backgroundColor: brandColors.white,
    border: `1px solid ${brandColors.coolGray3}`,
    color: brandColors.coolGray8,
    overflow: 'visible',
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      boxShadow: boxShadowLevels.low,
    },
  },
  dragging: {
    boxShadow: boxShadowLevels.pop,
  },
  fillContainer: {
    height: '100%',
    '& .card-content': { height: '100%' },
  },
  noPadding: {
    padding: 0,
  },
  transparent: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    border: 0,
    margin: 0,
  },
  small: {
    padding: theme.spacing(1),
  },
  mini: {
    padding: theme.spacing(0.5),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    lineHeight: 1,
    position: 'relative',
    zIndex: 2,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  title: {
    color: brandColors.coolGray8,
    fontWeight: theme.typography.fontWeightRegular,
    margin: 0,
    padding: 0,
    '&.pointer': {
      cursor: 'pointer',
    },
  },
  headerButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  ctas: {
    flex: 1,
    textAlign: 'right',
  },
  content: {
    '&.card-max-height': {
      maxHeight: 400,
    },
  },
}))

/**
 * @deprecated Use Card from dpl/core instead
 */
export const Card = memo<ICardProps>(
  ({
    collapsedByDefault = false,
    size = 'large',
    collapsible = false,
    transparent = false,
    callToAction = undefined,
    maxHeight = false,
    title = '',
    dataTest = uuid(),
    noPadding = false,
    className,
    children,
    dragging,
    info = undefined,
    fillContainer = false,
    editable = false,
    onEdit = () => {},
  }) => {
    const classes = useCardStyles()
    const [open, setOpen] = useState(!collapsedByDefault)

    const toggleOpen = useCallback(
      (e: any) => {
        e.preventDefault()

        if (!collapsible) return false
        const oKey = e.which === 111
        const cKey = e.which === 99
        if (oKey || cKey) setOpen(!!cKey)
        return setOpen(!open)
      },
      [collapsible, open]
    )

    const titleClass = classNames(classes.title, {
      pointer: collapsible,
    })

    const cardClass = classNames(
      'card',
      classes.card,
      {
        [classes.noPadding]: noPadding,
        [classes.fillContainer]: fillContainer,
        [classes.mini]: size === 'mini',
        [classes.small]: size === 'small',
        [classes.transparent]: transparent,
        [classes.dragging]: dragging,
      },
      className
    )

    const showContent = useMemo(() => {
      if (!collapsible && children) {
        return true
      }
      if (collapsible && open) {
        return true
      }
      return false
    }, [children, collapsible, open])

    return (
      <section className={cardClass} data-test={dataTest}>
        {title && (
          <header className={classes.header}>
            <div className={classes.titleWrapper}>
              <Typography
                className={titleClass}
                component='div'
                role='button'
                tabIndex={0}
                onClick={toggleOpen}>
                <div>{title}</div>
              </Typography>
              {info && <div>{info}</div>}
            </div>
            <div className={classes.headerButtons}>
              {callToAction && <div className={classes.ctas}>{callToAction}</div>}
              {editable && (
                <IconButton Icon={EditIcon} color='secondary' size='small' onClick={onEdit} />
              )}
            </div>
          </header>
        )}
        {showContent && (
          <article className={`${classes.content} ${maxHeight ? 'card-max-height' : ''}`}>
            {children}
          </article>
        )}
      </section>
    )
  }
)

export default Card
