import React, { useContext, createContext, ReactNode } from 'react'
import { useBreakpoint } from '../hooks/useBreakpoint'

const BreakpointContext = createContext<Partial<ReturnType<typeof useBreakpoint>>>({
  isMobile: false,
  isTablet: false,
  isDesktop: false,
})

export function BreakpointProvider({ children }: { children: ReactNode }) {
  const state = useBreakpoint()
  return <BreakpointContext.Provider value={state}>{children}</BreakpointContext.Provider>
}

export const useBreakpointContext = () => useContext(BreakpointContext)
