import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'

export const MuiIconButtonThemeOptions: Components['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: false,
  },
  styleOverrides: {
    root: {
      borderRadius: `${BASE_THEME.spacing(0.5)} !important`,
      color: 'inherit',

      '&.Mui-disabled:not(.MuiAutocomplete-popupIndicator)': {
        background: brandColors.gray1,
      },
    },

    // Colors
    colorPrimary: {
      '&:hover, &:active, &:focus': {
        color: BASE_THEME.palette.primary.contrastText,
        backgroundColor: BASE_THEME.palette.primary.dark,
        borderColor: BASE_THEME.palette.primary.dark,
      },
    },
    colorSecondary: {
      '&:hover, &:active, &:focus': {
        color: BASE_THEME.palette.secondary.contrastText,
        backgroundColor: BASE_THEME.palette.secondary.dark,
        borderColor: BASE_THEME.palette.secondary.dark,
      },
    },
    colorError: {
      '&:hover, &:active, &:focus': {
        color: BASE_THEME.palette.error.contrastText,
        backgroundColor: BASE_THEME.palette.error.dark,
        borderColor: BASE_THEME.palette.error.dark,
      },
    },
    colorInfo: {
      '&:hover, &:active, &:focus': {
        color: BASE_THEME.palette.info.contrastText,
        backgroundColor: BASE_THEME.palette.info.dark,
        borderColor: BASE_THEME.palette.info.dark,
      },
    },
    colorSuccess: {
      '&:hover, &:active, &:focus': {
        color: BASE_THEME.palette.success.contrastText,
        backgroundColor: BASE_THEME.palette.success.dark,
        borderColor: BASE_THEME.palette.success.dark,
      },
    },
    colorWarning: {
      '&:hover, &:active, &:focus': {
        color: BASE_THEME.palette.warning.contrastText,
        backgroundColor: BASE_THEME.palette.warning.dark,
        borderColor: BASE_THEME.palette.warning.dark,
      },
    },
  },
}
