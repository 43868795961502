import React from 'react'
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types'
import classNames from 'classnames'
import { makeStyles } from '../../../core'
import { Theme } from '../../../core/styles'
import { brandColors } from '../../../theme'

type TOrientation = 'horizontal' | 'vertical'

const edgeToOrientationMap: Record<Edge, TOrientation> = {
  top: 'horizontal',
  bottom: 'horizontal',
  left: 'vertical',
  right: 'vertical',
}

const strokeSize = 2
const terminalSize = 8
const offsetToAlignTerminalWithLine = (strokeSize - terminalSize) / 2
const lineOffset = `calc(-0.5 * ${strokeSize}px)`

const useDropIndicatorStyles = makeStyles<
  Theme,
  { edge: Edge; orientation: TOrientation; offset: number }
>(() => ({
  root: {
    position: 'absolute',
    height: `${strokeSize}px`,
    zIndex: 10,
    pointerEvents: 'none',
    backgroundColor: brandColors.activeBlue,
    '&::before': {
      content: '" "',
      height: `${terminalSize}px`,
      width: `${terminalSize}px`,
      borderColor: brandColors.activeBlue,
      borderStyle: 'solid',
      borderWidth: `${strokeSize}px`,
      position: 'absolute',
      borderRadius: '9999px',
    },
  },
  edgeTop: {
    transform: ({ edge, offset }) => (edge === 'top' ? `translateY(-${offset}px)` : undefined),
    top: ({ edge }) => (edge === 'top' ? `${lineOffset}` : undefined),
    '&::before': {
      top: ({ edge }) => (edge === 'top' ? `${offsetToAlignTerminalWithLine}px` : undefined),
    },
  },
  edgeBottom: {
    transform: ({ edge, offset }) => (edge === 'bottom' ? `translateY(${offset}px)` : undefined),
    bottom: ({ edge }) => (edge === 'bottom' ? `${lineOffset}` : undefined),
    '&::before': {
      bottom: ({ edge }) => (edge === 'bottom' ? `${offsetToAlignTerminalWithLine}px` : undefined),
    },
  },
  edgeLeft: {
    transform: ({ edge, offset }) => (edge === 'left' ? `translateX(${offset}px)` : undefined),
    left: ({ edge }) => (edge === 'left' ? `${lineOffset}` : undefined),
    '&::before': {
      left: ({ edge }) =>
        edge === 'left' ? `${offsetToAlignTerminalWithLine}px` : `-${terminalSize}px`,
    },
  },
  edgeRight: {
    transform: ({ edge, offset }) => (edge === 'right' ? `translateX(${offset}px)` : undefined),
    right: ({ edge }) => (edge === 'right' ? `${lineOffset}` : undefined),
    '&::before': {
      right: ({ edge }) => (edge === 'right' ? `${offsetToAlignTerminalWithLine}px` : undefined),
    },
  },
  orientation: ({ orientation }) =>
    orientation === 'horizontal'
      ? {
          height: `${strokeSize}px`,
          left: `${terminalSize / 2}px`,
          right: 0,
          '&::before': {
            left: `-${terminalSize}px`,
          },
        }
      : {
          width: `${strokeSize}px`,
          top: `${terminalSize / 2}px`,
          bottom: 0,
          '&::before': {
            top: `-${terminalSize}px`,
          },
        },
}))

/**
 * Shows a line to indicate the new placement of the draggable item
 * Orientation and placement of line is based off edge
 */
export function DropIndicator({ edge, offset }: { edge: Edge; offset?: number }) {
  const orientation = edgeToOrientationMap[edge]
  const classes = useDropIndicatorStyles({ edge, orientation, offset: offset ?? 0 })
  const classnames = classNames(classes.root, classes.orientation, {
    [classes.edgeTop]: edge === 'top',
    [classes.edgeBottom]: edge === 'bottom',
    [classes.edgeRight]: edge === 'right',
    [classes.edgeLeft]: edge === 'left',
  })

  return <div className={classnames} />
}
