import React, { ReactNode, useEffect } from 'react'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element'
import { Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { makeStyles, Theme } from '../../core'
import { DataGridFilters, IDataGridFilters, IDataGridFiltersProps } from '../DataGrid'
import { KanbanColumn } from './components'
import { LoadingTrigger } from './components/LoadingTrigger'
import { IBaseItem, IColumn } from './types'

export interface IKanbanBoardProps<
  TItem extends IBaseItem,
  TFiltersModel extends IDataGridFilters = {},
  TSortValue extends string = '',
> extends Pick<
    IDataGridFiltersProps<TFiltersModel, TSortValue>,
    'sortOptions' | 'defaultFilterValues' | 'defaultSortValue' | 'onFilterOrSortChange'
  > {
  /**
   * @default 'KanbanBoard'
   */
  dataTest?: string
  /**
   * Class name to apply to the root element
   */
  className?: string
  /**
   * Columns to display in the kanban board
   */
  columns: IColumn<TItem>[]
  /**
   * Component to render each item
   */
  ItemComponent: React.ComponentType<{ item: TItem }>
  /**
   * Function to determine if an item can be dropped in a column
   */
  canDrop: (item: TItem, columnId: string) => boolean
  /**
   * Function to call when an item is moved
   */
  onMoveItem: (
    item: TItem,
    columnId: string,
    { closestEdge, itemId }: { closestEdge: Edge | null; itemId: string }
  ) => void
  /**
   * Ref to the scrollable element
   */
  scrollRef: HTMLElement | null
  /**
   * Estimated size of an item
   */
  estimateSize: number
  /**
   * Function to call when more items should be loaded
   */
  onLoadMore?: () => void
  /**
   * Whether there are more items to load
   */
  hasMore?: boolean
  /**
   * Whether the board is loading more items
   */
  loading?: boolean
  /**
   * Label to display for adding a new item
   */
  addNewItemLabel?: ReactNode
  /**
   * Function to call when user clicks on new item button
   */
  onAddNewItem?: () => void
  /**
   * Function to determine if the add new item button should be shown
   */
  showAddNewItem?: () => boolean
  /**
   * @description The configuration for the filters that will appear above the DataGrid
   */
  filtersConfig?: IDataGridFiltersProps<TFiltersModel, TSortValue>['filtersConfig']
  /**
   * Limits the number of filters visible in DataGridFiltersHeader
   * @default {6}
   */
  visibleFiltersLimit?: number
}

const useStyles = makeStyles<Theme, { totalColumns: number }>(theme => ({
  kanban: {
    display: 'grid',
    gridTemplateColumns: ({ totalColumns }) => `repeat(${totalColumns}, minmax(0, 1fr))`,
    gap: theme.spacing(1.5),
    minWidth: 1024,
  },
  filters: {
    marginBottom: theme.spacing(3),
  },
}))

export function KanbanBoard<TItem extends IBaseItem, TFilters extends IDataGridFilters>({
  ItemComponent,
  addNewItemLabel,
  canDrop,
  className,
  columns,
  dataTest = 'KanbanBoard',
  defaultFilterValues,
  defaultSortValue,
  estimateSize,
  filtersConfig,
  hasMore = false,
  loading,
  onAddNewItem,
  onFilterOrSortChange,
  onLoadMore,
  onMoveItem,
  scrollRef,
  showAddNewItem,
  sortOptions,
  visibleFiltersLimit,
}: IKanbanBoardProps<TItem, TFilters>) {
  const classes = useStyles({ totalColumns: columns?.length })
  const shouldShowAddNewItem =
    showAddNewItem && Boolean(addNewItemLabel) && Boolean(onAddNewItem) && showAddNewItem()

  useEffect(() => {
    if (!scrollRef) return undefined

    return combine(
      monitorForElements({
        onDrag({ location, source }) {
          const [destination] = location.current.dropTargets
          const item = source.data.item as TItem

          document.body.style.cursor = 'not-allowed'

          if (!destination) return

          if (
            canDrop(item, destination.data.columnId as string) ||
            destination.data.columnId === source.data.columnId
          ) {
            document.body.style.cursor = 'grabbing'
          }
        },
        onDrop({ location, source }) {
          const [destination] = location.current.dropTargets

          document.body.style.cursor = 'default'

          if (!destination) return

          const item = source.data.item as TItem

          const closestEdge = extractClosestEdge(destination.data)
          onMoveItem(item, destination.data.columnId as string, {
            closestEdge,
            itemId: destination.data.id as string,
          })
        },
      }),
      autoScrollForElements({
        canScroll: () => true,
        element: scrollRef,
      })
    )
  }, [onMoveItem, canDrop])

  return (
    <div className={className}>
      {filtersConfig && (
        <div className={classes.filters}>
          <DataGridFilters
            filtersConfig={filtersConfig}
            defaultFilterValues={defaultFilterValues}
            sortOptions={sortOptions}
            defaultSortValue={defaultSortValue}
            onFilterOrSortChange={onFilterOrSortChange}
            dataTest={`${dataTest}-filters`}
            visibleFiltersLimit={visibleFiltersLimit}
          />
        </div>
      )}
      <div className={classes.kanban}>
        {columns.map(({ id, items, title, totalCount }) => {
          return (
            <KanbanColumn
              canDrop={canDrop}
              id={id}
              key={id}
              items={items}
              title={title}
              estimateSize={estimateSize}
              scrollRef={scrollRef}
              ItemComponent={ItemComponent}
              totalCount={totalCount}
              loading={loading}
              showAddNewItem={shouldShowAddNewItem}
              addNewItemLabel={addNewItemLabel}
              onAddNewItem={onAddNewItem}
            />
          )
        })}
        {hasMore && onLoadMore && <LoadingTrigger onLoadMore={onLoadMore} />}
      </div>
    </div>
  )
}
