import React, { useMemo } from 'react'
import { Box, makeStyles } from '../../../../core'
import AdminIcon from '../../../../icons/build/AdminIcon'
import EmailIcon from '../../../../icons/build/EmailIcon'
import { Tooltip } from '../../../Tooltip'
import { DataGridAvatarCell, IDataGridAvatarCellProps } from '../DataGridAvatarCell'

export interface IDataGridUserCellProps {
  email: string
  name: Nullable<string>
  imgSrc: Nullable<string>
  isOrgAdmin: Nullable<boolean>
  status: 'active' | 'invited' | 'deleted'
}

const useDataGridUserCellStyles = makeStyles(() => ({
  deletedUser: {
    opacity: 0.6,
  },
}))

export function DataGridUserCell({
  email,
  imgSrc,
  isOrgAdmin,
  name,
  status,
}: IDataGridUserCellProps) {
  const classes = useDataGridUserCellStyles()

  const isDeleted = useMemo(() => status === 'deleted', [status])
  const isInvited = useMemo(() => status === 'invited', [status])

  const label = useMemo(() => {
    if (isInvited) {
      return email
    }
    return name || email
  }, [email, isInvited, name])

  const bottomCaption = useMemo(() => {
    if (isInvited) {
      return null
    }
    return email
  }, [email, isInvited])

  const AvatarProps: IDataGridAvatarCellProps['AvatarProps'] = useMemo(() => {
    if (isInvited) {
      return {
        name: '',
        hideTooltip: true,
        Icon: <EmailIcon size='large' color='coolGray5' />,
      }
    }
    return {
      name: name || '',
      hideTooltip: true,
      src: imgSrc || '',
    }
  }, [imgSrc, isInvited, name])

  return (
    <DataGridAvatarCell
      AvatarProps={AvatarProps}
      bottomCaption={bottomCaption}
      className={isDeleted ? classes.deletedUser : ''}>
      <Box display='flex' alignItems='center' gap={0.5}>
        {label}
        {isOrgAdmin && (
          <Tooltip title='Organization Admin'>
            <div>
              <AdminIcon color='coolGray5' />
            </div>
          </Tooltip>
        )}
      </Box>
    </DataGridAvatarCell>
  )
}

export default DataGridUserCell
