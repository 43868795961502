import React, { useCallback, useMemo } from 'react'
import RadioGroup, { IRadioGroupProps } from '../../../../../RadioGroup'
import { IDataGridFilterOption, IDataGridFilterProps } from '../types'

export interface IDataGridRadioGroupProps extends IDataGridFilterProps<string> {
  /**
   * The options to display in the radio group
   */
  options: IDataGridFilterOption[]
  /**
   * @default 'DataGridRadioGroup'
   */
  dataTest?: string
}

export function DataGridRadioGroup({
  dataTest = 'DataGridRadioGroup',
  name,
  onChange,
  options: optionsProp,
  value,
}: IDataGridRadioGroupProps) {
  const options: IDataGridRadioGroupProps['options'] = useMemo(() => {
    return [{ label: 'Any', value: '', dataTest: `${dataTest}-any` }, ...optionsProp]
  }, [dataTest, optionsProp])

  const optionChangeHandler: IRadioGroupProps['onOptionChange'] = useCallback(
    (event, checked) => {
      const { target } = event || {}
      const { value } = target || {}
      if (checked) {
        onChange({ name, value })
      }
    },
    [name, onChange]
  )

  return (
    <RadioGroup
      dataTest={dataTest}
      options={options}
      checkedValue={value}
      onOptionChange={optionChangeHandler}
      stacked
      label=''
    />
  )
}
