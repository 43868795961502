import React, { memo, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { Badge, BadgeProps as MuiBadgeProps, makeStyles } from '../../core'
import { brandColors } from '../../theme/colors'

const useNotificationBadgeStyles = makeStyles({
  root: {
    position: 'relative',

    '& .MuiBadge-badge': {
      minWidth: 0,
      borderRadius: 2,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 4,
      paddingLeft: 4,
    },

    '& .MuiBadge-colorError': {
      fontSize: 12,
      backgroundColor: brandColors.skyBlue1,
      color: brandColors.skyBlue7,
    },
  },
})

type TOmittedProp = 'badgeContent' | 'color'

export interface INotificationBadgeProps extends Omit<MuiBadgeProps, TOmittedProp> {
  /**
   * Number of new/unread notifications
   * @default 0
   */
  notificationCount?: number
  /**
   * @example <BellIcon size='large' />
   */
}

const NotificationBadge = memo<PropsWithChildren<INotificationBadgeProps>>(
  ({ children, className: classNameProp, notificationCount = 0, ...rest }) => {
    const classes = useNotificationBadgeStyles()
    const className = classNames(classes.root, classNameProp)
    return (
      <Badge
        color='error'
        data-test='NotificationBadge'
        badgeContent={notificationCount}
        className={className}
        {...rest}>
        {children}
      </Badge>
    )
  }
)

export default NotificationBadge
