import React, { memo } from 'react'
import { createStyles, makeStyles } from '../../core/styles'
import { brandColors, TBrandColor } from '../../theme/colors'
import CurrencyInput, { ICurrencyInputProps } from '../CurrencyInput'

interface IUseCurrencyInputTransparentStylesProps {
  invalid: boolean | undefined
  validationMessageColor: string
}

const useCurrencyInputTransparentStyles = makeStyles(theme =>
  createStyles<string, IUseCurrencyInputTransparentStylesProps>({
    input: {
      '& input': {
        borderWidth: '0px !important',
        fontSize: theme.spacing(4.25),
        fontWeight: theme.typography.fontWeightBold,
        height: 'auto !important',
        textAlign: 'center',
        boxShadow: 'none !important',
      },
      '& fieldset': {
        borderWidth: '0px !important',
        boxShadow: 'none !important',
      },
    },
    helperText: {
      display: 'block',
      textAlign: 'center',
      padding: 0,
      marginLeft: 0,
      fontSize: '14px',
      color: ({ invalid, validationMessageColor }) =>
        invalid ? brandColors.error : validationMessageColor,
    },
    validationMessage: {
      fontSize: '14px',
      textAlign: 'center',
    },
  })
)

export interface ICurrencyInputTransparentProps extends ICurrencyInputProps {
  validationMessageColor?: TBrandColor
}

export const CurrencyInputTransparent = memo<ICurrencyInputTransparentProps>(
  ({
    dataTest = 'currency-input-transparent',
    error,
    placeholder = '$0',
    validationMessageColor: validationColorKey = 'coolGray8',
    ...props
  }) => {
    const validationMessageColor = brandColors[validationColorKey] // hex value

    const classes = useCurrencyInputTransparentStyles({ invalid: error, validationMessageColor })

    return (
      <CurrencyInput
        dataTest={dataTest}
        placeholder={placeholder}
        error={error}
        className={classes.input}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
        {...props}
      />
    )
  }
)

export default CurrencyInputTransparent
