import React, { useCallback, useState } from 'react'
import { ITabsProps, Tabs } from 'dpl/components'
import { makeStyles } from 'dpl/core'
import { PageTemplate } from 'dpl/templates'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { AddNewMemberDrawer } from './components/AddNewMemberDrawer'
import { DirectoryTab } from './components/DirectoryTab'
import { TABS } from './constants'

export interface IMembersProps {
  name: string
}

const useMembersStyles = makeStyles(theme => ({
  tabContent: {
    padding: theme.spacing(1, 2, 2),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2.25, 3, 3),
    },
  },
}))

export function Members({ name }: IMembersProps) {
  const classes = useMembersStyles()
  const [currentTab, setCurrentTab] = useState<string>('directory')
  const { createToggleHandler, modalState } = useModalState({
    addMember: false,
  })
  const toggleAddMemberPanel = createToggleHandler('addMember')

  const changeTabHandler: ITabsProps['onChange'] = useCallback(newActiveTabId => {
    setCurrentTab(newActiveTabId)
  }, [])

  return (
    <PageTemplate
      dataTest='route-members'
      pageTitle={name}
      PageBodyTitleProps={{
        actions: [
          {
            label: 'Add Member',
            buttonProps: {
              variant: 'contained',
            },
            onClick: toggleAddMemberPanel,
          },
        ],
      }}
      noPadding>
      <Tabs
        activeTabId={currentTab}
        onChange={changeTabHandler}
        tabList={TABS}
        dataTest='members-tabs'>
        <div className={classes.tabContent}>
          {currentTab === 'directory' && <DirectoryTab />}
          {/* TODO: This will be enable in the future {currentTab === 'activity' && <ActivityTab />} */}
        </div>
      </Tabs>
      <AddNewMemberDrawer open={modalState.addMember} onClose={toggleAddMemberPanel} />
    </PageTemplate>
  )
}
