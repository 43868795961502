import { TIME_INPUT_FORMAT } from '../types'

/**
 * Validates a time based on whether the numbers are within a range
 * for selected timeFormat
 */
export const isValidTime = (
  timeFormat: TIME_INPUT_FORMAT,
  hours: string,
  minutes?: string
): boolean => {
  const maxHourValue = timeFormat === TIME_INPUT_FORMAT.TWELVE_HR_SHORT ? 12 : 23
  const minHourValue = timeFormat === TIME_INPUT_FORMAT.TWELVE_HR_SHORT ? 1 : 0
  const hoursNumber = parseInt(hours, 10)
  const minutesNumber = parseInt(minutes || '0', 10)
  return hoursNumber >= minHourValue && hoursNumber <= maxHourValue && minutesNumber <= 59
}
