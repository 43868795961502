import { Components } from '@mui/material/styles'

/**
 * @name ListItemIcon
 * @see https://mui.com/material-ui/api/list-item-icon/
 */
export const MuiListItemIconThemeOptions: Components['MuiListItemIcon'] = {
  styleOverrides: {
    root: {
      minWidth: 'auto',
    },
  },
}
