import React, {
  RefObject,
  useMemo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react'
import classNames from 'classnames'
import flatpickr from 'flatpickr'
import { FLATPICKR_DEFAULTS } from '../../../constants/flatpickr'
import { Button } from '../../../core'
import { makeStyles } from '../../../core/styles'
import { useFlatpickrCalendarStyles } from '../../../hooks/useFlatpickrCalendarStyles'
import RightArrowIcon from '../../../icons/build/RightArrowIcon'
import { brandColors } from '../../../theme/colors'
import { useDateRangePickerOptionBaseStyles } from '../hooks/useDateRangePickerOptionBaseStyles'
import { IDateRangePickerOption } from '../types'
import { getSelectedOptionDate } from '../utils/getSelectedOptionDate'
import 'flatpickr/dist/themes/airbnb.css'

function useCalendarPosition(buttonRef: RefObject<HTMLElement>) {
  const [position, setPosition] = useState<'left' | 'right'>('right')

  useLayoutEffect(() => {
    function updatePosition() {
      if (!buttonRef.current) return

      const buttonRect = buttonRef.current.getBoundingClientRect()
      const viewportWidth = window.innerWidth
      const spaceOnRight = viewportWidth - buttonRect.right
      const spaceOnLeft = buttonRect.left
      const calendarWidth = 600
      const spaceLeftPosition = spaceOnLeft >= calendarWidth ? 'left' : 'right'

      setPosition(spaceOnRight >= calendarWidth ? 'right' : spaceLeftPosition)
    }

    updatePosition()
    window.addEventListener('resize', updatePosition)

    return () => {
      window.removeEventListener('resize', updatePosition)
    }
  }, [buttonRef])

  return position
}

const useDateRangeCalendarOptionStyles = makeStyles(theme => ({
  calendarWrapper: {
    position: 'relative',
    '& .flatpickr-wrapper': {
      display: 'block',
    },
  },
  rightPositioned: {
    '& .flatpickr-calendar': {
      left: 'calc(100% + 16px)!important',
      right: 'auto !important',
      top: '-31px !important',
      transform: 'translateX(-10px)',
      padding: theme.spacing(2),
      border: `1px solid ${brandColors.coolGray4}`,
      position: 'absolute !important',

      '&::before, &::after': {
        display: 'none',
      },
    },
  },
  leftPositioned: {
    '& .flatpickr-calendar': {
      right: 'calc(100% + 16px) !important',
      left: 'auto !important',
      top: '-31px !important',
      transform: 'translateX(10px)',
      padding: theme.spacing(2),
      border: `1px solid ${brandColors.coolGray4}`,
      position: 'absolute !important',

      '&::before, &::after': {
        display: 'none',
      },
    },
  },
  hiddenInput: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    border: 0,
  },
}))

interface IDateRangeCalendarOptionProps {
  parentRef: RefObject<HTMLDivElement>
  option: IDateRangePickerOption
  selectedOption: IDateRangePickerOption | null
  onSelectOption: (option: IDateRangePickerOption) => void
}

export function DateRangeCalendarOption({
  onSelectOption,
  option,
  parentRef,
  selectedOption,
}: IDateRangeCalendarOptionProps) {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const flatpickrInstance = useRef<any>(null)
  const [isOpen, setIsOpen] = useState(false)
  const position = useCalendarPosition(buttonRef)
  const flatpickrClasses = useFlatpickrCalendarStyles()
  const calendarClasses = useDateRangeCalendarOptionStyles()
  const baseClasses = useDateRangePickerOptionBaseStyles()

  const buttonClasses = classNames(baseClasses.button, {
    [baseClasses.activeButton]: option.key === selectedOption?.key,
  })

  const wrapperClasses = classNames(
    calendarClasses.calendarWrapper,
    position === 'right' ? calendarClasses.rightPositioned : calendarClasses.leftPositioned
  )

  const selectedOptionDate = useMemo(
    () => getSelectedOptionDate(selectedOption?.dateRange),
    [selectedOption?.dateRange]
  )

  const calendarDateSelectHandler = useCallback(
    ([startAt, endAt]: Date[]) => {
      if (!startAt || !endAt) {
        return
      }

      const dateRange = {
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
      }

      const currentOption = {
        ...option,
        dateRange,
      }

      onSelectOption(currentOption)
    },
    [onSelectOption, option]
  )

  // Initialize flatpickr
  useEffect(() => {
    if (!inputRef.current || flatpickrInstance.current) {
      return undefined
    }

    const fp = flatpickr(inputRef.current, {
      ...FLATPICKR_DEFAULTS,
      mode: 'range',
      showMonths: 2,
      appendTo: parentRef?.current || document.body,
      static: true,
      animate: false,
      onChange: calendarDateSelectHandler,
      onOpen() {
        setIsOpen(true)
      },
      onClose() {
        setIsOpen(false)
      },
      onReady(_, __, fp) {
        if (fp.calendarContainer) {
          fp.calendarContainer.classList.add(flatpickrClasses.flatpickrCalendar)
        }
      },
    })

    flatpickrInstance.current = fp

    // Cleanup on unmount
    return () => {
      if (flatpickrInstance.current) {
        flatpickrInstance.current.destroy()
        flatpickrInstance.current = null
      }
    }
  }, [calendarDateSelectHandler, parentRef])

  // Update the value when selectedOptionDate changes
  useEffect(() => {
    if (flatpickrInstance.current && selectedOptionDate) {
      flatpickrInstance.current.setDate(selectedOptionDate, false)
    }
  }, [selectedOptionDate])

  const handleButtonClick = useCallback(() => {
    if (!flatpickrInstance.current) return

    if (isOpen) {
      flatpickrInstance.current.close()
    } else {
      flatpickrInstance.current.open()
    }
  }, [isOpen])

  return (
    <div className={wrapperClasses} data-test='DateRangeCalendarOption'>
      <Button
        variant='text'
        className={buttonClasses}
        ref={buttonRef}
        onClick={handleButtonClick}
        endIcon={<RightArrowIcon size='medium' />}>
        {option.label}
      </Button>
      <input ref={inputRef} type='text' className={calendarClasses.hiddenInput} data-input />
    </div>
  )
}
