import React from 'react'
import { IDataGridProps, TDataGridFiltersConfig } from 'dpl/components/DataGrid'
import { DataGridUserCell } from 'dpl/components/DataGrid/cells'
import { IModuleRole } from 'dpl/templates'
import startCase from 'lodash/fp/startCase'
import { getProductModuleRoles } from 'user-profile'
import { TEAM_KEYS } from '../../../../../../../constants/roleKeys'
import {
  IOrganizationUser,
  IProductModules,
  IOrganizationTeam,
  IProductModule,
} from '../../../../../../../types/graphqlTypes'
import { FILTERS_OPTIONS_CONFIG } from '../../../constants'
import { IFilters } from '../../../types'
import DataGridActionCell from '../components/DataGridActionsCell'
import DataGridLastSeenCell from '../components/DataGridLastSeenCell'
import DataGridProductAccessCell from '../components/DataGridProductAccessCell'
import DataGridStatusCell from '../components/DataGridStatusCell'

type ProductModulesStatus = {
  [key in IProductModules]?: string
}

export interface IReturnGetRows extends ProductModulesStatus {
  id: string
  user: { name: Nullable<string>; email: string; status: string }
  status: {
    state: string
    statusChangedAt: string
  }
  lastSeen: Nullable<string>
  productModuleRoles: IModuleRole[]
  actions: {
    userId: string
    deletedAt: Nullable<string>
    isInvited: boolean
  }
}

export const getColumns = (): IDataGridProps<IReturnGetRows>['columns'] => [
  { field: 'id', headerName: 'ID' },
  {
    field: 'user',
    headerName: 'Member name',
    width: 250,
    minWidth: 250,
    flex: 1,
    renderCell: ({ value: props }) => <DataGridUserCell {...props} />,
  },
  {
    field: 'productModuleRoles',
    headerName: 'Product Access',
    minWidth: 250,
    flex: 1,
    renderCell: ({ value }) => <DataGridProductAccessCell productModuleRoles={value} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 170,
    flex: 1,
    renderCell: ({ value: props }) => <DataGridStatusCell {...props} />,
  },
  {
    field: 'lastSeen',
    headerName: 'Last Seen',
    flex: 1,
    minWidth: 120,
    renderCell: ({ value }) => <DataGridLastSeenCell date={value} />,
  },
  {
    field: 'actions',
    width: 58,
    renderHeader: () => null,
    renderCell: ({ value: props }) => <DataGridActionCell {...props} />,
  },
]

/**
 * Maps organization members to rows containing user info, roles, and actions.
 * @param {IOrganizationUser[]} members - List of organization members to process.
 * @returns {IReturnGetRows[]} - Array of rows with transformed user data.
 */
export const getRows = (
  members: IOrganizationUser[],
  productModules: IProductModule[]
): IReturnGetRows[] =>
  members.map(
    ({ deletedAt, id, lastLogInAt, organizationTeams, status, statusChangedAt, user }) => {
      return {
        id,
        user: {
          name:
            user?.firstName || user?.lastName ? `${user.firstName} ${user.lastName}`.trim() : null,
          email: user?.emailAddress,
          status,
          isOrgAdmin: organizationTeams.some(team => team.key === TEAM_KEYS.ORG_ADMIN),
        },
        productModuleRoles: getProductModuleRoles({
          organizationTeams,
          productModules,
        }),
        status: {
          state: status,
          statusChangedAt,
        },
        statusChangedAt,
        lastSeen: lastLogInAt,
        actions: {
          userId: id,
          deletedAt,
          isInvited: status === 'invited',
        },
      }
    }
  )

export const INITIAL_MEMBERS_DATA_GRID_STATE = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
  pagination: { paginationModel: { pageSize: 5 } },
}

function categorizeData(organizationTeams: IOrganizationTeam[], productModules: IProductModule[]) {
  const result = {
    product: new Set(),
    roles: new Set(),
  }

  organizationTeams
    .filter(team => team.system)
    .forEach(team => {
      if (team.key.includes('admin')) {
        result.roles.add(TEAM_KEYS.ADMIN)
      } else if (team.key.includes('user')) {
        result.roles.add(TEAM_KEYS.USERS)
      } else {
        result.roles.add(team.name)
      }
    })

  return {
    products: productModules.map(({ key, name }) => ({ label: name, value: key })),
    roles: Array.from(result.roles) as string[],
  }
}

export const getFiltersOptionsConfig = (
  organizationTeams: IOrganizationTeam[],
  productModules: IProductModule[]
): TDataGridFiltersConfig<IFilters> => {
  const { products, roles } = categorizeData(organizationTeams, productModules)

  const updatedProductModulesFilter = {
    ...FILTERS_OPTIONS_CONFIG.productModules,
    options: products,
  }

  const updatedRoleFilter = {
    ...FILTERS_OPTIONS_CONFIG.role,
    options: [
      ...roles.map(role => ({
        label: startCase(role),
        value: `${role}`,
      })),
    ],
  }

  return {
    ...FILTERS_OPTIONS_CONFIG,
    productModules: updatedProductModulesFilter,
    role: updatedRoleFilter,
  }
}
