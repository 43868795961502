import React, { useState } from 'react'
import { makeStyles } from '../../core/styles'
import { TextArea, ITextAreaProps } from '../TextArea'
import ChipsDisplayed from './components/ChipsDisplayed'

export const TAG_DISPLAY_LIMIT = 20

export interface IChipsTextInputProps {
  /**
   * @default []
   */
  chipLabels: string[]
  /**
   * @default ''
   */
  placeholder?: string
  /**
   * Function that takes all the labels of chips, both shown or hidden by 'view more'
   * Mainly useful for updating a state variable uses values from the chips
   * It runs each time a chip is added or removed
   */
  onChange?: (newValue: string[]) => void
  /**
   * Number of chips to display below the text input before remaining chips are cut off
   * and 'View More' appears
   * @default 20
   */
  chipsDisplayedLimit?: number
  /**
   * When user clicks 'View More', this increment is applied to increase the number of displayed
   * chips
   * @default 20
   */
  displayLimitIncrease?: number
  /**
   * The number of characters displayed on a chip before label is truncated with '...'
   * @default 20
   */
  chipCharacterLimit?: number
  /**
   * @default 'chip-text-input'
   */
  dataTest?: string
}

const inputStyles = makeStyles(theme => ({
  box: {
    paddingBottom: theme.spacing(1),
    margin: 0,
  },
}))

function ChipsTextInput({
  onChange,
  placeholder = '',
  chipLabels = [],
  chipCharacterLimit = 20,
  chipsDisplayedLimit = 20,
  displayLimitIncrease = 20,
  dataTest = 'chip-text-input',
}: IChipsTextInputProps) {
  const inputClass = inputStyles()
  const [value, setInputValue] = useState<ITextAreaProps['value']>('')

  const setChipLabelsHandler = (labels: string[]) => {
    if (onChange !== undefined) {
      onChange(labels)
    }
  }

  const updateChips = (input: string, chipLabels: string[]) => {
    const newChips = input.split('\n').filter(string => string.trim().length !== 0)
    // we only want unique values - this eliminates duplicates
    setChipLabelsHandler([...new Set([...chipLabels, ...newChips])])
    setInputValue('')
  }

  const textInputChangeHandler: ITextAreaProps['onChange'] = e => {
    setInputValue(e.target.value)
    // if there is a line break in the input, apply updates
    if (e.target.value.indexOf('\n') > -1) {
      updateChips(e.target.value, chipLabels)
    }
  }

  const textInputBlurHandler: ITextAreaProps['onBlur'] = e => {
    updateChips(e.target.value, chipLabels)
  }

  return (
    <div data-test={`${dataTest}-wrapper`} className={inputClass.box}>
      <TextArea
        placeholder={placeholder}
        onChange={textInputChangeHandler}
        onBlur={textInputBlurHandler}
        value={value}
        dataTest={dataTest}
      />
      {chipLabels.length > 0 && (
        <ChipsDisplayed
          dataTest={`${dataTest}-chips`}
          chipLabels={chipLabels}
          onSetChipLabels={setChipLabelsHandler}
          chipsDisplayedLimit={chipsDisplayedLimit}
          displayLimitIncrease={displayLimitIncrease}
          chipCharacterLimit={chipCharacterLimit}
        />
      )}
    </div>
  )
}

export default ChipsTextInput
