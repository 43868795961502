import React, { memo, ReactNode } from 'react'
import { useGridApiContext } from '@mui/x-data-grid/hooks/utils/useGridApiContext'
import { makeStyles, GridRowId as MuiGridRowId } from '../../../../core'
import EditIcon from '../../../../icons/build/EditIcon'
import { IconButton } from '../../../IconButton'

interface IDataGridEditableCellProps {
  children: ReactNode | ReactNode[]
  id: MuiGridRowId
  field: string
}

const useDataGridEditableCellStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',

    '&:hover $cellIcon': {
      display: 'flex',
    },
  },
  cellContent: {
    flex: 1,
  },
  cellIcon: {
    display: 'none',
    marginLeft: theme.spacing(1),
  },
}))

export const DataGridEditableCell = memo<IDataGridEditableCellProps>(({ children, field, id }) => {
  const classes = useDataGridEditableCellStyles()
  const apiRef = useGridApiContext()

  const enterEditState = () => {
    apiRef.current.startCellEditMode({ id, field })
  }
  return (
    <div className={classes.root} data-test='DataGridEditableCell'>
      <div className={classes.cellContent}>{children}</div>
      <IconButton
        noPadding
        color='primary'
        size='large'
        Icon={EditIcon}
        onClick={enterEditState}
        className={classes.cellIcon}
      />
    </div>
  )
})
