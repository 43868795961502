import { makeStyles } from '../../../core/styles'
import { brandColors } from '../../../theme/colors'

export const useDateRangePickerOptionBaseStyles = makeStyles(theme => ({
  button: {
    backgroundColor: brandColors.transparent,
    border: 0,
    borderRadius: 0,
    color: brandColors.coolGray8,
    fontWeight: theme.typography.fontWeightLight,
    margin: 0,
    padding: theme.spacing(0.75, 2),
    textAlign: 'left',
    transition: 'none',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&:focus': {
      boxShadow: 'none',
    },
  },
  activeButton: {
    backgroundColor: brandColors.coolGray1,
    fontWeight: theme.typography.fontWeightBold,
  },
}))
