import { datadogRum } from '@datadog/browser-rum'

const { error, log } = console

export function trackEvent(eventValue: string, properties?: Record<string, unknown>) {
  try {
    if (!['production'].includes(import.meta.env.MODE)) {
      log('[TFX Analytics] ', eventValue, properties || {})
    }
    datadogRum.addAction(eventValue, properties || {})
  } catch (e) {
    error('TrackEvent: ', e)
  }
}
