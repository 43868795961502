import React from 'react'
import { MembersOverflowMenu } from '../../MembersOverflowMenu'
import { IReturnGetRows } from '../utils/grid'

type IDataGridActionCellProps = IReturnGetRows['actions']

export function DataGridActionCell({ deletedAt, isInvited, userId }: IDataGridActionCellProps) {
  let userStatus
  if (isInvited) {
    userStatus = 'invited'
  } else if (deletedAt) {
    userStatus = 'deleted'
  } else {
    userStatus = 'active'
  }

  return <MembersOverflowMenu userId={userId} userStatus={userStatus} />
}

export default DataGridActionCell
