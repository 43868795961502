import React, { memo } from 'react'
import get from 'lodash/get'
import { createStyles, CSSProperties, makeStyles } from '../../core'
import { brandColors } from '../../theme'

export type TSpinnerSize = 'small' | 'medium' | 'large'

export interface IRadialSpinnerProps {
  /**
   * @default 'absolute'
   */
  position: 'absolute' | 'relative'
  /**
   * @default 'large'
   */
  size?: TSpinnerSize
}

// Number of individual paths in svg element
const STEPS = 8

/**
 * Returns an object with generated `nth-child(i)` styles,
 * and programmatic animation offsets
 */
const generateChildrenStyles = (): Record<string, CSSProperties> => {
  const generatedStyles: Record<string, CSSProperties> = {}
  for (let i = 1; i <= STEPS; i += 1) {
    generatedStyles[`&:nth-child(${i})`] = {
      animationDelay: `-${1 - (1 / STEPS) * i}s`,
    }
  }
  return generatedStyles
}

const PX_BY_SIZE = {
  small: 26,
  medium: 39,
  large: 52,
} as const

const getPxBySize = (size: TSpinnerSize) => {
  return get(PX_BY_SIZE, size, PX_BY_SIZE.large)
}

type TClassKey = 'relative' | 'absolute' | 'spinner' | '@keyframes RadialSpin'

const useRadialSpinnerStyles = makeStyles(() =>
  createStyles<TClassKey, { size: TSpinnerSize }>({
    '@keyframes RadialSpin': {
      '0%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
    relative: {
      position: 'relative',
      top: 'inherit',
      left: 'inherit',
      transform: 'none',
    },
    absolute: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      // calculates offset
      transform: ({ size }) => {
        return `translateX(-${getPxBySize(size) / 2}px) translateY(-${getPxBySize(size) / 2}px)`
      },
    },
    spinner: {
      '& path': {
        animation: '$RadialSpin linear 1s infinite',
        ...generateChildrenStyles(),
      },
    },
  })
)

/**
 * Displays a spinner with 8 points that fade in and out
 */
export const RadialSpinner = memo<IRadialSpinnerProps>(
  ({ position = 'absolute', size = 'large' }) => {
    const classes = useRadialSpinnerStyles({ size })
    const pxSize = getPxBySize(size)
    return (
      <div className={position === 'absolute' ? classes.absolute : classes.relative}>
        <svg
          className={classes.spinner}
          width={pxSize}
          height={pxSize}
          viewBox='0 0 52 52'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26.0003 0.333344C27.289 0.333344 28.3337 1.37801 28.3337 2.66668V12C28.3337 13.2887 27.289 14.3333 26.0003 14.3333C24.7117 14.3333 23.667 13.2887 23.667 12V2.66668C23.667 1.37801 24.7117 0.333344 26.0003 0.333344Z'
            fill={brandColors.skyBlue6}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44.1462 7.85343C45.0574 8.76465 45.0574 10.242 44.1462 11.1533L37.5428 17.7566C36.6316 18.6678 35.1542 18.6678 34.243 17.7566C33.3318 16.8454 33.3318 15.368 34.243 14.4568L40.8463 7.85343C41.7575 6.94221 43.2349 6.94221 44.1462 7.85343Z'
            fill={brandColors.skyBlue6}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M37.667 26C37.667 24.7113 38.7117 23.6667 40.0003 23.6667H49.3337C50.6223 23.6667 51.667 24.7113 51.667 26C51.667 27.2887 50.6223 28.3333 49.3337 28.3333H40.0003C38.7117 28.3333 37.667 27.2887 37.667 26Z'
            fill={brandColors.skyBlue6}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M34.243 34.2434C35.1542 33.3322 36.6316 33.3322 37.5428 34.2434L44.1462 40.8467C45.0574 41.758 45.0574 43.2354 44.1462 44.1466C43.2349 45.0578 41.7575 45.0578 40.8463 44.1466L34.243 37.5432C33.3318 36.632 33.3318 35.1546 34.243 34.2434Z'
            fill={brandColors.skyBlue6}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26.0003 37.6667C27.289 37.6667 28.3337 38.7113 28.3337 40V49.3333C28.3337 50.622 27.289 51.6667 26.0003 51.6667C24.7117 51.6667 23.667 50.622 23.667 49.3333V40C23.667 38.7113 24.7117 37.6667 26.0003 37.6667Z'
            fill={brandColors.skyBlue6}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17.7565 34.2434C18.6677 35.1546 18.6677 36.632 17.7565 37.5432L11.1532 44.1466C10.2419 45.0578 8.76456 45.0578 7.85334 44.1466C6.94212 43.2354 6.94212 41.758 7.85334 40.8467L14.4567 34.2434C15.3679 33.3322 16.8453 33.3322 17.7565 34.2434Z'
            fill={brandColors.skyBlue6}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.333008 26C0.333008 24.7113 1.37768 23.6667 2.66634 23.6667H11.9997C13.2883 23.6667 14.333 24.7113 14.333 26C14.333 27.2887 13.2883 28.3333 11.9997 28.3333H2.66634C1.37768 28.3333 0.333008 27.2887 0.333008 26Z'
            fill={brandColors.skyBlue6}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.85334 7.85343C8.76456 6.94221 10.2419 6.94221 11.1532 7.85343L17.7565 14.4568C18.6677 15.368 18.6677 16.8454 17.7565 17.7566C16.8453 18.6678 15.3679 18.6678 14.4567 17.7566L7.85334 11.1533C6.94212 10.242 6.94212 8.76465 7.85334 7.85343Z'
            fill={brandColors.skyBlue6}
          />
        </svg>
      </div>
    )
  }
)

export default RadialSpinner
