import React, { memo } from 'react'
import classNames from 'classnames'
import { makeStyles, MenuItem } from '../../core'
import { brandColors } from '../../theme/colors'
import { ISingleSelectOption } from './types'

export interface ISingleSelectOptionProps extends ISingleSelectOption {
  id?: string
  selected: boolean
  onSelect: (option: ISingleSelectOption) => void
  dataTest: string
  checkbox?: boolean
  tooltip?: JSX.Element
  isEmptyState?: boolean
}

const useSingleSelectOptionStyles = makeStyles(theme => ({
  link: {
    color: brandColors.skyBlue6,
  },
  buttonDisplay: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  ellipsis: {
    wordWrap: 'normal',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  selected: {
    backgroundColor: `${brandColors.coolGray1} !important`,
    fontWeight: 700,
    '&:focus, &:hover, &:active': {
      color: brandColors.coolGray6,
      backgroundColor: `${brandColors.skyBlue2} !important`,
      cursor: 'inherit',
    },
  },
}))

export const SingleSelectOption = memo<ISingleSelectOptionProps>(
  ({
    checkbox,
    className: classNameProp,
    dataTest,
    disabled,
    icon,
    isLink,
    label,
    onSelect,
    selected,
    value,
  }) => {
    const styles = useSingleSelectOptionStyles()

    const clickHandler = () => {
      onSelect({ value, label })
    }

    const buttonClassNames = classNames(classNameProp, styles.ellipsis, {
      [styles.link]: isLink,
      [styles.selected]: selected,
    })

    const showIcon = !!icon && !checkbox

    return (
      <MenuItem
        onClick={clickHandler}
        data-test={dataTest}
        disabled={disabled}
        className={buttonClassNames}>
        <div className={styles.buttonDisplay}>
          {showIcon && icon}
          <div className={styles.ellipsis}>{!checkbox && label}</div>
        </div>
      </MenuItem>
    )
  }
)
