import { Components } from '@mui/material/styles'

/**
 * @name DialogTitle
 * @see https://mui.com/material-ui/api/dialog-title/
 */
export const MuiDialogTitleThemeOptions: Components['MuiDialogTitle'] = {
  styleOverrides: {
    root: {
      borderBottom: 'none',
      padding: 0,
    },
  },
}
