import React from 'react'
import { Avatar } from 'dpl/components/Avatar'
import { makeStyles, Typography } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'

export interface IEmailTagProps {
  email: string
}

const useEmailTagStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
    backgroundColor: brandColors.coolGray1,
    border: `1px solid ${brandColors.coolGray3}`,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.75, 1.25),
    width: 'fit-content',
  },
}))

export function EmailTag({ email }: IEmailTagProps) {
  const classes = useEmailTagStyles()
  return (
    <div className={classes.root}>
      <Avatar size='xsmall' hideTooltip />
      <Typography variant='body1' fontWeight={600}>
        {email}
      </Typography>
    </div>
  )
}
