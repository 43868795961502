import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'
import { calcRem } from '../utils'

/**
 * @name TableCell
 * @see https://mui.com/material-ui/api/table-cell/
 */
export const MuiTableCellThemeOptions: Components['MuiTableCell'] = {
  styleOverrides: {
    root: {
      padding: BASE_THEME.spacing(2, 1),
      lineHeight: calcRem(19),
      borderBottom: `1px solid ${BASE_THEME.palette.divider}`,
    },
    head: {
      color: brandColors.coolGray5,
      backgroundColor: brandColors.white,
      fontWeight: 600,
    },
    stickyHeader: {
      backgroundColor: brandColors.white,
    },
  },
}
