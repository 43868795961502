import React, { memo } from 'react'
import NumericFormat, { INumericFormatProps } from '../NumericFormat'

export interface IPercentageInputProps
  extends Omit<INumericFormatProps, 'type' | 'thousandsSeparator' | 'valueIsNumericString'> {
  /**
   * @default 'PercentageInput'
   */
  dataTest?: string
}

export const PercentageInput = memo<IPercentageInputProps>(
  ({ dataTest = 'PercentageInput', ...props }) => {
    return (
      <NumericFormat
        dataTest={dataTest}
        type='tel'
        displayType='input'
        suffix='%'
        thousandSeparator
        valueIsNumericString
        {...props}
      />
    )
  }
)
