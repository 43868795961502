import React, { useCallback, useEffect } from 'react'
import { Outlet, To, useNavigate, Navigate } from 'react-router-dom'
import { useAuthContext, useAuthMember, useAuthMemberSession } from '../hooks'

export interface IProtectedLayoutProps {
  /**
   * @description Location to redirect user to if they are unauthenticated.
   * @example '/sign-in'
   */
  redirectTo: To
  redirectToExternal?: boolean
  /**
   * @description Location to redirect user to if they are authenticated, but not onboarded.
   * @example '/onboard'
   */
  redirectToOnboarding?: To
}

/**
 * @description {B2B SaaS Authentication} Renders nothing, and has a check to see if an authenticated session exists.
 * This will confirm that a user is signed in. If an authenticated session does not exist, then the user will be
 * redirected to the specified `redirectTo`. This protects all wrapped children routes which will be mounted at the
 * location of the `<Outlet />`.
 * @todo Permission support.
 */
export function ProtectedLayout({
  redirectTo,
  redirectToExternal = false,
  redirectToOnboarding,
}: IProtectedLayoutProps) {
  const navigate = useNavigate()
  const { authClient } = useAuthContext()
  const { session } = useAuthMemberSession()
  const { member } = useAuthMember()
  const { member_password_id: memberPasswordId } = member ?? {}

  const redirectForExternals = useCallback(async () => {
    try {
      await authClient.sessions.authenticate()
    } catch {
      if (typeof redirectTo === 'string') {
        window.location.href = redirectTo
      }
    }
  }, [redirectTo])

  useEffect(() => {
    if (!session && redirectToExternal && typeof redirectTo === 'string') {
      redirectForExternals()
    }
  }, [navigate, redirectForExternals, redirectTo, redirectToExternal, session])

  if (!session && !redirectToExternal) {
    return <Navigate to={redirectTo} />
  }

  if (!memberPasswordId && redirectToOnboarding) {
    return <Navigate to={redirectToOnboarding} />
  }

  return <Outlet />
}
