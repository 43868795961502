import React from 'react'
import { FormattedDateTime } from 'dpl/components/FormattedDateTime'
import { DISPLAY } from 'dpl/constants'
import { IReturnGetRows } from '../utils/grid'

type IDataGridLastSeenCellProps = {
  date: IReturnGetRows['lastSeen']
}

export function DataGridLastSeenCell({ date }: IDataGridLastSeenCellProps) {
  if (!date) return <span>{DISPLAY.empty}</span>

  return <FormattedDateTime datetime={date} format='dateAndYear' inSentence />
}

export default DataGridLastSeenCell
