import React, { memo } from 'react'
import { formatUppercaseAmPm } from '../FormattedDateTimeRange'
import { datetimeFormatter, IDatetimeFormatterArgs } from './utils/datetimeFormatter'

export interface IFormattedDatetimeProps extends IDatetimeFormatterArgs {
  className?: string
  dataTest?: string
}

export const FormattedDateTime = memo<IFormattedDatetimeProps>(
  ({
    abbreviated,
    className,
    dataTest,
    datetime,
    displayTimezone,
    format,
    inSentence,
    isUppercaseAmPm,
    showTimezone,
    timeShort,
  }) => {
    const formatted = datetimeFormatter({
      datetime,
      displayTimezone,
      format,
      inSentence,
      showTimezone,
      abbreviated,
      timeShort,
    })
    const formattedDateTime = isUppercaseAmPm ? formatUppercaseAmPm(formatted) : formatted

    return (
      <span className={className} data-test={dataTest}>
        {formattedDateTime}
      </span>
    )
  }
)

export default FormattedDateTime
