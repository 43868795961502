import { createTheme, Theme } from '@mui/material/styles'
import {
  MuiAccordionThemeOptions,
  MuiAppBarThemeOptions,
  MuiAutocompleteThemeOptions,
  MuiAvatarThemeOptions,
  MuiAvatarGroupThemeOptions,
  MuiButtonBaseThemeOptions,
  MuiButtonThemeOptions,
  MuiCardThemeOptions,
  MuiCardContentThemeOptions,
  MuiCardHeaderThemeOptions,
  MuiCheckboxThemeOptions,
  MuiChipThemeOptions,
  MuiCssBaselineThemeOptions,
  MuiDialogActionsThemeOptions,
  MuiDialogContentThemeOptions,
  MuiDialogTitleThemeOptions,
  MuiDividerThemeOptions,
  MuiFabThemeOptions,
  MuiFilledInputThemeOptions,
  MuiIconButtonThemeOptions,
  MuiInputLabelThemeOptions,
  MuiLinkThemeOptions,
  MuiListItemButtonThemeOptions,
  MuiListItemIconThemeOptions,
  MuiListSubheaderThemeOptions,
  MuiOutlinedInputThemeOptions,
  MuiRadioThemeOptions,
  MuiSliderThemeOptions,
  MuiStepperThemeOptions,
  MuiStepLabelThemeOptions,
  MuiSwitchThemeOptions,
  MuiTableCellThemeOptions,
  MuiTabsThemeOptions,
  MuiTabThemeOptions,
  MuiTextFieldThemeOptions,
  MuiTypographyThemeOptions,
  MuiToggleButtonThemeOptions,
  MuiToggleButtonGroupThemeOptions,
  MuiToolbarThemeOptions,
  MuiTooltipThemeOptions,
  MuiDateCalendarThemeOptions,
  MuiDatePickerToolbarThemeOptions,
  MuiDateTimePickerToolbarThemeOptions,
  MuiTimePickerToolbarThemeOptions,
  MuiPickersPopperThemeOptions,
  MuiDataGridThemeOptions,
} from './MUIComponents'
import { BASE_THEME } from './baseTheme'
import { TYPOGRAPHY_THEME_OPTIONS } from './typography'

const theme: Theme = createTheme(BASE_THEME, {
  typography: TYPOGRAPHY_THEME_OPTIONS,
  components: {
    MuiCssBaseline: MuiCssBaselineThemeOptions,
    MuiAccordion: MuiAccordionThemeOptions,
    MuiAppBar: MuiAppBarThemeOptions,
    MuiAutocomplete: MuiAutocompleteThemeOptions,
    MuiAvatar: MuiAvatarThemeOptions,
    MuiAvatarGroup: MuiAvatarGroupThemeOptions,
    MuiButton: MuiButtonThemeOptions,
    MuiButtonBase: MuiButtonBaseThemeOptions,
    MuiCard: MuiCardThemeOptions,
    MuiCardContent: MuiCardContentThemeOptions,
    MuiCardHeader: MuiCardHeaderThemeOptions,
    MuiCheckbox: MuiCheckboxThemeOptions,
    MuiChip: MuiChipThemeOptions,
    MuiDataGrid: MuiDataGridThemeOptions,
    MuiDateCalendar: MuiDateCalendarThemeOptions,
    MuiDatePickerToolbar: MuiDatePickerToolbarThemeOptions,
    MuiDateTimePickerToolbar: MuiDateTimePickerToolbarThemeOptions,
    MuiTimePickerToolbar: MuiTimePickerToolbarThemeOptions,
    MuiDialogActions: MuiDialogActionsThemeOptions,
    MuiDialogContent: MuiDialogContentThemeOptions,
    MuiDialogTitle: MuiDialogTitleThemeOptions,
    MuiDivider: MuiDividerThemeOptions,
    MuiFab: MuiFabThemeOptions,
    MuiFilledInput: MuiFilledInputThemeOptions,
    MuiIconButton: MuiIconButtonThemeOptions,
    MuiInputLabel: MuiInputLabelThemeOptions,
    MuiLink: MuiLinkThemeOptions,
    MuiListItemButton: MuiListItemButtonThemeOptions,
    MuiListItemIcon: MuiListItemIconThemeOptions,
    MuiListSubheader: MuiListSubheaderThemeOptions,
    MuiOutlinedInput: MuiOutlinedInputThemeOptions,
    MuiPickersPopper: MuiPickersPopperThemeOptions,
    MuiRadio: MuiRadioThemeOptions,
    MuiSlider: MuiSliderThemeOptions,
    MuiStepLabel: MuiStepLabelThemeOptions,
    MuiStepper: MuiStepperThemeOptions,
    MuiSwitch: MuiSwitchThemeOptions,
    MuiTableCell: MuiTableCellThemeOptions,
    MuiTab: MuiTabThemeOptions,
    MuiTabs: MuiTabsThemeOptions,
    MuiTextField: MuiTextFieldThemeOptions,
    MuiToggleButton: MuiToggleButtonThemeOptions,
    MuiToggleButtonGroup: MuiToggleButtonGroupThemeOptions,
    MuiToolbar: MuiToolbarThemeOptions,
    MuiTooltip: MuiTooltipThemeOptions,
    MuiTypography: MuiTypographyThemeOptions,
  },
})

export default theme
