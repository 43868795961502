import React, { memo } from 'react'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

export interface IAutocompleteHighlightTextProps {
  /**
   * The input the user has entered
   */
  inputValue: string
  /**
   * The label to highlight
   */
  label: string
}

export const AutocompleteHighlightText = memo<IAutocompleteHighlightTextProps>(
  ({ inputValue, label }) => {
    const matches = match(label, inputValue, { insideWords: true })
    const parts = parse(label, matches)
    return (
      <div>
        {parts.map(({ highlight, text }, index) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{
              fontWeight: highlight ? 700 : 400,
            }}>
            {text}
          </span>
        ))}
      </div>
    )
  }
)
