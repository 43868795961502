import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation'
import { BASE_THEME } from '../baseTheme'
import { TYPOGRAPHY_THEME_OPTIONS } from '../typography'
import { calcRem } from '../utils'

export const MuiDatePickerToolbarThemeOptions: PickerComponents['MuiDatePickerToolbar'] = {
  defaultProps: {
    toolbarFormat: 'MMM DD',
  },
  styleOverrides: {
    root: {
      '& > span': {
        ...TYPOGRAPHY_THEME_OPTIONS.caption,
        textTransform: 'capitalize',
        fontSize: calcRem(12),
      },
      '& .MuiDatePickerToolbar-title': {
        ...TYPOGRAPHY_THEME_OPTIONS.h3,
        fontWeight: 500,
      },
      '& .MuiPickersToolbar-content': {
        marginTop: BASE_THEME.spacing(1),
      },
    },
  },
}
