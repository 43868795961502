import { createTheme } from '@mui/material/styles'
import { breakpoints } from './breakpoints'
import { brandColors } from './colors'
import shadows from './shadows'

export const BASE_THEME = createTheme({
  /**
   * @see https://mui.com/material-ui/customization/palette/
   */
  palette: {
    primary: {
      main: brandColors.skyBlue6,
      dark: brandColors.skyBlue7,
      contrastText: brandColors.white,
    },
    secondary: {
      main: brandColors.skyBlue1,
      dark: brandColors.skyBlue2,
      contrastText: brandColors.skyBlue6,
    },
    success: {
      main: brandColors.green2,
      contrastText: brandColors.white,
    },
    error: {
      main: brandColors.error1,
      dark: brandColors.hotButtonHover,
      contrastText: brandColors.white,
    },
    info: {
      main: brandColors.skyBlue2,
      dark: brandColors.skyBlue3,
      contrastText: brandColors.skyBlue6,
    },
    warning: {
      main: brandColors.warning1,
    },
    divider: brandColors.coolGray3,
    mode: 'light',
    text: {
      primary: brandColors.coolGray8,
      secondary: brandColors.coolGray5,
    },
  },
  /**
   * API that enables the use of breakpoints in a wide variety of contexts.
   * @see https://mui.com/material-ui/customization/breakpoints/
   */
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
  /**
   * Material-UI uses a recommended 8px scaling factor by default.
   * @see https://mui.com/material-ui/customization/spacing/
   */
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  shadows,
  transitions: {},
})
