import { useState, MouseEvent, useCallback, KeyboardEvent } from 'react'

/**
 * A hook containing some state and click handlers that are often used along
 * with the Popper component or other components that utilize it to open/close a menu.
 * For example: `<OverflowMenu />`, `<SingleSelect />`
 *
 * Returns whether or not the menu is expanded, handlers to open and close the menu,
 * and `anchorEl`, the anchor element that is used to set the position of the menu.
 */
export const useMenuToggle = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const expanded = Boolean(anchorEl)

  const handleClick = useCallback((event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
    const { currentTarget } = event || {}
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(prevState => {
      if (prevState) return null
      return currentTarget
    })
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return { expanded, handleClose, handleClick, anchorEl }
}
