import React, { memo, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { Box, makeStyles, Typography } from '../../../../core'
import { Theme } from '../../../../core/styles'
import { brandColors } from '../../../../theme/colors'

export interface IDataGridAdvancedTextCellProps {
  className?: string
  /**
   * @description Caption to be displayed above text content
   * @example 'Shipper Name'
   */
  topCaption?: string | null
  /**
   * @description Caption to be displayed below text content
   * @example 'test@shipper.com'
   */
  bottomCaption?: string | null
  /**
   * @description Describes how the text and captions are aligned
   * @default 'start'
   */
  alignment?: 'start' | 'center' | 'end'
}

const useDataGridAdvancedTextCellStyles = makeStyles<Theme, { alignment: string }>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: ({ alignment }) => alignment,
  },
})

export const DataGridAdvancedTextCell = memo<PropsWithChildren<IDataGridAdvancedTextCellProps>>(
  ({ alignment = 'start', bottomCaption, children, className: classNameProp, topCaption }) => {
    const classes = useDataGridAdvancedTextCellStyles({ alignment })
    const rootClasses = classNames(classes.root, classNameProp)

    return (
      <div className={rootClasses} data-test='DataGridAdvancedTextCell'>
        {topCaption && (
          <Typography variant='caption' color={brandColors.coolGray5}>
            {topCaption}
          </Typography>
        )}
        <Box width='100%'>{children}</Box>
        {bottomCaption && (
          <Typography variant='caption' color={brandColors.coolGray5}>
            {bottomCaption}
          </Typography>
        )}
      </div>
    )
  }
)
