import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'
import { getBrandColor } from '../utils'

export const MuiButtonThemeOptions: Components<typeof BASE_THEME>['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
    disableRipple: true,
    disableFocusRipple: false,
    variant: 'contained',
    color: 'primary',
    size: 'medium',
  },
  variants: [
    /** Sizes */
    {
      props: { size: 'xsmall' },
      style: {
        padding: BASE_THEME.spacing(0.75),
      },
    },
    {
      props: { size: 'small' },
      style: {
        padding: BASE_THEME.spacing(1.25),
      },
    },
    {
      props: { size: 'medium' },
      style: {
        padding: BASE_THEME.spacing(1.75),
      },
    },
    {
      props: { size: 'large' },
      style: {
        padding: BASE_THEME.spacing(2.25),
      },
    },
    /** Variants */
    {
      props: { variant: 'pill', color: 'primary' },
      style: {
        border: `1px solid ${brandColors.coolGray4}`,
        borderRadius: BASE_THEME.spacing(5),
        fontSize: '1rem !important',
        lineHeight: '1.3125rem !important',
        '&:hover': {
          background: brandColors.white,
          borderColor: brandColors.black,
        },
        '&:focus, &.popover-open': {
          borderColor: brandColors.skyBlue6,
          boxShadow: `inset ${brandColors.skyBlue6} 0px 0px 0px 1px`,
        },
        '&.hasValue': {
          borderColor: brandColors.skyBlue6,
        },
      },
    },
    {
      props: { variant: 'pill', size: 'xsmall' },
      style: {
        padding: `${BASE_THEME.spacing(0.75)} ${BASE_THEME.spacing(1.5)}`,
      },
    },
  ],
  styleOverrides: {
    root: {
      '&:focus': {
        boxShadow: `0 0 0 3px ${brandColors.skyBlue3}`,
      },
      '&.Mui-disabled': {
        color: brandColors.gray3,
        background: brandColors.gray1,
      },
    },
    // Icons
    startIcon: {
      marginLeft: 0,
    },
    endIcon: {
      marginRight: 0,
    },
    // Variant/Color combos
    outlinedPrimary: {
      borderColor: brandColors.skyBlue6,
      '&:hover': {
        borderColor: brandColors.skyBlue6,
        background: brandColors.skyBlue2,
      },
    },
    textPrimary: {
      '&:hover': {
        background: brandColors.skyBlue2,
      },
    },
    outlinedSecondary: {
      color: brandColors.skyBlue6,
      borderColor: brandColors.skyBlue6,
      '&:hover': {
        borderColor: brandColors.skyBlue6,
        background: brandColors.skyBlue2,
      },
    },
    textSecondary: {
      color: brandColors.skyBlue6,
      '&:hover': {
        color: brandColors.skyBlue6,
        background: brandColors.skyBlue2,
      },
    },
    containedError: {
      '&:focus': {
        boxShadow: `0 0 0 3px ${getBrandColor('error1').alpha(0.4).toString()}`,
      },
    },
    outlinedError: {
      borderColor: brandColors.error1,
      '&:hover': {
        backgroundColor: brandColors.error0,
      },
      '&:focus': {
        boxShadow: `0 0 0 3px ${getBrandColor('error1').alpha(0.4).toString()}`,
      },
    },
    textError: {
      '&:hover': {
        backgroundColor: brandColors.error0,
      },
      '&:focus': {
        boxShadow: `0 0 0 3px ${getBrandColor('error1').alpha(0.4).toString()}`,
      },
    },
  },
}
