export const REQUIREMENT_KEYS = ['length', 'letters', 'numbers', 'special', 'score'] as const

export type TPasswordRequirementKeys = (typeof REQUIREMENT_KEYS)[number]

export type TPasswordValidationState = Record<TPasswordRequirementKeys, boolean>

export enum EPasswordStrengthLevel {
  weak = 'Weak',
  fair = 'Fair',
  good = 'Good',
  great = 'Great',
}
