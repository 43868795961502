import React, { memo, PropsWithChildren, ReactNode, useCallback, useMemo } from 'react'
import { kebabCase } from 'lodash'
import { Typography, makeStyles, Drawer, DrawerProps, Theme, Button } from '../../core'
import CloseIcon from '../../icons/build/CloseIcon'
import { IconButton } from '../IconButton'
import { ISidePanelAction } from './types'

export interface ISidePanelProps
  extends Pick<DrawerProps, 'PaperProps' | 'anchor' | 'onClose' | 'open'> {
  /**
   * The title of the SidePanel.
   * @example 'Settings'
   */
  title?: string
  /**
   * Optional header that can be shown in lieu of the title
   * @optional
   */
  header?: ReactNode
  /**
   * The menu items to be displayed in the header.
   * @example <Button>Some Action</Button>
   */
  menuItems?: React.ReactNode
  /**
   * Determines whether the close button should be shown in the SidePanel.
   * @default true
   */
  showCloseButton?: boolean
  /**
   * The actions to be displayed in the footer.
   * @example [{ label: 'Cancel', anchor: 'left', onClick: () => {} }, { label: 'Save', anchor: 'right' onClick: () => console.log('Save') }]
   * @default []
   */
  actions?: ISidePanelAction[]
  /**
   * The data test attribute for testing purposes.
   * @default 'SidePanel'
   */
  dataTest?: string
  /**
   * Event handler for when the SidePanel is closed.
   * @param event - The event object.
   * @param reason - The reason for the SidePanel being closed. Can be 'backdropClick', 'escapeKeyDown', or 'closeButton'.
   */
  onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown' | 'closeButton') => void
}

const useSidePanelStyles = makeStyles<Theme, Pick<ISidePanelProps, 'anchor'>>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: ({ anchor }) => (anchor === 'left' || anchor === 'right' ? '100vh' : 'auto'),

    [theme.breakpoints.up('md')]: {
      width: ({ anchor }) => (anchor === 'left' || anchor === 'right' ? '500px' : '100%'),
      maxWidth: '100%',
    },

    [theme.breakpoints.up('xl')]: {
      width: ({ anchor }) => (anchor === 'left' || anchor === 'right' ? '35vw' : '100%'),
    },
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(0.25),
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 2),
    },
  },
  title: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  menuItems: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(0.25),
  },
  scrollWrapper: {
    flex: 1,
    overflowY: 'scroll',
  },
  content: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  buttonGroup: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

const SidePanel = memo<PropsWithChildren<ISidePanelProps>>(
  ({
    PaperProps,
    anchor,
    header,
    actions = [],
    children,
    dataTest = 'SidePanel',
    menuItems,
    onClose,
    open = true,
    showCloseButton = true,
    title,
  }) => {
    const classes = useSidePanelStyles({ anchor })

    const showHeader = useMemo(
      () => title || menuItems || showCloseButton,
      [title, menuItems, showCloseButton]
    )

    const showFooter = useMemo(() => actions.length > 0, [actions])

    const keyDownHandler = useCallback(
      (event: React.KeyboardEvent<{}>) => {
        const { key } = event || {}
        if (key === 'Escape' && onClose) {
          onClose(event, 'escapeKeyDown')
        }
      },
      [onClose]
    )

    const closeHandler = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClose) {
          onClose(event, 'closeButton')
        }
      },
      [onClose]
    )

    return (
      <Drawer
        open={open}
        anchor={anchor}
        onClose={onClose}
        onKeyDown={keyDownHandler}
        PaperProps={{ className: classes.root, ...PaperProps }}
        variant='temporary'
        data-test={dataTest}>
        {showHeader && (
          <div className={classes.head} data-test={`${dataTest}-head`}>
            {title && (
              <Typography variant='h3' component='div' className={classes.title}>
                {title}
              </Typography>
            )}
            {header && header}
            <div className={classes.menuItems}>
              {menuItems}
              {showCloseButton && (
                <IconButton
                  Icon={CloseIcon}
                  color='secondary'
                  size='large'
                  onClick={closeHandler}
                  dataTest={`${dataTest}-close`}
                />
              )}
            </div>
          </div>
        )}
        <div className={classes.scrollWrapper}>
          <div className={classes.content}>{children}</div>
        </div>
        {showFooter && (
          <div className={classes.footer}>
            {['left', 'right'].map(position => (
              <div key={position} className={classes.buttonGroup}>
                {actions
                  .filter(({ anchor = 'right' }) => anchor === position)
                  .map(({ label, ...actionProps }) => (
                    <div key={`side-panel-action-${kebabCase(label)}}`}>
                      <Button data-test={`${dataTest}-${kebabCase(label)}`} {...actionProps}>
                        {label}
                      </Button>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        )}
      </Drawer>
    )
  }
)

export default SidePanel
