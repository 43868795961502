import Color from 'color'
import get from 'lodash/get'
import { brandColors, TBrandColor } from '../colors'

/**
 * Returns a Color object based on the brandColor
 */
export const getBrandColor = (colorName: TBrandColor): Color<string> => {
  const colorValue = get(brandColors, colorName, brandColors.coolGray8)
  return Color(colorValue)
}
