import React, { memo } from 'react'
import classNames from 'classnames'
import { Snackbar } from '../../core'
import { makeStyles, Theme } from '../../core/styles'
import { CloseIcon } from '../../icons'
import { brandColors } from '../../theme/colors'
import { IconButton } from '../IconButton'
import { TToastVariation, IToastState } from './types'

interface IToastProps extends IToastState {
  onClose: () => void
}

const useToastStyles = makeStyles<Theme, { style: TToastVariation }>(theme => ({
  toast: {
    '&.MuiSnackbar-root': {
      zIndex: theme.zIndex.snackbar,
    },
    '& .MuiSnackbarContent-root': {
      backgroundColor: ({ style }) =>
        style === 'alert' ? brandColors.error1 : brandColors.coolGray6,
    },
    '&.MuiSnackbar-anchorOriginTopCenter': {
      top: '32px',
    },
    '& .MuiSnackbarContent-message': {
      '& a': {
        color: brandColors.white,
        textDecoration: 'underline',
        '&:hover, &:focus, &:active': {
          color: brandColors.white,
        },
      },
    },
  },
  toastNoWrap: {
    textWrap: 'nowrap',
  },
}))

/**
 * Use `openToast` from the `useToastContext` hook within the app in order to render a Toast.
 * This `<Toast />` should be used within `<ToastProvider />` at the top level of the app and should not need to be rendered directly.
 */
export const Toast = memo<IToastProps>(
  ({
    duration = 5000,
    multiLine,
    onClose,
    toastMessage,
    toastOpen: open,
    toastType: style = 'default',
  }) => {
    const styles = useToastStyles({ style })
    const classes = classNames(styles.toast, {
      [styles.toastNoWrap]: !multiLine,
    })

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        data-test='toast-alert'
        classes={{ root: classes }}
        open={open}
        autoHideDuration={duration}
        onClose={onClose}
        message={toastMessage}
        action={
          <IconButton
            color='secondary'
            Icon={CloseIcon}
            onClick={onClose}
            label='Close'
            size='large'
          />
        }
      />
    )
  }
)

export default Toast
