import { useEffect, useState, useCallback, useMemo } from 'react'
import { breakpoints } from '../theme'

export enum Breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export type TBreakpoint = keyof typeof Breakpoints

const CONFIG: Record<Breakpoints, number> = {
  xs: 1,
  sm: breakpoints.sm,
  md: breakpoints.md,
  lg: breakpoints.lg,
  xl: breakpoints.xl,
}

interface IWindowSize {
  height: number
  width: number
}

/**
 * Creates hook instance of media queries
 * @param {IUseBreakpointConfig} config - Optional config
 * @example const { breakpoint } = useBreakpoint()
 */
export const useBreakpoint = () => {
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  /**
   * @see https://usehooks.com/useWindowSize/
   * Handler to call on window resize
   */
  useEffect(() => {
    // Set window width/height to state
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  /**
   * Returns true if `current` is greater than or equal to min media query
   */
  const from = useCallback(
    (min: TBreakpoint): boolean => {
      return windowSize.width >= CONFIG[min]
    },
    [windowSize.width]
  )

  /**
   * Returns true if `current` is below max media query
   */
  const until = useCallback(
    (max: TBreakpoint): boolean => {
      return windowSize.width < CONFIG[max]
    },
    [windowSize.width]
  )

  /**
   * Returns true if both conditions are met
   */
  const breakpoint = useCallback(
    ({ from: fromMin, until: untilMax }: { from?: TBreakpoint; until?: TBreakpoint }): boolean => {
      const conditions: boolean[] = []
      if (fromMin) conditions.push(from(fromMin))
      if (untilMax) conditions.push(until(untilMax))
      return !conditions.includes(false)
    },
    [from, until]
  )

  const isMobile = !!useMemo(() => until('md'), [until])
  const isTablet = !!useMemo(() => until('lg'), [until])
  const isDesktop = !!useMemo(() => from('lg'), [from])

  return {
    until,
    from,
    breakpoint,
    isMobile,
    isTablet,
    isDesktop,
  }
}
