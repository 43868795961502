import { Components } from '@mui/material/styles'
import { brandColors } from '../colors'

/**
 * @name AppBar
 * @see https://mui.com/material-ui/api/app-bar/
 */
export const MuiAppBarThemeOptions: Components['MuiAppBar'] = {
  defaultProps: {
    color: 'default',
    elevation: 0,
  },
  styleOverrides: {
    colorDefault: {
      color: brandColors.coolGray1,
      backgroundColor: brandColors.coolGray8,
    },
  },
}
