import React, { ChangeEvent, memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { PhoneInput, IPhoneInputProps } from 'dpl/components/PhoneInput'
import formatPhone, { unformatPhone } from 'dpl/utils/formatPhone'

type IBaseControllerProps = ControllerProps<React.ComponentType<IPhoneInputProps>>

export interface IControlledPhoneInputProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  PhoneInputProps?: Partial<IPhoneInputProps>
}

/**
 * Helper component for use with React Hook Form
 * @see https://react-hook-form.com/api#Controller
 * @example
 * ```
 * <ControlledPhoneInput
 *   name='phone'
 *   control={control}
 *   PhoneInputProps={{
 *     label: 'Pickup Number',
 *     placeholder: '1234',
 *   }}
 * />
 * ```
 */
const ControlledPhoneInput = memo<IControlledPhoneInputProps>(
  ({ control, name, defaultValue = '', PhoneInputProps = {} }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          const formattedValue = formatPhone(value, { removeCountryCode: '+1 ' })

          const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
            onChange(unformatPhone(e.target.value))
          }

          const clearHandler = () => {
            onChange('')
          }

          return (
            <PhoneInput
              onChange={changeHandler}
              onClear={clearHandler}
              value={formattedValue}
              {...PhoneInputProps}
            />
          )
        }}
      />
    )
  }
)

export default ControlledPhoneInput
