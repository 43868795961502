import React, { memo } from 'react'
import { Controller, ControllerProps, useFieldArray } from 'react-hook-form'
import { brandColors, ISelectFieldProps } from 'dpl'
import { Box, Link, makeStyles, Typography } from 'dpl/core'
import { IProductModuleProps, ProductModule } from './ProductModule'

type IBaseControllerProps = ControllerProps<React.ComponentType<ISelectFieldProps>>

export interface IControlledModuleAccessRolesAutocompleteProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  SelectFieldProps?: Omit<ISelectFieldProps, 'onChange' | 'value'>
  isOrgAdmin?: boolean
}

const useControlledModuleAccessRolesAutocompleteStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
  },
}))

export const ControlledModuleAccessRolesAutocomplete =
  memo<IControlledModuleAccessRolesAutocompleteProps>(
    ({ SelectFieldProps, control, isOrgAdmin = false, name }) => {
      const classes = useControlledModuleAccessRolesAutocompleteStyles()
      const { fields } = useFieldArray({
        control,
        name,
      })

      if (fields.length === 0) {
        return null
      }

      return (
        <>
          <Typography variant='subtitle1'>Product Access</Typography>
          <Typography variant='body2' color={brandColors.coolGray5} className={classes.title}>
            Select the products members can access and their roles. To manage organization-wide
            settings and user accounts,{' '}
            <Link href='http://contact.transfix.io' target='_blank'>
              submit a ticket
            </Link>{' '}
            to be an Organization Admin, which includes Product Admin permissions.
          </Typography>
          {fields.map((item, index) => {
            return (
              <Controller
                key={item.id}
                name={`${name}.${index}`}
                control={control}
                defaultValue={item}
                render={({ onChange, value: valueProp }) => {
                  const changeHandler: IProductModuleProps['onChange'] = (
                    targetProductModule,
                    targetValue
                  ) => {
                    onChange({
                      productModule: targetProductModule,
                      team: targetValue,
                    })
                  }

                  return (
                    <Box marginBottom={1.5}>
                      <ProductModule
                        productModule={valueProp.productModule}
                        SelectFieldProps={SelectFieldProps}
                        onChange={changeHandler}
                        onDeactive={changeHandler}
                        value={valueProp.team}
                        disabled={isOrgAdmin}
                      />
                    </Box>
                  )
                }}
              />
            )
          })}
        </>
      )
    }
  )
