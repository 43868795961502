import {
  IModuleRoleResult,
  IOrganizationTeam,
  ModuleTeamKeySuffixes,
  ProductModuleRoles,
} from '../types'
import { isOrganizationAdmin } from './isOrganizationAdmin'

interface IIsModuleRoleProps {
  organizationTeams: IOrganizationTeam[]
  productModuleKey: string
}

function isModuleAdmin({ organizationTeams, productModuleKey }: IIsModuleRoleProps): boolean {
  return organizationTeams.some(
    team => team.key === `${productModuleKey}_${ModuleTeamKeySuffixes.Admins}`
  )
}

function isModuleUser({ organizationTeams, productModuleKey }: IIsModuleRoleProps): boolean {
  return organizationTeams.some(
    team => team.key === `${productModuleKey}_${ModuleTeamKeySuffixes.Users}`
  )
}

interface IGetProductModuleRolesProps {
  /**
   * @description The user's organization teams.
   */
  organizationTeams: IOrganizationTeam[]
  /**
   * @description The keys for the organization's product modules.
   */
  productModules: {
    key: string
    name: string
  }[]
}

/**
 * @returns The user's role for each product module in their organization.
 */
export const getProductModuleRoles = ({
  organizationTeams,
  productModules,
}: IGetProductModuleRolesProps): IModuleRoleResult[] => {
  if (isOrganizationAdmin(organizationTeams)) {
    return productModules.map(({ key, name }) => ({
      moduleKey: key,
      moduleName: name,
      role: ProductModuleRoles.Admin,
    }))
  }

  return productModules.map(({ key, name }) => {
    let role = null

    if (isModuleAdmin({ organizationTeams, productModuleKey: key })) {
      role = ProductModuleRoles.Admin
    } else if (isModuleUser({ organizationTeams, productModuleKey: key })) {
      role = ProductModuleRoles.User
    }

    return {
      moduleName: name,
      moduleKey: key,
      role,
    }
  })
}
