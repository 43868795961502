import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { ProtectedLayout } from 'auth/b2b'
import { PageNotFound } from 'dpl/templates/PageNotFound'
import { GraphiQL } from 'network'
import { useUserDataQuery } from '../graphql/UserData'
import { RootLayout } from '../layouts'
import { PROTECTED_PATHS } from './constants'
import { Home } from './home'
import { MemberProfile } from './member-profile'
import { Members } from './members'
import { UserProfile } from './user-profile'

const { MODE, VITE_CONSOLE_URL } = import.meta.env

const isStaging = ['branch', 'localStaging', 'staging'].includes(MODE)

/**
 * @see https://reactrouter.com/en/6.26.0/routers/create-browser-router
 */
const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '',
        element: <ProtectedLayout redirectTo={`${VITE_CONSOLE_URL}/login`} redirectToExternal />,
        children: [
          {
            path: PROTECTED_PATHS.userProfile,
            element: <UserProfile />,
          },
          {
            path: PROTECTED_PATHS.home,
            element: <Home />,
          },
          {
            path: PROTECTED_PATHS.members,
            element: <Members />,
          },
          {
            path: `${PROTECTED_PATHS.members}/:userId`,
            element: <MemberProfile />,
          },
        ],
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
  ...(!import.meta.env.PROD
    ? [
        {
          path: '/graphiql',
          element: <GraphiQL />,
        },
      ]
    : []),
])

export function Router() {
  const { boot: bootIntercom } = useIntercom()
  const { data: currentUserData } = useUserDataQuery()

  const organizationUserId = currentUserData?.currentSession?.organizationUser?.id
  const { emailAddress, firstName, lastName } =
    currentUserData?.currentSession?.organizationUser?.user || {}
  const { id: companyId = '', name: companyName = '' } =
    currentUserData?.currentSession?.organizationUser?.organization || {}

  bootIntercom({
    hideDefaultLauncher: true,
    userId: organizationUserId ? String(organizationUserId) : undefined,
    name: `${firstName || ''} ${lastName || ''}`.trim(),
    email: emailAddress,
    company: {
      companyId,
      name: companyName,
    },
    customAttributes: {
      /**
       * Staging credentials are used in different modes,
       * we ensure the env is properly set for intercom.
       */
      env: isStaging ? 'staging' : MODE,
    },
  })

  return <RouterProvider router={router} />
}
