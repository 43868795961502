import React from 'react'
import { GridColTypeDef as MuiGridColTypeDef } from '../../../core'
import { DataGridAvatarCell } from '../cells/DataGridAvatarCell'

export const avatarGridColTypeDef: MuiGridColTypeDef = {
  type: 'custom',
  renderCell: ({ value }) => {
    return <DataGridAvatarCell AvatarProps={{ name: value }}>{value}</DataGridAvatarCell>
  },
}
