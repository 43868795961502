import React, { PropsWithChildren, useMemo } from 'react'
import Toast from './Toast'
import { ToastContext } from './ToastContext'
import { useGlobalToast } from './useGlobalToast'

/**
 * When the `<ToastProvider />` is used at the top level of the app, a toast can be triggered using `openToast` from the ToastContext.
 */
function ToastProvider({ children }: PropsWithChildren<{}>) {
  const { closeToast, openToast, toastState } = useGlobalToast()
  const contextValue = useMemo(() => ({ openToast, closeToast }), [closeToast, openToast])

  return (
    <ToastContext.Provider value={contextValue}>
      <Toast {...toastState} onClose={closeToast} />
      {children}
    </ToastContext.Provider>
  )
}

export default ToastProvider
