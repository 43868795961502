import React from 'react'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import MuiSkeleton, {
  SkeletonProps as MuiSkeletonProps,
  SkeletonTypeMap,
} from '@mui/material/Skeleton'

export interface ISkeletonProps extends MuiSkeletonProps {}

/**
 * @see https://v4.mui.com/components/skeleton/#skeleton
 */
const Skeleton: OverridableComponent<SkeletonTypeMap> = (props: ISkeletonProps) => {
  return <MuiSkeleton {...props} />
}

export default Skeleton
