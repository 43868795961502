import { Components } from '@mui/material/styles'
import { brandColors } from '../colors'

/**
 * @name Tabs
 * @see https://mui.com/material-ui/api/tabs/
 */
export const MuiTabsThemeOptions: Components['MuiTabs'] = {
  styleOverrides: {
    root: {
      '&:has(.MuiTabs-scrollableX)': {
        borderBottom: `1px solid ${brandColors.coolGray3}`,
      },
      '&:not(:has(.MuiTabs-scrollableX)) .MuiTabs-flexContainer': {
        display: 'inline-flex',
        borderBottom: `1px solid ${brandColors.coolGray3}`,
      },
    },
    scrollButtons: {
      color: brandColors.coolGray8,

      '&:hover': {
        color: brandColors.skyBlue6,
      },
    },
  },
}
