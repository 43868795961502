/**
 * @description Converts File to an encoded Base64 string in data URL format.
 * @example 'data:application/pdf;base64,JVBERi0xLjQKJf////8KMS…uZm8gMSAwIFIKPj4Kc3RhcnR4cmVmCjg2MjI1CiUlRU9GCg=='
 */
export const convertFileToDataUrl = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = () => reject(new Error(`Error occurred reading file: ${file.name}`))
  })
