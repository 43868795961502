import React from 'react'
import { ICheckboxProps } from './types'

export function CheckboxChecked({ className = '', disabled = false }: ICheckboxProps) {
  return disabled ? (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='16' height='16' rx='2' fill='#A9B2BD' />
      <path d='M4 8.04944L7.11111 11.5062' stroke='#697684' strokeWidth='2' strokeLinecap='round' />
      <path
        d='M11.5555 4.44444L7.1111 11.5062'
        stroke='#697684'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  ) : (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='16' height='16' rx='2' fill='#0072B5' />
      <path d='M4 8.04944L7.11111 11.5062' stroke='white' strokeWidth='2' strokeLinecap='round' />
      <path
        d='M11.8889 4.44444L7.44444 11.5062'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}
