import React, { memo, Key, ReactNode } from 'react'
import { MenuItem } from '../../core'
import TextField, { ITextFieldProps } from '../TextField'

interface ISelectFieldOptions<TValue> {
  label: ReactNode
  value: TValue
}

export interface ISelectFieldProps<TOptionValue extends Key = string>
  extends Omit<ITextFieldProps, 'select' | 'clearable' | 'onClear'> {
  /**
   * Set of options to display in the select field
   * @default []
   */
  options: ISelectFieldOptions<TOptionValue>[]
  /**
   * @default 'SelectField'
   */
  dataTest?: string
}

function SelectFieldComponent<TOptionValue extends Key = string>({
  options = [],
  dataTest = 'SelectField',
  ...props
}: ISelectFieldProps<TOptionValue>) {
  return (
    <TextField select dataTest={dataTest} {...props}>
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value} data-test={`${dataTest}-option-${value}`}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export const SelectField = memo(SelectFieldComponent) as typeof SelectFieldComponent
