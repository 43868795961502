import React, { memo } from 'react'
import classnames from 'classnames'
import { makeStyles, MenuItem, Typography } from '../../../core'
import { brandColors } from '../../../theme'
import { Tag } from '../Tag'
import { TAG_SIZE, TAG_STYLE, TOptionValue, IFunctionalTagOption } from '../types'

interface IFunctionalTagOptionProps<T extends TOptionValue = string> {
  itemClassNames?: string
  option: IFunctionalTagOption<T>
  size: TAG_SIZE
  dataTest: string
  onOptionSelected: (option: IFunctionalTagOption<T>) => void
  showDescription?: boolean
  autoFocus?: boolean
}

const useFunctionalTagOptionStyles = makeStyles(theme => ({
  listBoxItem: {
    display: 'block',
    flex: '0 0 auto',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    margin: 0,
    padding: theme.spacing(0.75, 2),
    textAlign: 'left',
    background: 'inherit',
    '&:hover, &:focus, &:active': {
      backgroundColor: brandColors.coolGray1,
    },
  },
  description: {
    marginTop: theme.spacing(0.5),
    textWrap: 'wrap',
    color: brandColors.gray3,
  },
}))

export const FunctionalTagOption = memo<IFunctionalTagOptionProps>(
  ({ autoFocus, dataTest, itemClassNames, onOptionSelected, option, showDescription, size }) => {
    const styles = useFunctionalTagOptionStyles()
    const { label, outlineOnly, style } = option
    const itemClasses = classnames(styles.listBoxItem, itemClassNames)

    const optionClickHandler = () => {
      onOptionSelected(option)
    }

    return (
      <MenuItem
        onClick={optionClickHandler}
        className={itemClasses}
        data-test={dataTest}
        disabled={option.disabled}
        autoFocus={autoFocus}>
        <Tag
          size={size}
          label={label}
          tagStyle={option.disabled ? TAG_STYLE.disabled : style}
          outlineOnly={outlineOnly}
        />
        {showDescription && (
          <Typography variant='caption' component='div' className={styles.description}>
            {option.description}
          </Typography>
        )}
      </MenuItem>
    )
  }
)

export default FunctionalTagOption
