import { brandColors } from '../../../theme'
import { TEnvironment } from './types'

export const ENVIRONMENT_BG_COLORS: Record<TEnvironment, string> = {
  dev: brandColors.warning0,
  localStaging: brandColors.warning0,
  branch: brandColors.warning0,
  staging: brandColors.warning0,
  uat: brandColors.skyBlue2,
  production: brandColors.skyBlue2,
}

export const ENVIRONMENT_NAMES: Record<TEnvironment, string> = {
  dev: '',
  localStaging: '',
  branch: 'Staging',
  staging: 'Staging',
  uat: 'Demo',
  production: '',
}
