import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'

/**
 * @name AvatarGroup
 * @see https://mui.com/material-ui/api/avatar-group/
 */
export const MuiAvatarGroupThemeOptions: Components['MuiAvatarGroup'] = {
  defaultProps: {
    variant: 'circular',
    spacing: 4,
  },
  styleOverrides: {
    root: {
      fontWeight: BASE_THEME.typography.fontWeightBold,
    },
    avatar: {
      borderWidth: BASE_THEME.spacing(0.125),
    },
  },
}
