import React from 'react'
import { Table, TableHead, TableCell, TableRow, TableBody } from 'dpl/core'
import {
  IOrganizationModulesTableRowProps,
  OrganizationModulesTableRow,
} from './OrganizationModulesTableRow'

export interface IOrganizationModulesTableProps {
  /**
   * A list of module data to be displayed in the table.
   * @default []
   * @example
   * [
   *   { moduleName: 'Custom Cost Model', activatedAt: '2023-07-15T00:00:00Z' },
   *   { moduleName: 'Routing Guide Automation', activatedAt: '2023-08-01T00:00:00Z' }
   * ]
   */
  modules: IOrganizationModulesTableRowProps[]
}

export function OrganizationModulesTable({ modules = [] }: IOrganizationModulesTableProps) {
  return (
    <Table data-test='modules-table'>
      <TableHead>
        <TableRow>
          <TableCell>Products</TableCell>
          <TableCell>Members</TableCell>
          <TableCell>Admins</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {modules.map(module => (
          <OrganizationModulesTableRow key={module.moduleName} {...module} />
        ))}
      </TableBody>
    </Table>
  )
}
