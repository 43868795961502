import React, { useCallback, useState } from 'react'
import { DataGridFiltersHeader } from './DataGridFiltersHeader'
import { DataGridFiltersMenu } from './DataGridFiltersMenu'
import { IDataGridSortOption } from './components'
import { SORT_QUERY_PARAM_KEY } from './constants'
import { IUseDataGridQueryParamsProps, useDataGridQueryParams } from './hooks'
import { IDataGridFilters, TDataGridFiltersConfig } from './types'

export interface IDataGridFiltersProps<
  TFilters extends IDataGridFilters,
  TSortValue extends string,
> {
  /**
   * @default 'DataGridFilters'
   */
  dataTest?: string
  /**
   * Configuration object for the filters
   * @example { userSearch: { type: 'textInput' }, isActive: { type: 'switch' }, }
   */
  filtersConfig: TDataGridFiltersConfig<TFilters>
  /**
   * Default values for the filters
   * @note Default values do not change the url. They are only used to initialize the filters
   * @example { userSearch: 'John Doe' }
   * @default {}
   */
  defaultFilterValues?: Partial<TFilters>
  /**
   * Sort options for the data grid
   */
  sortOptions?: IDataGridSortOption<TSortValue>[]
  /**
   * Default sort option for the data grid
   */
  defaultSortValue?: TSortValue
  /**
   * Callback function that is called when a one of the filters or sort value changes
   */
  onFilterOrSortChange?: (queryParams: TFilters & { [SORT_QUERY_PARAM_KEY]: TSortValue }) => void
  /**
   * Limits the number of filters visible in DataGridFiltersHeader
   * @default {6}
   */
  visibleFiltersLimit?: number
  /**
   * Options for the query params hook
   */
  queryParamOptions?: IUseDataGridQueryParamsProps<TFilters, TSortValue>['queryParamOptions']
}

function DataGridFiltersComponent<TFilters extends IDataGridFilters, TSortValue extends string>({
  dataTest = 'DataGridFilters',
  defaultFilterValues = {},
  filtersConfig,
  onFilterOrSortChange,
  sortOptions,
  defaultSortValue,
  visibleFiltersLimit,
  queryParamOptions,
}: IDataGridFiltersProps<TFilters, TSortValue>) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { clearAllFilters, queryParams, setFiltersAndSort, setSingleFilter, setSort } =
    useDataGridQueryParams<TFilters, TSortValue>({
      filtersConfig,
      defaultFilterValues,
      defaultSortValue,
      onQueryParamChange: onFilterOrSortChange,
      queryParamOptions,
    })

  const filtersMenuToggleHandler = useCallback(() => {
    setIsMenuOpen(prev => !prev)
  }, [])

  const applyMenuFiltersAndSortHandler = useCallback(
    (params: TFilters & { [SORT_QUERY_PARAM_KEY]: TSortValue }) => {
      setFiltersAndSort(params)
      setIsMenuOpen(false)
    },
    [setFiltersAndSort]
  )

  return (
    <div data-test={dataTest}>
      <DataGridFiltersHeader
        filtersConfig={filtersConfig}
        sortOptions={sortOptions}
        queryParams={queryParams}
        onFilterChange={setSingleFilter}
        onAllFiltersClick={filtersMenuToggleHandler}
        onClearAllClick={clearAllFilters}
        onSortChange={setSort}
        dataTest={`${dataTest}-header`}
        visibleFiltersLimit={visibleFiltersLimit}
      />
      <DataGridFiltersMenu
        open={isMenuOpen}
        onClose={filtersMenuToggleHandler}
        filtersConfig={filtersConfig}
        queryParams={queryParams}
        onApply={applyMenuFiltersAndSortHandler}
        onClearAllClick={clearAllFilters}
        sortOptions={sortOptions}
        dataTest={`${dataTest}-menu`}
      />
    </div>
  )
}

export const DataGridFilters = React.memo(
  DataGridFiltersComponent
) as typeof DataGridFiltersComponent
