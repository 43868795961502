import moment from 'moment-timezone'
import { AllowedTimezones } from '../../../constants/allowedTimezones'
import FORMATS from '../../../constants/datetime'
import { isTimeOnTheHour } from '../../RelativeDateTime'

export type TDatetimeRangeKey = 'dateAndYear' | 'time' | 'onlyTime' | 'T24time'

export interface IFormatDateTimeRangeArgs {
  startAt: string
  endAt: string
  /**
   * A string value indicating the timezone in which you want to display the
   * given time. This is a required field, that should either come from the
   * backend or generated using `moment.tz.guess()`.
   *
   * @example 'America/New_York'
   */
  startAtTimezone: string | AllowedTimezones
  /**
   * A string value indicating the timezone in which you want to display the
   * given time. This is a required field, that should either come from the
   * backend or generated using `moment.tz.guess()`.
   *
   * @example 'America/New_York'
   */
  endAtTimezone: string | AllowedTimezones
  /**
   * The format you wish to display the given datetime in – this should map to
   * an available format in the datetimes guidelines in figma.
   */
  format: TDatetimeRangeKey
  /**
   * When set to true, enables the abbreviated format
   */
  abbreviated?: boolean
  /**
   * Set to true if you want to append the timezone to the end of the formatted
   * string.
   */
  showTimezone?: boolean
  /**
   * If set to true, truncates time values to only show the hour if the time is on the hour.
   * i.e. 8:00am would become 8am
   */
  timeShort?: boolean
  /**
   * If true, 'am' and 'pm' is displayed 'AM' and 'PM'
   */
  isUppercaseAmPm?: boolean
}

export const formatDateTimeRange = ({
  abbreviated = false,
  endAt,
  endAtTimezone,
  format,
  showTimezone = true,
  startAt,
  startAtTimezone,
  timeShort = false,
}: IFormatDateTimeRangeArgs): string => {
  const current = moment()
  const start = moment.tz(startAt, startAtTimezone)
  const end = moment.tz(endAt, endAtTimezone)

  const shouldShortenStartTime = isTimeOnTheHour(start) && timeShort
  const shouldShortenEndTime = isTimeOnTheHour(end) && timeShort

  const isSameYear = end.isValid() ? start.isSame(end, 'years') : current.isSame(start, 'years')
  const isSameMonth = start.isSame(end, 'months')
  const isSameDay = start.isSame(end, 'days')
  const isSamePartOfDay = start.format('a') === end.format('a')
  const isSameTimezone = start.format('z') === end.format('z')

  switch (format) {
    case 'dateAndYear': {
      const startFormat = isSameYear ? FORMATS.monthDay : FORMATS.readableFormat // Aug 11 // Aug 11 2020
      const endFormat = isSameMonth ? FORMATS.dayYear : FORMATS.readableFormat // 11, 2020 // Aug 11 2020

      // eslint-disable-next-line no-irregular-whitespace
      return `${start.format(startFormat)}${' '}${'–'}${' '}${end.format(endFormat)}`
    }

    case 'time': {
      let startFormat: string = shouldShortenStartTime
        ? FORMATS.dayMonthYearTimeOnlyHours // Tue, Aug 11 2020, 12am
        : FORMATS.dayMonthYearTime // Tue, Aug 11 2020, 12:00am
      let endFormat: string = shouldShortenStartTime
        ? FORMATS.dayMonthYearTimeOnlyHours // Tue, Aug 11 2020, 12am
        : FORMATS.dayMonthYearTime // Tue, Aug 11 2020, 12:00am

      if (isSameYear && !abbreviated) {
        const samePartOfDayFormatStart = shouldShortenStartTime
          ? FORMATS.dayMonthTimeNoAmPmOnlyHours // Tue, Aug 11, 12
          : FORMATS.dayMonthTimeNoAmPm // Tue, Aug 11, 15:00
        const diffPartOfDayFormatStart = shouldShortenStartTime
          ? FORMATS.dayMonthTimeOnlyHours // Tue, Aug 11, 12am
          : FORMATS.dayMonthTime // Tue, Aug 11, 12:00am

        const sameDayFormatEnd = shouldShortenEndTime
          ? FORMATS.militaryTimeFormatShortHour12H // 12am
          : FORMATS.militaryTimeFormatShort12H // 12:00am
        const diffDayFormatEnd = shouldShortenEndTime
          ? FORMATS.dayMonthTimeOnlyHours // Tue, Aug 11, 12am
          : FORMATS.dayMonthTime // Tue, Aug 11, 12:00am

        startFormat = isSamePartOfDay ? samePartOfDayFormatStart : diffPartOfDayFormatStart

        endFormat = isSameDay ? sameDayFormatEnd : diffDayFormatEnd
      } else if (isSameYear && abbreviated) {
        const samePartOfDayFormatStart = shouldShortenStartTime
          ? FORMATS.dayMonthTimeNoAmPmShortOnlyHours // Tue, 8/11, 12
          : FORMATS.dayMonthTimeNoAmPmShort // Tue, 8/11, 12:00
        const diffPartOfDayFormatStart = shouldShortenStartTime
          ? FORMATS.dayMonthTimeShortOnlyHours // Tue, 8/11, 12am
          : FORMATS.dayMonthTimeShort // Tue, 8/11, 12:00am

        const sameDayFormatEnd = shouldShortenEndTime
          ? FORMATS.militaryTimeFormatShortHour12H // 12am
          : FORMATS.militaryTimeFormatShort12H // 12:00am
        const diffDayFormatEnd = shouldShortenEndTime
          ? FORMATS.dayMonthTimeShortOnlyHours // Tue, 8/11, 12am
          : FORMATS.dayMonthTimeShort // Tue, 8/11, 12:00am

        startFormat = isSamePartOfDay ? samePartOfDayFormatStart : diffPartOfDayFormatStart
        endFormat = isSameDay ? sameDayFormatEnd : diffDayFormatEnd
      }

      // Always show timezone at start if timezones are different
      if (!isSameTimezone) startFormat += ' z'

      // Show timezone for end if prop is true, or if different
      if (showTimezone || !isSameTimezone) endFormat += ' z'

      // eslint-disable-next-line no-irregular-whitespace
      return `${start.format(startFormat)}${
        endAt ? `${' '}${'–'}${' '}${end.format(endFormat)}` : ''
      }`
    }

    case 'T24time': {
      let startFormat: string = shouldShortenStartTime
        ? FORMATS.dayMonthYearTimeOnlyHoursT24 // Tue, Aug 11 2020, 15
        : FORMATS.dayMonthYearTimeT24 // Tue, Aug 11 2020, 15:00
      let endFormat: string = shouldShortenStartTime
        ? FORMATS.dayMonthYearTimeOnlyHoursT24 // Tue, Aug 11 2020, 15
        : FORMATS.dayMonthYearTimeT24 // Tue, Aug 11 2020, 15:00

      if (isSameYear && !abbreviated) {
        const samePartOfDayFormatStart = shouldShortenStartTime
          ? FORMATS.dayMonthTimeNoAmPmOnlyHoursT24 // Tue, Aug 11, 12
          : FORMATS.dayMonthTimeNoAmPmT24 // Tue, Aug 11, 15:00
        const diffPartOfDayFormatStart = shouldShortenStartTime
          ? FORMATS.dayMonthTimeOnlyHoursT24 // Tue, Aug 11, 15
          : FORMATS.dayMonthTimeT24 // Tue, Aug 11, 15:00

        const sameDayFormatEnd = FORMATS.militaryTimeFormat
        const diffDayFormatEnd = shouldShortenEndTime
          ? FORMATS.dayMonthTimeOnlyHoursT24 // Tue, Aug 11, 15
          : FORMATS.dayMonthTimeT24 // Tue, Aug 11, 15:00

        startFormat = isSamePartOfDay ? samePartOfDayFormatStart : diffPartOfDayFormatStart

        endFormat = isSameDay ? sameDayFormatEnd : diffDayFormatEnd
      } else if (isSameYear && abbreviated) {
        const samePartOfDayFormatStart = shouldShortenStartTime
          ? FORMATS.dayMonthTimeNoAmPmShortOnlyHoursT24 // Tue, 8/11, 12
          : FORMATS.dayMonthTimeNoAmPmShortT24 // Tue, 8/11, 12:00
        const diffPartOfDayFormatStart = shouldShortenStartTime
          ? FORMATS.dayMonthTimeShortOnlyHoursT24 // Tue, 8/11, 12am
          : FORMATS.dayMonthTimeShortT24 // Tue, 8/11, 12:00am

        const sameDayFormatEnd = FORMATS.militaryTimeFormat
        const diffDayFormatEnd = shouldShortenEndTime
          ? FORMATS.dayMonthTimeShortOnlyHoursT24 // Tue, 8/11, 12am
          : FORMATS.dayMonthTimeShortT24 // Tue, 8/11, 12:00am

        startFormat = isSamePartOfDay ? samePartOfDayFormatStart : diffPartOfDayFormatStart
        endFormat = isSameDay ? sameDayFormatEnd : diffDayFormatEnd
      }

      // Always show timezone at start if timezones are different
      if (!isSameTimezone) startFormat += ' z'

      // Show timezone for end if prop is true, or if different
      if (showTimezone || !isSameTimezone) endFormat += ' z'

      // eslint-disable-next-line no-irregular-whitespace
      return `${start.format(startFormat)}${
        endAt ? `${' '}${'–'}${' '}${end.format(endFormat)}` : ''
      }`
    }

    case 'onlyTime': {
      const startFormat = start.format(
        `${FORMATS.militaryTimeFormat}${showTimezone && !isSameTimezone ? ' z' : ''}`
      )
      const endFormat = end.format(`${FORMATS.militaryTimeFormat}${showTimezone ? ' z' : ''}`)

      return `${startFormat}${endAt ? `${' '}${'–'}${' '}${endFormat}` : ''}`
    }

    default: {
      return ''
    }
  }
}

export const formatUppercaseAmPm = (formattedDateTime: string) => {
  return formattedDateTime.replace(/am|pm/g, match => match.toUpperCase())
}
