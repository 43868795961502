import React, { useCallback } from 'react'
import { FormGroup } from '../../../../../../core'
import { Checkbox, ICheckboxProps } from '../../../../../Checkbox'
import { IDataGridFilterOption, IDataGridFilterProps } from '../types'

export interface IDataGridCheckboxGroupProps extends IDataGridFilterProps<string[]> {
  /**
   * An array of options for the checkbox group.
   */
  options: IDataGridFilterOption[]
  /**
   * The data test attribute for testing purposes.
   * @default 'DataGridCheckboxGroup'
   */
  dataTest?: string
}

export function DataGridCheckboxGroup({
  dataTest = 'DataGridCheckboxGroup',
  options = [],
  value: valueProp = [],
  onChange,
  name,
}: IDataGridCheckboxGroupProps) {
  const changeHandler: ICheckboxProps['onChange'] = useCallback(
    event => {
      if (!onChange) {
        return
      }

      const { target } = event || {}
      const { checked, value } = target || {}

      if (checked) {
        onChange({ name, value: [...new Set([...valueProp, value])] })
      } else {
        onChange({ name, value: valueProp.filter(v => v !== value) })
      }
    },
    [name, onChange, valueProp]
  )

  return (
    <FormGroup data-test={dataTest}>
      {options.map(({ label: checkboxLabel, value: checkboxValue }) => (
        <Checkbox
          key={checkboxValue}
          dataTest={`${dataTest}-checkbox-${checkboxValue}`}
          label={checkboxLabel}
          value={checkboxValue}
          onChange={changeHandler}
          checked={valueProp.includes(checkboxValue)}
          checkboxCentered
        />
      ))}
    </FormGroup>
  )
}
