import React, { memo, ReactNode, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase'
import { Tooltip } from '../../../components'
import { IconButton } from '../../../components/IconButton'
import {
  Typography,
  ITypographyProps,
  makeStyles,
  Button,
  IButtonProps,
  Divider,
} from '../../../core'
import LeftArrowIcon from '../../../icons/build/LeftArrowIcon'

type TPageType = 'homePage' | 'mainPage' | 'subPage'

export interface IPageTitleProps {
  /**
   * Typography variant shown will be based off the page variant
   * Use `titleProps` if needed to override
   */
  title?: string
  /**
   * Caption will be shown within the title, meaning, title needs
   * to be present in order to have a caption.
   */
  titleCaption?: string
  /**
   * Type of page
   * @default 'main-page'
   */
  pageType?: TPageType
  /**
   * For each action provided, a button will appear on the right of the title
   * @example {
   *  label: 'Edit Profile',
   *  onClick: () => {},
   *  buttonProps: { variant: 'outlined' }
   * }
   */
  actions?: {
    label: string
    onClick?: () => void
    disabledDescription?: string
    buttonProps?: IButtonProps
  }[]
  /**
   * Optional props used to customize title
   */
  titleProps?: ITypographyProps
  className?: string
  /**
   * @default true
   */
  showDivider?: boolean
  onBackButtonClick?: () => void
  /**
   * @default 'PageTitle'
   */
  dataTest?: string
  /**
   * Extra content to be shown in the middle of the title and actions
   */
  extra?: ReactNode
}

const titleVariant: Record<TPageType, ITypographyProps['variant']> = {
  homePage: 'h2',
  mainPage: 'h3',
  subPage: 'h4',
}

const usePageTitleStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(3, 2),
    flexWrap: 'wrap',
    /**
     * Magic number to account for size when actions are present 🪄✨
     */
    minHeight: 96,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
  },
}))

export const PageTitle = memo<IPageTitleProps>(
  ({
    titleCaption,
    title,
    pageType = 'mainPage',
    titleProps = {},
    actions = [],
    className,
    showDivider = true,
    onBackButtonClick,
    dataTest = 'PageTitle',
    extra = null,
  }) => {
    const navigate = useNavigate()

    const classes = usePageTitleStyles()
    const rootClassNames = classNames(classes.root, className)

    const backButtonClickHandler = useCallback(() => {
      if (onBackButtonClick) onBackButtonClick()
      else {
        navigate(-1) // defaults to going back one page
      }
    }, [navigate, onBackButtonClick])

    return (
      <>
        <div className={rootClassNames} data-test={dataTest}>
          {pageType === 'subPage' && (
            <IconButton
              Icon={LeftArrowIcon}
              onClick={backButtonClickHandler}
              size='large'
              color='secondary'
              dataTest='page-back-button'
            />
          )}
          {title && (
            <Typography
              variant={titleVariant[pageType]}
              {...titleProps}
              className={classes.title}
              data-test='page-title'>
              {title}
              {titleCaption && (
                <Typography component='span' variant='subtitle2' color='GrayText'>
                  {titleCaption}
                </Typography>
              )}
            </Typography>
          )}
          {extra}
          {actions.map(action => {
            const { label, onClick, buttonProps = {}, disabledDescription } = action || {}
            const { disabled } = buttonProps || {}

            if (disabled && disabledDescription) {
              return (
                <Tooltip key={label} title={disabledDescription}>
                  <div>
                    <Button
                      data-test={`${kebabCase(label)}-button`}
                      variant='text'
                      onClick={onClick}
                      {...buttonProps}>
                      {label}
                    </Button>
                  </div>
                </Tooltip>
              )
            }

            return (
              <Button
                key={label}
                data-test={`${kebabCase(label)}-button`}
                variant='text'
                onClick={onClick}
                {...buttonProps}>
                {label}
              </Button>
            )
          })}
        </div>
        {showDivider && <Divider />}
      </>
    )
  }
)
