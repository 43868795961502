import React, { useMemo } from 'react'
import { makeStyles, Theme, Typography } from '../../../core'
import { brandColors } from '../../../theme'
import { ENVIRONMENT_BG_COLORS, ENVIRONMENT_NAMES } from './constants'
import { TEnvironment } from './types'

export interface IEnvironmentBannerProps {
  environment: TEnvironment
}

const useEnvironmentBannerStyles = makeStyles<Theme, IEnvironmentBannerProps>(theme => ({
  root: {
    backgroundColor: ({ environment }) => ENVIRONMENT_BG_COLORS[environment],
    padding: theme.spacing(1.5, 2),
    width: '100vw',
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}))

export function EnvironmentBanner(props: IEnvironmentBannerProps) {
  const { environment } = props
  const classes = useEnvironmentBannerStyles(props)

  const title = useMemo(() => {
    const environmentName = ENVIRONMENT_NAMES[environment]
    return `${environmentName} Environment`
  }, [environment])

  return (
    <div className={classes.root}>
      <Typography
        color={brandColors.coolGray8}
        className={classes.title}
        variant='subtitle2'
        component='span'>
        {title}
      </Typography>
    </div>
  )
}
