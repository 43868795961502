import React, { memo, useState, useEffect, ChangeEvent, useMemo, useCallback } from 'react'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { v4 as uuid } from 'uuid'
import { Theme, makeStyles, Autocomplete } from '../../core'
import { boxShadowLevels, brandColors } from '../../theme'
import { usePrevious } from '../../utils/usePrevious'
import { TextField, ITextFieldProps } from '../TextField'
import { TimeSuggestion } from './components/TimeSuggestion'
import { DEFAULT_SUGGESTION_STEP_MINUTES } from './constants'
import { TIME_INPUT_FORMAT } from './types'
import { getSuggestions, parseTime, buildDateTime } from './utils'

export interface ITimeInputProps
  extends Pick<
    ITextFieldProps,
    | 'label'
    | 'size'
    | 'helperText'
    | 'required'
    | 'error'
    | 'dataTest'
    | 'fullWidth'
    | 'disabled'
    | 'placeholder'
    | 'clearable'
    | 'onClear'
  > {
  value: string
  targetDate?: string
  anchorDateTime?: {
    date: string
    time: string
  }
  id?: string
  className?: string
  /**
   * @default 'h:mma', // 12:00am (Military short)
   */
  timeFormat?: TIME_INPUT_FORMAT
  /**
   * Number of minutes each option should be incremented by
   * @default 30
   */
  minuteIncrement?: number
  onChange(change: ChangeEvent<HTMLInputElement>, dayDiff: number): void
  ariaLabel?: string
  /**
   * @default true
   */
  showSuggestionDropdown?: boolean
}

const useTimeInputStyles = makeStyles<Theme, { clearable: boolean }>(theme => ({
  inputContainer: {
    '& > div.MuiInputBase-root.MuiOutlinedInput-root': {
      padding: 0,
    },
    '& input': {
      width: ({ clearable }) => (clearable ? '115px' : '80px'),
    },
  },
  inputContainerFullWidth: {
    '& input': {
      width: 'auto !important',
    },
  },
  paper: {
    boxShadow: boxShadowLevels.high,
    backgroundColor: brandColors.white,
    border: `1px solid ${brandColors.coolGray3}`,
    borderRadius: '2px',
    margin: theme.spacing(1, 0, 0),
    minWidth: '144px',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: 'auto',
    willChange: 'transform',
    zIndex: 4,
  },
  listbox: {
    '& > li': {
      fontSize: theme.typography.caption.fontSize,
      padding: theme.spacing(0.5, 1.25),
      color: brandColors.black,
      '&:hover': {
        backgroundColor: `${brandColors.skyBlue2} !important`,
      },
    },
  },
  selected: {
    backgroundColor: `${brandColors.skyBlue2} !important`,
  },
}))

export const TimeInput = memo<ITimeInputProps>(
  ({
    value: valueProp,
    targetDate,
    onChange,
    anchorDateTime: { date: anchorDate = '', time: anchorTime = '' } = {},
    id = uuid(),
    dataTest,
    placeholder,
    label,
    required,
    error,
    size,
    className = '',
    timeFormat = TIME_INPUT_FORMAT.TWELVE_HR_SHORT,
    disabled,
    minuteIncrement = DEFAULT_SUGGESTION_STEP_MINUTES, // 30
    ariaLabel,
    fullWidth = false,
    helperText,
    clearable = false,
    onClear,
    showSuggestionDropdown = true,
  }) => {
    const classes = useTimeInputStyles({ clearable })
    const [inputValue, setInputValue] = useState<string>(valueProp)
    const [value, setValue] = useState<string | null>(valueProp)
    const [isInvalid, setIsInvalid] = useState<boolean>(false)
    const [guessedOption, setGuessedOption] = useState<string>('')

    const anchorDateTime = useMemo(
      () =>
        anchorDate && anchorTime && targetDate
          ? buildDateTime(anchorDate, anchorTime, timeFormat)
          : undefined,
      [anchorDate, anchorTime, targetDate, timeFormat]
    )

    const suggestions: string[] = useMemo(
      () =>
        showSuggestionDropdown
          ? getSuggestions({
              guessedOption,
              anchorDateTime,
              timeFormat,
              minuteIncrement,
            })
          : [],
      [showSuggestionDropdown, guessedOption, anchorDateTime, timeFormat, minuteIncrement]
    )

    const highlightedOption = useMemo(() => {
      if (guessedOption) return guessedOption

      if (value) return value

      // Add an hour to the anchor date time
      if (anchorDateTime) return anchorDateTime.clone().add(1, 'h').format(timeFormat)

      const now = moment()
      return now
        .clone()
        .subtract(now.minute() % minuteIncrement, 'm')
        .format(timeFormat)
    }, [guessedOption, value, anchorDateTime, timeFormat, minuteIncrement])

    const changeHandler = useCallback(
      (event, newValue: string | null) => {
        if (clearable && newValue === '' && typeof onClear === 'function') {
          onClear()
        }
        setValue(newValue)
      },
      [clearable, onClear]
    )

    const optionSelectHandler = useCallback(
      (newValue: string, dayDiff: number = 0) => {
        onChange({ target: { value: newValue, id } } as ChangeEvent<HTMLInputElement>, dayDiff)
        if (newValue && !isInvalid) {
          setIsInvalid(false)
        }
      },
      [id, isInvalid, onChange]
    )

    const inputChangeHandler = useCallback(
      (e, newVal) => {
        if (e) {
          setInputValue(newVal)
          optionSelectHandler(newVal)
        }
      },
      [setInputValue, optionSelectHandler]
    )

    /**
     * When the value prop updates, also update the inputValue
     * and update validation
     */
    useEffect(() => {
      if (valueProp !== undefined) {
        setInputValue(valueProp || '')
        setValue(valueProp)
        setIsInvalid(false)
      } else if (inputValue && !isInvalid) {
        setInputValue(valueProp)
        setValue(valueProp)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueProp])

    /**
     * If the date prop has changed, calculate the new value
     * and trigger the onChange handler
     */
    const prevAnchorDateTime = usePrevious(anchorDateTime)
    useEffect(() => {
      if (anchorDateTime && prevAnchorDateTime && value) {
        const currentDateTime = buildDateTime(targetDate, value, timeFormat)
        const diffMinutes = currentDateTime ? currentDateTime.diff(prevAnchorDateTime, 'm') : 0
        const newValue = anchorDateTime.clone().add(diffMinutes, 'm')
        let dayDiff = 0
        if (targetDate && !newValue.isSame(targetDate, 'd')) {
          dayDiff = newValue.diff(targetDate, 'd') || (newValue.isBefore(targetDate) ? -1 : 1)
        }
        optionSelectHandler(newValue.format(timeFormat), dayDiff)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorDateTime])

    /**
     * When the input value updates, set the guessedOption
     * and update validation
     */
    useEffect(() => {
      if (!inputValue && isInvalid) {
        setIsInvalid(false)
      }

      if (inputValue !== value) {
        const parsed = parseTime(inputValue, timeFormat)
        setGuessedOption(parsed || '')
        setIsInvalid(!!inputValue && !parsed)
      }
    }, [inputValue, isInvalid, timeFormat, value])

    const textInputClassName = classNames(classes.inputContainer, {
      [classes.inputContainerFullWidth]: fullWidth,
    })

    const renderOption = useCallback(
      (props, option) => {
        const { key, ...optionProps } = props
        const selected = option === highlightedOption
        const className = selected
          ? `${classes.selected} ${optionProps.className}`
          : optionProps.className
        return (
          <li key={key} {...optionProps} className={className}>
            <TimeSuggestion
              label={option}
              targetDate={targetDate}
              anchorDateTime={anchorDateTime}
              timeFormat={timeFormat}
            />
          </li>
        )
      },
      [anchorDateTime, classes.selected, highlightedOption, targetDate, timeFormat]
    )

    return (
      <div className={className} data-test={`${dataTest}-container`}>
        <Autocomplete
          id={id}
          freeSolo
          autoHighlight
          value={value}
          onChange={changeHandler}
          inputValue={inputValue}
          onInputChange={inputChangeHandler}
          options={suggestions}
          classes={{
            paper: classes.paper,
            listbox: classes.listbox,
          }}
          renderInput={params => {
            return (
              <div ref={params.InputProps.ref}>
                <TextField
                  {...params.inputProps}
                  color='primary'
                  dataTest={dataTest}
                  placeholder={placeholder || highlightedOption}
                  label={label}
                  required={required}
                  fullWidth={fullWidth}
                  error={isInvalid || error}
                  helperText={isInvalid ? 'Invalid time' : helperText}
                  clearable={clearable}
                  className={textInputClassName}
                  size={size}
                  disabled={disabled}
                  aria-label={ariaLabel}
                  onClear={onClear}
                />
              </div>
            )
          }}
          renderOption={renderOption}
        />
      </div>
    )
  }
)

export default TimeInput
