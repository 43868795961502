import { DateTimePickerProps } from 'react-flatpickr'

export const FLATPICKR_DEFAULTS: DateTimePickerProps['options'] = {
  allowInput: true,
  defaultHour: 0,
  time_24hr: true,
  altInputClass: 'date-input',
  dateFormat: 'm/d/Y',
  monthSelectorType: 'static',
}
