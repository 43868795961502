import React, { memo } from 'react'
import { makeStyles } from '../../core/styles'
import FacetRadio from '../FacetRadio'
import LabelComponent from '../LabelComponent'

export interface IFacetRadioOption<T = string> {
  label: string
  value: T
}

export interface IFacetRadioGroupProps<T = string> {
  /**
   * @example [{ label: 'Dry Van', value: 'dry_van' }, { label: 'Reefer', value: 'reefer' }]
   */
  options: IFacetRadioOption<T>[]
  /**
   * @example 'dry_van'
   */
  value: T | undefined | null
  onChange: (value: T) => void
  dataTest?: string
  label?: string
  /**
   * @default false
   */
  required?: boolean
  errorMessage?: string
}

const useFacetRadioGroupStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  facets: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
}))

/**
 * @deprecated Use the `ToggleButtonGroup` component instead.
 */
const FacetRadioGroup = memo<IFacetRadioGroupProps>(
  ({
    dataTest = '',
    errorMessage,
    label,
    onChange,
    options,
    required = false,
    value: groupValue,
  }) => {
    const classes = useFacetRadioGroupStyles()
    return (
      <div className={classes.root}>
        {label && <LabelComponent label={label} required={required} />}
        <div className={classes.facets}>
          {options.map(({ label, value: optionValue }) => (
            <FacetRadio
              key={optionValue}
              value={optionValue}
              label={label}
              dataTest={`${dataTest}-${optionValue}`}
              selected={groupValue === optionValue}
              onClick={onChange}
            />
          ))}
        </div>
        {errorMessage && (
          <div className='invalid'>
            <span className='validation-message'>{errorMessage}</span>
          </div>
        )}
      </div>
    )
  }
)

export default FacetRadioGroup
