import { BooleanParam, ArrayParam, StringParam, JsonParam } from 'use-query-params'
import {
  DataGridCheckboxGroup,
  DataGridMultiSelect,
  DataGridRadioGroup,
  DataGridSearchInput,
  DataGridSingleSelect,
  DataGridSwitch,
  DataGridToggleButton,
} from './components'
import { IDataGridFilterConfig } from './types'

/**
 * Key for the sort query param
 */
export const SORT_QUERY_PARAM_KEY = 'sort'

/**
 * Map of filter types to query param types
 */
export const FILTERS_QUERY_PARAM_MAP: Record<
  IDataGridFilterConfig['type'],
  typeof StringParam | typeof ArrayParam | typeof BooleanParam | typeof JsonParam
> = {
  textInput: StringParam,
  singleSelect: StringParam,
  multiSelect: ArrayParam,
  switch: BooleanParam,
  toggleButton: BooleanParam,
  custom: JsonParam,
}

/**
 * Map of filter types to empty values
 */
export const FILTERS_EMPTY_VALUES_MAP: Record<IDataGridFilterConfig['type'], any> = {
  textInput: '',
  singleSelect: '',
  multiSelect: [],
  switch: false,
  toggleButton: false,
  custom: null,
}

/**
 * Map of filter types to filter components for the header
 */
export const HEADER_FILTERS_COMPONENT_MAP: Record<
  Exclude<IDataGridFilterConfig['type'], 'custom'>,
  React.FC<any>
> = {
  textInput: DataGridSearchInput,
  singleSelect: DataGridSingleSelect,
  multiSelect: DataGridMultiSelect,
  switch: DataGridSwitch,
  toggleButton: DataGridToggleButton,
}

/**
 * Map of filter types to filter components for the side panel menu
 */
export const MENU_FILTERS_COMPONENT_MAP: Record<
  Exclude<IDataGridFilterConfig['type'], 'custom'>,
  React.FC<any>
> = {
  textInput: DataGridSearchInput,
  singleSelect: DataGridRadioGroup,
  multiSelect: DataGridCheckboxGroup,
  switch: DataGridSwitch,
  toggleButton: DataGridSwitch,
}
