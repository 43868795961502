import React, { memo, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { Box, makeStyles } from '../../../../core'
import { ISvgIconProps } from '../../../../icons/components/SvgIcon'
import { TIconComponent } from '../../../../icons/types'
import { ITooltipProps, Tooltip } from '../../../Tooltip'
import {
  DataGridAdvancedTextCell,
  IDataGridAdvancedTextCellProps,
} from '../DataGridAdvancedTextCell'

export interface IDataGridIconCellProps extends IDataGridAdvancedTextCellProps {
  /**
   * @description Icon to be displayed on menu item
   * @example UserIcon
   */
  icon: TIconComponent
  /**
   * @description Determines if icon should be positioned left or right of children
   * @default 'left'
   */
  iconPosition?: 'left' | 'right'
  /**
   * @description Boolean value that determines if content should be justified with 'space-between'
   * @default false
   */
  spaceBetween?: boolean
  /**
   * @description Text to show in Icon Tooltip. If provided, tooltip will be shown.
   */
  iconTooltipTitle?: string
  TooltipProps?: Omit<ITooltipProps, 'title' | 'children'>
  IconProps?: ISvgIconProps
}

const useDataGridIconCellStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'inherit',
    gap: theme.spacing(1.5),
  },
  textCell: {
    flex: 0,
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
}))

function IconComponent({
  IconProps = {},
  TooltipProps = {},
  icon: Icon,
  iconTooltipTitle,
}: Pick<IDataGridIconCellProps, 'icon' | 'IconProps' | 'TooltipProps' | 'iconTooltipTitle'>) {
  if (iconTooltipTitle) {
    return (
      <Tooltip title={iconTooltipTitle} {...TooltipProps}>
        <Box display='flex' alignItems='center'>
          <Icon {...IconProps} />
        </Box>
      </Tooltip>
    )
  }
  return <Icon {...IconProps} />
}

export const DataGridIconCell = memo<PropsWithChildren<IDataGridIconCellProps>>(
  ({
    IconProps,
    TooltipProps,
    bottomCaption,
    children,
    icon: Icon,
    iconPosition = 'left',
    iconTooltipTitle,
    spaceBetween = false,
    topCaption,
  }) => {
    const classes = useDataGridIconCellStyles()
    const positionIconFirst = iconPosition === 'left'

    const rootClasses = classNames(classes.root, {
      [classes.spaceBetween]: spaceBetween,
    })

    return (
      <div className={rootClasses} data-test='DataGridIconCell'>
        {positionIconFirst && !!Icon && (
          <IconComponent
            icon={Icon}
            iconTooltipTitle={iconTooltipTitle}
            IconProps={IconProps}
            TooltipProps={TooltipProps}
          />
        )}
        {children && (
          <DataGridAdvancedTextCell
            bottomCaption={bottomCaption}
            topCaption={topCaption}
            className={classes.textCell}>
            {children}
          </DataGridAdvancedTextCell>
        )}
        {!positionIconFirst && !!Icon && (
          <IconComponent
            icon={Icon}
            iconTooltipTitle={iconTooltipTitle}
            IconProps={IconProps}
            TooltipProps={TooltipProps}
          />
        )}
      </div>
    )
  }
)
