import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DataGrid from 'dpl/components/DataGrid'
import compact from 'lodash/compact'
import { IOrganizationTeam, IOrganizationUserSortBy } from '../../../../types/graphqlTypes'
import { PROTECTED_PATHS } from '../../../constants'
import { useCurrentUserProductModulesQuery } from '../../graphql/OrganizationProductModules'
import { useOrganizationTeamsQuery } from '../../graphql/OrganizationTeams'
import {
  getRows,
  getColumns,
  INITIAL_MEMBERS_DATA_GRID_STATE,
  getFiltersOptionsConfig,
} from './components/DataGrid/utils/grid'
import { PAGE_RANGES, SORT_OPTIONS_CONFIG } from './constants'
import { useGetUsersData } from './hooks/useGetUsersData'
import { IFilters, IPagination, ISorting } from './types'

export function DirectoryTab() {
  const { data: organizationProductModulesData } = useCurrentUserProductModulesQuery()
  const { data: organizationTeamsData } = useOrganizationTeamsQuery()
  const productModules = useMemo(
    () =>
      organizationProductModulesData?.currentSession?.organizationUser.organization.productModules.map(
        ({ productModule }) => productModule
      ) ?? [],
    [organizationProductModulesData?.currentSession?.organizationUser.organization.productModules]
  )

  const organizationTeams = useMemo(
    () => organizationTeamsData?.organizationTeams?.nodes ?? [],
    [organizationTeamsData?.organizationTeams?.nodes]
  ) as IOrganizationTeam[]

  const [filters, setFilters] = useState<IFilters>({
    searchName: null,
    statuses: [],
    role: null,
    productModules: [],
    organizationAdmin: false,
  })
  const [sorting, setSorting] = useState<ISorting>({
    sortBy: IOrganizationUserSortBy.firstName,
  })

  const [pagination, setPagination] = useState<IPagination>({
    page: 0,
    pageSize: PAGE_RANGES[0],
  })

  const handleFilterChange = useCallback((newFilters: Partial<IFilters>) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
    }))
    setPagination(prevPagination => ({
      ...prevPagination,
      page: 0,
    }))
  }, [])

  const handleSortChange = useCallback((newSorting: Partial<ISorting>) => {
    setSorting(prevSorting => ({
      ...prevSorting,
      ...newSorting,
    }))
    setPagination(prevPagination => ({
      ...prevPagination,
      page: 0,
    }))
  }, [])

  const handlePaginationChange = useCallback((newPagination: IPagination) => {
    setPagination(newPagination)
  }, [])

  const queryParams = useMemo(
    () => ({
      ...filters,
      ...sorting,
      ...pagination,
    }),
    [filters, sorting, pagination]
  )

  const {
    isLoading,
    members,
    productModules: productModulesList,
    totalItems,
  } = useGetUsersData({
    ...queryParams,
    organizationProductModules: productModules,
    sortBy: sorting.sortBy,
  })

  const rows = useMemo(() => {
    const orgModuleList = compact(
      productModules.map(moduleKey => productModulesList.find(({ key }) => key === moduleKey))
    )

    return getRows(members, orgModuleList)
  }, [members, productModules, productModulesList])
  const columns = useMemo(() => getColumns(), [])

  const handleFilterOrSortChange = useCallback(
    params => {
      const { organizationAdmin, productModules, role, searchName, sort, statuses } = params
      const sortBy = sort

      handleFilterChange({ searchName, statuses, role, productModules, organizationAdmin })
      handleSortChange({ sortBy })
    },
    [handleFilterChange, handleSortChange]
  )

  const navigate = useNavigate()

  const handleRowClick = useCallback(
    ({ row }) => {
      navigate(`${PROTECTED_PATHS.members}/${row.id}`)
    },
    [navigate]
  )

  return (
    <DataGrid
      dataTest='members-data-grid'
      loading={isLoading ?? true}
      initialState={INITIAL_MEMBERS_DATA_GRID_STATE}
      columns={columns}
      rows={rows}
      filtersConfig={getFiltersOptionsConfig(organizationTeams, productModulesList)}
      sortOptions={SORT_OPTIONS_CONFIG}
      defaultFilterValues={filters}
      defaultSortValue={sorting.sortBy}
      onPaginationModelChange={handlePaginationChange}
      onFilterOrSortChange={handleFilterOrSortChange}
      pageSizeOptions={PAGE_RANGES}
      rowCount={totalItems}
      paginationMode='server'
      paginationModel={pagination}
      onRowClick={handleRowClick}
    />
  )
}

export default DirectoryTab
