import Color from 'color'
import { createTheme } from '../../core'
import { brandColors } from '../../theme'
import baseTheme from '../../theme/theme'

export const theme = createTheme(baseTheme, {
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: brandColors.coolGray2,
          color: brandColors.coolGray6,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: brandColors.coolGray6,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: brandColors.coolGray8,
          '&:hover, &:focus, &:active': {
            color: `${brandColors.black} !important`,
            backgroundColor: `${Color(brandColors.black).fade(0.8)} !important`,
            borderColor: 'transparent !important',
            boxShadow: `0 0 0 4px ${Color(brandColors.black).fade(0.8)}`,
            borderRadius: '50%',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          color: brandColors.black,
        },
        textPrimary: {
          '&:hover': {
            background: Color(brandColors.black).fade(0.8),
          },
        },
      },
    },
  },
})
