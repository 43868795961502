import React, { HTMLProps, MouseEventHandler } from 'react'
import { Checkbox as MuiCheckbox, InputBaseComponentProps, FormControlLabel } from '../../core'
import { CheckboxChecked, CheckboxIndeterminate, CheckboxUnchecked } from './icons'

export interface ICheckboxProps extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
  disabled?: boolean
  label?: React.ReactNode
  indeterminate?: boolean
  dataTest?: string
  /**
   * When true, the checkbox is veritically centered with the content of the
   * label. Otherwise, the checkbox will be aligned with the top of the label.
   *
   * Note: This is really only applicable when the label is expected to span
   * multiple lines.
   */
  checkboxCentered?: boolean
  inputProps?: InputBaseComponentProps
  onClick?: MouseEventHandler
  checkboxIcon?: JSX.Element
  checkboxCheckedIcon?: JSX.Element
  checkboxIndeterminateIcon?: JSX.Element
}

export function Checkbox({
  id,
  checkboxCentered = false,
  checked,
  dataTest = 'checkbox',
  disabled,
  indeterminate,
  inputProps = {},
  label,
  onChange,
  onClick,
  value,
  checkboxIcon,
  checkboxCheckedIcon,
  checkboxIndeterminateIcon,
  className,
}: ICheckboxProps) {
  /**
   * The `alignItems: 'flex-start'` and `paddingTop: 7` styles in this
   * component are to ensure that the checkbox always aligns with the top row
   * of the label if the label's text wraps to more than one row.
   */
  return (
    <FormControlLabel
      style={{ alignItems: checkboxCentered ? 'center' : 'flex-start', flexGrow: 1 }}
      control={
        <MuiCheckbox
          id={id}
          color='primary'
          checked={checked}
          value={value}
          disabled={disabled}
          indeterminate={checked ? false : indeterminate}
          onChange={onChange}
          onClick={onClick}
          icon={
            checkboxIcon !== undefined ? checkboxIcon : <CheckboxUnchecked disabled={disabled} />
          }
          checkedIcon={
            checkboxCheckedIcon !== undefined ? (
              checkboxCheckedIcon
            ) : (
              <CheckboxChecked disabled={disabled} />
            )
          }
          indeterminateIcon={
            checkboxIndeterminateIcon !== undefined ? (
              checkboxIndeterminateIcon
            ) : (
              <CheckboxIndeterminate />
            )
          }
          data-test={dataTest}
          inputProps={inputProps}
          className={className}
        />
      }
      label={<div style={!checkboxCentered ? { paddingTop: 7 } : {}}>{label}</div>}
    />
  )
}

export default Checkbox
