import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'

/**
 * @name DialogActions
 * @see https://mui.com/material-ui/api/dialog-actions/
 */
export const MuiDialogActionsThemeOptions: Components['MuiDialogActions'] = {
  defaultProps: {
    disableSpacing: false,
  },
  styleOverrides: {
    root: {
      display: 'flex',
      gap: BASE_THEME.spacing(1),
      '& > button': {
        margin: '0 !important',
        flex: 1,
        [BASE_THEME.breakpoints.up('md')]: {
          flex: 'none',
        },
      },
    },
    spacing: {
      padding: BASE_THEME.spacing(3),
    },
  },
}
