import React, { useState } from 'react'
import { makeStyles } from '../../../core/styles'
import { brandColors } from '../../../theme/colors'
import { InputChip } from '../../InputChip'

export interface IChipsDisplayedProps {
  onSetChipLabels: (chips: string[]) => void
  /**
   * Each string is this array will be a chip, and the chip will display that string
   * @example ['one', 'two']
   */
  chipLabels: string[]
  /**
   * The number of chips displayed before a 'View More' button appears
   * @default 20
   */
  chipsDisplayedLimit: number
  /**
   * Each time a 'View More' button is clicked, the number of chips able to be displayed increases
   * by this much. If there are still more chips, the 'View More' button remains
   * @default 20
   */
  displayLimitIncrease: number
  /**
   * The number of characters displayed on a chip before label is truncated with '...'
   * @default 20
   */
  chipCharacterLimit: number
  /**
   * @default 'chips-box'
   */
  dataTest?: string
}

const useChipsDisplayedStyles = makeStyles(theme => ({
  interactive: {
    cursor: 'pointer',
    color: brandColors.skyBlue6,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
    fontSize: 12,
    fontWeight: 600,
    height: theme.spacing(3),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  box: {
    marginTop: theme.spacing(1),
  },
}))

function ChipsDisplayed({
  chipCharacterLimit = 20,
  chipLabels,
  chipsDisplayedLimit = 20,
  dataTest = 'chips-box',
  displayLimitIncrease = 20,
  onSetChipLabels,
}: IChipsDisplayedProps) {
  const chipsDisplayedClasses = useChipsDisplayedStyles()

  const [displayLimit, setLimit] =
    useState<IChipsDisplayedProps['chipsDisplayedLimit']>(chipsDisplayedLimit)

  const removeChip = (chip: string) => {
    const chipLabelsUpdate = chipLabels.filter(chipPointer => chipPointer !== chip)
    onSetChipLabels(chipLabelsUpdate)
  }

  const increaseLimitHandler = () => {
    setLimit(displayLimit + displayLimitIncrease)
  }

  const clearAllHandler = () => {
    onSetChipLabels([])
    setLimit(chipsDisplayedLimit)
  }

  const increaseLimitKeyPressHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      increaseLimitHandler()
    }
  }

  const clearAllKeyPressHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      clearAllHandler()
    }
  }

  return (
    <div data-test={dataTest} className={chipsDisplayedClasses.box}>
      {chipLabels.slice(0, displayLimit).map(label => {
        const closeHandler = () => removeChip(label)
        const closeKeyPressHandler = (e: React.KeyboardEvent) => {
          if (e.key === 'Enter') {
            removeChip(label)
          }
        }
        return (
          <InputChip
            key={label}
            dataTest={`${dataTest}-${label}`}
            onClose={closeHandler}
            onKeyPress={closeKeyPressHandler}
            label={label}
            characterLimit={chipCharacterLimit}
          />
        )
      })}
      {displayLimit < chipLabels.length && (
        <span
          role='button'
          tabIndex={0}
          className={chipsDisplayedClasses.interactive}
          onClick={increaseLimitHandler}
          onKeyPress={increaseLimitKeyPressHandler}
          title='Show more'
          data-test='show-more-chips'>
          View More ({chipLabels.length - displayLimit})
        </span>
      )}
      {chipLabels.length > 0 && (
        <span
          role='button'
          tabIndex={0}
          className={chipsDisplayedClasses.interactive}
          onClick={clearAllHandler}
          onKeyPress={clearAllKeyPressHandler}
          data-test='clear-chips'>
          Clear All
        </span>
      )}
    </div>
  )
}

export default ChipsDisplayed
