import React, { useCallback, useState } from 'react'
import { usePermissionsContext } from 'auth/common/context'
import { INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl'
import { Button, Grid, makeStyles } from 'dpl/core'
import ControlledTextField from 'forms/components/ControlledTextField'
import { ControlledAvatarImageUpload } from '../../../../components/ControlledAvatarImageUpload'
import { useUpdateOrgInfoMutation } from '../graphql/UpdateOrgInfo'
import { useOrganizationEditForm } from '../hooks'
import { TOrganizationEditFormSchema } from '../schema'

export interface IOrganizationEditFormProps {
  orgFormData: TOrganizationEditFormSchema
  onClose?: () => void
}

const useOrganizationEditFormStyles = makeStyles(theme => ({
  orgImageContainer: {
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  editBadgeButtonContainer: {
    height: 'auto',
    padding: 0,
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  editAvatarButton: {
    minWidth: 'auto',
    borderRadius: '50%',
    padding: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',

    '& > button': {
      flex: 1,

      [theme.breakpoints.up('md')]: {
        flex: 0,
      },
    },
  },
}))

export function OrganizationEditForm({ onClose, orgFormData }: IOrganizationEditFormProps) {
  const classes = useOrganizationEditFormStyles()
  const { control, errors, handleSubmit, watch } = useOrganizationEditForm({
    defaultValues: orgFormData,
  })
  const { userPermissions } = usePermissionsContext()
  const [apiError, setApiError] = useState('')
  const [uploadLogo] = useUpdateOrgInfoMutation()
  const values = watch()
  const { image: formValueImage, name: formValueName } = values || {}
  const { file: formValueImageBlob } = formValueImage || {}

  // Errors
  const { name: nameError } = errors || {}
  const { message: nameErrorMessage } = nameError || {}

  const saveHandler = useCallback(() => {
    handleSubmit(async data => {
      setApiError('')
      try {
        const { data: uploadLogoData } = await uploadLogo({
          variables: {
            input: {
              logo: data?.image?.file?.startsWith('https://') ? undefined : data?.image?.file,
              name: data?.name,
            },
          },
        })
        const { updateOrganization } = uploadLogoData || {}
        const { errors = [] } = updateOrganization || {}

        if (errors.length > 0) {
          setApiError(errors[0].message)
        } else if (onClose) {
          onClose()
        } else {
          throw new Error('onClose method is not defined')
        }
      } catch (e) {
        setApiError(e.message)
      }
    })()
  }, [handleSubmit])

  const canUpdateLogo = userPermissions['org_admin_app.update_org_logo']

  return (
    <Grid container spacing={4}>
      {apiError && (
        <Grid item xs={12}>
          <InlineMessage message={apiError} type={INLINE_MESSAGE_VARIANTS.ALERT} />
        </Grid>
      )}
      <Grid item xs={12} className={classes.orgImageContainer}>
        <ControlledAvatarImageUpload
          name='image'
          variant='rounded'
          control={control}
          avatarName={formValueName}
          avatarImageSrc={formValueImageBlob}
          UploadInputProps={{
            disabled: !canUpdateLogo,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          name='name'
          TextFieldProps={{
            label: 'Organization Name',
            error: Boolean(nameError),
            helperText: nameErrorMessage,
            dataTest: 'organization-name-input',
            required: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.buttons}>
          {onClose && (
            <Button variant='text' onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button onClick={saveHandler} disabled={!canUpdateLogo}>
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}
