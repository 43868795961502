import { IButtonProps } from '../../../core'
import { brandColors } from '../../../theme/colors'

export enum INLINE_MESSAGE_VARIANTS {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ALERT = 'alert',
}

export const backgroundColorsByVariant = {
  [INLINE_MESSAGE_VARIANTS.ALERT]: brandColors.errorLight,
  [INLINE_MESSAGE_VARIANTS.INFO]: brandColors.infoLight,
  [INLINE_MESSAGE_VARIANTS.SUCCESS]: brandColors.green0,
  [INLINE_MESSAGE_VARIANTS.WARNING]: brandColors.warningLight,
}

export const colorsByVariant = {
  [INLINE_MESSAGE_VARIANTS.ALERT]: brandColors.error,
  [INLINE_MESSAGE_VARIANTS.INFO]: brandColors.info,
  [INLINE_MESSAGE_VARIANTS.SUCCESS]: brandColors.green0,
  [INLINE_MESSAGE_VARIANTS.WARNING]: brandColors.warning,
}

export const buttonColorsByVariant: Record<INLINE_MESSAGE_VARIANTS, IButtonProps['color']> = {
  [INLINE_MESSAGE_VARIANTS.ALERT]: 'error',
  [INLINE_MESSAGE_VARIANTS.INFO]: 'info',
  [INLINE_MESSAGE_VARIANTS.SUCCESS]: 'success',
  [INLINE_MESSAGE_VARIANTS.WARNING]: 'warning',
}
