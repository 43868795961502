import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import {
  ListItemText as MuiListItemText,
  makeStyles,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SelectChangeEvent as MuiSelectChangeEvent,
  Checkbox as MuiCheckbox,
  Box,
} from '../../core'
import CloseIcon from '../../icons/build/CloseIcon'
import DownArrowIcon from '../../icons/build/DownArrowIcon'
import { TIconComponent } from '../../icons/types'
import { brandColors } from '../../theme'
import { IconButton } from '../IconButton'
import { PillSelectMultiValueLabel, PillSelectSingleValueLabel } from './components'
import { IPillSelectOption } from './types'

const SELECT_ALL_TOGGLE_VALUE = 'PillSelectSelectAllToggle'

export interface IPillSelectProps<Value = any>
  extends Pick<MuiSelectProps<Value>, 'label' | 'value' | 'multiple'> {
  /**
   * The options to display in the select dropdown menu
   * @default []
   */
  options: IPillSelectOption<Value>[]
  /**
   * The function to call when the value of the select changes
   * @param selectedValue The value of the selected option
   */
  onChange: (selectedValue: Value) => void
  /**
   * Whether the select should display a clear button when a value is selected
   * @default true
   */
  clearable?: boolean
  /**
   * The icon to display at the start of the select
   */
  icon?: TIconComponent
  /**
   * @default 'PillSelect'
   */
  dataTest?: string
  /**
   * Whether the select should render in a portal
   * @default false
   */
  disablePortal?: boolean
}

export const usePillStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(5),
    // gap: theme.spacing(1),
    width: 'auto',

    '&.clearable .MuiSelect-select': {
      padding: `${theme.spacing(0.75, 1.5)} !important`,
    },

    '&:not(.clearable) .MuiSelect-select': {
      padding: `${theme.spacing(0.75, 5, 0.75, 1.5)} !important`,
    },

    '& fieldset': {
      borderColor: brandColors.coolGray4,
    },

    '&.hasSelection fieldset': {
      borderColor: brandColors.skyBlue6,
    },
  },
  input: {
    border: 'none',
    height: 0,
    fontWeight: theme.typography.fontWeightBold,
  },
  clearButton: {
    color: brandColors.skyBlue6,
  },
  multiValueLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  menuItem: {
    gap: theme.spacing(1),
    '&.Mui-selected': {
      background: 'none',
      color: brandColors.coolGray8,

      '&:hover': {
        background: brandColors.coolGray1,
        color: brandColors.coolGray8,
      },
    },

    '&:hover': {
      background: brandColors.coolGray1,
    },
  },
  checkbox: {
    padding: 0,
  },
}))

export function PillSelect({
  dataTest = 'PillSelect',
  icon: IconComponent,
  label: labelProp,
  onChange,
  value,
  options: optionsProp = [],
  multiple: multipleProp,
  clearable = true,
  disablePortal = false,
}: IPillSelectProps) {
  const classes = usePillStyles()

  const valueProp = useMemo(() => value || (multipleProp ? [] : ''), [multipleProp, value])

  const showClearButton = useMemo(() => {
    if (!clearable) return false

    if (multipleProp) {
      return valueProp.length > 0
    }
    return Boolean(valueProp)
  }, [clearable, multipleProp, valueProp])

  const isAllOptionsSelected = useMemo(() => {
    if (multipleProp) {
      return valueProp.length === optionsProp.length
    }
    return false
  }, [multipleProp, optionsProp, valueProp])

  const changeHandler = useCallback(
    (event: MuiSelectChangeEvent) => {
      const { target } = event || {}
      const { value } = target || {}

      if (!multipleProp) {
        onChange(value)
      }

      if (value.includes(SELECT_ALL_TOGGLE_VALUE)) {
        if (isAllOptionsSelected) onChange([])
        else onChange(optionsProp.map(({ value }) => value))
        return
      }

      onChange(value)
    },
    [isAllOptionsSelected, multipleProp, onChange, optionsProp]
  )

  const clearHandler = useCallback(() => {
    if (multipleProp) {
      onChange([])
    } else {
      onChange('')
    }
  }, [multipleProp, onChange])

  return (
    <MuiSelect
      displayEmpty
      variant='outlined'
      IconComponent={props => {
        return !showClearButton ? (
          <Box {...props} marginRight={0.5} marginTop='-3px' display='flex'>
            <DownArrowIcon size='large' color='skyBlue6' data-test={`${dataTest}-arrow`} />
          </Box>
        ) : null
      }}
      data-test={dataTest}
      value={valueProp}
      multiple={multipleProp}
      onChange={changeHandler}
      slotProps={{
        root: {
          className: classNames(classes.root, {
            hasSelection: showClearButton,
            clearable: showClearButton,
          }),
        },
        input: { className: classes.input },
      }}
      MenuProps={{
        disablePortal,
      }}
      startAdornment={
        IconComponent ? <IconComponent size='large' data-test={`${dataTest}-icon`} /> : null
      }
      endAdornment={
        showClearButton ? (
          <IconButton
            Icon={CloseIcon}
            onClick={clearHandler}
            size='large'
            color='secondary'
            dataTest={`${dataTest}-clear`}
            className={classes.clearButton}
            noPadding
          />
        ) : null
      }
      renderValue={val => (
        <div>
          {multipleProp ? (
            <PillSelectMultiValueLabel
              value={val}
              label={labelProp}
              dataTest={`${dataTest}-label`}
            />
          ) : (
            <PillSelectSingleValueLabel
              value={val}
              label={labelProp}
              options={optionsProp}
              dataTest={`${dataTest}-label`}
            />
          )}
        </div>
      )}>
      {multipleProp && (
        <MuiMenuItem
          value={SELECT_ALL_TOGGLE_VALUE}
          data-test={`${dataTest}-option-all-toggle`}
          className={classes.menuItem}
          divider>
          <MuiCheckbox className={classes.checkbox} checked={isAllOptionsSelected} />
          {isAllOptionsSelected ? 'Deselect All' : 'Select All'}
        </MuiMenuItem>
      )}
      {optionsProp.map(({ label: optionLabel, value: optionValue }) => (
        <MuiMenuItem
          key={optionValue}
          value={optionValue}
          data-test={`${dataTest}-option-${optionValue}`}
          className={classes.menuItem}>
          {multipleProp && (
            <MuiCheckbox className={classes.checkbox} checked={valueProp.includes(optionValue)} />
          )}
          <MuiListItemText primaryTypographyProps={{ variant: 'body2' }}>
            {optionLabel}
          </MuiListItemText>
        </MuiMenuItem>
      ))}
    </MuiSelect>
  )
}
