import React from 'react'
import {
  GridRenderCellParams as MuiGridRenderCellParams,
  GridValidRowModel as MuiGridValidRowModel,
} from '../../../core'
import { DataGridEditableCell } from '../cells/DataGridEditableCell/DataGridEditableCell'
import { TDataGridColDef } from '../types'

export function getEditableCellProps<TRowModel extends MuiGridValidRowModel>({
  renderCell: renderCellFromProps,
}: TDataGridColDef<TRowModel>): Pick<TDataGridColDef<TRowModel>, 'renderCell' | 'editable'> {
  return {
    renderCell: (renderCellParams: MuiGridRenderCellParams<TRowModel>) => (
      <DataGridEditableCell id={renderCellParams.id} field={renderCellParams.field}>
        {renderCellFromProps
          ? renderCellFromProps(renderCellParams)
          : renderCellParams.formattedValue}
      </DataGridEditableCell>
    ),
    editable: true,
  }
}
