import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '../../../core'
import { getTabIdentifiers } from '../utils/getTabIdentifiers'

const useTabPanelStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
  },
}))

interface ITabPanelProps {
  /**
   * Unique ID for tab
   * @example 'home'
   */
  tabId: string
  /**
   * @example 'home'
   */
  activeTabId: string
  className?: string
}

export function TabPanel({ activeTabId, className, tabId }: ITabPanelProps) {
  const classes = useTabPanelStyles()
  const { tabIdentifier, tabPanelIdentifier } = getTabIdentifiers(tabId)

  return (
    <div
      role='tabpanel'
      id={tabPanelIdentifier}
      aria-labelledby={tabIdentifier}
      className={classNames(classes.root, className)}
      hidden={tabId !== activeTabId}
    />
  )
}
