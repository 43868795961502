import React, { memo, useMemo } from 'react'
import { makeStyles, Typography } from '../../../../../../core'
import { IDataGridFilterProps } from '../../components'
import { MENU_FILTERS_COMPONENT_MAP } from '../../constants'
import { IDataGridFilters, TDataGridFiltersConfig } from '../../types'
import { DataGridFiltersMenuAccordion } from '../DataGridFiltersMenuAccordion'

export interface IDataGridFiltersMenuInputProps<TFilters extends IDataGridFilters> {
  /**
   * The key of the filter that this input is for
   */
  filterKey: keyof TFilters
  /**
   * Configuration object for the filters
   * @example { userSearch: { type: 'textInput' }, isActive: { type: 'switch' }, }
   */
  filtersConfig: TDataGridFiltersConfig<TFilters>
  /**
   * The current state of the query params
   * { userSearch: 'John Doe', isActive: true }
   */
  filtersCurrentState: TFilters
  /**
   * Callback function that is called when the filter is changed
   */
  onChange: IDataGridFilterProps<any, keyof TFilters>['onChange']
  /**
   * @default 'DataGridFiltersMenuInput'
   */
  dataTest?: string
}

const useDataGridFiltersMenuInputStyles = makeStyles(theme => ({
  defaultWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: 54,
  },
}))

function DataGridFiltersMenuInputComponent<TFilters extends IDataGridFilters>({
  dataTest = 'DataGridFiltersMenuInput',
  filterKey,
  filtersConfig,
  filtersCurrentState,
  onChange,
}: IDataGridFiltersMenuInputProps<TFilters>) {
  const classes = useDataGridFiltersMenuInputStyles()

  const filterConfig = filtersConfig[filterKey]
  const {
    FilterComponent: FilterComponentProp,
    FilterMenuComponent: FilterMenuComponentProp,
    type: filterType,
    ...filterProps
  } = filterConfig || {}
  const filterValue = filtersCurrentState[filterKey]

  const FilterComponent =
    filterType === 'custom' && FilterComponentProp
      ? FilterComponentProp
      : MENU_FILTERS_COMPONENT_MAP[filterType]

  const FilterMenuComponent =
    filterType === 'custom' ? FilterMenuComponentProp : MENU_FILTERS_COMPONENT_MAP[filterType]

  const Input = useMemo(() => {
    return (
      <FilterComponent
        variant='menu'
        name={filterKey as string}
        onChange={onChange}
        value={filterValue}
        {...filterProps}
      />
    )
  }, [FilterComponent, filterKey, filterProps, filterValue, onChange])

  if (filterConfig.type === 'custom' && FilterMenuComponent) {
    return (
      <FilterMenuComponent
        variant='menu'
        name={filterKey as string}
        onChange={onChange}
        value={filterValue}
        {...filterProps}
      />
    )
  }

  if (filterConfig.type === 'multiSelect' || filterConfig.type === 'singleSelect') {
    return (
      <DataGridFiltersMenuAccordion
        filterConfig={filterConfig}
        filterValue={filterValue}
        dataTest={`${dataTest}-accordion`}>
        {Input}
      </DataGridFiltersMenuAccordion>
    )
  }

  if (filterConfig.type === 'switch' || filterConfig.type === 'toggleButton') {
    const { label: filterLabel } = filterConfig || {}
    return (
      <div className={classes.switchWrapper} data-test={`${dataTest}-switch-wrapper`}>
        <div>
          <Typography variant='subtitle1'>{filterLabel}</Typography>
        </div>
        <div>{Input}</div>
      </div>
    )
  }

  return (
    <div className={classes.defaultWrapper} data-test={`${dataTest}-default-wrapper`}>
      {Input}
    </div>
  )
}

export const DataGridFiltersMenuInput = memo(
  DataGridFiltersMenuInputComponent
) as typeof DataGridFiltersMenuInputComponent
