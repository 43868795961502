import moment from 'moment'

const defaultLocale: Pick<moment.LocaleSpecification, 'relativeTime'> = {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
}

const shortHandLocale: Pick<moment.LocaleSpecification, 'relativeTime'> = {
  relativeTime: {
    ...defaultLocale,
    s: 'a few seconds',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
}

export interface IFormatRelativeTimeProps {
  /**
   * A string in ISO 8601 format containing the time from which you want to
   * display a relatve time.
   *
   * This component will always display a time relative to the user's current
   * timezone. This is generated using `moment.tz.guess()`.
   */
  datetime: string
  /**
   * When false, shortens the relative datetime string to _only_ show the
   * relative datetime without the "ago" or "in" indicating in which direction
   * the relative datetime is. (i.e. "3 hours" rather than "3 hours ago")
   * @default true
   */
  inSentence?: boolean
  endTime?: string | moment.Moment
  /**
   * Outputs the relative time in a shorthand format e.g 3h ago instead of 3 hours ago
   * @default false
   */
  isShorthand?: boolean
}

export const isTimeOnTheHour = (datetime: moment.Moment): boolean => datetime.minutes() === 0

export const formatRelativeTime = ({
  datetime,
  endTime = moment(),
  inSentence = false,
  isShorthand = false,
}: IFormatRelativeTimeProps) => {
  moment.defineLocale('en-relative-time', isShorthand ? shortHandLocale : defaultLocale)
  return moment(datetime).locale('en-relative-time').from(endTime, !inSentence)
}
