import React, { useEffect } from 'react'
import handleViewport, { InjectedViewportProps } from 'react-in-viewport'

interface ILoadingTriggerProps extends InjectedViewportProps<HTMLDivElement> {
  onLoadMore?: () => void
}

function LoadingTriggerComponent({ forwardedRef, inViewport, onLoadMore }: ILoadingTriggerProps) {
  useEffect(() => {
    if (inViewport && onLoadMore) {
      onLoadMore()
    }
  }, [inViewport, onLoadMore])

  return <div ref={forwardedRef} />
}

export const LoadingTrigger = handleViewport(LoadingTriggerComponent)
