import { INavProps } from 'dpl/templates/AppLayout/Nav'

interface IGetNavListsArgs {
  navLists: INavProps['navLists']
  currentPathname: string
  homePath?: string
}

const isSelected = (currentPathname: string, itemHref: string, homePath: string) => {
  if (itemHref === homePath) {
    return currentPathname === itemHref
  }
  return currentPathname.includes(itemHref)
}

export const getNavLists = ({
  currentPathname,
  navLists = [],
  homePath = '/',
}: IGetNavListsArgs) => {
  return navLists.map(list => ({
    ...list,
    navListItems: list.navListItems.map(item => {
      return {
        ...item,
        selected: isSelected(currentPathname, item.href, homePath),
      }
    }),
  }))
}
