import React, { forwardRef, PropsWithChildren } from 'react'
import { useEffectOnce } from 'react-use'
import { makeStyles, Theme } from '../../core'
import { PageTitle, IPageTitleProps } from './PageTitle'

export interface IPageTemplateProps {
  /**
   * Removes default padding from body content
   * @default false
   */
  noPadding?: boolean
  /**
   * Title Caption of page, shown next to the title
   * @optional
   */
  pageTitleCaption?: string
  /**
   * Title of page, shown above children
   * @optional
   */
  pageTitle?: string
  /**
   * @default 'Transfix'
   */
  documentTitlePrefix?: string
  /**
   * Document title to set on mount
   */
  documentTitle?: string
  /**
   * Additional props to pass into <PageBodyTitle />
   */
  PageBodyTitleProps?: Omit<IPageTitleProps, 'title' | 'dataTest'>
  /**
   * @default 'PageTemplate'
   */
  dataTest?: string
}

const usePageTemplateStyles = makeStyles<Theme, { noPadding: boolean }>(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'auto',
  },
  bodyContent: {
    overflowX: 'scroll',
    padding: ({ noPadding }) => (noPadding ? 0 : theme.spacing(2)),
    [theme.breakpoints.up('md')]: {
      padding: ({ noPadding }) => (noPadding ? 0 : theme.spacing(3)),
    },
  },
}))

export const PageTemplate = forwardRef<HTMLDivElement, PropsWithChildren<IPageTemplateProps>>(
  function PageTemplateWithRef(
    {
      PageBodyTitleProps = {},
      children,
      noPadding = false,
      pageTitleCaption,
      pageTitle,
      dataTest = 'PageTemplate',
      documentTitle,
      documentTitlePrefix = 'Transfix',
    },
    ref
  ) {
    const classes = usePageTemplateStyles({ noPadding })

    useEffectOnce(() => {
      document.title = [documentTitlePrefix, documentTitle || pageTitle].join(' - ')
    })

    return (
      <div ref={ref} className={classes.root} data-test={dataTest}>
        {pageTitle && (
          <PageTitle
            {...PageBodyTitleProps}
            title={pageTitle}
            titleCaption={pageTitleCaption}
            dataTest={`${dataTest}-title`}
          />
        )}
        <div className={classes.bodyContent}>{children}</div>
      </div>
    )
  }
)
