import { TIME_INPUT_FORMAT } from '../types'
import { formatTime } from './formatTime'
import { isValidTime } from './isValidTime'

/**
 * Returns a string that parses the time value and removes/adds to create
 * valid time string
 * @param {string} input - raw time input
 * @param {TIME_INPUT_FORMAT} timeFormat - military or non-military format
 */
export const parseTime = (input: string, timeFormat: TIME_INPUT_FORMAT): string | undefined => {
  const normalizedInput = input.toLowerCase().replace(/\s/g, '')
  let parsedTime = ''

  const [numbers] = /^\d+:?(\d+)?/.exec(normalizedInput) || ['']

  if (numbers.includes(':')) {
    // hours and minutes with colon between
    const [h, m] = numbers.split(':')
    if (isValidTime(timeFormat, h, m)) parsedTime = formatTime(timeFormat, h, m)
  } else if (numbers.length < 3) {
    // just hours
    if (isValidTime(timeFormat, numbers)) parsedTime = formatTime(timeFormat, numbers)
  } else if (numbers.length === 3 && isValidTime(timeFormat, numbers[0], numbers.slice(1, 3))) {
    // 1 digit for hours and 2 for minutes without delimiter
    parsedTime = formatTime(timeFormat, numbers[0], numbers.slice(1, 3))
  } else if (isValidTime(timeFormat, numbers.slice(0, 2), numbers.slice(2, 4))) {
    // 2 digits for hours and 1 or 2 for minutes without delimiter
    parsedTime = formatTime(timeFormat, numbers.slice(0, 2), numbers.slice(2, 4))
  }

  if (!parsedTime) return undefined

  if (timeFormat === TIME_INPUT_FORMAT.MILITARY) return parsedTime

  const [letters = ''] = /[a-z]+$/.exec(normalizedInput) || []
  const isAM = ['a', 'am'].includes(letters)
  const isPM = ['p', 'pm'].includes(letters)
  if (letters && !isAM && !isPM) return undefined

  return isAM ? `${parsedTime}am` : `${parsedTime}pm`
}
