import { KyInstance } from 'ky'
import {
  IAuthenticateTokenRequestBody,
  IAuthenticateTokenResponseBody,
  IRequestMagicLinkRequestBody,
} from './types'

export interface IDiscoveryClientOptions {
  connection: KyInstance
}

export interface IDiscoveryClient {
  authenticateToken: (
    data: IAuthenticateTokenRequestBody
  ) => Promise<IAuthenticateTokenResponseBody>
  requestMagicLink: (data: IRequestMagicLinkRequestBody) => Promise<{}>
}

export const DISCOVERY_ENDPOINTS = {
  authenticateToken: 'discoveries/authenticate_token',
  requestMagicLink: 'discoveries/request_magic_link',
}

export const makeDiscoveryClient = ({ connection }: IDiscoveryClientOptions): IDiscoveryClient => {
  return {
    authenticateToken: data => {
      return connection
        .post(DISCOVERY_ENDPOINTS.authenticateToken, {
          json: data,
        })
        .json()
    },
    requestMagicLink: data => {
      return connection.post(DISCOVERY_ENDPOINTS.requestMagicLink, { json: data }).json()
    },
  }
}
