import React, { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl/components'
import SidePanel from 'dpl/components/SidePanel'
import { Box } from 'dpl/core'
import { IUserProfileInfo } from 'dpl/templates'
import compact from 'lodash/compact'
import { getModules } from '../../routes/members/components/AddNewMemberDrawer/components/ControlledModuleAccessRolesAutocomplete/utils'
import { IOrganizationProductModule, IOrganizationTeam } from '../../types/graphqlTypes'
import { EditProfileForm } from './EditProfileForm'
import { useUpdateUserMutation } from './graphql/UpdateUser'
import { useEditProfileForm } from './useEditProfileForm'

export interface IEditProfileDrawerProps {
  open: boolean
  onClose: () => void
  userInfo: IUserProfileInfo & { organizationUserId: string }
  userTeams?: Pick<IOrganizationTeam, 'key'>[]
  organizationModules?: Pick<IOrganizationProductModule, 'productModule'>[]
  isAdmin: boolean
}

export function EditProfileDrawer({
  isAdmin,
  onClose,
  open,
  userInfo,
  userTeams = [],
  organizationModules = [],
}: IEditProfileDrawerProps) {
  const [apiError, setApiError] = useState('')
  const methods = useEditProfileForm({
    defaultValues: {
      ...userInfo,
      imageFile: { file: userInfo.imageSrc },
      modules: getModules({ organizationModules, userTeams }),
    },
  })
  const [updateUser] = useUpdateUserMutation()

  /**
   * Refresh data when it is updated
   */
  useEffect(() => {
    if (userTeams.length === 0) return

    methods.reset({
      ...userInfo,
      imageFile: { file: userInfo.imageSrc },
      modules: getModules({ organizationModules, userTeams }),
    })
    /**
     * This effect should only run when the userInfo changes
     * meaning that the methods.reset should only run when the userInfo changes
     * avoiding infinite loops
     */
  }, [methods.reset, userInfo, userTeams])

  const { handleSubmit } = methods || {}

  const saveHandler = handleSubmit(async values => {
    setApiError('')
    const defaultValues = getModules({ organizationModules, userTeams })
    const newModules = values.modules

    const modulesToRemove = defaultValues
      .filter(({ team }, index) => team && !newModules?.[index].team)
      .map(({ productModule }) => productModule)
    const teamsToAdd = compact(newModules)
      .filter(({ team }, index) => team && team !== defaultValues?.[index].team)
      .map(({ team }) => team || '')

    try {
      const { data } = await updateUser({
        variables: {
          userInput: {
            organizationUserId: userInfo.organizationUserId,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
          },
          includeTeam: teamsToAdd.length > 0,
          includeUnassign: modulesToRemove.length > 0,
          teamInput: {
            organizationUserId: userInfo.organizationUserId,
            organizationTeamKeys: teamsToAdd,
          },
          unassignInput: {
            organizationUserId: userInfo.organizationUserId,
            organizationProductModuleKeys: modulesToRemove,
          },
        },
      })

      const {
        assignTeamsUser: assignData,
        unassignTeamsUser: unassignData,
        updateUser: updateUserData,
      } = data || {}
      const { errors: updateErrors = [] } = updateUserData || {}
      const { errors: assignErrors = [] } = assignData || {}
      const { errors: unassignErrors = [] } = unassignData || {}
      const errors = [...updateErrors, ...assignErrors, ...unassignErrors]

      if (errors.length > 0) {
        setApiError(errors[0].message)
      } else if (onClose) {
        onClose()
      } else {
        throw new Error('onClose method is not defined')
      }
    } catch (e) {
      setApiError(e.message)
    }
  })

  return (
    <SidePanel open={open} onClose={onClose} anchor='right' title='Edit Profile'>
      <FormProvider {...methods}>
        <Box display='flex' gap={1} flexDirection='column'>
          {apiError && <InlineMessage message={apiError} type={INLINE_MESSAGE_VARIANTS.ALERT} />}
          <EditProfileForm isAdmin={isAdmin} onClose={onClose} onSubmit={saveHandler} />
        </Box>
      </FormProvider>
    </SidePanel>
  )
}
