import React from 'react'
import { Box, Typography } from '../../../../core'
import { brandColors } from '../../../../theme'

export function DataGridColumnEmptyMenu() {
  return (
    <Box padding={1.5}>
      <Typography
        variant='body2'
        color={brandColors.coolGray5}
        data-test='DataGridEmptyMenuMessage'>
        Select rows to see actions
      </Typography>
    </Box>
  )
}
