import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { createStyles, makeStyles } from '../../core'
import TextField, { ITextFieldProps } from '../TextField/TextField'

type TTextFieldConfig<P> = P & ITextFieldProps & { name: string }

export interface ITextFieldGroupProps<P = ITextFieldProps> {
  /**
   * @optional
   * Provide a Component if something other than TextField should be used.
   * @example NumericFormat
   */
  Component?: React.ComponentType<P>
  /**
   * Label used for the TextFieldGroup, shown as the label for the first TextField component
   */
  label?: string
  /**
   * Array of TextFieldProps, one for each text field to render in the group
   */
  fields?: TTextFieldConfig<P>[]
  /**
   * Optional class for TextFieldGroup container
   */
  className?: string
  dataTest?: string
  /**
   * If you want to multiple inputs to be rendered in a single row, you can pass children instead of fields
   */
  children?: ReactNode
}

const useTextFieldGroupStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      border: 0,
      margin: 0,
      padding: 0,
      '& > .MuiTextField-root': {
        // Remove left border on all elements except first child
        marginLeft: -1,
        '& .MuiOutlinedInput-input, & fieldset': {
          borderRadius: 0,
        },
        '&:first-of-type': {
          marginLeft: 0,
          '& .MuiOutlinedInput-input, & fieldset': {
            borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
          },
        },
        '&:last-of-type': {
          '& .MuiOutlinedInput-input, & fieldset': {
            borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
          },
        },
      },
    },
  })
)

export function TextFieldGroup<P>({
  className: classNameProp = '',
  label,
  dataTest,
  fields = [],
  Component: ComponentProp,
  children,
}: ITextFieldGroupProps<P>) {
  const classes = useTextFieldGroupStyles()
  const containerClasses = classNames(classes.root, {
    [classNameProp]: !!classNameProp,
  })
  const Component = ComponentProp ?? TextField

  if (children) {
    return (
      <fieldset className={containerClasses} data-test={dataTest} aria-label={label}>
        {children}
      </fieldset>
    )
  }

  return (
    <fieldset className={containerClasses} data-test={dataTest} aria-label={label}>
      {fields.map((textFieldProps, i) => {
        const { name, InputLabelProps = {} } = textFieldProps || {}

        if (i === 0) {
          return (
            <Component
              key={name}
              dataTest={`${dataTest}-${name}`}
              {...textFieldProps}
              label={label}
              InputLabelProps={{ ...InputLabelProps, shrink: true }}
              aria-label={name}
            />
          )
        }
        return (
          <Component
            key={name}
            dataTest={`${dataTest}-${name}`}
            {...textFieldProps}
            aria-label={name}
          />
        )
      })}
    </fieldset>
  )
}

export default TextFieldGroup
