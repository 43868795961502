import React from 'react'
import classNames from 'classnames'
import { makeStyles, Step as MUIStep, StepLabel as MUIStepLabel } from '../../core'
import CheckFilledIcon from '../../icons/build/CheckFilledIcon'
import { brandColors } from '../../theme/colors'

const useStepStyles = makeStyles(({ spacing }) => ({
  circle: {
    position: 'relative',
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: brandColors.coolGray5,
    fontWeight: 600,
    zIndex: 1,

    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: `2px solid ${brandColors.coolGray5}`,
      transition: 'all 0.3s ease',
    },
  },
  isHighlighted: {
    backgroundColor: brandColors.skyBlue6,
    color: brandColors.white,

    '&::before': {
      borderColor: brandColors.skyBlue6,
    },
  },
  isActive: {
    color: brandColors.white,
    backgroundColor: brandColors.skyBlue6,

    '&::before': {
      transform: 'scale(1.4)',
      border: `1px solid ${brandColors.skyBlue6}`,
    },
  },
  isClickable: {
    backgroundColor: brandColors.skyBlue6,
    color: brandColors.white,

    '&::before': {
      borderColor: brandColors.skyBlue6,
    },
  },
  isCompleted: {
    backgroundColor: brandColors.transparent,
    color: brandColors.skyBlue6,

    '&::before': {
      borderColor: brandColors.skyBlue6,
    },
  },
  step: {
    padding: spacing(1.5),
    borderRadius: 4,
    cursor: 'not-allowed',
    userSelect: 'none',
    '&:first-child:hover': {
      backgroundColor: brandColors.coolGray1,
      cursor: 'pointer',
    },
  },
  isInteractive: {
    cursor: 'pointer',
    '& .MuiStepLabel-label, & .MuiStepLabel-root.Mui-disabled': {
      cursor: 'pointer !important',
    },
    '& .MuiStepLabel-label': {
      color: brandColors.skyBlue6,
    },
    '&:hover': {
      backgroundColor: brandColors.coolGray1,
    },
  },
}))

interface IStepProps {
  index: number
  isCompleted?: boolean
  name: string
  stepClickHandler: (index: number) => void
  firstIncompleteStep: number
  isActive?: boolean
  isHighlighted?: boolean
}

export function Step(props: IStepProps) {
  const {
    firstIncompleteStep,
    index,
    isActive,
    isCompleted,
    isHighlighted,
    name,
    stepClickHandler,
  } = props
  const classes = useStepStyles()
  const isInitialStep = index === 0
  const isStepCompleted = isCompleted
  const isStepClickable = isInitialStep || index === firstIncompleteStep || isCompleted

  const iconClasses = classNames(classes.circle, {
    [classes.isActive]: isActive,
    [classes.isCompleted]: isCompleted && !isActive,
    [classes.isHighlighted]: isHighlighted,
    [classes.isClickable]: isStepClickable,
  })

  const renderIcon = () => {
    if (isCompleted && !isActive) {
      return <CheckFilledIcon size='xlarge' />
    }
    return <span>{index + 1}</span>
  }

  return (
    <MUIStep
      data-test={`step-${index}`}
      onClick={() => (isStepClickable ? stepClickHandler(index) : null)}
      active={isActive}
      className={classNames(classes.step, {
        [classes.isInteractive]: isStepClickable,
      })}
      completed={isStepCompleted}
      {...props}>
      <MUIStepLabel
        data-test={`step-label-${index}`}
        icon={<div className={iconClasses}>{renderIcon()}</div>}>
        {name}
      </MUIStepLabel>
    </MUIStep>
  )
}

export default Step
