import { avatarColors, brandColors } from '../../theme'
import { TAG_STYLE } from './types'

export const TAG_STYLE_COLORS: Record<TAG_STYLE, { text: string; background: string }> = {
  [TAG_STYLE.light]: {
    text: brandColors.coolGray8,
    background: brandColors.white,
  },
  [TAG_STYLE.dark]: {
    text: brandColors.coolGray8,
    background: brandColors.coolGray4,
  },
  [TAG_STYLE.infoLight]: {
    text: brandColors.infoDark,
    background: brandColors.infoLight,
  },
  [TAG_STYLE.info]: {
    text: brandColors.infoDark,
    background: brandColors.infoMedium,
  },
  [TAG_STYLE.success]: {
    text: brandColors.green3,
    background: brandColors.green1,
  },
  [TAG_STYLE.warning]: {
    text: brandColors.warning,
    background: brandColors.warningLight,
  },
  [TAG_STYLE.gold]: {
    text: brandColors.black,
    background: brandColors.gold2,
  },
  [TAG_STYLE.error]: {
    text: brandColors.error,
    background: brandColors.errorLight,
  },
  [TAG_STYLE.errorDark]: {
    text: brandColors.white,
    background: brandColors.error,
  },
  [TAG_STYLE.accentRed]: {
    text: brandColors.coolGray8,
    background: avatarColors.roseRed,
  },
  [TAG_STYLE.accentPurple]: {
    text: brandColors.coolGray8,
    background: avatarColors.lavenderPurple,
  },
  [TAG_STYLE.accentGreen]: {
    text: brandColors.coolGray8,
    background: avatarColors.artichokeGreen,
  },
  [TAG_STYLE.accentBlue]: {
    text: brandColors.coolGray8,
    background: avatarColors.iceBlue,
  },
  [TAG_STYLE.accentWarning]: {
    text: brandColors.black,
    background: brandColors.warning,
  },
  [TAG_STYLE.spruceGreen]: {
    text: brandColors.coolGray8,
    background: avatarColors.spruceGreen,
  },
  [TAG_STYLE.plumPurple]: {
    text: brandColors.coolGray8,
    background: avatarColors.plumPurple,
  },
  [TAG_STYLE.disabled]: {
    text: brandColors.coolGray5,
    background: brandColors.coolGray1,
  },
}
