import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'
import { calcRem } from '../utils'

export const MuiToggleButtonThemeOptions: Components['MuiToggleButton'] = {
  defaultProps: {
    color: 'primary',
    size: 'medium',
  },
  variants: [
    {
      props: { color: 'primary' },
      style: {
        '&:not(.Mui-selected)': {
          border: `1px solid ${brandColors.coolGray4}`,
        },

        '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
          background: brandColors.skyBlue2,
          border: `1px solid ${brandColors.skyBlue7}`,
          color: brandColors.skyBlue7,
        },
      },
    },
    {
      props: { color: 'error' },
      style: {
        '&:not(.Mui-selected)': {
          border: `1px solid ${brandColors.error1}`,
        },

        '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
          background: brandColors.error0,
          border: `1px solid ${brandColors.error1}`,
          color: brandColors.error1,
        },
      },
    },
  ],
  styleOverrides: {
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: BASE_THEME.spacing(0.75, 1.5),
      borderRadius: BASE_THEME.spacing(5),

      '&.MuiButtonBase-root': {
        fontSize: calcRem(16),
        lineHeight: calcRem(21),
      },

      '&:not(.Mui-selected)': {
        color: brandColors.coolGray8,
      },
    },
  },
}
