import { TypographyOptions } from '@mui/material/styles/createTypography'
import { BASE_THEME } from './baseTheme'
import { wilsonFontFamily, wilsonHeaderFontFamily } from './fontFamilies'
import { calcRem } from './utils'

export const TYPOGRAPHY_THEME_OPTIONS: TypographyOptions = {
  fontFamily: wilsonFontFamily,
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  /** Mobile */
  h1: {
    fontFamily: wilsonHeaderFontFamily,
    fontWeight: 700,
    letterSpacing: 0,
    fontSize: calcRem(48), // 48px
    lineHeight: 1.2,

    /** Desktop */
    [BASE_THEME.breakpoints.up('md')]: {
      letterSpacing: calcRem(-0.5),
      fontSize: calcRem(60),
    },
  },
  /** Mobile */
  h2: {
    fontFamily: wilsonHeaderFontFamily,
    fontWeight: 600,
    letterSpacing: 0,
    fontSize: calcRem(24), // 24px
    lineHeight: 1.2,

    /** Desktop */
    [BASE_THEME.breakpoints.up('md')]: {
      fontSize: calcRem(34), // 34px
      letterSpacing: calcRem(0.25),
    },
  },
  /** Mobile */
  h3: {
    fontFamily: wilsonHeaderFontFamily,
    fontWeight: 400,
    letterSpacing: calcRem(0.15),
    fontSize: calcRem(24),
    lineHeight: 1.2,

    /** Desktop */
    [BASE_THEME.breakpoints.up('md')]: {
      fontWeight: 500,
    },
  },
  h4: {
    fontFamily: wilsonHeaderFontFamily,
    fontWeight: 400,
    fontSize: calcRem(20), // 20px
    lineHeight: 1.2,
    letterSpacing: calcRem(0.15),

    /** Desktop */
    [BASE_THEME.breakpoints.up('md')]: {
      fontWeight: 500,
    },
  },
  h5: {
    fontFamily: wilsonHeaderFontFamily,
  },
  h6: {
    fontFamily: wilsonHeaderFontFamily,
  },
  /** Mobile */
  subtitle1: {
    fontFamily: wilsonFontFamily,
    fontWeight: 600,
    letterSpacing: calcRem(0.15),
    fontSize: calcRem(18),
    lineHeight: 1.36375,

    /** Desktop */
    [BASE_THEME.breakpoints.up('md')]: {
      fontSize: calcRem(16),
    },
  },
  /** Mobile */
  subtitle2: {
    fontFamily: wilsonFontFamily,
    fontWeight: 600,
    fontSize: calcRem(16),
    lineHeight: 1.36375,
    letterSpacing: calcRem(0.1),

    /** Desktop */
    [BASE_THEME.breakpoints.up('md')]: {
      fontSize: calcRem(14),
      lineHeight: calcRem(19),
    },
  },
  /** Mobile */
  body1: {
    fontFamily: wilsonFontFamily,
    fontWeight: 400,
    fontSize: calcRem(18), // 18px,
    lineHeight: calcRem(25),
    letterSpacing: 0,
    fontStyle: 'normal',
    /** Desktop */
    [BASE_THEME.breakpoints.up('md')]: {
      fontSize: calcRem(16),
      lineHeight: calcRem(22),
    },
  },
  /** Mobile */
  body2: {
    fontFamily: wilsonFontFamily,
    fontWeight: 400,
    fontSize: calcRem(16),
    lineHeight: calcRem(22),
    letterSpacing: 0,
    fontStyle: 'normal',
    /** Desktop */
    [BASE_THEME.breakpoints.up('md')]: {
      fontSize: calcRem(14),
      lineHeight: calcRem(19),
    },
  },
  /** Mobile */
  button: {
    fontFamily: wilsonFontFamily,
    fontWeight: 700,
    fontSize: calcRem(16),
    lineHeight: calcRem(22),
    letterSpacing: 0,
    textTransform: 'none',
    fontStyle: 'normal',
    /** Desktop */
    [BASE_THEME.breakpoints.up('md')]: {
      fontSize: calcRem(14),
      lineHeight: calcRem(19),
    },
  },
  /** Mobile */
  caption: {
    fontFamily: wilsonFontFamily,
    fontWeight: 400,
    fontSize: calcRem(14),
    letterSpacing: calcRem(0.25),
    lineHeight: calcRem(19),
    fontStyle: 'normal',
    /** Desktop */
    [BASE_THEME.breakpoints.up('md')]: {
      fontWeight: 600,
      fontSize: calcRem(12),
      lineHeight: calcRem(16),
      letterSpacing: calcRem(0.2),
    },
  },
  /** Mobile and Desktop */
  overline: {
    fontFamily: wilsonFontFamily,
    fontWeight: 600,
    fontSize: calcRem(12),
    letterSpacing: calcRem(1.5),
    textTransform: 'uppercase',
    fontStyle: 'normal',
    lineHeight: calcRem(16),
  },
}
