import React, { useCallback } from 'react'
import {
  Slider as MuiSlider,
  Typography,
  SliderProps as IMuiSliderProps,
  FormHelperText,
  makeStyles,
} from 'dpl/core'
import { Theme } from '../../core/styles'

export interface ISliderProps extends Omit<IMuiSliderProps, 'color' | 'min' | 'max'> {
  /**
   * Visible label for the slider.
   * If visible label is not used, provide aria-label or getAriaLabel for accessibility.
   * @example Temperature
   */
  label?: React.ReactNode
  /**
   * The minimum allowed value of the slider. Should not be equal to max.
   * @required
   */
  min: number
  /**
   * The maximum allowed value of the slider. Should not be equal to min.
   * @required
   */
  max: number
  /**
   * When true, shows min and max labels underneath slider, without needing marks and keeps labels within the container.
   * @default true
   */
  showMinMaxValueLabels?: boolean
  /**
   * Label shown for min value. If not provided, the min value will be used.
   * @optional
   */
  minLabel?: React.ReactNode
  /**
   * Label shown for max value. If not provided, the max value will be used.
   * @optionals
   */
  maxLabel?: React.ReactNode
  /**
   * @default 'SliderComponent'
   */
  dataTest?: string
  /**
   * If `true`, the color of the helper text will be changed to the error color.
   * @default false
   */
  error?: boolean
  /**
   * The helper text content.
   * @default ''
   */
  helperText?: string
}

const useSliderStyles = makeStyles<
  Theme,
  { orientation?: string; showMinMaxValueLabels?: boolean }
>(theme => ({
  root: {
    height: '100%',
    width: '100%',
    paddingBottom: ({ showMinMaxValueLabels }) => (showMinMaxValueLabels ? theme.spacing(2) : 0),
  },
  minMaxLabels: {
    display: 'flex',
    pointerEvents: 'none', // This is so any mark labels between the min and max can still be clicked on.
    flexDirection: ({ orientation }) => (orientation === 'vertical' ? 'column-reverse' : 'row'),
    justifyContent: 'space-between',
    position: 'absolute',
    top: ({ orientation }) => (orientation === 'vertical' ? '0' : '30px'),
    left: ({ orientation }) => (orientation === 'vertical' ? '36px' : '0'),
    height: ({ orientation }) => (orientation === 'vertical' ? '100%' : 'auto'),
    width: ({ orientation }) => (orientation === 'vertical' ? 'auto' : '100%'),
    '& > span': {
      cursor: 'pointer',
      pointerEvents: 'auto',
    },
  },
  sliderWrapper: {
    position: 'relative',
    display: 'flex',
    height: ({ orientation }) => (orientation === 'vertical' ? '100%' : 'auto'),
    flexDirection: ({ orientation }) => (orientation === 'vertical' ? 'row' : 'column'),
  },
  label: {
    marginBottom: ({ orientation }) => (orientation === 'vertical' ? theme.spacing(1.25) : ''),
  },
  helperText: {
    marginTop: ({ orientation }) =>
      orientation === 'vertical' ? theme.spacing(2) : theme.spacing(4),
  },
}))

export function Slider({
  dataTest = 'SliderComponent',
  disabled = false,
  error = false,
  getAriaValueText,
  helperText = '',
  label,
  marks,
  max,
  maxLabel,
  min,
  minLabel,
  onChange,
  orientation,
  showMinMaxValueLabels = true,
  value,
  valueLabelDisplay = 'auto',
  valueLabelFormat,
  ...props
}: ISliderProps) {
  const classes = useSliderStyles({ orientation, showMinMaxValueLabels })

  const changeHandler = useCallback(
    (event: Event, newValue: number | number[], activeThumb: number) => {
      if (onChange) onChange(event, newValue, activeThumb)
    },
    [onChange]
  )

  const minValueChangeHandler = useCallback(
    e => {
      const newValue = Array.isArray(value) ? [min, value?.[1]] : min
      if (onChange) onChange(e, newValue, 0)
    },
    [min, onChange, value]
  )

  const maxValueChangeHandler = useCallback(
    e => {
      const newValue = Array.isArray(value) ? [value?.[0], max] : max
      if (onChange) onChange(e, newValue, 1)
    },
    [max, onChange, value]
  )

  return (
    <div data-test={dataTest} className={classes.root}>
      {label && (
        <div className={classes.label}>
          <Typography
            id='slider-label'
            variant='body1'
            component='label'
            data-test={`${dataTest}-label`}>
            {label}
          </Typography>
        </div>
      )}
      <div className={classes.sliderWrapper}>
        <MuiSlider
          data-test={`${dataTest}-slider`}
          aria-labelledby='slider-label'
          getAriaValueText={getAriaValueText}
          valueLabelFormat={valueLabelFormat}
          valueLabelDisplay={valueLabelDisplay}
          value={value}
          min={min}
          max={max}
          onChange={changeHandler}
          marks={marks}
          orientation={orientation}
          disabled={disabled}
          {...props}
        />
        {showMinMaxValueLabels && (
          <div className={classes.minMaxLabels} data-test={`${dataTest}-minMaxLabels`}>
            <Typography
              variant='caption'
              color='textSecondary'
              aria-hidden='true'
              onClick={minValueChangeHandler}>
              {minLabel || min}
            </Typography>
            <Typography
              variant='caption'
              color='textSecondary'
              aria-hidden='true'
              onClick={maxValueChangeHandler}>
              {maxLabel || max}
            </Typography>
          </div>
        )}
      </div>
      {helperText && (
        <FormHelperText
          error={error}
          data-test={`${dataTest}-helperText`}
          className={classes.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  )
}
