import React, { memo, ComponentType } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { ITextFieldProps, TextField } from 'dpl/components'

type IBaseControllerProps = ControllerProps<ComponentType<ITextFieldProps>>

export interface IControlledTextFieldProps extends Pick<IBaseControllerProps, 'control' | 'name'> {
  defaultValue?: string
  TextFieldProps?: Omit<ITextFieldProps, 'value'>
}

export const ControlledTextField = memo<IControlledTextFieldProps>(
  ({ control, name, defaultValue, TextFieldProps = {} }) => {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onBlur, onChange, value: val }) => {
          const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
            const {
              target: { value = '' },
            } = e || {}
            const { onChange: onChangeProp } = TextFieldProps
            if (onChangeProp) {
              onChangeProp(e)
            }
            onChange(value)
          }

          const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur()
            const { onBlur: onBlurProp } = TextFieldProps
            if (onBlurProp) {
              onBlurProp(e)
            }
          }

          const clearHandler = () => {
            onChange('')
          }

          return (
            <TextField
              name={name}
              id={name}
              {...TextFieldProps}
              value={val}
              onBlur={blurHandler}
              onChange={changeHandler}
              onClear={clearHandler}
            />
          )
        }}
      />
    )
  }
)

export default ControlledTextField
