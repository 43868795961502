import { IFileBody } from '../types/file'

/**
 * Promise wrapped process of reading a file as data url.
 * https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
 * @param file File Object
 * @returns promise that resolves with an object containing the file name and the file content as data url
 */
export const readFileAsDataURL = (file: File): Promise<IFileBody> => {
  const reader = new FileReader()

  return new Promise(resolve => {
    const loadHandler = () => {
      const fileBody = {
        file: reader.result,
        fileFileName: file.name,
        fileSize: file.size,
      }

      // cleanup load handler 👀
      reader.removeEventListener('load', loadHandler, false)

      resolve(fileBody)
    }

    reader.addEventListener('load', loadHandler, false)

    reader.readAsDataURL(file)
  })
}

/**
 * Promise wrapped process of reading a file as array buffer.
 * https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsArrayBuffer
 * @param file File Object
 * @returns promise that resolves with an object containing the file name and the file content as array buffer
 */
export const readFileAsArrayBuffer = (file: File): Promise<IFileBody> => {
  const reader = new FileReader()

  return new Promise(resolve => {
    const loadHandler = () => {
      const fileBody = {
        file: reader.result,
        fileFileName: file.name,
      }

      // cleanup load handler 👀
      reader.removeEventListener('load', loadHandler, false)

      resolve(fileBody)
    }

    reader.addEventListener('load', loadHandler, false)

    reader.readAsArrayBuffer(file)
  })
}

export const filePromises = (files: File[]): Promise<IFileBody>[] => files.map(readFileAsDataURL)

export const selectFile = (
  contentType: string = '.pdf, .jpg, .jpeg, .png, .doc, .docx, .tiff, .txt'
): Promise<IFileBody> => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = contentType

    input.onchange = async () => {
      const { files } = input || {}

      if (!files) return

      try {
        const [file] = Array.from(files)
        const document = await readFileAsDataURL(file)

        resolve(document)
      } catch (error) {
        reject(error)
      }
    }

    input.click()
  })
}

export const readFileAsText = (contentType: string = '.edi'): Promise<IFileBody> => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = contentType

    input.onchange = async () => {
      const { files } = input || {}

      if (!files) return

      try {
        const [file] = Array.from(files)
        const document = await file.text()
        const { name, size } = file

        resolve({
          file: document,
          fileFileName: name,
          fileSize: size,
        } as IFileBody)
      } catch (error) {
        reject(error)
      }
    }

    input.click()
  })
}
