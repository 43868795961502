import { DataGridComponents } from '@mui/x-data-grid/themeAugmentation'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'

export const MuiDataGridThemeOptions: DataGridComponents['MuiDataGrid'] = {
  styleOverrides: {
    root: {
      border: 0,
      '& .MuiTablePagination-input': {
        width: 'auto',
      },
      '& .MuiDataGrid-row--borderBottom .MuiDataGrid-filler': {
        border: 'none',
      },
    },
    row: {
      borderTop: 'none',
      borderBottom: `1px solid ${brandColors.coolGray3}`,
      '&:hover': {
        backgroundColor: brandColors.coolGray0,
        cursor: 'pointer',
      },
      '&:first-of-type': {
        borderTop: `1px solid ${brandColors.coolGray3}`,
      },
      '&.Mui-selected': {
        backgroundColor: brandColors.skyBlue0,
      },
      '&.Mui-selected:hover': {
        backgroundColor: brandColors.coolGray1,
        cursor: 'pointer',
      },
      '&.MuiDataGrid-row--dynamicHeight > .MuiDataGrid-cell': {
        whiteSpace: 'break-word',
      },
    },
    columnHeader: {
      boxShadow: 'none',
      border: 'none !important',
      padding: BASE_THEME.spacing(2, 1.5),
      [BASE_THEME.breakpoints.up('sm')]: {
        padding: BASE_THEME.spacing(1.5),
      },
    },
    cell: {
      alignContent: 'center',
      height: 'auto',
      boxShadow: 'none',
      border: 0,
      padding: BASE_THEME.spacing(2, 1.5),
      [BASE_THEME.breakpoints.up('sm')]: {
        padding: BASE_THEME.spacing(1.5),
      },
      '& .MuiInputBase-root': {
        height: '100%',
      },
      '& > .MuiInputBase-root': {
        height: '100%',
      },
      '&.MuiDataGrid-cell--editable .MuiIconButton-root': {
        color: brandColors.coolGray5,
        width: BASE_THEME.spacing(4),
        height: BASE_THEME.spacing(4),
      },
      '&.MuiDataGrid-cell--editable .MuiIconButton-root:hover, .MuiIconButton-root:focus, .MuiIconButton-root:active':
        {
          backgroundColor: `rgb(from ${brandColors.coolGray3} r g b / 0.3)`,
          borderRadius: '50%',
        },
      '&.MuiDataGrid-cell--editing': {
        padding: 0,
        height: 'auto',
        boxShadow: 'none',
      },
      '&.MuiDataGrid-cell--editing .MuiSelect-icon': {
        color: brandColors.coolGray8,
      },
      '& .MuiDataGrid-rowReorderCell--draggable svg': {
        color: brandColors.coolGray5,
      },
    },
    footerContainer: {
      border: 'none',
    },
    columnHeaders: {
      color: brandColors.coolGray5,
    },
    overlayWrapperInner: {
      borderTop: `1px solid ${brandColors.coolGray3}`,
    },
    columnHeaderTitleContainer: {
      '& .MuiCheckbox-root': {
        color: brandColors.coolGray5,
      },
      '& .MuiCheckbox-root.Mui-checked': {
        color: brandColors.skyBlue6,
      },
    },
    cellCheckbox: {
      '& .MuiCheckbox-root': {
        color: brandColors.coolGray5,
      },
      '& .MuiCheckbox-root.Mui-checked': {
        color: brandColors.skyBlue6,
      },
    },
  },
}
