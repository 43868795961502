import React, { memo } from 'react'
import { DISPLAY } from '../../constants/display'
import { Box, Typography } from '../../core'
import { makeStyles } from '../../core/styles'
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard'
import { SuccessIcon, CopyIcon } from '../../icons'
import { brandColors } from '../../theme/colors'

interface IconProps {
  displayText: string
  useIcon?: boolean
}

const CopyIconComponent = memo<IconProps>(({ displayText, useIcon }) =>
  useIcon ? <CopyIcon color='skyBlue6' size='medium' /> : <span>{displayText}</span>
)

const SuccessIconComponent = memo<IconProps>(({ useIcon }) =>
  useIcon ? (
    <SuccessIcon color='info' size='medium' data-test='success-icon' />
  ) : (
    <span className='text-success'>{DISPLAY.success}</span>
  )
)

type TAlignment = 'bottom' | 'top' | 'left' | 'right'

export interface ICopyProps {
  /**
   * @example 'left'
   */
  alignClass: TAlignment
  /**
   * This is the string that will be copied to the clipboard
   * @example 'testemail@gmail.com'
   */
  copyText: string
  /**
   * This is the string that  gets displayed as feedback to users
   * @example 'email'
   */
  displayText?: string
  /**
   * Boolean variable to toggle icon
   * @example 'false'
   */
  useIcon?: boolean
  /**
   * @example 'false'
   */
  large?: boolean
  /**
   * Label of what is being copied displayed to the right of the copy button
   * @optional
   * @example 'emails'
   */
  label?: string
}

const useCopyStyles = makeStyles({
  root: {
    color: brandColors.coolGray8,
  },
})

const Copy = memo(({ copyText, displayText, label, useIcon }: ICopyProps) => {
  const [isCopied, onCopy] = useCopyToClipboard()
  const classes = useCopyStyles()
  const text = displayText || `"${copyText}"`
  const tooltip = isCopied ? `Copied ${text} to clipboard!` : `Copy ${text} to clipboard`
  const copyHandler = () => onCopy(copyText)
  const dataTest = isCopied ? 'success-icon' : 'copy-icon'

  if (!copyText) {
    return null
  }

  return (
    <Box
      display='inline-flex'
      alignItems='center'
      role='button'
      tabIndex={0}
      className={classes.root}
      onClick={copyHandler}
      onKeyPress={copyHandler}
      data-tooltip={tooltip}
      data-test='copy'>
      <Box display='inline-flex' alignItems='center' columnGap={2} data-test={dataTest}>
        {isCopied ? (
          <SuccessIconComponent useIcon={useIcon} displayText={text} />
        ) : (
          <CopyIconComponent useIcon={useIcon} displayText={text} />
        )}
        {label && (
          <Typography variant='button' color='primary'>
            {label}
          </Typography>
        )}
      </Box>
    </Box>
  )
})

export default Copy
