import React, { memo } from 'react'
import classNames from 'classnames'
import { Typography } from 'dpl/core'
import { ClassNameMap, Theme, makeStyles } from 'dpl/core/styles'
import { brandColors } from 'dpl/theme/colors'
import { EPasswordStrengthLevel } from '../../types'
import { STRENGTH_BAR_COLORS, STRENGTH_LABEL_COLORS, STRENGTH_NUMBER } from './constants'

const usePasswordStrengthMeterStyles = makeStyles<Theme, { level?: EPasswordStrengthLevel }>(
  theme => ({
    root: {
      display: 'flex',
      gap: theme.spacing(0.5),
      flexDirection: 'column',
    },
    strengthMeter: {
      display: 'flex',
      gap: theme.spacing(0.5),
    },
    bar: {
      height: theme.spacing(0.75),
      width: '100%',
      backgroundColor: brandColors.coolGray3,
      borderRadius: theme.spacing(0.25),
    },
    barFilled: {
      backgroundColor: ({ level }) => (level ? STRENGTH_BAR_COLORS[level] : ''),
    },
    label: {
      color: ({ level }) => (level ? brandColors.coolGray8 : brandColors.coolGray6),
    },
    levelDescription: {
      color: ({ level }) => (level ? STRENGTH_LABEL_COLORS[level] : ''),
    },
  })
)

const getBarClasses = ({
  barNumber,
  classes,
  level,
}: {
  barNumber: number
  classes: ClassNameMap<string>
  level?: EPasswordStrengthLevel
}) => {
  return classNames(classes.bar, {
    [classes.barFilled]: level ? barNumber <= STRENGTH_NUMBER[level] : false,
  })
}

const BARS = [1, 2, 3, 4]

export interface IPasswordStrengthMeterProps {
  /**
   * Password strength level
   * @example Weak
   */
  level?: EPasswordStrengthLevel
}

function PasswordStrengthMeterComponent({ level }: IPasswordStrengthMeterProps) {
  const classes = usePasswordStrengthMeterStyles({ level })

  return (
    <div className={classes.root} data-test='password-strength-meter'>
      <div className={classes.label}>
        <Typography variant='body2' component='span'>
          Strength:
        </Typography>{' '}
        <Typography variant='button' className={classes.levelDescription}>
          {level}
        </Typography>
      </div>
      <div className={classes.strengthMeter}>
        {BARS.map(barNumber => (
          <div
            key={barNumber}
            className={getBarClasses({
              barNumber,
              classes,
              level,
            })}
          />
        ))}
      </div>
    </div>
  )
}

export const PasswordStrengthMeter = memo<IPasswordStrengthMeterProps>(
  PasswordStrengthMeterComponent
)
