import React from 'react'
import { brandColors } from 'dpl'
import { makeStyles, Typography } from 'dpl/core'
import { NotFoundIcon } from './components'

const usePageNotFoundStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(6),
    },
    rowGap: theme.spacing(3),
  },
}))

export function PageNotFound() {
  const classes = usePageNotFoundStyles()

  return (
    <div data-test='page-not-found' className={classes.root}>
      <NotFoundIcon />
      <Typography variant='h2'>Oops, we hit a roadblock!</Typography>
      <Typography variant='body1' color={brandColors.coolGray5}>
        It seems the page you&apos;re looking for has gone missing or doesn&apos;t exist anymore.
      </Typography>
    </div>
  )
}
