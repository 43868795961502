export const pagination = (currentPageNumber: number, numberOfPages: number): number[] => {
  const delta = 1
  const range = []
  const rangeWithDots = []
  let left

  range.push(1)
  for (let i = currentPageNumber - delta; i <= currentPageNumber + delta; i += 1) {
    if (i < numberOfPages && i > 1) {
      range.push(i)
    }
  }
  range.push(numberOfPages)

  for (const i of range) {
    if (left) {
      if (i - left === 2) {
        rangeWithDots.push(left + 1)
      } else if (i - left !== 1) {
        // -1 represents '...'
        rangeWithDots.push(i - i * 2)
      }
    }
    rangeWithDots.push(i)
    left = i
  }

  return rangeWithDots
}
