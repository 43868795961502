import { useCallback, useEffect, useMemo } from 'react'
import { QueryParamOptions } from 'use-query-params'
import { useQueryParams } from '../../../../../hooks'
import { IDataGridFilterProps } from '../components'
import { SORT_QUERY_PARAM_KEY } from '../constants'
import { IDataGridFilters, TDataGridFiltersConfig } from '../types'
import { getDataGridFiltersEmptyState, getDataGridQueryParamConfigMap } from '../utils'

export interface IUseDataGridQueryParamsProps<
  TFilters extends IDataGridFilters,
  TSortValue extends string,
> {
  /**
   * Configuration object for the filters
   * @example { userSearch: { type: 'textInput' }, isActive: { type: 'switch' }, }
   */
  filtersConfig: TDataGridFiltersConfig<TFilters>
  /**
   * Default values for the filters
   * @note Default values do not change the url. They are only used to initialize the filters
   * @example { userSearch: 'John Doe' }
   * @default {}
   */
  defaultFilterValues?: Partial<TFilters>
  /**
   * Default sort option value for the data grid
   * @note Default values do not change the url. They are only used to initialize the sort dropdown
   */
  defaultSortValue?: TSortValue
  /**
   * Callback function that is called when a query param changes
   */
  onQueryParamChange?: (queryParams: TFilters & { [SORT_QUERY_PARAM_KEY]: TSortValue }) => void
  /**
   * Options for the query params hook
   */
  queryParamOptions?: QueryParamOptions
}

export interface IUseDataGridQueryParamsState<
  TFilters extends IDataGridFilters,
  TSortValue extends string,
> {
  /**
   * The current state of the query params
   */
  queryParams: TFilters & { [SORT_QUERY_PARAM_KEY]: TSortValue }
  /**
   * Function to set multiple filters
   * @param filters Partial object with the filters to set
   */
  setFiltersAndSort: (filters: Partial<TFilters & { [SORT_QUERY_PARAM_KEY]: TSortValue }>) => void
  /**
   * Function to set a single filter
   * @param name Name of the filter to set
   * @param value Value to set for the filter query param
   */
  setSingleFilter: IDataGridFilterProps<any, keyof TFilters>['onChange']
  /**
   * Function to clear all filters
   */
  clearAllFilters: () => void
  /**
   * Function to set the sort value
   * @param newSortValue Value to set for the sort query param
   */
  setSort: (newSortValue: TSortValue) => void
}

/**
 * Hook to manage the query params for the data grid filters
 * @param filtersConfig Configuration for the filters
 * @param defaultFilterValues Default values for the filters (optional)
 */
export function useDataGridQueryParams<
  TFilters extends IDataGridFilters,
  TSortValue extends string,
>({
  defaultFilterValues = {},
  filtersConfig,
  defaultSortValue,
  onQueryParamChange,
  queryParamOptions,
}: IUseDataGridQueryParamsProps<TFilters, TSortValue>): IUseDataGridQueryParamsState<
  TFilters,
  TSortValue
> {
  const queryParamConfigMap = useMemo(
    () =>
      getDataGridQueryParamConfigMap<TFilters, TSortValue>({
        filtersConfig,
        defaultFilterValues,
        defaultSortValue,
      }),
    [defaultFilterValues, defaultSortValue, filtersConfig]
  )

  const [queryParams, setQueryParams] = useQueryParams<
    TFilters & { [SORT_QUERY_PARAM_KEY]: TSortValue }
  >(queryParamConfigMap, queryParamOptions)

  const setSingleFilter: IDataGridFilterProps<any, keyof TFilters>['onChange'] = useCallback(
    ({ name, value }) => {
      const newQueryParams = {
        ...queryParams,
        [name]: value,
      }
      setQueryParams(newQueryParams)
      if (onQueryParamChange) {
        onQueryParamChange(newQueryParams)
      }
    },
    [onQueryParamChange, queryParams, setQueryParams]
  )

  const setFiltersAndSort = useCallback(
    (filters: Partial<TFilters & { [SORT_QUERY_PARAM_KEY]: TSortValue }>) => {
      const newQueryParams = {
        ...queryParams,
        ...filters,
      }
      setQueryParams(newQueryParams)
      if (onQueryParamChange) {
        onQueryParamChange(newQueryParams)
      }
    },
    [onQueryParamChange, queryParams, setQueryParams]
  )

  const clearAllFilters = useCallback(() => {
    const emptyState = getDataGridFiltersEmptyState({ filtersConfig })
    setFiltersAndSort(emptyState)
    if (onQueryParamChange) {
      onQueryParamChange({
        ...emptyState,
        [SORT_QUERY_PARAM_KEY]: queryParams[SORT_QUERY_PARAM_KEY],
      })
    }
  }, [filtersConfig, onQueryParamChange, queryParams, setFiltersAndSort])

  const setSort = useCallback(
    (newSortValue: TSortValue) => {
      const newQueryParams = {
        ...queryParams,
        [SORT_QUERY_PARAM_KEY]: newSortValue,
      }
      setQueryParams(newQueryParams)
      if (onQueryParamChange) {
        onQueryParamChange(newQueryParams)
      }
    },
    [onQueryParamChange, queryParams, setQueryParams]
  )

  /**
   * This will trigger the callback when loading the page so the parent can make the correct API call
   */
  useEffect(() => {
    if (onQueryParamChange) {
      onQueryParamChange(queryParams)
    }
  }, [])

  return {
    queryParams,
    setFiltersAndSort,
    setSingleFilter,
    clearAllFilters,
    setSort,
  }
}
