import React, { memo } from 'react'
import classNames from 'classnames'
import { makeStyles } from '../../core/styles'
import SortIcon from '../../icons/build/SortIcon'
import { ISvgIconProps, getColor } from '../../icons/components/SvgIcon'
import { TIconColor } from '../../icons/components/SvgIcon/types'

export enum ISortToggleDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}

const useSortToggleStyles = makeStyles<any, { sortDirectionColor: TIconColor }>(() => ({
  icon: {
    '&.asc .Sort_svg__sort-arrow-up, &.asc .sort-arrow-up': {
      fill: ({ sortDirectionColor }: { sortDirectionColor: TIconColor }) =>
        getColor(sortDirectionColor),
    },
    '&.desc .Sort_svg__sort-arrow-down, &.desc .sort-arrow-down': {
      fill: ({ sortDirectionColor }: { sortDirectionColor: TIconColor }) =>
        getColor(sortDirectionColor),
    },
  },
}))

export interface ISortToggleProps extends Omit<ISvgIconProps, 'className'> {
  sortDirection?: ISortToggleDirection
  sortDirectionColor?: TIconColor
}

const SortToggle = memo<ISortToggleProps>(
  ({
    color = 'coolGray3',
    sortDirection = ISortToggleDirection.NONE,
    sortDirectionColor = 'activeBlue',
    ...rest
  }) => {
    const classes = useSortToggleStyles({ sortDirectionColor })
    const className = classNames(classes.icon, {
      [ISortToggleDirection.ASC]: sortDirection === ISortToggleDirection.ASC,
      [ISortToggleDirection.DESC]: sortDirection === ISortToggleDirection.DESC,
    })
    return <SortIcon className={className} color={color} data-test='sort-icon-toggle' {...rest} />
  }
)

export default SortToggle
