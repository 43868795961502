import React, { PropsWithChildren } from 'react'
import { Tooltip as MuiTooltip, TooltipProps as IMuiTooltipProps, makeStyles } from '../../core'
import { brandColors, TBrandColor } from '../../theme'

export type TTooltipColor = 'dark' | 'light'

export interface ITooltipProps
  extends Omit<
    IMuiTooltipProps,
    | 'arrow'
    | 'components'
    | 'componentProps'
    | 'disableInteractive'
    | 'disableHoverListener'
    | 'disableFocusListener'
    | 'disableTouchListener'
  > {
  /**
   * @default 'dark'
   */
  color?: TTooltipColor
  /**
   * @default false
   */
  disabled?: boolean
}

const getTooltipColors = (backgroundColor: TBrandColor, color: TBrandColor) => {
  return {
    backgroundColor: brandColors[backgroundColor],
    color: brandColors[color],
    '&.MuiTooltip-tooltipArrow .MuiTooltip-arrow': {
      color: brandColors[backgroundColor],
    },
  }
}

const useTooltipStyles = makeStyles(() => ({
  'tooltip-dark': { ...getTooltipColors('coolGray8', 'white') },
  'tooltip-light': { ...getTooltipColors('coolGray1', 'coolGray8') },
}))

const disabledProps = {
  disableHoverListener: true,
  disableFocusListener: true,
  disableTouchListener: true,
}

export function Tooltip({
  children,
  color = 'dark',
  disabled = false,
  slotProps,
  title,
  ...props
}: PropsWithChildren<ITooltipProps>) {
  const classes = useTooltipStyles()

  return (
    <MuiTooltip
      title={title}
      slotProps={{
        ...slotProps,
        tooltip: { className: classes[`tooltip-${color}`] },
      }}
      {...(disabled ? disabledProps : {})}
      {...props}>
      {children}
    </MuiTooltip>
  )
}
