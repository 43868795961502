import React, { useCallback, useState } from 'react'
import { InputAdornment } from '../../core'
import ViewOffIcon from '../../icons/build/ViewOffIcon'
import ViewOnIcon from '../../icons/build/ViewOnIcon'
import { IconButton } from '../IconButton'
import TextField, { ITextFieldProps } from '../TextField'

export interface IPasswordFieldProps
  extends Omit<ITextFieldProps, 'type' | 'clearable' | 'onClear'> {}

export function PasswordField({
  InputProps,
  dataTest = 'PasswordField',
  ...props
}: IPasswordFieldProps) {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = useCallback(() => {
    setShowPassword(prev => !prev)
  }, [])

  return (
    <TextField
      {...props}
      dataTest={dataTest}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              Icon={showPassword ? ViewOnIcon : ViewOffIcon}
              onClick={toggleShowPassword}
              size='xlarge'
              color='secondary'
              dataTest={`${dataTest}-toggle`}
            />
          </InputAdornment>
        ),
      }}
    />
  )
}
