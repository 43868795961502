import { useState } from 'react'
import copy from 'copy-to-clipboard'

const COPIED_PROMPT_TIMEOUT_MS = 1000

export const useCopyToClipboard = (): [boolean, (value: string) => void] => {
  const [isCopied, setCopied] = useState<boolean>(false)

  const copyText = (text: string) => {
    if (isCopied) {
      // prevent double-click
      return
    }
    copy(text || '')
    setCopied(true)
    setTimeout(() => setCopied(false), COPIED_PROMPT_TIMEOUT_MS)
  }

  return [isCopied, copyText]
}
