import React, { useMemo } from 'react'
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteValue as MuiAutocompleteValue,
  CircularProgress as MuiCircularProgress,
} from '../../core'
import Box from '../../core/Box'
import CloseIcon from '../../icons/build/CloseIcon'
import { ITextFieldProps, TextField } from '../TextField'
import { AutocompleteHighlightText } from './AutocompleteHighlightText'

const START_TYPING_TEXT = 'Start typing to see results...'
const NO_MATCHING_OPTIONS_TEXT = 'No results'

export interface IAutocompleteProps<
  Value,
  FreeSolo extends boolean | undefined = false,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
> extends Omit<
      MuiAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>,
      'renderInput' | 'loadingText'
    >,
    Pick<
      ITextFieldProps,
      'autoFocus' | 'error' | 'helperText' | 'label' | 'onClear' | 'required' | 'type' | 'variant'
    > {
  /**
   * Used to display the start adornment of the input.
   *
   * @example () => <SearchIcon size='large'/>
   * @example (selectedOption) => <Avatar name={selectedOption?.name} src={selectedOption?.profileImgSrc} size='xsmall'/>
   *
   * @param selectedOption - The selected option
   */
  renderStartAdornment?: (
    selectedOption: MuiAutocompleteValue<Value, Multiple, DisableClearable, FreeSolo> | undefined
  ) => React.ReactNode
  /**
   *
   * @default 'Autocomplete'
   */
  dataTest?: string
}

/**
 * This filter function is used to prevent the default filtering behavior of the MUIAutocomplete component.
 * See: https://mui.com/material-ui/react-autocomplete/#search-as-you-type
 */
const DEFAULT_FILTERING: IAutocompleteProps<any>['filterOptions'] = options => options

export function Autocomplete<
  Value,
  FreeSolo extends boolean | undefined = false,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
>({
  autoFocus,
  dataTest = 'Autocomplete',
  error,
  filterOptions = DEFAULT_FILTERING,
  helperText,
  inputValue,
  label,
  loading,
  options,
  placeholder,
  renderStartAdornment,
  required,
  value,
  noOptionsText: noOptionsTextProp,
  type = 'text',
  variant,
  ...props
}: IAutocompleteProps<Value, FreeSolo, Multiple, DisableClearable>) {
  const startAdornment = useMemo(() => {
    if (renderStartAdornment) {
      return renderStartAdornment(value)
    }
    return null
  }, [renderStartAdornment, value])

  const noOptionsText = useMemo(() => {
    const startTypingText = noOptionsTextProp ?? START_TYPING_TEXT

    return inputValue ? NO_MATCHING_OPTIONS_TEXT : startTypingText
  }, [inputValue, noOptionsTextProp])

  return (
    <MuiAutocomplete
      data-test={dataTest}
      noOptionsText={noOptionsText}
      renderOption={(
        optionProps,
        option,
        { inputValue: autocompleteInputValue },
        { getOptionLabel }
      ) => (
        <li {...optionProps}>
          <AutocompleteHighlightText
            label={getOptionLabel(option)}
            inputValue={autocompleteInputValue}
          />
        </li>
      )}
      {...props}
      inputValue={inputValue}
      value={value}
      loading={loading}
      filterOptions={filterOptions}
      options={loading ? [] : options}
      renderInput={inputProps => (
        <TextField
          {...inputProps}
          type={type}
          autoFocus={autoFocus}
          dataTest={`${dataTest}-input`}
          error={error}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
          required={required}
          _autocompleteTags={inputProps.InputProps.startAdornment}
          variant={variant}
          InputProps={{
            ...inputProps.InputProps,
            startAdornment,
          }}
        />
      )}
      loadingText={
        <Box paddingY={1.5} display='flex' alignItems='center' justifyContent='center'>
          <MuiCircularProgress size={24} />
        </Box>
      }
      clearIcon={<CloseIcon size='xlarge' data-test={`${dataTest}-clear-icon`} />}
      slotProps={{
        ...props.slotProps,
        clearIndicator: {
          color: 'secondary',
        },
      }}
    />
  )
}

export default Autocomplete
