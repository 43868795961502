import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'

/**
 * @name DialogContent
 * @see https://mui.com/material-ui/api/dialog-content/
 */
export const MuiDialogContentThemeOptions: Components['MuiDialogContent'] = {
  styleOverrides: {
    root: {
      padding: BASE_THEME.spacing(3),
    },
  },
}
