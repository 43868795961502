import {
  B2BOrganizationsGetBySlugOptions,
  B2BOrganizationsGetBySlugResponse,
  StytchB2BHeadlessClient,
} from '@stytch/vanilla-js/b2b/headless'
import { KyInstance } from 'ky'

export interface IOrganizationsClientOptions {
  connection: KyInstance
  stytch: StytchB2BHeadlessClient
}

export interface IOrganizationsClient {
  getBySlug: (data: B2BOrganizationsGetBySlugOptions) => Promise<B2BOrganizationsGetBySlugResponse>
}

export const makeOrganizationsClient = ({
  stytch,
}: IOrganizationsClientOptions): IOrganizationsClient => {
  return {
    getBySlug: data => {
      return stytch.organization.getBySlug(data)
    },
  }
}
