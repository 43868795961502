import React from 'react'
import { makeStyles, Typography } from '../../../core'
import { AddIcon } from '../../../icons'
import { brandColors } from '../../../theme'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    backgroundColor: brandColors.skyBlue2,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))

export function DropHere() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AddIcon size='xlarge' color='skyBlue7' />
      <Typography variant='subtitle2' color={brandColors.skyBlue7}>
        Drop here
      </Typography>
    </div>
  )
}
