import React, { memo } from 'react'
import classNames from 'classnames'
import { Radio, GridRenderCellParams, makeStyles, useGridApiContext } from '../../../../core'

const useDataGridRadioCellStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editing: {
    padding: theme.spacing(2, 1.5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1.5),
    },
  },
}))

export interface IDataGridRadioCellProps extends GridRenderCellParams {
  /**
   * @description Determines if radio should be checked or unchecked
   */
  checked: boolean
  /**
   * @description Label to display besides radio
   */
  label?: string
}

export const DataGridRadioCell = memo<IDataGridRadioCellProps>(({ checked, field, id, label }) => {
  const classes = useDataGridRadioCellStyles()
  const apiRef = useGridApiContext()
  const cellMode = apiRef.current.getCellMode(id, field)
  const isEditMode = cellMode === 'edit'
  const rootClasses = classNames(classes.root, {
    [classes.editing]: isEditMode,
  })

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    if (!isEditMode) {
      await apiRef.current.startCellEditMode({ id, field })
    }
    await apiRef.current.setEditCellValue({ id, field, value: newValue })
    await apiRef.current.stopCellEditMode({ id, field })
  }

  return (
    <div className={rootClasses} data-test='DataGridRadioCell'>
      <Radio checked={checked} onChange={handleChange} />
      {label}
    </div>
  )
})
