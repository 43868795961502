import React, { memo, ReactNode, useCallback, useMemo } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import classNames from 'classnames'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { makeStyles, createStyles, CreateCSSProperties, Input, Theme } from '../../core'
import { UploadIcon } from '../../icons'
import { brandColors } from '../../theme'
import { useToastContext } from '../Toast'
import { IFileObject } from './types'
import { convertFileToDataUrl } from './utils'

export type TAlertType = 'info' | 'error'

export interface IDropzoneClasses {
  root?: string
  icon?: string
}

export interface IDropzoneProps extends Pick<DropzoneOptions, 'onDropRejected'> {
  /**
   * A comma separated list of MIME Types (NOT file extensions) to accept, by default an empty array allows all.
   * @example ['image/png', 'text/csv']
   * @see https://www.iana.org/assignments/media-types/media-types.xhtml
   * @default []
   */
  acceptedFiles?: string[]
  classes?: IDropzoneClasses
  dropZoneText?: ReactNode
  /**
   * @default false
   */
  error?: boolean
  /**
   * Limits the number of files that can be dropped into the zone or selected to be uploaded.
   * @default 3
   */
  filesLimit?: number
  /**
   * @description Displays snackbar when files are dropped, deleted or rejected.
   * @default ['info', 'error']
   */
  showAlerts?: TAlertType[]
  alertSnackbarProps?: object
  onFileChange: (files: IFileObject[]) => void
  /**
   * @default 'dropzone'
   */
  dataTest?: string
}

const dropzoneActiveStyle: CreateCSSProperties = {
  animation: 'none',
  backgroundImage: 'none',
  borderColor: 'none',
  background: brandColors.skyBlue1,
  '& svg': {
    color: brandColors.skyBlue5,
  },
}

const useDropzoneStyles = makeStyles<Theme, Pick<IDropzoneProps, 'error'>>(theme =>
  createStyles<
    'active' | 'text' | 'textContainer' | 'root' | 'icon',
    Pick<IDropzoneProps, 'error'>
  >({
    active: {
      ...dropzoneActiveStyle,
    },
    text: {
      marginTop: 8,
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 16,
      lineHeight: '22px',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '80%',
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: ({ error }) => (error ? brandColors.error1 : brandColors.coolGray5),
      borderStyle: 'dashed',
      borderWidth: '1px',
      borderRadius: theme.shape.borderRadius,
      boxSizing: 'border-box',
      cursor: 'pointer',
      minHeight: '250px',
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
      '&:hover': {
        ...dropzoneActiveStyle,
      },
      '&:focus': {
        outline: 'none',
      },
    },
    icon: {
      color: brandColors.coolGray4,
    },
  })
)
export const Dropzone = memo<IDropzoneProps>(
  ({
    acceptedFiles: acceptedMimeTypes = [],
    classes: classesProp,
    dropZoneText,
    error = false,
    filesLimit = 3,
    showAlerts = ['error', 'info'],
    onDropRejected: onDropRejectedProp,
    onFileChange: onFileChangeProp,
    dataTest = 'dropzone',
  }) => {
    const classes = useDropzoneStyles({ error })
    const { openToast } = useToastContext() || {}

    /**
     * @example { 'image/png': [], 'text/csv': [] }
     */
    const accept = useMemo(() => {
      return mapValues(keyBy(acceptedMimeTypes), () => [])
    }, [acceptedMimeTypes])

    const dropAcceptedHandler = useCallback<NonNullable<DropzoneOptions['onDropAccepted']>>(
      acceptedFiles => {
        const promises: Promise<IFileObject>[] = acceptedFiles.map(async file => {
          const base64String = await convertFileToDataUrl(file)

          return {
            file,
            data: base64String,
          }
        })

        const resolve = async () => {
          try {
            const files = await Promise.all(promises)

            if (showAlerts.includes('info')) {
              openToast({
                toastMessage: 'File(s) successfully added.',
                toastType: 'default',
              })
            }

            onFileChangeProp(files)
          } catch (err) {
            if (showAlerts.includes('error')) {
              openToast({
                toastMessage: err?.message,
                toastType: 'alert',
              })
            }
          }
        }

        resolve()
      },
      [onFileChangeProp, openToast, showAlerts]
    )

    const dropRejectedHandler = useCallback<NonNullable<DropzoneOptions['onDropRejected']>>(
      (fileRejections, event) => {
        if (showAlerts.includes('error')) {
          openToast({
            toastMessage: fileRejections[0]?.errors?.[0]?.message,
            toastType: 'alert',
          })
        }

        if (onDropRejectedProp) {
          onDropRejectedProp(fileRejections, event)
        }
      },
      [onDropRejectedProp, openToast, showAlerts]
    )

    const errorHandler = useCallback<NonNullable<DropzoneOptions['onError']>>(
      error => {
        if (showAlerts.includes('error')) {
          openToast({
            toastMessage: error.message,
            toastType: 'alert',
          })
        }
      },
      [openToast, showAlerts]
    )

    const { getInputProps, getRootProps, isDragActive } = useDropzone({
      accept,
      onDropAccepted: dropAcceptedHandler,
      onDropRejected: dropRejectedHandler,
      onError: errorHandler,
      maxFiles: filesLimit,
      /**
       * 50 megabytes -> 52428800 bytes, in base 2
       */
      maxSize: 52428800,
    })

    const rootClassName = classNames(classes.root, classesProp?.root, {
      [classes.active]: isDragActive,
    })

    const iconClassName = classNames(classes.icon, classesProp?.icon)

    return (
      <div {...getRootProps()} className={rootClassName} data-test={dataTest}>
        <Input inputProps={getInputProps()} />

        <div className={classes.textContainer}>
          <UploadIcon className={iconClassName} size='xxlarge' />
          {dropZoneText && <div className={classes.text}>{dropZoneText}</div>}
        </div>
      </div>
    )
  }
)

export default Dropzone
