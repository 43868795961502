import React from 'react'
import classNames from 'classnames'
import { brandColors } from 'dpl'
import { makeStyles, Typography, Avatar as MuiAvatar } from 'dpl/core'

const useNotFoundIconStyles = makeStyles(theme => ({
  avatar: {
    '&.avatar-color-default': {
      backgroundColor: brandColors.coolGray1,
    },
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  large: {
    fontSize: '1rem',
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
}))

export function NotFoundIcon() {
  const styles = useNotFoundIconStyles()
  const classes = classNames(styles.icon, styles.avatar, styles.large, 'avatar-color-default')

  return (
    <MuiAvatar color={brandColors.badgeRed} className={classes} variant='rounded'>
      <Typography variant='h4' color={brandColors.coolGray5}>
        404
      </Typography>
    </MuiAvatar>
  )
}
