import React from 'react'
import { makeStyles, SelectProps as MuiSelectProps } from '../../../../core'
import { Badge } from '../../../Badge'

export interface IPillSelectMultiValueLabelProps<Value = any>
  extends Pick<MuiSelectProps<Value>, 'label' | 'value'> {
  /**
   * @default 'PillSelectMultiValueLabel'
   */
  dataTest?: string
}

const usePillSelectMultiValueLabelStyles = makeStyles(theme => ({
  multiValueLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))

export function PillSelectMultiValueLabel({
  dataTest = 'PillSelectMultiValueLabel',
  label,
  value,
}: IPillSelectMultiValueLabelProps) {
  const classes = usePillSelectMultiValueLabelStyles()
  return (
    <div data-test={dataTest} className={classes.multiValueLabel}>
      {label}
      {value.length > 0 && (
        <Badge color='blue' label={`${value.length}`} dataTest={`${dataTest}-badge`} />
      )}
    </div>
  )
}
