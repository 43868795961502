import moment, { Moment } from 'moment-timezone'

export const buildDateTime = (
  date?: string,
  time?: string,
  timeFormat?: string,
  timezone?: string
): Moment | undefined => {
  if (!date || !time) return undefined
  const dateMoment = moment(date).startOf('d')
  const timeMoment = moment(time, timeFormat)
  const dateWithNewTime = dateMoment
    .set({
      hours: timeMoment.hours(),
      minutes: timeMoment.minutes(),
    })
    .tz(timezone ?? moment.tz.guess(), true)
  return dateWithNewTime
}
