import React, { useState, useCallback } from 'react'
import { Tab as MuiTab, TabProps as MuiTabProps } from '../../../core'
import { makeStyles } from '../../../core/styles'
import { Badge } from '../../Badge'
import { getTabIdentifiers } from '../utils/getTabIdentifiers'

const useTabStyles = makeStyles(theme => ({
  badge: {
    marginLeft: theme.spacing(1),
  },
}))

type TOmittedProp = 'value' | 'data-test' | 'id'

export interface ITabProps extends Omit<MuiTabProps, TOmittedProp> {
  /**
   * Unique ID for tab
   * @example 'home'
   */
  tabId: string
  /**
   * Optional count that displays if defined
   * @example 12
   */
  count?: number
  dataTest?: string
  selected?: boolean
}

interface ITabLabelProps extends Pick<ITabProps, 'label' | 'count'> {
  isHighlighted: boolean
}

function TabLabel({ count, isHighlighted, label }: ITabLabelProps) {
  const classes = useTabStyles()

  if (typeof count !== 'number') {
    return <span>{label}</span>
  }

  return (
    <span>
      {label}
      <Badge className={classes.badge} color={isHighlighted ? 'blue' : 'gray'} label={`${count}`} />
    </span>
  )
}

export function Tab({ count, dataTest, label, tabId, ...rest }: ITabProps) {
  const [isHovered, setIsHovered] = useState(false)
  const isHighlighted = rest?.selected || isHovered
  const mouseEnterHandler = useCallback(() => {
    setIsHovered(true)
  }, [])
  const mouseLeaveHandler = useCallback(() => {
    setIsHovered(false)
  }, [])
  const { tabIdentifier, tabPanelIdentifier } = getTabIdentifiers(tabId)

  return (
    <MuiTab
      {...rest}
      id={tabIdentifier}
      component='div'
      label={<TabLabel label={label} count={count} isHighlighted={isHighlighted} />}
      aria-controls={tabPanelIdentifier}
      data-test={dataTest}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    />
  )
}
