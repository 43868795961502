import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'

/**
 * @name Accordion
 * @see https://mui.com/material-ui/api/accordion/
 */
export const MuiAccordionThemeOptions: Components['MuiAccordion'] = {
  defaultProps: {
    variant: 'outlinedBottom',
  },
  variants: [
    {
      props: { variant: 'outlinedBottom' },
      style: {
        boxShadow: 'none',
        borderBottom: `1px solid ${BASE_THEME.palette.divider}`,

        '& .MuiAccordionSummary-root': {
          padding: BASE_THEME.spacing(2, 0),

          '&.Mui-expanded': {
            minHeight: 'auto !important',
          },

          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        },

        '& .MuiAccordionDetails-root': {
          padding: BASE_THEME.spacing(0.5),
        },

        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    {
      props: { variant: 'filled' },
      style: {
        background: brandColors.coolGray0,

        '&.Mui-disabled': {
          background: brandColors.coolGray0,
          '& .MuiAccordionSummary-root': {
            opacity: 1,
            '& .MuiAccordionSummary-expandIconWrapper svg': {
              color: brandColors.gray2,
            },
          },
        },

        '& .MuiAccordionSummary-root': {
          flexDirection: 'row-reverse',
          gap: BASE_THEME.spacing(0.5),
          padding: BASE_THEME.spacing(1.5),

          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper svg': {
            color: brandColors.coolGray8,
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        },

        '& .MuiAccordionDetails-root': {
          borderTop: `1px solid ${brandColors.coolGray2}`,
          padding: BASE_THEME.spacing(1.5, 0),
          margin: BASE_THEME.spacing(0, 1.5),
        },
      },
    },
  ],
}
