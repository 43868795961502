import React from 'react'
import { Avatar } from '../../../components/Avatar'
import { ButtonBase, Card, CardContent, CardHeader, makeStyles, Typography } from '../../../core'
import { brandColors } from '../../../theme/colors'
import { IOrganizationInfo } from '../types'

export interface IOrganizationCardProps {
  organizationInfo: IOrganizationInfo
  profileLink: string
}

const useOrganizationCardStyles = makeStyles(theme => ({
  organization: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    '& > a': {
      '&:focus': {
        boxShadow: `0 0 0 3px ${brandColors.skyBlue3}`,
      },
    },
  },
}))

export function OrganizationCard({ organizationInfo, profileLink }: IOrganizationCardProps) {
  const { image, name } = organizationInfo || {}
  const classes = useOrganizationCardStyles()

  return (
    <Card data-test='user-organization-card'>
      <CardHeader title='Organization' />
      <CardContent>
        <div className={classes.organization}>
          <ButtonBase href={profileLink}>
            <Avatar
              variant='rounded'
              name={name}
              src={image}
              hideTooltip
              size='large'
              alt={`${name} Profile`}
            />
          </ButtonBase>
          <Typography variant='subtitle1'>{name}</Typography>
        </div>
      </CardContent>
    </Card>
  )
}
