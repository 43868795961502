import React, { memo, useCallback } from 'react'
import { IToggleButtonProps, ToggleButton } from '../../../../../ToggleButton'
import { IDataGridFilterProps } from '../types'

export interface IDataGridToggleButtonProps
  extends IDataGridFilterProps<boolean>,
    Pick<IToggleButtonProps, 'label' | 'startIcon'> {
  /**
   * @default 'DataGridToggleButton'
   */
  dataTest?: string
}

export const DataGridToggleButton = memo<IDataGridToggleButtonProps>(
  ({ dataTest = 'DataGridToggleButton', name, onChange, value = false, ...rest }) => {
    const changeHandler: IToggleButtonProps['onChange'] = useCallback(() => {
      onChange({ name, value: !value })
    }, [name, onChange, value])

    return (
      <ToggleButton dataTest={dataTest} onChange={changeHandler} selected={value} value {...rest} />
    )
  }
)
