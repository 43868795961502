import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  createRef,
  ReactElement,
  KeyboardEvent,
  MouseEvent,
} from 'react'
import classnames from 'classnames'
import { v4 as uuid } from 'uuid'
import { KEY_VALUES } from '../../constants/keyValues'
import { makeStyles } from '../../core/styles'
import { DownArrowIcon } from '../../icons'
import { brandColors } from '../../theme/colors'
import { boxShadowLevels } from '../../theme/shadows'

const INTERACTIVE_TAGS = ['button', 'a', 'input']
const HANDLE_KEYS = [KEY_VALUES.ENTER, KEY_VALUES.SPACE, KEY_VALUES.SPACEBAR].map(key =>
  key.toLowerCase()
)

export interface IExpandableRowProps {
  /**
   * ID of the expandable row.
   *
   * @default uuid()
   */
  id?: string
  /**
   * Content of the row, clicking the row will expand it below this content.
   */
  row: ReactElement
  /**
   * Content of the expanded part of the row.  This is what gets displayed below the row element.
   */
  details: ReactElement
  /**
   * The state of whether the expanded row is expanded.
   *
   * @default false
   */
  expanded?: boolean
  /**
   * The event to be fired upon expansion of the row.
   *
   * @default () => {}
   */
  onExpand?: () => void
  /**
   * Specifies any additional classes to be added to the clickable portion of the row.
   *
   * @default ''
   */
  rowClass?: string
  /**
   * Specifies any additional classes to be added to the details section.
   *
   * @default ''
   */
  detailsClass?: string
  /**
   * Optional - add class for expandable row
   *
   * @default ''
   */
  expandableRowClassProp?: string
  /**
   * Optional - add class for details pane
   *
   * @default ''
   */
  paneClassProp?: string
}

const useExpandableRowStyles = makeStyles(theme => ({
  expandableRow: {
    '& .row': {
      margin: 0,
      position: 'relative',
    },

    '& .row-target': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: brandColors.white,
      cursor: 'pointer',
      zIndex: 1,
      padding: `${theme.spacing(3)}`,
      '&:hover': {
        backgroundColor: brandColors.coolGray1,
      },
    },

    '&.expandable-row-expanded': {
      '& .row-target': {
        borderRadius: theme.spacing(0.25),
        boxShadow: `0 0 0 1px ${brandColors.coolGray5}, ${boxShadowLevels.medium}`,
        position: 'relative',
      },
      '& .pointer': {
        transform: 'rotate(180deg)',
      },
    },

    '& .expandable-row-pane': {
      overflowY: 'auto',
      maxHeight: 'unset',
      marginTop: `-${theme.spacing(0.25)}`,

      '& > div': {
        backgroundColor: brandColors.coolGray1,
        border: `1px solid ${brandColors.coolGray3}`,
        padding: theme.spacing(3),
      },

      '&:last-of-type': {
        '& > div': {
          borderRadius: `0 0 ${theme.spacing(0.25)} ${theme.spacing(0.25)}`,
        },
      },
    },
  },
  rowContent: {
    flexGrow: 1,
  },
  pointer: {},
}))

export const ExpandableRow = memo<IExpandableRowProps>(
  ({
    id = uuid(),
    row,
    details,
    expanded: expandedProp = false,
    onExpand = () => {},
    rowClass = '',
    detailsClass = '',
    expandableRowClassProp = '',
    paneClassProp = '',
  }) => {
    const classes = useExpandableRowStyles()
    const [expanded, setExpanded] = useState(expandedProp)
    const paneRef = createRef<HTMLDivElement>()

    const toggle = useCallback(
      (overrideExpand = undefined) => {
        const newExpandVal = typeof overrideExpand === 'boolean' ? overrideExpand : !expanded
        if (newExpandVal && onExpand) onExpand()
        setExpanded(newExpandVal)
      },
      [expanded, onExpand]
    )

    useEffect(() => {
      setExpanded(expandedProp)
    }, [expandedProp])

    const onRowClickHandler = ({ target }: MouseEvent) => {
      const selectedTarget = target as HTMLElement
      if (!INTERACTIVE_TAGS.includes(selectedTarget.tagName.toLowerCase())) {
        toggle()
      }
    }

    const onKeyDownHandler = ({ key }: KeyboardEvent) => {
      const validKeyPress = !key || HANDLE_KEYS.includes(key.toLowerCase())
      if (validKeyPress) {
        toggle()
      }
    }

    // for expandableRowClass and paneClass, the following names will be applied
    // if the user does not pass an arg for those props
    const expandableRowClass =
      expandableRowClassProp !== ''
        ? expandableRowClassProp
        : classnames(classes.expandableRow, {
            'expandable-row-expanded': expanded,
          })
    const paneClass = classnames(paneClassProp !== '' ? paneClassProp : 'expandable-row-pane')

    return (
      <div className={expandableRowClass}>
        <div
          data-test={`expandable-row-${id}`}
          className={`row-target flex items-center ${rowClass}`}
          onClick={onRowClickHandler}
          onKeyDown={onKeyDownHandler}
          role='button'
          tabIndex={-1}>
          <div className={classes.rowContent}>{row}</div>
          <div
            aria-label='Expand row'
            className={`pointer ${classes.pointer}`}
            data-test={`expandable-toggle-${id}`}>
            <DownArrowIcon />
          </div>
        </div>
        {expanded && (
          <div data-test={`expandable-details-${id}`} className={paneClass} ref={paneRef}>
            <div className={detailsClass}>{details}</div>
          </div>
        )}
      </div>
    )
  }
)

export default ExpandableRow
