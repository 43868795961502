import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { makeStyles, Theme } from '../../core/styles'
import { TIconComponent } from '../../icons/types'
import { Action, IActionProps } from './components/Action'
import { backgroundColorsByVariant, colorsByVariant, INLINE_MESSAGE_VARIANTS } from './constants'

const useInlineMessageStyles = makeStyles<Theme, { type: INLINE_MESSAGE_VARIANTS }>(theme => ({
  inlineMessage: {
    backgroundColor: ({ type }) => backgroundColorsByVariant[type],
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    display: 'inline-flex',
    gap: theme.spacing(1.5),
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: 'solid',
    borderLeftColor: ({ type }) => colorsByVariant[type],
    borderRadius: theme.spacing(0.25),

    '& p': {
      padding: '0',
    },

    '& button, & button:hover, & a, & .icon-container': {
      color: ({ type }) => colorsByVariant[type],
      verticalAlign: 'inherit',
    },
  },
  fullWidth: {
    width: '100%',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

export interface IInlineMessageProps {
  message: string | ReactNode
  type?: INLINE_MESSAGE_VARIANTS
  cta?: IActionProps
  dataTest?: string
  fullWidth?: boolean
  title?: string
  className?: string
  /**
   * Pass a JSX element to act as a prefix
   */
  IconComponent?: TIconComponent
}

export function InlineMessage({
  message,
  type = INLINE_MESSAGE_VARIANTS.INFO,
  cta,
  dataTest = '',
  fullWidth,
  title,
  className,
  IconComponent,
}: IInlineMessageProps) {
  const styles = useInlineMessageStyles({ type })
  const { action, label, type: ctaType, uploadAction, url } = cta || {}
  const classes = classNames(styles.inlineMessage, className, {
    [styles.fullWidth]: fullWidth,
  })
  const showCta = label && (action || uploadAction || url)

  return (
    <div className={classes} data-test={dataTest}>
      {IconComponent && (
        <div className={`icon-container ${styles.iconContainer}`}>
          <IconComponent />
        </div>
      )}
      <div className={styles.content}>
        {title && <div className={styles.title}>{title}</div>}
        <div>{message}</div>
      </div>
      {showCta && (
        <Action
          label={label}
          url={url}
          action={action}
          uploadAction={uploadAction}
          type={ctaType || type}
        />
      )}
    </div>
  )
}

export default InlineMessage
