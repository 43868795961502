import React from 'react'
import { SelectProps as MuiSelectProps } from '../../../../core'
import { IPillSelectOption } from '../../types'

export interface IPillSelectSingleValueLabelProps<Value = any>
  extends Pick<MuiSelectProps<Value>, 'label' | 'value'> {
  options: IPillSelectOption<Value>[]
  /**
   * @default 'PillSelectSingleValueLabel'
   */
  dataTest?: string
}

export function PillSelectSingleValueLabel({
  dataTest = 'PillSelectSingleValueLabel',
  label,
  options,
  value,
}: IPillSelectSingleValueLabelProps) {
  const selectedOption = options.find(({ value: optionValue }) => value === optionValue)
  const { label: selectedOptionLabel } = selectedOption || {}

  return <div data-test={dataTest}>{selectedOptionLabel || label}</div>
}
