import React from 'react'
import classNames from 'classnames'
import { Box, Button, Typography, makeStyles } from '../../../core'

const useFooterStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    boxShadow: `0 -1px 2px rgba(0,0,0,0.15)`,
    height: 'auto',
    zIndex: 3, // z-index of .top-nav
    [theme.breakpoints.up('md')]: {
      height: '72px',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  rightSideActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  messaging: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}))

export interface ActionConfig {
  disabled?: boolean
  onClick: () => void
  buttonText: string
}

interface IFooterProps {
  className?: string
  primaryActionConfig?: ActionConfig
  secondaryActionConfig?: ActionConfig
  tertiaryActionConfig?: ActionConfig
  renderCustomFooter?: () => React.ReactNode
  messaging?: {
    text: string
    subtext: string
  }
}

export function Footer({
  className = '',
  messaging,
  primaryActionConfig,
  renderCustomFooter,
  secondaryActionConfig,
  tertiaryActionConfig,
}: IFooterProps) {
  const classes = useFooterStyles()
  return (
    <Box className={classNames(classes.root, className)} data-test='full-page-overlay-footer'>
      <div>
        {tertiaryActionConfig && (
          <Button
            data-test='tertiary-action'
            onClick={tertiaryActionConfig?.onClick}
            variant='text'
            size='large'>
            {tertiaryActionConfig?.buttonText}
          </Button>
        )}
      </div>
      {renderCustomFooter && (
        <Box mr={3} flexGrow={1}>
          {renderCustomFooter()}
        </Box>
      )}
      <div className={classes.rightSideActions}>
        {!renderCustomFooter && messaging && (
          <div className={classes.messaging}>
            <Typography variant='body2'>{messaging.text}</Typography>
            {messaging?.subtext && (
              <>
                <Typography variant='body2' color='textSecondary'>
                  &nbsp;&middot;&nbsp;
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {messaging?.subtext}
                </Typography>
              </>
            )}
          </div>
        )}
        {secondaryActionConfig && (
          <div>
            <Button
              data-test='secondary-action'
              onClick={secondaryActionConfig?.onClick}
              variant='outlined'
              color='primary'
              size='large'
              disabled={secondaryActionConfig?.disabled}>
              {secondaryActionConfig?.buttonText}
            </Button>
          </div>
        )}
        {primaryActionConfig && (
          <div>
            <Button
              data-test='primary-action'
              onClick={primaryActionConfig?.onClick}
              color='primary'
              size='large'
              disabled={primaryActionConfig?.disabled}>
              {primaryActionConfig?.buttonText}
            </Button>
          </div>
        )}
      </div>
    </Box>
  )
}
