import React from 'react'
import { ICheckboxProps } from './types'

export function CheckboxIndeterminate({ className = '' }: ICheckboxProps) {
  return (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='16' height='16' rx='2' fill='#0072B5' />
      <line x1='4' y1='8' x2='12' y2='8' stroke='white' strokeWidth='2' strokeLinecap='round' />
    </svg>
  )
}
