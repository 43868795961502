import React from 'react'
import { Tooltip } from 'dpl/components'
import { Box, Button, Card, CardContent, CardHeader, Typography } from 'dpl/core'

export interface IDeleteAccountCardProps {
  onClick: () => void
  disabled?: boolean
}

export function DeleteAccountCard({ disabled = false, onClick }: IDeleteAccountCardProps) {
  return (
    <Card>
      <CardHeader title='Delete Account' />
      <CardContent>
        <Typography variant='body2' mb={2.5}>
          Deleting an account is permanent and will result in no longer being able to login and
          access any data.
        </Typography>
        {disabled ? (
          <Tooltip title='Contact Transfix to take this action' placement='top'>
            <Box display='inline-block'>
              <Button
                color='error'
                variant='outlined'
                onClick={onClick}
                data-test='delete-account-button'
                disabled>
                Delete Account
              </Button>
            </Box>
          </Tooltip>
        ) : (
          <Button
            color='error'
            variant='outlined'
            onClick={onClick}
            data-test='delete-account-button'>
            Delete Account
          </Button>
        )}
      </CardContent>
    </Card>
  )
}
