import React, { useMemo } from 'react'
import isEqual from 'lodash/isEqual'
import {
  DataGrid as MuiDataGrid,
  DataGridProps as MuiDataGridProps,
  GridValidRowModel as MuiGridValidRowModel,
  GridSlotsComponent as MuiGridSlotsComponent,
  GridSlotsComponentsProps as MuiGridSlotsComponentsProps,
  Stack as MuiStack,
} from '../../core'
import DownArrowIcon from '../../icons/build/DownArrowIcon'
import UpArrowIcon from '../../icons/build/UpArrowIcon'
import {
  DataGridNoRowsOverlay,
  DataGridColumnMenu,
  IDataGridFiltersProps,
  IDataGridFilters,
  DataGridFilters,
} from './components'
import { IDataGridBulkAction, TDataGridColDef } from './types'
import { getDataGridColumns } from './utils'

export interface IDataGridProps<
  TRowModel extends MuiGridValidRowModel,
  TFiltersModel extends IDataGridFilters = {},
  TSortValue extends string = '',
> extends Pick<
      MuiDataGridProps<TRowModel>,
      | 'apiRef'
      | 'autosizeOnMount'
      | 'autosizeOptions'
      | 'disableColumnMenu'
      | 'getDetailPanelContent'
      | 'getDetailPanelHeight'
      | 'hideFooter'
      | 'hideFooterPagination'
      | 'initialState'
      | 'loading'
      | 'onPaginationModelChange'
      | 'onRowClick'
      | 'onRowSelectionModelChange'
      | 'pageSizeOptions'
      | 'paginationMode'
      | 'paginationModel'
      | 'processRowUpdate'
      | 'rowCount'
      | 'rowReordering'
      | 'rows'
      | 'rowSelectionModel'
    >,
    Pick<
      IDataGridFiltersProps<TFiltersModel, TSortValue>,
      'sortOptions' | 'defaultFilterValues' | 'defaultSortValue' | 'onFilterOrSortChange'
    > {
  /**
   * @description Set of columns to display in DataGrid
   */
  columns: readonly TDataGridColDef<TRowModel>[]
  /**
   * @description The configuration for the filters that will appear above the DataGrid
   */
  filtersConfig?: IDataGridFiltersProps<TFiltersModel, TSortValue>['filtersConfig']
  /**
   * @description The bulk action menu items that will appear in the checkbox header column menu.
   * @example [{
   *  label: 'Sample Action',
   *  icon: UserIcon,
   *  onClick: (selectedRows) => performAction(selectedRows)
   * }]
   */
  bulkActions?: IDataGridBulkAction<TRowModel>[]
  /**
   * @default 'DataGrid'
   */
  dataTest?: string
  /**
   * Limits the number of filters visible in DataGridFiltersHeader
   * @default {6}
   */
  visibleFiltersLimit?: number
  /**
   * noRowsOverlay component to display when there are no rows
   * @default DataGridNoRowsOverlay
   */
  noRowsOverlay?: MuiGridSlotsComponent['noRowsOverlay']
  /**
   * Options for the query params hook
   * @default { updateType: 'replaceIn' }
   */
  queryParamOptions?: IDataGridFiltersProps<TFiltersModel, TSortValue>['queryParamOptions']
}

const DEFAULT_SLOT_PROPS: MuiGridSlotsComponentsProps = {
  loadingOverlay: {
    variant: 'skeleton',
  },
}

function DataGrid<
  TRowModel extends MuiGridValidRowModel,
  TFiltersModel extends IDataGridFilters,
  TSortValue extends string,
>({
  apiRef,
  autosizeOnMount,
  autosizeOptions,
  bulkActions = [],
  columns: columnsProp,
  dataTest = 'DataGrid',
  defaultFilterValues,
  defaultSortValue,
  disableColumnMenu = true,
  filtersConfig,
  getDetailPanelContent,
  getDetailPanelHeight,
  hideFooter,
  hideFooterPagination,
  initialState,
  loading,
  onFilterOrSortChange,
  onPaginationModelChange,
  onRowClick,
  onRowSelectionModelChange,
  pageSizeOptions,
  paginationMode,
  paginationModel,
  processRowUpdate: processRowUpdateProp,
  rowCount,
  rows = [],
  rowSelectionModel,
  rowReordering,
  sortOptions,
  visibleFiltersLimit,
  noRowsOverlay = DataGridNoRowsOverlay,
  queryParamOptions = {
    updateType: 'replaceIn',
  },
}: IDataGridProps<TRowModel, TFiltersModel, TSortValue>) {
  const hasRows = useMemo(() => rows.length > 0, [rows])
  const columns = useMemo(
    () => getDataGridColumns<TRowModel>({ columns: columnsProp, bulkActions }),
    [columnsProp, bulkActions]
  )

  const slotProps: MuiGridSlotsComponentsProps = useMemo(
    () => ({
      ...DEFAULT_SLOT_PROPS,
      columnMenu: { bulkActions },
    }),
    [bulkActions]
  )

  const slots: Partial<MuiGridSlotsComponent> = useMemo(
    () => ({
      columnMenu: DataGridColumnMenu,
      noRowsOverlay,
      detailPanelExpandIcon: props => <DownArrowIcon size='medium' color='coolGray5' {...props} />,
      detailPanelCollapseIcon: props => <UpArrowIcon size='medium' color='coolGray5' {...props} />,
    }),
    [noRowsOverlay]
  )

  const showPagination = (rowCount ?? 0) > (paginationModel?.pageSize ?? 0)

  const processRowUpdateHandler = React.useCallback(
    async (newRow: TRowModel, oldRow: TRowModel) => {
      const hasUpdatedRow = !isEqual(newRow, oldRow)
      if (hasUpdatedRow && processRowUpdateProp) {
        return processRowUpdateProp(newRow, oldRow)
      }
      return newRow
    },
    [processRowUpdateProp]
  )

  const checkboxSelection = bulkActions.length > 0
  const keepNonExistentRowsSelected = checkboxSelection && paginationMode === 'server'

  return (
    <MuiStack gap={2} data-test={dataTest}>
      {filtersConfig && (
        <DataGridFilters
          filtersConfig={filtersConfig}
          defaultFilterValues={defaultFilterValues}
          sortOptions={sortOptions}
          defaultSortValue={defaultSortValue}
          onFilterOrSortChange={onFilterOrSortChange}
          dataTest={`${dataTest}-filters`}
          visibleFiltersLimit={visibleFiltersLimit}
          queryParamOptions={queryParamOptions}
        />
      )}
      <MuiDataGrid
        apiRef={apiRef}
        autosizeOnMount={autosizeOnMount}
        autosizeOptions={autosizeOptions}
        autoHeight
        /**
         * @description Custom CSS variable to set the height of the overlay
         * https://mui.com/x/react-data-grid/layout/#overlay-height
         */
        sx={{ '--DataGrid-overlayHeight': '150px' }}
        checkboxSelection={checkboxSelection}
        columns={columns}
        disableColumnMenu={disableColumnMenu}
        disableColumnSorting
        disableRowSelectionOnClick
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight ?? (() => 'auto')}
        hideFooter={!hasRows || hideFooter}
        hideFooterPagination={!hasRows || hideFooterPagination}
        initialState={initialState}
        loading={loading}
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        processRowUpdate={processRowUpdateHandler}
        rows={rows}
        rowReordering={rowReordering}
        slots={slots}
        slotProps={slotProps}
        getRowHeight={() => 'auto'}
        paginationMode={paginationMode}
        paginationModel={paginationModel}
        pagination={showPagination}
        rowCount={rowCount}
        onRowClick={onRowClick}
        keepNonExistentRowsSelected={keepNonExistentRowsSelected}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
      />
    </MuiStack>
  )
}

export default DataGrid
