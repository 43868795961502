import React, { useCallback, useMemo } from 'react'
import { makeStyles } from 'dpl/core'
import { useBreakpoint } from 'dpl/hooks'
import {
  UserDetailsHeader,
  AppCard,
  OrganizationCard,
  DeleteAccountCard,
  ResendInvitationCard,
  DeleteAccountConfirmationModal,
  IUserProfileInfo,
  IOrganizationInfo,
  IAppInfo,
  ReactivateAccountCard,
} from 'dpl/templates'
import { PageTemplate } from 'dpl/templates/PageTemplate'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { EditProfileDrawer } from '../../components/EditProfileDrawer'
import { useMemberActions } from '../../hooks/useMemberActions'
import { IOrganizationProductModule, IOrganizationTeam } from '../../types/graphqlTypes'
import { PROTECTED_PATHS } from '../constants'

export interface IMemberProfileProps {
  userInfo: IUserProfileInfo & { organizationUserId: string }
  organizationInfo: IOrganizationInfo
  /**
   * Org admins will have a badge icon next to their name and can edit user email
   */
  isOrgAdmin: boolean
  /**
   * Transfix admins can edit user email
   */
  isTransfixAdmin: boolean
  /**
   * if it's current uswer we should disabled some options
   */
  isCurrentUser: boolean
  /**
   * Admin and product modules that the user has access to
   */
  moduleAccess: IAppInfo
  userTeams: Pick<IOrganizationTeam, 'key'>[]
  organizationModules: Pick<IOrganizationProductModule, 'productModule'>[]
}

const useMemberProfileStyles = makeStyles(theme => ({
  profileBody: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '8fr 4fr',
      padding: theme.spacing(3),
      flexDirection: 'row',
    },
  },
  accountCards: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  appCards: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}))

function MemberProfile({
  isCurrentUser,
  isOrgAdmin,
  isTransfixAdmin,
  moduleAccess,
  organizationInfo,
  organizationModules,
  userInfo,
  userTeams,
}: IMemberProfileProps) {
  const { isMobile } = useBreakpoint()
  const { deleteUser, reactivateUser, resendInvitation } = useMemberActions(
    userInfo.organizationUserId
  )
  const classes = useMemberProfileStyles()
  const isAdmin = isTransfixAdmin || isOrgAdmin

  const { createToggleHandler, modalState } = useModalState({
    deleteAccount: false,
    editProfile: false,
  })
  const toggleDeleteAccountConfirmationModal = createToggleHandler('deleteAccount')
  const toggleEditProfilePanel = createToggleHandler('editProfile')

  const deleteAccountHandler = useCallback(() => {
    toggleDeleteAccountConfirmationModal()
    deleteUser()
  }, [deleteUser, toggleDeleteAccountConfirmationModal])

  const AccountCards = useMemo(() => {
    return (
      <>
        {userInfo.status === 'deleted' && <ReactivateAccountCard onClick={reactivateUser} />}
        {userInfo.status === 'invited' && (
          <>
            <ResendInvitationCard onClick={resendInvitation} />
            <DeleteAccountCard
              disabled={isCurrentUser}
              onClick={toggleDeleteAccountConfirmationModal}
            />
          </>
        )}
        {userInfo.status === 'active' && (
          <DeleteAccountCard
            disabled={isCurrentUser}
            onClick={toggleDeleteAccountConfirmationModal}
          />
        )}
      </>
    )
  }, [reactivateUser, resendInvitation, toggleDeleteAccountConfirmationModal, userInfo.status])

  return (
    <PageTemplate
      pageTitle='Member Profile'
      PageBodyTitleProps={{
        pageType: 'subPage',
        actions: [{ label: 'Edit Profile', onClick: toggleEditProfilePanel }],
      }}
      noPadding>
      <UserDetailsHeader userInfo={userInfo} isOrgAdmin={isOrgAdmin} />
      <div className={classes.profileBody}>
        <div className={classes.accountCards}>
          <OrganizationCard
            organizationInfo={organizationInfo}
            profileLink={PROTECTED_PATHS.home}
          />
          {!isMobile && AccountCards}
        </div>
        <div className={classes.appCards}>
          <AppCard {...moduleAccess} />
        </div>
        {isMobile && AccountCards}
      </div>
      <EditProfileDrawer
        open={modalState.editProfile}
        userInfo={userInfo}
        onClose={toggleEditProfilePanel}
        isAdmin={isAdmin}
        userTeams={userTeams}
        organizationModules={organizationModules}
      />
      <DeleteAccountConfirmationModal
        open={modalState.deleteAccount}
        userInfo={userInfo}
        onClose={toggleDeleteAccountConfirmationModal}
        onConfirm={deleteAccountHandler}
      />
    </PageTemplate>
  )
}

export default MemberProfile
