import React, { memo, ReactNode, useCallback } from 'react'
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogProps as IMuiDialogProps,
  useMediaQuery,
  useTheme,
} from '../../../core'
import { IModalFooterProps, ModalFooter } from './ModalFooter'
import { IModalHeaderProps, ModalHeader } from './ModalHeader'

export interface IModalBaseProps
  extends Pick<
      IMuiDialogProps,
      'open' | 'children' | 'className' | 'maxWidth' | 'fullWidth' | 'scroll'
    >,
    Omit<IModalHeaderProps, 'className' | 'title'>,
    IModalFooterProps {
  /**
   * The class name to apply to the modal body
   * @default ''
   */
  bodyClassName?: string
  /**
   * If true, the modal will close when the backdrop is clicked
   * @default false
   */
  closeOnBackdropClick?: boolean
  /**
   * The data-test attribute
   * @default 'ModalBase'
   */
  dataTest?: string
  /**
   * The class name to apply to the modal footer
   */
  footerClassName?: string
  /**
   * The class name to apply to the modal header
   */
  headerClassName?: string
  /**
   * The title of the modal
   * @optional
   */
  title?: ReactNode
}

/**
 * ModalBase should not be used directly.
 * Use ConfirmationModal, FeatureUnavailableModal, or FormModal instead.
 */
export const ModalBase = memo<IModalBaseProps>(
  ({
    actions,
    bodyClassName = '',
    children,
    className,
    closeOnBackdropClick = false,
    dataTest = 'ModalBase',
    footer,
    footerClassName = '',
    fullWidth,
    header,
    headerClassName = '',
    includeCloseIconButton,
    maxWidth,
    onClose,
    open,
    subtitle,
    subtitleVariant,
    title,
    titleVariant,
  }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const handleClose: IMuiDialogProps['onClose'] = useCallback(
      (_, reason) => {
        if (closeOnBackdropClick && reason === 'backdropClick') {
          onClose()
        } else if (reason !== 'backdropClick') {
          onClose()
        }
      },
      [closeOnBackdropClick, onClose]
    )

    return (
      <MuiDialog
        open={open}
        onClose={handleClose}
        data-test={dataTest}
        className={className}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        fullWidth={fullWidth}>
        {title && (
          <ModalHeader
            className={headerClassName}
            dataTest={`${dataTest}-header`}
            header={header}
            includeCloseIconButton={includeCloseIconButton}
            onClose={onClose}
            title={title || ''}
            titleVariant={titleVariant}
            subtitle={subtitle}
            subtitleVariant={subtitleVariant}
          />
        )}
        <MuiDialogContent className={bodyClassName}>{children}</MuiDialogContent>
        <ModalFooter
          actions={actions}
          className={footerClassName}
          dataTest={`${dataTest}-footer`}
          footer={footer}
        />
      </MuiDialog>
    )
  }
)
