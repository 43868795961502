import React from 'react'
import { ICheckboxProps } from './types'

export function CheckboxUnchecked({ className = '', disabled = false }: ICheckboxProps) {
  return disabled ? (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='1'
        y='1'
        width='14'
        height='14'
        rx='1'
        fill='#F3F5F7'
        stroke='#A9B2BD'
        strokeWidth='2'
      />
    </svg>
  ) : (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='1'
        y='1'
        width='14'
        height='14'
        rx='1'
        fill='#F3F5F7'
        stroke='#697684'
        strokeWidth='2'
      />
    </svg>
  )
}
