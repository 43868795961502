import React, { memo, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import PatternFormat, { IPatternFormatProps } from '../PatternFormat'

export interface IPhoneInputProps
  extends Omit<IPatternFormatProps, 'format' | 'type' | 'displayType'> {}

export const PhoneInput = memo<IPhoneInputProps>(
  ({ clearable, id = uuid(), mask = '_', placeholder = '(555) 555-5555', value, ...props }) => {
    const hasValue = useMemo(() => !!value, [value])

    return (
      <PatternFormat
        id={id}
        type='tel'
        displayType='input'
        format='(###) ###-####'
        mask={mask}
        value={value}
        placeholder={placeholder}
        clearable={clearable && hasValue}
        {...props}
      />
    )
  }
)

export default PhoneInput
