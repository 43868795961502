import React from 'react'
import SvgIcon from '../components/SvgIcon'
import { FilterUnselectedSvg } from '../icons'

function FilterUnselectedIcon(props) {
  return (
    <SvgIcon
      className='RogersSvgIcon-FilterUnselected'
      component={FilterUnselectedSvg}
      {...props}
    />
  )
}

export default FilterUnselectedIcon
