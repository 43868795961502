import React, { ChangeEvent, memo } from 'react'
import { makeStyles, Theme } from '../../core'
import { brandColors } from '../../theme/colors'
import FormHelperText from '../FormHelperText'
import { IRadioProps, Radio } from '../Radio'

const useRadioGroupStyles = makeStyles<
  Theme,
  { stacked: boolean; required: boolean; big: boolean }
>(theme => ({
  root: {
    border: 0,
    margin: 0,
    padding: 0,
  },
  legend: {
    border: 0,
    lineHeight: 1.3,
    margin: 0,
    padding: 0,
    textTransform: 'none',
    width: '100%',
    fontSize: '12px',
    '&::after': {
      content: ({ required }) => (required ? '"•"' : ''),
      position: 'relative',
      color: brandColors.error1,
      paddingLeft: '2px',
    },
  },
  options: {
    display: 'flex',
    flexDirection: ({ stacked }) => (stacked ? 'column' : 'row'),
    gap: ({ big, stacked }) => (stacked && big ? theme.spacing(2) : undefined),
    '& > div': {
      maxWidth: ({ stacked }) => (stacked ? '100%' : undefined),
      marginRight: ({ stacked }) => (stacked ? 0 : undefined),
    },
  },
}))

export interface IRadioGroupOption
  extends Omit<IRadioProps, 'onChange' | 'helperText' | 'error' | 'checked'> {}

export interface IRadioGroupProps {
  label?: string
  name?: string
  disabled?: boolean
  /**
   * The value of the currently selected radio option
   */
  checkedValue?: string | number | boolean | null
  options: IRadioGroupOption[]
  stacked?: boolean
  required?: boolean
  error?: boolean
  helperText?: string
  dataTest?: string
  onOptionChange: (event: ChangeEvent<any>, checked: boolean) => void
  /**
   * If true, adds a rounded background to the Radio buttons
   */
  big?: boolean
}

export const RadioGroup = memo<IRadioGroupProps>(
  ({
    label,
    checkedValue = '',
    disabled = false,
    stacked = false,
    required = true,
    error = false,
    options = [],
    name,
    dataTest,
    helperText,
    big = false,
    onOptionChange = () => {},
  }) => {
    const classes = useRadioGroupStyles({ stacked, required, big })

    return (
      <fieldset disabled={disabled} data-test={dataTest} className={classes.root}>
        {label && <legend className={classes.legend}>{label}</legend>}
        <div className={classes.options}>
          {options.map(option => (
            <Radio
              name={name}
              disabled={disabled || option.disabled}
              onChange={onOptionChange}
              id={option.id}
              dataTest={option.dataTest || `${dataTest}-${option.value}`}
              key={option.value}
              value={option.value}
              checked={checkedValue === option.value}
              big={big}
              description={option.description}
              {...option}
            />
          ))}
        </div>
        {helperText && (
          <FormHelperText data-test={`${dataTest}-message`} hasError={error}>
            {helperText}
          </FormHelperText>
        )}
      </fieldset>
    )
  }
)

export default RadioGroup
