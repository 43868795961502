import React, { memo } from 'react'
import { makeStyles } from '../../../../core'
import { ITagProps, TAG_SIZE, Tag } from '../../../Tag'

const useDataGridTagCellStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const DataGridTagCell = memo<ITagProps>(({ size = TAG_SIZE.small, ...rest }) => {
  const classes = useDataGridTagCellStyles()

  return (
    <div className={classes.root} data-test='DataGridTagCell'>
      <Tag size={size} {...rest} />
    </div>
  )
})
