import React, { memo } from 'react'
import { v4 as uuid } from 'uuid'
import NumericFormat, { INumericFormatProps } from '../NumericFormat'

export interface ICurrencyInputProps
  extends Omit<INumericFormatProps, 'type' | 'thousandsSeparator' | 'valueIsNumericString'> {}

/**
 * Re-usable utility function for converting a string value, input through the
 * underlying text input, and returns a number value that can be set in the
 * form's state
 *
 * @example parseFormInput('8,500') // 8500
 * @param {string} fieldValue - Currency amount, i.e. '8,500'
 */
export const parseFormInput = (fieldValue?: string): number | undefined => {
  return fieldValue ? Number.parseFloat(fieldValue.replace(/[^0-9.-]+/g, '')) : undefined
}

export const CurrencyInput = memo<ICurrencyInputProps>(({ id = uuid(), ...props }) => {
  return (
    <NumericFormat
      id={id}
      type='tel'
      displayType='input'
      prefix='$'
      thousandSeparator
      valueIsNumericString
      {...props}
    />
  )
})

export default CurrencyInput
