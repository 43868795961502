import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { TRedirectUrls } from 'auth/b2b/components/types'
import DefaultCompany from 'dpl/assets/DefaultCompany.svg'
import { Spinner } from 'dpl/components/Spinner'
import compact from 'lodash/compact'
import { getAdminModuleRoles, getProductModuleRoles, isOrganizationAdmin } from 'user-profile'
import MemberProfile from './MemberProfile'
import { useMemberProfileQuery } from './graphql/MemberProfile'

const TRANSFIX_ORG_NAME = 'Transfix'

export const AUTH_REDIRECT_URLS: TRedirectUrls = {
  reset_password_redirect_url: `${import.meta.env.VITE_ROOT}/reset-password`,
  sign_in_redirect_url: `${import.meta.env.VITE_ROOT}/authenticate`,
  sign_up_redirect_url: `${import.meta.env.VITE_ROOT}/authenticate`,
}

function MemberProfileContainer() {
  const { userId: organizationUserId } = useParams()

  const { data, loading } = useMemberProfileQuery({
    variables: {
      id: organizationUserId!,
    },
  })
  const { currentSession, organizationUser, productModules: productModulesList } = data || {}
  const { organizationUser: currentOrganizationUser } = currentSession || {}
  const { id: currentOrganizationUserId = '' } = currentOrganizationUser || {}
  const {
    organization,
    organizationTeams = [],
    status: userStatus,
    statusChangedAt,
    user,
  } = organizationUser || {}
  const { emailAddress: email = '', firstName = '', lastName = '', phoneNumber } = user || {}
  const { fullLogo, name: organizationName = '', productModules = [] } = organization || {}
  const { url: image = '' } = fullLogo || {}
  const isOrgAdmin = isOrganizationAdmin(organizationTeams)

  const userInfo = useMemo(() => {
    return {
      firstName,
      lastName,
      fullName: firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName,
      email,
      phoneNumber,
      statusChangedAt: statusChangedAt || '',
      status: userStatus || '',
      organizationUserId: organizationUserId || '',
    }
  }, [email, firstName, lastName, phoneNumber, statusChangedAt, organizationUserId, userStatus])

  const isCurrentUser = currentOrganizationUserId === organizationUserId

  const organizationInfo = useMemo(() => {
    return {
      name: organizationName,
      image: image || DefaultCompany,
    }
  }, [image, organizationName])

  const apps = useMemo(
    () => ({
      adminModuleRoles: getAdminModuleRoles({
        organizationTeams,
        isTransfixUser: organizationName === TRANSFIX_ORG_NAME,
      }),
      productModuleRoles: getProductModuleRoles({
        organizationTeams,
        productModules: compact(
          productModules.map(({ productModule }) =>
            productModulesList?.find(({ key }) => key === productModule)
          )
        ),
      }),
    }),
    [productModules, organizationTeams, productModulesList]
  )

  if (loading) {
    return <Spinner />
  }

  return (
    <MemberProfile
      isTransfixAdmin={isOrgAdmin}
      isOrgAdmin={isOrgAdmin}
      userInfo={userInfo}
      organizationInfo={organizationInfo}
      moduleAccess={apps}
      userTeams={organizationTeams}
      organizationModules={productModules}
      isCurrentUser={isCurrentUser}
    />
  )
}

export default MemberProfileContainer
