export const wilsonFontFamily = [
  '"Nunito Sans"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"avenir next"',
  'avenir',
  'helvetica',
  'helvetica neue',
  'ubuntu',
  'roboto',
  'noto',
  '"segoe ui"',
  'arial',
  'sans-serif',
].join(',')

export const wilsonHeaderFontFamily = [
  'Exo',
  '"Nunito Sans"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"avenir next"',
  'avenir',
  'helvetica',
  'helvetica neue',
  'ubuntu',
  'roboto',
  'noto',
  '"segoe ui"',
  'arial',
  'sans-serif',
].join()
