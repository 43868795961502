import moment from 'moment'
import { TDateFormat } from '../types'

export function getParsedValue<TDate extends TDateFormat>(
  value: TDate | null | undefined
): TDate | null | undefined {
  if (!value) {
    return value
  }

  return moment(value) as TDate
}
