import React, { createContext, PropsWithChildren, useContext } from 'react'
import { IUserPermissions } from './types'
import { IUseUserPermissionsProps, useUserPermissions } from './useUserPermissions'

export interface IPermissionsContextState {
  userPermissions: IUserPermissions
}

export const PermissionsContext = createContext<IPermissionsContextState>({
  userPermissions: {} as IUserPermissions,
})

export interface IPermissionsProviderProps extends IUseUserPermissionsProps {}

export function PermissionsProvider({
  children,
  usePermissionsLazyQuery,
  user,
}: PropsWithChildren<IPermissionsProviderProps>) {
  const userPermissionsState = useUserPermissions({ usePermissionsLazyQuery, user })
  const { hasLoadedPermissions } = userPermissionsState || {}

  return (
    <PermissionsContext.Provider value={userPermissionsState}>
      {hasLoadedPermissions ? children : null}
    </PermissionsContext.Provider>
  )
}

export const usePermissionsContext = () => useContext(PermissionsContext)
