import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'

export const MuiChipThemeOptions: Components['MuiChip'] = {
  variants: [
    {
      props: { variant: 'filled', color: 'default' },
      style: {
        backgroundColor: brandColors.coolGray2,
      },
    },
    {
      props: { variant: 'outlined', color: 'default' },
      style: {
        borderColor: brandColors.coolGray3,
        color: brandColors.coolGray5,
        '& .MuiChip-icon': {
          color: brandColors.coolGray5,
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'secondary' },
      style: {
        borderColor: brandColors.skyBlue2,
        color: brandColors.skyBlue6,
      },
    },
  ],
  styleOverrides: {
    root: {
      padding: BASE_THEME.spacing(0, 1.5),
      '& .MuiChip-deleteIcon': {
        margin: BASE_THEME.spacing(0, 0, 0, 0.5),
      },
    },
    icon: {
      margin: BASE_THEME.spacing(0, 1, 0, 0),
    },
    iconSmall: {
      width: `${BASE_THEME.spacing(2)} !important`,
      height: `${BASE_THEME.spacing(2)} !important`,
    },
    iconMedium: {
      width: `${BASE_THEME.spacing(2.25)} !important`,
      height: `${BASE_THEME.spacing(2.25)} !important`,
    },
    label: {
      fontWeight: 600,
      padding: 0,
    },
    labelSmall: {
      fontSize: '0.75rem',
    },
    labelMedium: {
      fontSize: '1rem',
    },
    sizeMedium: {
      height: '30px',
    },
  },
}
