import React, { memo, useMemo } from 'react'
import { Moment } from 'moment-timezone'
import { makeStyles } from '../../../core'
import { brandColors } from '../../../theme'
import { buildDateTime } from '../utils/buildDatetime'

export interface ITimeSuggestionProps {
  label: string
  anchorDateTime?: Moment
  targetDate?: string
  timeFormat: string
}

const floor = (value: number) => {
  return (value < 0 ? -1 : 1) * Math.floor(Math.abs(value))
}

const useTimeSuggestionStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1.25),
    '&:hover': {
      backgroundColor: brandColors.skyBlue2,
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: brandColors.skyBlue2,
    cursor: 'pointer',
  },
}))

export const TimeSuggestion = memo<ITimeSuggestionProps>(
  ({ anchorDateTime, label, targetDate, timeFormat }) => {
    const classes = useTimeSuggestionStyles()

    const dateTime = useMemo(
      () => (targetDate ? buildDateTime(targetDate, label, timeFormat) : undefined),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [label, targetDate]
    )

    const relativeTime = useMemo(() => {
      const minutesDiff =
        anchorDateTime && dateTime ? dateTime.diff(anchorDateTime, 'm') : undefined
      if (minutesDiff === undefined) return ''

      if (Math.abs(minutesDiff) < 60) return `(${minutesDiff} min)`

      const hoursDiff = minutesDiff / 60
      if (Math.abs(hoursDiff) < 24) {
        return hoursDiff % 1 !== 0
          ? `(${floor(hoursDiff)} hr ${Math.abs(minutesDiff) % 60} min)`
          : `(${hoursDiff} hr)`
      }

      return ''
    }, [anchorDateTime, dateTime])

    return (
      <span data-test={`time-suggestion-${label}`} className={classes.root}>
        {label}&nbsp;{relativeTime}
      </span>
    )
  }
)
