import React, { memo } from 'react'
import {
  formatDateTimeRange,
  formatUppercaseAmPm,
  IFormatDateTimeRangeArgs,
} from './utils/formatDateTimeRange'

export interface IFormattedDateTimeRangeProps extends IFormatDateTimeRangeArgs {
  dataTest?: string
  className?: string
}

export const FormattedDateTimeRange = memo<IFormattedDateTimeRangeProps>(
  ({
    abbreviated,
    className,
    dataTest,
    endAt,
    endAtTimezone,
    format,
    isUppercaseAmPm,
    showTimezone,
    startAt,
    startAtTimezone,
    timeShort,
  }) => {
    const formatted = formatDateTimeRange({
      format,
      startAt,
      endAt,
      startAtTimezone,
      endAtTimezone,
      abbreviated,
      showTimezone,
      timeShort,
    })
    const formattedDateTimeRange = isUppercaseAmPm ? formatUppercaseAmPm(formatted) : formatted

    return (
      <span className={className} data-test={dataTest}>
        {formattedDateTimeRange}
      </span>
    )
  }
)

export default FormattedDateTimeRange
