import React, { ReactNode } from 'react'
import { ListSubheader, PopoverOrigin } from '@mui/material'
import classNames from 'classnames'
import { Box, makeStyles, Menu, MenuItem } from '../../core'
import { useBreakpoint } from '../../hooks'
import { brandColors } from '../../theme/colors'
import { boxShadowLevels } from '../../theme/shadows'

export interface IMenuItem {
  label: string
  disabled?: boolean
  itemComponent: ReactNode
  action: (value?: string) => void
  id?: string
  dataTest?: string
}
export interface IOverflowMenuProps {
  /**
   * Each option should contain at the very least a label and action. Label can
   * either be text or a react component.
   */
  options: IMenuItem[]
  expanded: boolean
  /**
   * This is used as an HTML id and to generate a key prop for each dropdown
   * item.
   */
  dropdownId: string
  dataTest?: string
  /**
   * A component that, when clicked, opens the overflow menu.
   */
  trigger: ReactNode
  onSelectMenuItem?: (optionIdx: number) => void
  /**
   * Callback fired when the overflow menu is closed when navigating away (clickaway, escape key, tab key)
   */
  onClose: () => void
  /*
   * Set to right will position the dropdown part of the overflow component
   * with its right most edge flush with the right side of its container.
   */
  alignment?: 'left' | 'right'
  /**
   * Element that is used to set the position of the menu
   * If null, the menu is not open
   */
  anchorEl: HTMLElement | null
  className?: string
  /**
   * If true, mobile view of overflow menu displays under trigger element (same as in desktop view).
   * @default false
   */
  overrideMobileStyles?: boolean
  /**
   * The element matching the id will be highlighted. If not provided, none will be highlighted.
   */
  selectedOptionId?: string
  /**
   * Adds a label to the menu List
   */
  menuListLabel?: string
}

const useOverflowMenuStyles = makeStyles(theme => ({
  mobileBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `rgba(${brandColors.coolGray5}, 0.5)`,
    // Starting state for animation
    opacity: 0,
    animation: 'fadeIn 150ms ease-out forwards',
  },
  root: {
    position: 'relative',
  },
  menuRoot: {
    '& .MuiMenu-paper': {
      boxShadow: boxShadowLevels.medium,
      border: `1px solid ${brandColors.coolGray1}`,
      borderRadius: '2px',
    },
  },
  mobileMenuRoot: {
    '& .MuiMenu-paper': {
      fontSize: '1rem',
      padding: theme.spacing(2),
      borderRadius: '8px 8px 0 0',
      bottom: 0,
      left: '0 !important',
      right: 0,
      top: 'auto !important',
      position: 'fixed',
      width: '100%',
      animation: 'flyInUp 150ms ease-in-out forwards',
      maxWidth: 'inherit',
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: brandColors.coolGray1,
    },
  },
  label: {
    margin: theme.spacing(2),
    color: brandColors.coolGray5,
    lineHeight: 0,
  },
}))

export function OverflowMenu({
  alignment,
  anchorEl,
  className,
  dataTest = 'dropdown',
  dropdownId,
  expanded,
  menuListLabel,
  onClose,
  onSelectMenuItem,
  options,
  overrideMobileStyles = false,
  selectedOptionId,
  trigger,
}: IOverflowMenuProps) {
  const classes = useOverflowMenuStyles()
  const { isMobile } = useBreakpoint()
  const menuClassnames = classNames(classes.menuRoot, {
    [classes.mobileMenuRoot]: isMobile && !overrideMobileStyles,
  })

  const selectMenuItemHandler = (optionIdx: number) => {
    const action = options?.[optionIdx]?.action
    if (action) action()
    if (onSelectMenuItem) onSelectMenuItem(optionIdx)
  }

  const alignmentProps: { transformOrigin?: PopoverOrigin; anchorOrigin?: PopoverOrigin } =
    alignment === 'right'
      ? {
          transformOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
          },
        }
      : {}

  return (
    <div className={`${className} ${classes.root}`} data-test={dataTest}>
      {trigger}
      {isMobile && expanded && !overrideMobileStyles && (
        <Box className={classes.mobileBackground} onClick={onClose} onKeyDown={onClose} />
      )}
      <Menu
        open={expanded}
        id={dropdownId}
        anchorEl={anchorEl}
        onClose={onClose}
        className={menuClassnames}
        {...alignmentProps}>
        <div>
          {menuListLabel && (
            <ListSubheader className={classes.label} disableGutters>
              {menuListLabel}
            </ListSubheader>
          )}
          {options.map((option, i) => (
            <MenuItem
              data-test={option.dataTest}
              className={classes.menuItem}
              key={`${dropdownId}-${option.label}`}
              id={option.id}
              disabled={option.disabled}
              selected={selectedOptionId ? option.id === selectedOptionId : false}
              onClick={() => selectMenuItemHandler(i)}>
              {option.itemComponent}
            </MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  )
}

export default OverflowMenu
