import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'

/**
 * @name Tab
 * @see https://mui.com/material-ui/api/tab/
 */
export const MuiTabThemeOptions: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      fontWeight: BASE_THEME.typography.fontWeightBold,
      textTransform: 'none',

      '&:hover': {
        color: brandColors.skyBlue6,
      },
    },
    textColorPrimary: {
      color: brandColors.coolGray8,
    },
  },
}
