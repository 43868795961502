import React, { PropsWithChildren } from 'react'
import {
  Link,
  Typography,
  makeStyles,
  Card,
  CardHeaderProps as ICardHeaderProps,
  CardContent,
} from 'dpl/core'
import { brandColors } from 'dpl/theme'
import { AuthenticationCardHeader } from './AuthenticationCardHeader'

const YEAR = new Date().getFullYear()

const useAuthenticationCardStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
    border: 'none',
    width: '100%',
    borderRadius: 0,

    [theme.breakpoints.up('md')]: {
      borderRadius: theme.spacing(1.5),
      maxWidth: 500,
      minWidth: 500,
      border: `1px solid ${theme.palette.divider}`,
    },
  },
  header: {
    padding: theme.spacing(4, 0, 0),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 3, 0),
    },
  },
  content: {
    '&:last-child': {
      padding: theme.spacing(3, 0, 0),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3, 3, 4),
      },
    },
  },
  footer: {
    color: brandColors.coolGray5,
    display: 'flex',
    marginTop: theme.spacing(2),
  },
}))

export interface IAuthenticationCardProps {
  /**
   * The full URL for the terms of service agreement.
   * @example https://transfix.io/terms-of-service
   */
  termsOfServiceUrl?: string
  title?: ICardHeaderProps['title']
  subheader?: ICardHeaderProps['subheader']
  dataTest?: string
  /**
   * Shows Transfix logo in Card Header
   * @default true
   */
  showLogo?: boolean
}

export function AuthenticationCard({
  children,
  dataTest,
  showLogo = true,
  subheader,
  termsOfServiceUrl,
  title,
}: PropsWithChildren<IAuthenticationCardProps>) {
  const classes = useAuthenticationCardStyles()
  const showHeader = showLogo || !!title || !!subheader

  return (
    <Card className={classes.card} data-test={dataTest}>
      {showHeader && (
        <AuthenticationCardHeader
          title={title}
          subheader={subheader}
          showLogo={showLogo}
          className={classes.header}
        />
      )}

      <CardContent className={classes.content}>
        {children}

        {!!termsOfServiceUrl && (
          <div className={classes.footer}>
            <Typography mr={1} variant='body2'>
              @{YEAR} Transfix
            </Typography>

            <Link
              variant='body2'
              href={termsOfServiceUrl}
              rel='noreferrer'
              target='_blank'
              color='inherit'
              underline='always'>
              Terms of Service
            </Link>
          </div>
        )}
      </CardContent>
    </Card>
  )
}
