import React from 'react'
import { Fade, makeStyles, Popper as MuiPopper, PopperProps as MuiPopperProps } from '../../core'

export interface IPopperProps extends MuiPopperProps {
  /**
   * Time in ms before popper is toggled
   * @default 350
   */
  timeout?: number
  dataTest?: string
}

const useStyles = makeStyles(() => ({
  popover: {
    zIndex: 99999,
  },
}))

export function Popper({
  anchorEl,
  children,
  dataTest = 'popper',
  id,
  open,
  timeout = 350,
  ...props
}: IPopperProps) {
  const classes = useStyles()

  return (
    <MuiPopper
      className={classes.popover}
      data-test={dataTest}
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
      {...props}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={timeout}>
          <div>{children}</div>
        </Fade>
      )}
    </MuiPopper>
  )
}

export default Popper
