import BookmarkIcon from 'dpl/icons/build/BookmarkIcon'
import CheckOutlineIcon from 'dpl/icons/build/CheckOutlineIcon'
import EmailIcon from 'dpl/icons/build/EmailIcon'
import InfoIcon from 'dpl/icons/build/InfoIcon'
import { ISupportModalLink } from './types'

export const MODAL_BODY = 'Need help? Here are some ways to get in touch with us.'

export const DEFAULT_LINKS: ISupportModalLink[] = [
  {
    label: 'Submit a support ticket',
    url: 'http://contact.transfix.io',
    description: 'Raise a request through our contact.transfix.io portal',
    icon: BookmarkIcon,
  },

  {
    label: 'Email us: productsupport@transfix.io',
    url: 'mailto:productsupport@transfix.io',
    description: 'We’ll respond as soon as possible',
    icon: EmailIcon,
  },

  {
    label: 'Visit our FAQs',
    url: 'https://support.transfix.io/',
    description: 'Tutorials & answers to FAQs',
    icon: InfoIcon,
  },

  {
    label: 'Check Status Page',
    url: 'https://status.transfix.io/',
    description: 'Get updates on our system status',
    icon: CheckOutlineIcon,
  },
]
