import React, { ReactNode } from 'react'
import classNames from 'classnames'
import {
  FormControl as MuiFormControl,
  FormControlProps as MuiFormControlProps,
  InputLabelProps as MuiInputLabelProps,
  FormHelperTextProps as MuiFormHelperTextProps,
  InputLabel,
  FormHelperText,
  makeStyles,
  Theme,
} from '../../core'
import { brandColors } from '../../theme'
import { LabelComponent } from '../LabelComponent'

/**
 * We're overriding FormControl styles to
 * - put the radio buttons border red when there's an error (this is available in latest MUI version as a variation)
 */
const useStyles = makeStyles<Theme, { hasError: boolean }>(() => ({
  overrideFormControl: {
    '& .MuiFormControlLabel-root .MuiButtonBase-root': {
      color: ({ hasError }) => hasError && brandColors.error,
    },
  },
  overrideFormHelperText: {
    '&': {
      display: 'inline-block',
    },
  },
}))

interface IFormControlProps extends MuiFormControlProps {
  /**
   * @example 'First name'
   */
  label?: ReactNode
  /**
   * @example 'This field is required'
   */
  validationMessage?: ReactNode
  /**
   * @example 'Enter a valid email'
   */
  helperMessage?: string
  InputLabelProps?: MuiInputLabelProps
  FormHelperTextProps?: MuiFormHelperTextProps
  className?: string
  children: ReactNode
}

/**
 * @name FormControl
 * This handles the validation state, message, and label for input elements.
 * Combines a few elements (label, helper text) for convenience.
 */
export function FormControl({
  children,
  label: labelProp,
  validationMessage,
  helperMessage,
  InputLabelProps = {},
  FormHelperTextProps = {},
  className: classNameProp,
  ...FormControlProps
}: IFormControlProps) {
  const classes = useStyles({ hasError: Boolean(FormControlProps.error) })
  const formControlClassName = classNames(classNameProp, classes.overrideFormControl)

  return (
    <MuiFormControl className={formControlClassName} {...FormControlProps}>
      {labelProp && (
        <InputLabel shrink {...InputLabelProps} data-test='input-label'>
          <LabelComponent required={FormControlProps.required} label={labelProp} />
        </InputLabel>
      )}
      {children}
      {(validationMessage || helperMessage) && (
        <div data-test='form-helper-text'>
          <FormHelperText className={classes.overrideFormHelperText} {...FormHelperTextProps}>
            {validationMessage || helperMessage}
          </FormHelperText>
        </div>
      )}
    </MuiFormControl>
  )
}

export default FormControl
