import {
  withStyles as muiWithStyles,
  Styles,
  WithStylesOptions,
  WithStyles,
  StyledComponentProps,
  ThemeOfStyles,
  ClassKeyOfStyles,
  PropsOfStyles,
} from '@mui/styles'
import { PropInjector } from '@mui/types'

/**
 * Thin abstraction for MUI's withStyles
 * @see https://v4.mui.com/styles/api/#withstyles-styles-options-higher-order-component
 */
function withStyles<
  StylesType extends Styles<any, any>,
  Options extends WithStylesOptions<ThemeOfStyles<StylesType>> = {},
>(
  style: StylesType,
  options?: Options
): PropInjector<
  WithStyles<StylesType, Options['withTheme']>,
  StyledComponentProps<ClassKeyOfStyles<StylesType>> & PropsOfStyles<StylesType>
> {
  return muiWithStyles(style, options)
}

export default withStyles
