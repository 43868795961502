import { Components } from '@mui/material/styles'
import { BASE_THEME } from '../baseTheme'
import { brandColors } from '../colors'

/**
 * @name Fab
 * @see https://mui.com/material-ui/api/fab/
 */
export const MuiFabThemeOptions: Components['MuiFab'] = {
  styleOverrides: {
    root: {
      position: 'fixed',
      bottom: BASE_THEME.spacing(3), // 24px
      right: BASE_THEME.spacing(3), // 24px
      zIndex: BASE_THEME.zIndex.modal + 1,

      // Sets icon height/width default
      '& .icon': {
        height: '20px',
        width: '20px',
      },
    },
    primary: {
      backgroundColor: brandColors.skyBlue6,
      borderColor: brandColors.skyBlue6,
      color: brandColors.coolGray1,
      transition: 'none',

      '&:hover, &:focus, &:active': {
        backgroundColor: brandColors.skyBlue7,
        borderColor: brandColors.skyBlue7,
        color: brandColors.coolGray1,
        transition: 'none',
      },
    },
    secondary: {
      backgroundColor: brandColors.white,
      borderColor: brandColors.white,
      color: brandColors.skyBlue7,
      transition: 'none',

      '&:hover, &:focus, &:active': {
        backgroundColor: brandColors.skyBlue2,
        borderColor: brandColors.skyBlue2,
        color: brandColors.skyBlue6,
        transition: 'none',
      },
    },
  },
}
