import React, { PropsWithChildren, useCallback, ReactNode, useMemo, useState } from 'react'
import { makeStyles, Theme } from '../../core'
import { useBreakpointContext } from '../../providers/BreakpointProvider'
import { brandColors } from '../../theme'
import { AppBar, IAppBarProps } from './AppBar'
import { MobileNav } from './MobileNav'
import { INavProps } from './Nav'
import { NavDrawer } from './NavDrawer'
import {
  APP_BAR_HEIGHT,
  BANNER_HEIGHT_DESKTOP,
  BANNER_HEIGHT_MOBILE,
  NAV_DRAWER_WIDTH_COLLAPSED,
  NAV_DRAWER_WIDTH_EXPANDED,
  ORG_NAV_ITEM_HEIGHT,
} from './constants'

const useAppLayoutStyles = makeStyles<
  Theme,
  { isNavDrawerOpen: boolean; showNavDrawer: boolean; showBanner: boolean }
>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: brandColors.coolGray8,
    height: '100vh',
    width: '100vw',
  },
  main: {
    background: brandColors.coolGray8,
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',

    [theme.breakpoints.up('md')]: {
      marginTop: ({ showBanner, showNavDrawer }) =>
        showBanner && showNavDrawer ? theme.spacing(1.5) : 0,
      marginRight: ({ showNavDrawer }) => (showNavDrawer ? theme.spacing(1.5) : 0),
      marginBottom: ({ showNavDrawer }) => (showNavDrawer ? theme.spacing(1.5) : 0),
      marginLeft: ({ isNavDrawerOpen, showNavDrawer }) => {
        if (!showNavDrawer) return 0
        return `${isNavDrawerOpen ? NAV_DRAWER_WIDTH_EXPANDED : NAV_DRAWER_WIDTH_COLLAPSED}px`
      },
      borderRadius: ({ showNavDrawer }) => (showNavDrawer ? theme.spacing(1.5) : 0),
    },
  },
  contentContainer: {
    background: brandColors.white,
    height: '100%',
    overflow: 'auto',
    width: '100%',
    position: 'relative',
  },
  drawer: {
    top: ({ showBanner }) => (showBanner ? APP_BAR_HEIGHT + BANNER_HEIGHT_MOBILE : APP_BAR_HEIGHT),
    bottom: ORG_NAV_ITEM_HEIGHT,
    [theme.breakpoints.up('md')]: {
      top: ({ showBanner }) =>
        showBanner ? APP_BAR_HEIGHT + BANNER_HEIGHT_DESKTOP + 12 : APP_BAR_HEIGHT,
    },
  },
}))

export interface IAppLayoutProps {
  /**
   * Used to render user avatar and menu in AppBar
   */
  userInfo?: IAppBarProps['userInfo']
  /**
   * Navigation items that display in the sidebar
   * @default []
   */
  navLists?: INavProps['navLists']
  /**
   * @default false
   */
  isOrgNavButtonEnabled?: INavProps['isOrgNavButtonEnabled']
  /**
   * @default undefined
   */
  orgNavButtonProps?: INavProps['orgNavButtonProps']
  /**
   * @default undefined
   */
  orgNavItem?: INavProps['orgNavItem']
  /**
   * Banner component shown under `<AppBar />`
   */
  BannerComponent?: ReactNode
  /**
   * Used to show/hide Banner
   */
  showBanner?: boolean
  /**
   * Callback that handles logging out from User Menu
   */
  onLogout: IAppBarProps['onLogout']
  /**
   * Function that triggers when user clicks on admin console link
   */
  onClickAdminConsoleLink?: INavProps['onClickAdminConsoleLink']
  /**
   * disables AppBarLink
   */
  disableAppBarLink?: boolean
}

export function AppLayout({
  children,
  disableAppBarLink,
  userInfo,
  navLists = [],
  isOrgNavButtonEnabled = false,
  orgNavButtonProps = undefined,
  orgNavItem = undefined,
  showBanner = false,
  BannerComponent,
  onClickAdminConsoleLink,
  onLogout,
}: PropsWithChildren<IAppLayoutProps>) {
  const { isMobile } = useBreakpointContext()
  const showNav = useMemo(() => navLists.length > 0, [navLists])

  /**
   * Nav should be open initially on desktop and closed initially on mobile
   */
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState<boolean>(true)
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false)

  const classes = useAppLayoutStyles({
    showNavDrawer: showNav,
    isNavDrawerOpen,
    showBanner,
  })

  const menuToggleHandler = useCallback(() => {
    if (isMobile) {
      setIsMobileNavOpen(prevState => !prevState)
    } else {
      setIsNavDrawerOpen(prevState => !prevState)
    }
  }, [isMobile])

  const closeMobileNavHandler = useCallback(() => {
    setIsMobileNavOpen(false)
  }, [])

  return (
    <div className={classes.root}>
      <AppBar
        onMenuClick={menuToggleHandler}
        userInfo={userInfo}
        disableMenuButton={!showNav}
        onLogout={onLogout}
        disableAppBarLink={disableAppBarLink}
      />
      {showBanner && <div>{BannerComponent}</div>}

      {showNav && (
        <>
          {!isMobile && (
            <NavDrawer
              isOpen={isNavDrawerOpen}
              isOrgNavButtonEnabled={isOrgNavButtonEnabled}
              orgNavButtonProps={orgNavButtonProps}
              navLists={navLists}
              orgNavItem={orgNavItem}
              PaperProps={{ className: classes.drawer }}
              onClickAdminConsoleLink={onClickAdminConsoleLink}
            />
          )}

          {isMobile && (
            <MobileNav
              isOpen={isMobileNavOpen}
              navLists={navLists}
              isOrgNavButtonEnabled={isOrgNavButtonEnabled}
              orgNavButtonProps={orgNavButtonProps}
              orgNavItem={orgNavItem}
              onClickAdminConsoleLink={onClickAdminConsoleLink}
              onClose={closeMobileNavHandler}
            />
          )}
        </>
      )}

      <div className={classes.main}>
        <div className={classes.contentContainer}>{children}</div>
      </div>
    </div>
  )
}
