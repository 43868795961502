import React from 'react'
import { createGraphiQLFetcher } from '@graphiql/toolkit'
import GraphiQLComponent from 'graphiql'
import 'graphiql/graphiql.css'

const fetcher = createGraphiQLFetcher({
  url: import.meta.env.VITE_GRAPHQL_API,
})

export function GraphiQL() {
  return (
    <div style={{ height: '100vh' }}>
      <GraphiQLComponent fetcher={fetcher} editorTheme='dracula' />
    </div>
  )
}
