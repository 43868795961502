import React, { memo } from 'react'
import { IconButton, TIconButtonProps } from '../../../components/IconButton'
import { Tooltip, ITooltipProps } from '../../../components/Tooltip'
import { IconButtonProps as MuiIconButtonProps } from '../../../core'

export interface IAppBarLinkProps {
  /**
   * Props for IconButton constrained to anchor elements
   */
  IconButtonProps: TIconButtonProps & Omit<MuiIconButtonProps<'a'>, 'color' | 'size'>
  /**
   * Props for Tooltip
   */
  TooltipProps?: Omit<ITooltipProps, 'title' | 'children'>
  /**
   * If tooltipText is not provided, then tooltip is not rendered
   */
  tooltipText?: string
  /**
   * disable icon redirecting to home
   * @default false
   */
  isDisabled?: boolean
}

export const AppBarLink = memo<IAppBarLinkProps>(
  ({
    IconButtonProps,
    isDisabled = false,
    tooltipText,
    TooltipProps = { color: 'light', placement: 'right' },
  }) => {
    return (
      <Tooltip title={tooltipText} {...TooltipProps}>
        <IconButton size='large' noPadding {...IconButtonProps} disabled={isDisabled} />
      </Tooltip>
    )
  }
)
