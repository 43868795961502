import { DefaultValues, useForm, UseFormOptions, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditProfileFormSchema, TEditProfileFormSchema } from './EditProfileFormSchema'

export const getDefaultValues = <TFieldValues>(values: DefaultValues<TFieldValues>) => {
  return EditProfileFormSchema.cast({
    ...values,
  })
}

interface IUseEditProfileFormProps
  extends Omit<UseFormOptions<TEditProfileFormSchema>, 'resolver'> {}

export const useEditProfileForm = ({ defaultValues = {}, ...props }: IUseEditProfileFormProps) => {
  return useForm<TEditProfileFormSchema>({
    resolver: yupResolver(EditProfileFormSchema),
    defaultValues: getDefaultValues(defaultValues),
    ...props,
  })
}

export const useEditProfileFormContext = () => useFormContext<TEditProfileFormSchema>()
