import { TIME_INPUT_FORMAT } from '../types'

/**
 * Returns a formatted string, i.e. '10:30' or '04:21'
 */
export const formatTime = (
  timeFormat: TIME_INPUT_FORMAT,
  hours: string,
  minutes: string = '0'
): string => {
  const maxHourValue = timeFormat === TIME_INPUT_FORMAT.TWELVE_HR_SHORT ? 12 : 23
  const hourValue = parseInt(hours || `${maxHourValue}`, 10)

  const militaryHour = hourValue < 10 ? `0${hourValue}` : hourValue
  const hourFormatted = timeFormat === TIME_INPUT_FORMAT.TWELVE_HR_SHORT ? hourValue : militaryHour
  const minutesNumber = parseInt(minutes || '0', 10)

  return `${hourFormatted}:${minutesNumber > 9 ? '' : '0'}${minutesNumber}`
}
