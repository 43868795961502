import React, { memo, ReactNode } from 'react'
import classNames from 'classnames'
import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from '../../core'
import { makeStyles } from '../../core/styles'
import UserIcon from '../../icons/build/UserIcon'
import { calcRem } from '../../theme'
import { avatarColors, brandColors } from '../../theme/colors'
import { ITooltipProps, Tooltip } from '../Tooltip'
import { getInitials, getColorIndex } from './utils'

enum AvatarSize {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  input = 'input',
  xlarge = 'xlarge',
}

export interface IAvatarProps extends Omit<MuiAvatarProps<'div', {}>, 'sx'> {
  name?: string
  dataTest?: string
  size?: keyof typeof AvatarSize
  // Tooltip Related props
  tooltipProps?: Partial<ITooltipProps>
  tooltipClassName?: string
  defaultVal?: string
  hideTooltip?: boolean
  tooltip?: JSX.Element
  tooltipName?: string
  details?: string
  /**
   * Overrides the user icon it shows when there is not avatarName
   * @default <UserIcon color='coolGray8' />
   */
  Icon?: ReactNode
}

const useAvatarStyles = makeStyles(theme => ({
  wrapper: {
    display: 'inline-flex',
    position: 'relative',
  },
  avatar: {
    // Colors
    '&.avatar-color-default': {
      backgroundColor: brandColors.coolGray2,
    },
    '&.avatar-color-0': { backgroundColor: avatarColors.orange },
    '&.avatar-color-1': { backgroundColor: avatarColors.salmonRed },
    '&.avatar-color-2': { backgroundColor: avatarColors.roseRed },
    '&.avatar-color-3': { backgroundColor: avatarColors.bubblegumPink },
    '&.avatar-color-4': { backgroundColor: avatarColors.plumPurple },
    '&.avatar-color-5': { backgroundColor: avatarColors.lavenderPurple },
    '&.avatar-color-6': { backgroundColor: avatarColors.steelBlue },
    '&.avatar-color-7': { backgroundColor: avatarColors.iceBlue },
    '&.avatar-color-8': { backgroundColor: avatarColors.spruceGreen },
    '&.avatar-color-9': { backgroundColor: avatarColors.artichokeGreen },
  },
  // Unassigned
  unassigned: {
    color: brandColors.coolGray8,
  },
  // Sizes
  xsmall: {
    fontSize: '0.875rem',
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  small: {
    fontSize: '0.875rem',
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  medium: {
    fontSize: '1rem',
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  large: {
    fontSize: '1.15rem',
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
  },
  input: {
    boxShadow: 'none',
    fontSize: '0.75rem',
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
  },
  xlarge: {
    ...theme.typography.h2,
    fontSize: calcRem(34),
    letterSpacing: calcRem(0.25),
    width: theme.spacing(11.875),
    height: theme.spacing(11.875),
  },
}))

export const Avatar = memo<IAvatarProps>(
  ({
    className,
    dataTest = '',
    defaultVal,
    details,
    hideTooltip,
    name,
    size = 'small',
    tooltip,
    tooltipClassName,
    tooltipName,
    tooltipProps,
    Icon = <UserIcon color='coolGray8' />,
    ...props
  }) => {
    const styles = useAvatarStyles()
    const avatarName = name?.toLowerCase() !== 'unassigned' ? name : undefined
    const avatarColor = `avatar-color-${getColorIndex(avatarName)}`
    const classes = classNames(className, styles.avatar, styles[size], avatarColor)

    const avatar = (
      <MuiAvatar className={classes} data-test={dataTest} {...props}>
        {avatarName ? (
          <span>
            {size === AvatarSize.xsmall || size === AvatarSize.input
              ? avatarName[0]
              : getInitials(avatarName)}
          </span>
        ) : (
          Icon
        )}
      </MuiAvatar>
    )

    const { color, enterDelay, followCursor, placement = 'bottom' } = tooltipProps || {}

    const tooltipContent = (
      <div className={tooltipClassName}>
        {tooltip || (
          <>
            <strong>{tooltipName ?? name ?? defaultVal ?? ''}</strong>
            {details && <div>{details}</div>}
          </>
        )}
      </div>
    )

    if (hideTooltip) {
      return avatar
    }

    return (
      <Tooltip
        placement={placement}
        color={color}
        followCursor={followCursor}
        enterDelay={enterDelay}
        title={tooltipContent}
        className={styles.wrapper}
        disabled={hideTooltip}>
        {avatar}
      </Tooltip>
    )
  }
)

export default Avatar
