import React, { memo, PropsWithChildren, useMemo } from 'react'
import { AccordionDetails, AccordionSummary, Accordion, Typography } from '../../../../../../core'
import DownArrowIcon from '../../../../../../icons/build/DownArrowIcon'
import { IDataGridMultiSelectFilterConfig, IDataGridSingleSelectFilterConfig } from '../../types'

export interface IDataGridFiltersMenuAccordionProps {
  /**
   * Configuration object for the filter
   */
  filterConfig: IDataGridSingleSelectFilterConfig | IDataGridMultiSelectFilterConfig
  /**
   * The current value of the filter
   */
  filterValue: any
  /**
   * @default 'DataGridFiltersMenuAccordion'
   */
  dataTest?: string
}

export const DataGridFiltersMenuAccordion = memo<
  PropsWithChildren<IDataGridFiltersMenuAccordionProps>
>(({ children, dataTest = 'DataGridFiltersMenuAccordion', filterConfig, filterValue }) => {
  const { label, options } = filterConfig || {}

  const valueLength = useMemo(() => {
    return Array.isArray(filterValue) ? filterValue.length : 0
  }, [filterValue])

  const selectedValuesLabel = useMemo(() => {
    if (!valueLength && Array.isArray(filterValue)) {
      return ''
    }

    if (!Array.isArray(filterValue)) {
      return options.find(option => option.value === filterValue)?.label || ''
    }

    return options
      .filter(option => filterValue.includes(option.value))
      .map(option => option.label)
      .join(', ')
  }, [filterValue, options, valueLength])

  return (
    <Accordion data-test={dataTest}>
      <AccordionSummary expandIcon={<DownArrowIcon size='large' color='coolGray8' />}>
        <div>
          <Typography variant='subtitle1' component='div' data-test={`${dataTest}-title`}>
            {label}{' '}
            {valueLength > 0 && <span data-test='filter-value-length'>({valueLength})</span>}
          </Typography>
          {selectedValuesLabel && (
            <Typography
              variant='body2'
              color='textSecondary'
              data-test={`${dataTest}-subtitle`}
              component='div'>
              {selectedValuesLabel}
            </Typography>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
})
