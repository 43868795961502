import React, { memo } from 'react'
import { Box } from '@mui/system'
import { IconButton } from '../../../../../components/IconButton'
import { Divider, Drawer, makeStyles, Popover, PopoverProps } from '../../../../../core'
import CloseIcon from '../../../../../icons/build/CloseIcon'
import { useBreakpointContext } from '../../../../../providers/BreakpointProvider'
import { brandColors } from '../../../../../theme/colors'
import { IOrgNavMenuContentsProps, OrgNavMenuContents } from '../OrgNavMenuContents'

export interface IOrgNavMenuProps
  extends Pick<PopoverProps, 'anchorEl' | 'id' | 'open'>,
    Omit<IOrgNavMenuContentsProps, 'dataTest' | 'onLinkClick'> {
  /**
   * @default 'OrgNavMenu'
   */
  dataTest?: string
  /**
   * The handler for closing the menu
   */
  onClose: () => void
}

const useOrgNavMenuStyles = makeStyles(theme => ({
  root: {
    width: 275,
  },
  divider: {
    borderColor: brandColors.coolGray2,
  },
  drawer: {
    zIndex: 1400,
    '& .MuiDrawer-paperAnchorBottom': {
      borderTopLeftRadius: theme.spacing(2),
      borderTopRightRadius: theme.spacing(2),
    },
  },
}))

export const OrgNavMenu = memo<IOrgNavMenuProps>(
  ({
    anchorEl,
    dataTest = 'OrgNavMenu',
    id,
    isAdmin,
    onClose,
    open,
    orgAdminHref,
    orgImgSrc,
    orgName,
    orgNavMenuLinks = [],
    orgProfileHref,
  }) => {
    const { isMobile } = useBreakpointContext()
    const classes = useOrgNavMenuStyles()

    const Contents = (
      <OrgNavMenuContents
        isAdmin={isAdmin}
        orgAdminHref={orgAdminHref}
        orgImgSrc={orgImgSrc}
        orgName={orgName}
        orgNavMenuLinks={orgNavMenuLinks}
        orgProfileHref={orgProfileHref}
        onLinkClick={onClose}
      />
    )

    if (isMobile) {
      return (
        <Drawer
          data-test={`${dataTest}-drawer`}
          anchor='bottom'
          open={open}
          onClose={onClose}
          className={classes.drawer}>
          <Box display='flex' justifyContent='flex-end' padding={0.75} marginY={0.5}>
            <IconButton
              Icon={CloseIcon}
              onClick={onClose}
              label='Close Modal'
              size='large'
              dataTest={`${dataTest}-close-button`}
              color='secondary'
            />
          </Box>
          <Divider className={classes.divider} />
          {Contents}
        </Drawer>
      )
    }

    return (
      <Popover
        data-test={`${dataTest}-popover`}
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            className: classes.root,
          },
        }}
        id={id}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        {Contents}
      </Popover>
    )
  }
)
